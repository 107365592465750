<template>
  <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Product(s) Configuration</h2>
    </div>
    <div class="mt-5">
      <!-- BEGIN: Event Shirt Config Content -->
      <div class="config-content">
        <div class="validate-form">
          <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12">
              <div class="intro-y box mt-2 p-6">
                <div>
                  <h2 class="col-span-12 text-base font-medium mb-4">
                    Products
                  </h2>
                  <button
                    v-if="!showProductForm"
                    class="btn btn-outline-primary mb-6"
                    type="button"
                    @click="toggleNewProductForm()"
                  >
                    Add New Product
                  </button>
                  <div
                    v-if="showProductForm"
                    class="grid grid-cols-12 gap-6 border border-solid border-gray-200 p-6 mb-6"
                  >
                    <div class="col-span-12">
                      <h3 class="text-base font-medium">
                        Add New Product
                      </h3>
                      <p class="italic">
                        Create a new product.
                      </p>
                    </div>
                    <div class="col-span-12 lg:col-span-6">
                      <label
                        for="product_name"
                        class="form-label placeholder-label relative w-full mt-3 text-base"
                      >
                        <input
                          id="product_name"
                          v-model="newProduct.name"
                          type="text"
                          class="form-control p-3"
                          placeholder=" "
                          required
                        />
                        <span
                          class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                          >Product Name</span
                        >
                      </label>
                    </div>
                    <div class="col-span-12 lg:col-span-6">
                      <label
                        for="product_price"
                        class="form-label placeholder-label relative w-full mt-3 text-base"
                      >
                        <input
                          id="product_price"
                          v-model.number="newProduct.price"
                          name="productPrice"
                          type="number"
                          min="5"
                          step="1"
                          class="form-control p-3"
                          placeholder=" "
                        />
                        <span
                          class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                          >Product Price</span
                        >
                      </label>
                    </div>
                    <div class="col-span-12 lg:col-span-6">
                      <div v-for="(size, index) in sizeOptions" :key="index">
                        <div class="py-1 text-base">
                          <input
                            v-model="newProduct.sizes"
                            type="checkbox"
                            :value="size"
                          />
                          <label class="ml-1" for="product_sizes">{{
                            size
                          }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-span-12 lg:col-span-3 mt-6">
                      <button
                        v-if="!newProduct.image"
                        class="btn btn-outline"
                        type="button"
                        @click="initializeProductImageUploadWidget()"
                      >
                        <UploadIcon class="w-4 h-4 mr-2" /> Add Product Image
                      </button>
                      <img
                        v-if="newProduct.image"
                        class="w-40"
                        :src="newProduct.image"
                      />
                    </div>
                    <div
                      v-if="
                        newProduct.name && newProduct.price && newProduct.image
                      "
                      class="col-span-12"
                    >
                      <button
                        class="btn btn-outline-primary"
                        type="button"
                        @click="addProduct()"
                      >
                        Add Product
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="showProductEditForm"
                    class="grid grid-cols-12 gap-6 border border-solid border-gray-200 p-6 mb-6"
                  >
                    <div class="col-span-12">
                      <h3 class="text-base font-medium">
                        Edit {{ eventSettings.products[editProductIndex].name }}
                      </h3>
                      <p class="italic">
                        Edit product.
                      </p>
                    </div>
                    <div class="col-span-12 lg:col-span-5">
                      <label
                        for="product_name"
                        class="form-label placeholder-label relative w-full mt-3 text-base"
                      >
                        <input
                          id="product_name"
                          v-model="
                            eventSettings.products[editProductIndex].name
                          "
                          type="text"
                          class="form-control p-3"
                          placeholder=" "
                          required
                        />
                        <span
                          class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                          >Product Name</span
                        >
                      </label>
                    </div>
                    <div class="col-span-12 lg:col-span-5">
                      <label
                        for="product_price"
                        class="form-label placeholder-label relative w-full mt-3 text-base"
                      >
                        <input
                          id="product_price"
                          v-model.number="
                            eventSettings.products[editProductIndex].price
                          "
                          name="productPrice"
                          type="number"
                          min="5"
                          step="1"
                          class="form-control p-3"
                          placeholder=" "
                        />
                        <span
                          class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                          >Product Price</span
                        >
                      </label>
                    </div>
                    <div class="col-span-12 lg:col-span-2 mt-6">
                      <div
                        class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
                      >
                        <input
                          id="product-active"
                          v-model.trim="
                            eventSettings.products[editProductIndex].active
                          "
                          class="form-check-switch mr-0 ml-0"
                          type="checkbox"
                          :checked="
                            eventSettings.products[editProductIndex].active
                          "
                        />
                        <label
                          class="form-check-label font-medium"
                          for="user-active"
                          ><span
                            v-show="
                              eventSettings.products[editProductIndex].active
                            "
                          >
                            Active
                          </span>
                          <span
                            v-show="
                              !eventSettings.products[editProductIndex].active
                            "
                          >
                            Inactive
                          </span></label
                        >
                      </div>
                    </div>
                    <div class="col-span-12 lg:col-span-6">
                      <div v-for="(size, index) in sizeOptions" :key="index">
                        <div class="py-1 text-base">
                          <input
                            v-model="
                              eventSettings.products[editProductIndex].sizes
                            "
                            type="checkbox"
                            :value="size"
                          />
                          <label class="ml-1" for="product_sizes">{{
                            size
                          }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-span-12 lg:col-span-3 mt-6">
                      <button
                        class="btn btn-outline"
                        type="button"
                        @click="
                          initializeProductImageUploadWidget(
                            'edit',
                            editProductIndex
                          )
                        "
                      >
                        <UploadIcon class="w-4 h-4 mr-2" /> Change Product Image
                      </button>
                      <img
                        v-if="eventSettings.products[editProductIndex].image"
                        class="w-40"
                        :src="eventSettings.products[editProductIndex].image"
                      />
                    </div>
                  </div>
                </div>
                <form
                  v-if="showProductForm || showProductEditForm"
                  class="validate-form"
                  @submit.prevent="saveEventSettings"
                >
                  <div class="flex justify-center mt-8 mb-12">
                    <button
                      type="submit"
                      class="btn btn-lg btn-primary w-20 mr-6"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      class="btn btn-lg btn-outline w-20"
                      @click="cancel()"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
                <h2 class="col-span-12 text-base font-medium">
                  Current Products
                </h2>
                <div
                  v-if="eventSettings && eventSettings.products"
                  class="mt-2"
                >
                  <div>
                    <div class="grid grid-cols-12 gap-6 mb-2">
                      <div
                        v-for="(product, index) in eventSettings.products"
                        :key="index"
                        class="col-span-3 border border-solid border-gray-400 p-3"
                      >
                        <div>
                          <img
                            v-if="product.image"
                            class="w-100"
                            :src="product.image"
                          />
                        </div>
                        <div class="mt-3">
                          <span class="block font-medium text-base">{{
                            product.name
                          }}</span>
                          <span>${{ product.price }}</span>
                        </div>
                        <div v-if="product.isGridded" class="mt-3">
                          <span class="block">{{ product.sizes }}</span>
                        </div>
                        <div v-if="product.active" class="mt-3">
                          <span class="block font-bold">Active</span>
                        </div>
                        <div v-if="!product.active" class="mt-3">
                          <span class="block font-bold">InActive</span>
                        </div>
                        <div class="mt-3 text-center">
                          <button
                            class="btn block mx-auto mb-6 btn-outline mt-1 w-40"
                            type="button"
                            @click="editProduct(index)"
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="eventSettings && eventSettings.eventProducts">
                  <h4 v-if="eventSettings.eventProducts.length === 0">
                    No products have been added.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form class="validate-form" @submit.prevent="saveEventSettings">
          <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12">
              <div class="intro-y box mt-2 p-6">
                <EventProductSettings
                  v-if="eventSettings && eventSettings.eventShirtSettings"
                  v-model:hasEventShirt="eventSettings.hasEventShirt"
                  :has-event-shirt="eventSettings.hasEventShirt"
                />
                <div class="flex justify-end mt-8">
                  <button type="submit" class="btn btn-primary w-20 mr-auto">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- END: Event Shirt Config Content -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "@/store";
import { updateEventSettings } from "@/api/event-settings";
import EventProductSettings from "@/components/form-inputs/EventShirtSettings";

export default defineComponent({
  components: {
    EventProductSettings
  },
  setup() {
    const validate = useVuelidate();
    const store = useStore();
    const eventSettings = computed(() => store.getters["main/eventSettings"]);
    const editProductIndex = ref(0);
    const showProductEditForm = ref(false);

    const showProductForm = ref(false);

    const toggleNewProductForm = () => {
      showProductForm.value = true;
    };

    const editProduct = index => {
      showProductEditForm.value = true;
      editProductIndex.value = index;
    };

    const cancel = () => {
      showProductEditForm.value = false;
      showProductForm.value = false;
    };

    const setEditImageUrl = async (index, value) => {
      await store.dispatch("main/editProductImage", { index, value });
    };

    const sizeOptions = [
      "XSmall",
      "Small",
      "Medium",
      "Large",
      "XLarge",
      "2XLarge",
      "3XLarge"
    ];

    //Add Cloudinary to head
    if (!window.cloudinary) {
      let cloudinaryScript = document.createElement("script");
      cloudinaryScript.setAttribute(
        "src",
        "https://upload-widget.cloudinary.com/global/all.js"
      );
      document.head.appendChild(cloudinaryScript);
    }

    const newProduct = reactive({
      active: true,
      name: "",
      price: 0,
      isGridded: true,
      sizes: [],
      image: ""
    });

    const addProduct = () => {
      if (newProduct.name && newProduct.price && newProduct.image) {
        saveProductToStore();
      }
    };

    const removeProduct = async index => {
      await store.dispatch("main/removeProduct", index);
    };

    const saveProductToStore = async () => {
      const product = {};
      Object.assign(product, newProduct);
      await store.dispatch("main/addProduct", product);
      newProduct.name = "";
      newProduct.price = "";
      newProduct.sizes = [];
      newProduct.image = "";
    };

    const initializeProductImageUploadWidget = async (type, index) => {
      // eslint-disable-next-line no-undef
      const productImageWidget = await cloudinary.createUploadWidget(
        {
          cloudName: process.env.VUE_APP_CLOUDINARY_CONTAINER,
          uploadPreset: process.env.VUE_APP_CLOUDINARY_TEAM_IMAGE_PRESET,
          sources: ["local", "google_drive", "dropbox"],
          maxFileSize: 2100000,
          cropping: true,
          styles: {
            palette: {
              window: "#ffffff",
              sourceBg: "#f4f4f5",
              windowBorder: "#90a0b3",
              tabIcon: "#000000",
              inactiveTabIcon: "#555a5f",
              menuIcons: "#555a5f",
              link: "#0433ff",
              action: "#339933",
              inProgress: "#0433ff",
              complete: "#339933",
              error: "#cc0000",
              textDark: "#000000",
              textLight: "#fcfffd"
            }
          }
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
            console.log(result.info);
            if (type == "edit") {
              setEditImageUrl(index, result.info.secure_url);
            } else {
              newProduct.image = result.info.secure_url;
            }
          }
        }
      );

      productImageWidget.open();
    };

    const initializeShirtImageUploadWidget = async () => {
      // eslint-disable-next-line no-undef
      const shirtImageWidget = await cloudinary.createUploadWidget(
        {
          cloudName: process.env.VUE_APP_CLOUDINARY_CONTAINER,
          uploadPreset: process.env.VUE_APP_CLOUDINARY_TEAM_IMAGE_PRESET,
          sources: ["local", "google_drive", "dropbox"],
          maxFileSize: 2100000,
          cropping: true,
          styles: {
            palette: {
              window: "#ffffff",
              sourceBg: "#f4f4f5",
              windowBorder: "#90a0b3",
              tabIcon: "#000000",
              inactiveTabIcon: "#555a5f",
              menuIcons: "#555a5f",
              link: "#0433ff",
              action: "#339933",
              inProgress: "#0433ff",
              complete: "#339933",
              error: "#cc0000",
              textDark: "#000000",
              textLight: "#fcfffd"
            }
          }
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
            console.log(result.info);
            store.dispatch("main/setEventShirtImage", result.info.secure_url);
          }
        }
      );

      shirtImageWidget.open();
    };

    const sendNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "Event settings have been updated."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem updating the event settings."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const saveEventSettings = async () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        let response = {
          code: 400
        };
        await sendNotification(response);
      } else {
        const saveEventSettingsResult = await updateEventSettings(
          eventSettings.value
        );
        showProductForm.value = false;
        showProductEditForm.value = false;
        await sendNotification(saveEventSettingsResult);
      }
    };

    return {
      eventSettings,
      showProductForm,
      newProduct,
      toggleNewProductForm,
      addProduct,
      removeProduct,
      saveProductToStore,
      initializeShirtImageUploadWidget,
      saveEventSettings,
      initializeProductImageUploadWidget,
      sizeOptions,
      editProductIndex,
      showProductEditForm,
      editProduct,
      cancel
    };
  }
});
</script>
