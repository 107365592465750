<template>
  <div>
    <div class="intro-y mt-8 text-center">
      <h1 class="text-4xl font-medium text-red mb-2">
        Welcome Back!
      </h1>
      <h2 class="text-lg font-medium mr-auto mb-2">{{ pageTitle }}</h2>
      <p>
        You will have the opportunity to update your Profile information and
        your Team information, including your Team Name, Members and Goals.
      </p>
    </div>
    <div class="grid grid-cols-12 gap-6 justify-items-center">
      <ReactivateProfile :user="user" :person="person" />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { getUser } from "@/api/users.js";
// import { getTeam } from "@/api/teams.js";
import ReactivateProfile from "./ReactivateProfile.vue";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    ReactivateProfile
  },
  setup() {
    //const store = useStore();
    const route = useRoute();
    let user = reactive({});
    let person = reactive({});
    // let team = reactive({});
    const store = useStore();
    let pageTitle = ref("Let's Reactivate Your Team");

    const setUser = async () => {
      let getTheUser = await getUser();
      if (route.params.user) {
        const theUserID = route.params.user;
        getTheUser = await getUser(theUserID);
      }
      // const getMyTeam = await getTeam();
      const campuses = store.state.lifeChurchCampuses.locations;

      if (getTheUser.person.lifeChurchLocation) {
        let campusName = getTheUser.person.lifeChurchLocation;
        let foundCampusValue = campuses.find(c => c.name === campusName);
        getTheUser.person.lifeChurchCampus = foundCampusValue;
      }

      Object.assign(user, getTheUser);
      Object.assign(person, getTheUser.person);
      // Object.assign(team, getMyTeam);
    };

    onMounted(async () => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .removeClass("frontend")
        .addClass("frontend reactivate-team");
      await setUser();
    });

    return {
      pageTitle,
      user,
      person
    };
  }
});
</script>
