const state = () => {
  return {
    menu: [
      {
        icon: "HomeIcon",
        pageName: "side-menu-dashboard",
        title: "Dashboard",
        subMenu: [
          {
            icon: "TrelloIcon",
            pageName: "my-team",
            title: "My Team"
          },
          {
            icon: "UserIcon",
            pageName: "edit-profile",
            title: "My Profile",
            childName: ["change-password"]
          }
        ]
      },
      {
        icon: "TrelloIcon",
        pageName: "teams",
        title: "Teams"
      },
      {
        icon: "UserIcon",
        pageName: "users",
        title: "Users"
      },
      {
        icon: "UsersIcon",
        pageName: "side-menu-layout",
        title: "Participants",
        subMenu: [
          {
            icon: "PocketIcon",
            pageName: "donors",
            title: "Donations"
          },
          {
            icon: "ShoppingCartIcon",
            pageName: "products-backend",
            title: "T-Shirts"
          },
          /**{
            icon: "ActivityIcon",
            pageName: "runners",
            title: "Runners"
          },**/
          {
            icon: "UserIcon",
            pageName: "participants",
            title: "All Participants"
          }
        ]
      },
      {
        icon: "DollarSignIcon",
        pageName: "transactions",
        title: "Transactions"
      },
      {
        icon: "RepeatIcon",
        pageName: "kindful",
        title: "Kindful"
      },
      {
        icon: "BarChartIcon",
        pageName: "side-menu-reports",
        title: "Reports",
        subMenu: [
          {
            icon: "LayoutIcon",
            pageName: "teamsReport",
            title: "Teams"
          },
          {
            icon: "LifeBuoyIcon",
            pageName: "lifeChurchReport",
            title: "Life Church"
          },
          /**{
            icon: "FastForwardIcon",
            pageName: "runnerReport",
            title: "Runners"
          },**/
          {
            icon: "ShoppingCartIcon",
            pageName: "productReport",
            title: "Shirts"
          },
          {
            icon: "DollarSignIcon",
            pageName: "donationReport",
            title: "Donations"
          },
          {
            icon: "DollarSignIcon",
            pageName: "transactionsReport",
            title: "Transactions"
          },
          {
            icon: "BarChartIcon",
            pageName: "dailyReports",
            title: "Daily Snapshots"
          }
        ]
      },
      {
        icon: "SettingsIcon",
        pageName: "event-settings",
        title: "Event Config",
        childName: ["change-password"]
      },
      {
        icon: "LayoutIcon",
        pageName: "side-menu-layout",
        title: "Admin Links",
        subMenu: [
          {
            icon: "",
            pageName: "donate",
            title: "Offline Donation",
            newWindow: true
          },
          {
            icon: "",
            pageName: "products",
            title: "Products"
          }
        ]
      }
    ],
    captainMenu: [
      {
        icon: "HomeIcon",
        pageName: "side-menu-dashboard",
        title: "Dashboard",
        subMenu: [
          {
            icon: "TrelloIcon",
            pageName: "my-team",
            title: "My Team"
          },
          {
            icon: "UserIcon",
            pageName: "edit-profile",
            title: "My Profile",
            childName: ["change-password"]
          }
        ]
      }
    ]
  };
};

// getters
const getters = {
  menu: state => state.menu
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
