<template>
  <div class="pt-6 border-t mt-6">
    <div class="md:grid md:grid-cols-12 md:gap-6 capitalize">
      <div class="type md:col-span-2 font-bold">{{ item.type }}</div>
      <div class="details md:col-span-8">
        <div v-if="item.type == 'tshirt'">
          <p class="font-bold block">
            {{ item.productDetails.name }}
          </p>
          <p>
            {{ item.productDetails.unitCount }} -
            {{ item.productDetails.size }} @ ${{
              item.productDetails.pricePerUnit
            }}
          </p>
          <p v-if="item.shippingAddress" class="mt-2">
            <span class="font-bold block">Ship To</span>
            {{ item.shippingAddress.address }}<br />
            {{ item.shippingAddress.city }}, {{ item.shippingAddress.state }}
            {{ item.shippingAddress.zipcode }}
          </p>
        </div>
        <div v-if="item.type == '5k' && item.runnerDetails">
          <p>
            Type: <strong>{{ item.runnerDetails.runType }}</strong> @ ${{
              item.runnerDetails.registrationCost
            }}
            <br />
            {{ item.runnerDetails.firstName }}
            {{ item.runnerDetails.lastName }} <br />
            <span class="lowercase">{{ item.runnerDetails.email }}</span
            ><br />
            {{ item.runnerDetails.phone }}
          </p>
          <p v-if="item.runnerDetails.shipping" class="mt-2">
            <span class="font-bold block">Ship To</span>
            {{ item.runnerDetails.shipping.address }}<br />
            {{ item.runnerDetails.shipping.city }},
            {{ item.runnerDetails.shipping.state }}
            {{ item.runnerDetails.shipping.zipcode }}
          </p>
        </div>
      </div>
      <div class="amount md:col-span-2 md:text-right">${{ item.amount }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  setup() {
    return {};
  }
});
</script>
