<template>
  <div>
    <FrontEndHeader fill="#be2032" />
    <!-- BEGIN: Create AddTeamMembersLayout -->
    <form class="validate-form" @submit.prevent="save">
      <div class="intro-y box mt-5 pb-10 max-w-3xl mx-auto">
        <Cart />
        <div
          class="font-bold text-3xl px-5 sm:px-20 py-10 sm:py-10 sm:pb-5 rounded-t"
        >
          {{ page.title }}
        </div>
        <div class="px-5 sm:px-20">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-3">
              <div class="px-0 sm:pr-8">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  How many team members are you adding?
                  <p class="mt-1 text-sm text-gray-600">
                    Please tell us the total team members you are adding.
                  </p>
                </h3>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-3">
              <div class="grid grid-cols-12 gap-4 gap-y-5">
                <div class="intro-y col-span-12 sm:col-span-3">
                  <div class="flex justify-between w-full text-lg">
                    <button
                      type="button"
                      class="btn-icon outline-none focus:outline-none"
                      @click="decrementParticipant()"
                    >
                      <MinusCircleIcon
                        class="w-6 h-6 mr-4 fill-theme-9 inline-block"
                      />
                    </button>
                    <div class="flex-grow text-center bg-gray-200 py-3">
                      {{ teamMembers.quantity }}
                    </div>
                    <button
                      type="button"
                      class="btn-icon outline-none focus:outline-none"
                      @click="incrementParticipant()"
                    >
                      <PlusCircleIcon
                        class="w-6 h-6 ml-4 fill-theme-9 inline-block"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="teamMembers.quantity > 0"
            class="md:grid md:grid-cols-3 md:gap-6 mt-6"
          >
            <div class="md:col-span-3">
              <div class="px-0 sm:pr-8">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Please tell us a little about your teammates.
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  We will not share your information outside our organization.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-3">
              <div
                v-for="(teamMember, i) in teamMembers.members"
                :key="i"
                class="grid grid-cols-12 gap-4 gap-y-5 mb-6"
              >
                <div class="intro-y col-span-6 sm:col-span-12">
                  <h3 class="text-base font-medium leading-6 text-gray-900">
                    Team Member {{ i + 1 }}
                  </h3>
                </div>
                <BasicInfo
                  v-model:firstName="teamMember.first_name"
                  v-model:lastName="teamMember.last_name"
                  v-model:emailAddress="teamMember.email"
                  v-model:phoneNumber="teamMember.phone"
                  :first-name="teamMember.first_name"
                  :last-name="teamMember.last_name"
                  :email-address="teamMember.email"
                  :phone-number="teamMember.phone"
                />
                <LifeChurchField
                  class="hidden"
                  v-show="teamMember.phone"
                  v-model:lifeChurch="teamMember.lifeChurch"
                  v-model:lifeChurchCampus="teamMember.lifeChurchCampus"
                  :campus-value="teamMember.lifeChurchCampus"
                  :is-life-church="teamMember.lifeChurch"
                  :id-hack="i"
                />
              </div>
              <div
                class="intro-y col-span-12 flex items-center justify-between mt-6"
              >
                <button
                  type="button"
                  class="btn block w-full intro-y py-3 sm:inline-block sm:w-auto btn-red border-reddark sm:px-10 btn-lg"
                  :disabled="
                    validate.$errors.length > 0 || teamMembers.quantity === 0
                  "
                  @click="setTeamMembers()"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <CancelButton />
    <!-- END: Add Team Members Layout -->
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { defineComponent, onMounted, computed, toRefs } from "vue";
import { useStore } from "@/store";
import { useVuelidate } from "@vuelidate/core";
import FrontEndHeader from "@/components/front-end/FrontEndHeader";
import Cart from "@/components/front-end/Cart";
import BasicInfo from "@/components/form-inputs/BasicInfo";
import LifeChurchField from "@/components/form-inputs/LifeChurch";
import CancelButton from "@/components/front-end/CancelButton";

export default defineComponent({
  components: {
    FrontEndHeader,
    Cart,
    BasicInfo,
    LifeChurchField,
    CancelButton
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const supporting = store.state.support.supporting;

    let teamMembers = store.state.teamMembers.teamMembers;

    const page = {
      title: "Add Additional Team Member"
    };

    onMounted(() => {
      if (supporting.teamDetails == "") {
        router.push({ path: "/frontend/join" });
      }

      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("frontend create-team");
    });

    const rules = computed(() => {
      return {};
    });

    const initialState = {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      lifeChurch: false,
      lifeChurchCampus: {
        value: 0,
        name: ""
      }
    };

    const setTeamMembers = () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        console.log("invalid");
      } else {
        setNewTeamMembers();
      }
    };

    const setNewTeamMembers = async () => {
      let newTeamMembers = { ...teamMembers };
      await store.dispatch("teamMembers/setTeamMembers", newTeamMembers);
      toSummary();
    };

    const toSummary = () => {
      router.push({ path: "/frontend/summary" });
    };

    const validate = useVuelidate(rules, toRefs(teamMembers));

    return {
      page,
      validate,
      supporting,
      initialState,
      teamMembers,
      setTeamMembers
    };
  },
  mounted() {
    this.onChangeMembers();
  },
  methods: {
    onChangeMembers() {
      this.teamMembers.members = [
        ...Array(this.teamMembers.quantity).keys()
      ].map(i => this.teamMembers.members[i] || { ...this.initialState });
    },
    decrementParticipant() {
      if (this.teamMembers.quantity > 1) {
        this.teamMembers.quantity--;
        this.onChangeMembers();
      }
    },
    incrementParticipant() {
      console.log("test");
      this.teamMembers.quantity++;
      this.onChangeMembers();
    }
  }
});
</script>
