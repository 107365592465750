<template>
  <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
    <!-- BEGIN: Change Password -->
    <div class="intro-y box lg:mt-5">
      <div
        class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
      >
        <h2 class="font-medium text-base mr-auto">Change Password</h2>
      </div>
      <div class="p-5">
        <form class="validate-form" @submit.prevent="saveUserPassword">
          <div class="grid grid-cols-12 gap-4 gap-y-5">
            <div class="intro-y col-span-12 sm:col-span-6">
              <PasswordField v-model="userPassword.password" />
            </div>
            <div class="intro-y col-span-12 sm:col-span-6">
              <ConfirmPasswordField
                v-model="userPassword.confirm"
                :password="userPassword.password"
              />
            </div>
          </div>
          <button
            type="submit"
            class="btn btn-primary mt-4"
            :disabled="validate.$errors.length > 0"
          >
            Change Password
          </button>
        </form>
      </div>
    </div>
    <!-- END: Change Password -->
  </div>
</template>
<script>
import { defineComponent, toRefs, computed, reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import PasswordField from "@/components/form-inputs/Password";
import ConfirmPasswordField from "@/components/form-inputs/PasswordConfirm";
import { updateUser } from "@/api/users";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    PasswordField,
    ConfirmPasswordField
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      },
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    let userPassword = reactive({
      password: "",
      confirm: ""
    });

    const sendNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "The password has been changed."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem changing the password."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const saveUserPassword = async () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        console.log("invalid");
        sendNotification({ code: true });
      } else {
        let thePassword = {
          password: userPassword.password
        };
        const saveUserResult = await updateUser(thePassword, props.user.id);
        sendNotification(saveUserResult);
      }
    };

    const rules = computed(() => {
      return {};
    });

    const validate = useVuelidate(rules, toRefs(userPassword));

    return {
      userPassword,
      saveUserPassword,
      validate
    };
  }
});
</script>
