import * as types from "./mutation-types";

const state = () => {
  return {
    initTransaction: {
      id: "",
      payload: "",
      total: 0
    },
    transaction: {
      id: "",
      payload: "",
      total: 0
    }
  };
};

// getters
const getters = {
  transaction: state => state.transaction
};

// actions
const actions = {
  setTransaction: async ({ commit }, transaction) => {
    await commit(types.SET_TRANSACTION, { transaction });
  },
  resetTransaction: async ({ commit }) => {
    await commit(types.RESET_TRANSACTION);
  }
};

// mutations
const mutations = {
  [types.RESET_TRANSACTION](state) {
    state.transaction = state.initTransaction;
  },
  [types.SET_TRANSACTION](state, { transaction }) {
    state.transaction.id = transaction.id;
    state.transaction.total = transaction.total;
    state.transaction.payload = transaction.payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
