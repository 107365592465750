<template>
  <div
    class="flex justify-start items-center col-span-12 rounded intro-y border-2 border-solid border-theme-9 px-3 py-2"
  >
    <div class="inline-block">
      <CheckCircleIcon class="w-6 h-6 mr-4 fill-theme-9 inline-block" />
    </div>
    <div class="donate-info inline-block flex-grow">
      <h6 class="text-xs">Supporting</h6>
      <h4 class="text-base">
        <span v-if="supporting.type === 'Event'">
          {{ appYear }} {{ appTitle }} -
          {{ supporting.eventDetails.city }}
        </span>
        <span v-if="supporting.type === 'Team'">
          {{ supporting.teamDetails.name }}
        </span>
      </h4>
    </div>
    <div class="edit-support-info justify-self-end">
      <router-link :to="supportingLink" type="button" class="btn btn-sm">
        Edit
      </router-link>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    supporting: {
      type: Object,
      default: function() {
        return {};
      }
    },
    fromDonation: {
      type: Boolean,
      default: function() {
        return false;
      }
    }
  },
  setup(props) {
    const store = useStore();
    const appTitle = computed(() => store.getters["main/appTitle"]);
    const appYear = computed(() => store.getters["main/appYear"]);

    let supportingLink = "/frontend/supporting";

    if (props.fromDonation) {
      supportingLink = "/frontend/supporting?donate=true";
    }

    return {
      appTitle,
      appYear,
      supportingLink
    };
  }
});
</script>
