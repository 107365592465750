import { fetch } from "@/api/fetch-auto-refresh";
import { useStore } from "@/store";
const store = useStore();
const apiURL = process.env.VUE_APP_API_URL;

const createTransaction = async transaction => {
  console.log("createTransaction", transaction);
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(transaction)
  };

  const response = await fetch(apiURL + "/v1/transactions", requestOptions);
  const data = await response.json();
  return data;
};

const getTransactions = async eventYear => {
  let authToken = store.state.auth.token;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  let requestParams = "/v1/transactions/?limit=800&sortBy=createdAt:desc";

  if (eventYear) {
    requestParams = `/v1/transactions/?eventYear=${eventYear}&limit=800&sortBy=createdAt:desc`;
  }

  const response = await fetch(apiURL + requestParams, requestOptions);
  const data = await response.json();
  return data;
};

const getTransaction = async transaction => {
  let authToken = store.state.auth.token;
  let transID = transaction;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  const response = await fetch(
    `${apiURL}/v1/transactions/${transID}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

const getTransactionsByTeam = async (team, options) => {
  let teamId = team;
  let authToken = store.state.auth.token;
  let request = `?team=${teamId}&sortBy=createdAt:desc`;
  if (options) {
    request = `?team=${teamId}${options}&sortBy=createdAt:desc`;
  }

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  const response = await fetch(
    `${apiURL}/v1/transactions/${request}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

const updateShipping = async (transId, hasShipped) => {
  let authToken = store.state.auth.token;

  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    },
    body: JSON.stringify(hasShipped)
  };

  const response = await fetch(
    `${apiURL}/v1/transactions/${transId}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

const updateSupporting = async (transId, supporting) => {
  let authToken = store.state.auth.token;

  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    },
    body: JSON.stringify(supporting)
  };

  const response = await fetch(
    `${apiURL}/v1/transactions/supporting/${transId}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

export {
  createTransaction,
  getTransactions,
  getTransaction,
  getTransactionsByTeam,
  updateShipping,
  updateSupporting
};
