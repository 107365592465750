<template>
  <div
    class="col-span-12 lg:col-span-4 xxl:col-span-3 flex lg:block flex-col-reverse"
  >
    <div class="intro-y box mt-5">
      <div class="relative flex items-center p-5">
        <div class="ml-2 mr-auto w-full">
          <div class="font-medium text-base">
            {{ person.firstName }} {{ person.lastName }}
          </div>
          <div class="text-gray-600">{{ user.email }}</div>
          <div class="mt-4 font-medium text-primary-1">
            <div v-if="user.team" class="flex justify-between">
              <div>{{ user.team.name }}</div>
              <div class="relative">
                <div
                  :class="[user.team.active ? 'bg-theme-9' : '', 'bg-theme-6']"
                  class="w-3 h-3 -ml-4 absolute left-0 mt-1 rounded-full border-2 border-white"
                ></div>
                <span v-show="user.team.active">Active</span>
                <span v-show="!user.team.active">Inactive</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-5 border-t border-gray-200 dark:border-dark-5">
        <router-link
          class="flex items-center text-theme-1 dark:text-theme-10"
          :to="path"
        >
          <UserIcon class="w-4 h-4 mr-2" /> Personal Information
        </router-link>
        <router-link class="flex items-center mt-5" :to="passwordPath">
          <LockIcon class="w-4 h-4 mr-2" /> Change Password
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      },
      required: false
    },
    person: {
      type: Object,
      default: () => {
        return {};
      },
      required: false
    }
  },
  setup() {
    const route = useRoute();
    let path = "/my-profile";
    let passwordPath = path + "/change-password";
    if (route.params.id) {
      /**
       * Set path for user edit
       */
      path = "/user/" + route.params.id;
      passwordPath = path + "/user-password";
    }
    return {
      path,
      passwordPath
    };
  }
});
</script>

<style scoped>
.router-link-exact-active {
  @apply font-medium;
}
</style>
