<template>
  <div class="col-span-12">
    <div class="grid grid-cols-12 gap-4 gap-y-5">
      <!--Address-->
      <div class="intro-y col-span-6 sm:col-span-12 address-field">
        <div class="address">
          <label
            for="address"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="address"
              v-model="validate.address.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.address.$error }"
              placeholder=" "
              @input="$emit('update:address', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >{{ addressTitle }}</span
            >
          </label>
          <div
            v-for="error of validate.address.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--City-->
      <div class="intro-y col-span-6 sm:col-span-6 city-field">
        <div class="city">
          <label
            for="city"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="city"
              v-model="validate.city.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.city.$error }"
              placeholder=" "
              @input="$emit('update:city', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >City</span
            >
          </label>
          <div
            v-for="error of validate.city.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--State-->
      <div class="intro-y col-span-12 sm:col-span-3 state-field">
        <div class="state">
          <div class="lg:-mt-3">
            <label for="state" class="form-label text-xs">
              Select State
            </label>
            <select
              id="state"
              v-model="state"
              class="form-select sm:mr-2 p-3 text-base"
              aria-label="State"
              :class="{ 'border-theme-6': validate.state.$error }"
              @change="$emit('update:state', state)"
            >
              <option value="" disabled selected>OK</option>
              <option
                v-for="theState in statesList"
                :key="theState"
                :value="theState"
              >
                {{ theState }}
              </option></select
            >
          </div>
        </div>
      </div>
      <!--Zipcode-->
      <div class="intro-y col-span-12 sm:col-span-3 zipcode-field">
        <div class="zipcode">
          <label
            for="zipcode"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="zipcode"
              v-model="validate.zipcode.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.zipcode.$error }"
              placeholder=" "
              @input="$emit('update:zipCode', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Zipcode</span
            >
          </label>
          <div
            v-for="error of validate.zipcode.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  required,
  minLength,
  maxLength,
  numeric,
  helpers
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "@/store";
export default defineComponent({
  props: {
    addressValue: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    cityValue: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    stateValue: {
      type: String,
      default: () => {
        return "OK";
      },
      required: false
    },
    zipcodeValue: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    noShipping: {
      type: Boolean,
      required: false
    },
    notRequired: {
      type: Boolean,
      default: () => {
        return false;
      },
      required: false
    },
    touch: {
      type: Boolean,
      required: false
    }
  },
  setup: () => ({ validate: useVuelidate() }),
  data() {
    const store = useStore();
    const states = store.state.statesList.states;
    let addressText = "Shipping Address";
    if (this.noShipping) {
      addressText = "Street Address";
    }

    return {
      address: this.addressValue,
      city: this.cityValue,
      state: this.stateValue,
      zipcode: this.zipcodeValue,
      addressTitle: addressText,
      statesList: states
    };
  },
  watch: {
    addressValue: function() {
      this.address = this.addressValue;
    },
    cityValue: function() {
      this.city = this.cityValue;
    },
    stateValue: function() {
      this.state = this.stateValue;
    },
    zipcodeValue: function() {
      this.zipcode = this.zipcodeValue;
    }
  },
  validations() {
    let validationProps = { required, minLength: minLength(2) };
    let validZip = {
      required,
      numeric: helpers.withMessage("Can only contain numbers.", numeric),
      minLength: helpers.withMessage(
        "Must be a valid 5 digit zipcode.",
        minLength(5)
      ),
      maxLength: helpers.withMessage(
        "Must be a valid 5 digit zipcode.",
        maxLength(5)
      )
    };
    if (this.notRequired) {
      validationProps = {};
      validZip = {};
    }
    return {
      address: validationProps,
      city: validationProps,
      state: validationProps,
      zipcode: validZip
    };
  }
});
</script>
