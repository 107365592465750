<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-6 flex">
        <h2 class="intro-y text-lg font-medium mt-10">Transaction</h2>
      </div>
      <div class="col-span-6 flex justify-end items-end">
        <router-link
          v-if="returnPath"
          :to="{ name: returnPath }"
          class="btn btn-outline shadow-md mr-2"
          ><ChevronLeftIcon class="h-4 w-4 mr-1" />Return to All
          {{ returnPathName }}</router-link
        >
        <router-link
          :to="{ path: '/transactions' }"
          class="btn btn-outline shadow-md mr-2"
          ><ChevronLeftIcon class="h-4 w-4 mr-1" />All Transactions</router-link
        >
        <router-link
          :to="{ name: 'invoice', params: { id: transactionData.id } }"
          class="btn btn-primary shadow-md"
          ><PrinterIcon class="h-4 w-4 mr-1" />Invoice</router-link
        >
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-8">
        <div class="intro-y box flex flex-wrap sm:flex-nowrap mt-2">
          <div class="p-6 text-base flex-1">
            <h3 class="font-bold">
              Transaction Details
            </h3>
            <div class="grid grid-cols-12 gap-6 mt-6 mb-6">
              <div
                v-if="
                  transactionData.team &&
                    transactionData.supportingType == 'team'
                "
                class="col-span-2"
              >
                <h3 class="font-bold">Team</h3>
                <p>{{ transactionData.team.name }}</p>
              </div>
              <div class="col-span-2">
                <h3 class="font-bold">Event Year</h3>
                <p>{{ transactionData.eventYear }}</p>
              </div>
              <div class="col-span-2">
                <h3 class="font-bold">Event City</h3>
                <p>{{ transactionData.eventCity }}</p>
              </div>
              <div class="col-span-2">
                <h3 class="font-bold">Total Amount</h3>
                <p>${{ transactionData.totalAmount }}</p>
              </div>
              <div
                v-if="transactionData.externalTransactionId"
                class="col-span-2"
              >
                <h3 class="font-bold">Stripe ID</h3>
                <p>{{ transactionData.externalTransactionId }}</p>
              </div>
              <div v-if="showTransactionUpdate" class="col-span-12">
                <Supporting
                  v-model:supportType="updateTransaction.supportingType"
                  v-model:eventCity="updateTransaction.eventDetails"
                  v-model:teamSelected="updateTransaction.teamDetails"
                />
              </div>
              <div class="col-span-12">
                <div class="grid grid-cols-12 gap-6 mt-6 mb-6">
                  <div class="col-span-6">
                    <button
                      class="btn btn-outline-primary"
                      type="button"
                      @click="toggleSupportingUpdate()"
                    >
                      <span v-if="showTransactionUpdate">Cancel</span
                      ><span v-else>Change Support</span>
                    </button>
                  </div>
                  <div class="col-span-6 text-right">
                    <button
                      v-if="showTransactionUpdate"
                      class="btn btn-primary"
                      type="button"
                      @click="updateTransactionSupporting()"
                    >
                      Update Transaction
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="transactionData.team" class="col-span-12 text-center">
              <router-link
                class="btn btn-outline"
                :to="`/team/${transactionData.team.id}`"
                ><EyeIcon class="h-4 w-4 mr-1" /> View Team</router-link
              >
            </div>
          </div>
        </div>
        <div class="intro-y box flex flex-wrap sm:flex-nowrap mt-6">
          <div class="p-6 text-base w-full">
            <h3 class="font-bold">
              Line Items
            </h3>
            <table v-if="transactionData.lineItems" class="table">
              <table
                v-for="(item, index) in transactionData.lineItems"
                :key="index"
                class="table person-table mt-4 mb-4 border border-solid border-gray-300"
              >
                <tr
                  v-for="(value, name) in item"
                  v-show="
                    name !== 'anonymous' &&
                      name !== 'team' &&
                      name !== 'runners' &&
                      name !== 'products' &&
                      name !== 'id' &&
                      name !== 'transaction' &&
                      name !== 'person' &&
                      name !== 'eventCity' &&
                      name !== 'eventYear'
                  "
                  :key="name"
                >
                  <td class="bg-gray-200 capitalize w-64">{{ name }}</td>
                  <td class="capitalize">
                    <span
                      v-if="
                        name === 'runnerDetails' ||
                          name === 'productDetails' ||
                          name === 'shippingAddress'
                      "
                    >
                      <span
                        v-for="(val, key) in value"
                        v-show="
                          key !== 'primaryContact' &&
                            key !== 'eventCity' &&
                            key !== 'eventYear' &&
                            key !== 'teams'
                        "
                        :key="key"
                        class="block"
                      >
                        <strong>{{ key }}</strong
                        >:
                        <span v-if="key == 'shipping'"
                          >{{ val.address }} {{ val.city }}, {{ val.state }}
                          {{ val.zipcode }}</span
                        ><span v-else>{{ val }}</span>
                      </span>
                    </span>
                    <span v-else>{{ value }}</span>
                  </td>
                </tr>
              </table>
            </table>
          </div>
        </div>
      </div>
      <div class="intro-y col-span-4">
        <div class="intro-y box flex flex-wrap sm:flex-nowrap mt-2">
          <div class="p-6 w-full text-base">
            <h3 class="font-bold">
              Primary Contact
            </h3>
            <p>
              {{ transactionData.contactFirstName }}
              {{ transactionData.contactLastName }}
            </p>
            <p>
              {{ transactionData.contactEmail }}
            </p>
            <div v-if="transactionData.donatorPerson">
              <h3 class="font-bold mt-3">
                Person Details
              </h3>
              <table
                class="table person-table mt-4 mb-4 border border-solid border-gray-300"
              >
                <tr>
                  <td class="bg-gray-200">Anonymous</td>
                  <td>{{ transactionData.donatorPerson.anonymous }}</td>
                </tr>
                <tr>
                  <td class="bg-gray-200">Marketing</td>
                  <td>{{ transactionData.donatorPerson.marketing }}</td>
                </tr>
                <tr>
                  <td class="bg-gray-200">LifeChurch</td>
                  <td>{{ transactionData.donatorPerson.lifeChurch }}</td>
                </tr>
                <tr v-if="transactionData.donatorPerson.lifeChurchLocation">
                  <td class="bg-gray-200">LifeChurch Location</td>
                  <td>
                    {{ transactionData.donatorPerson.lifeChurchLocation }}
                  </td>
                </tr>
              </table>

              <router-link
                class="btn btn-outline mb-6"
                :to="`/participants/${transactionData.donatorPerson.id}`"
                ><EyeIcon class="h-4 w-4 mr-1" /> View Person</router-link
              >
            </div>
            <h3 class="font-bold mt-3">
              Billing Contact
            </h3>
            <div v-if="transactionData.billingContact">
              <p
                v-for="(entry, index) in transactionData.billingContact"
                :key="index"
              >
                {{ entry }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "@/store";
import { getTransaction, updateSupporting } from "@/api/transactions.js";
import Supporting from "@/components/form-inputs/Supporting";

export default defineComponent({
  components: {
    Supporting
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    let transactionData = reactive({});
    let updateTransaction = reactive({});
    let showTransactionUpdate = ref(false);
    let transactionId = route.params.id;
    let returnPath = "";
    let returnPathName = "";
    if (route.query.returnTo) {
      returnPath = route.query.returnTo;
      returnPathName = route.query.returnName;
    }

    const getTransactionData = async () => {
      const theTransaction = await getTransaction(transactionId);
      Object.assign(transactionData, theTransaction);
    };

    const toggleSupportingUpdate = () => {
      showTransactionUpdate.value = !showTransactionUpdate.value;
    };

    onMounted(() => {
      getTransactionData();
    });

    const sendNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "Transaction has been updated."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem updating the transaction."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const updateTransactionSupporting = async () => {
      let supporting = {
        supportingType: updateTransaction.supportingType.toLowerCase()
      };
      if (updateTransaction.supportingType == "Team") {
        supporting.team = updateTransaction.teamDetails.id;
      }
      if (updateTransaction.supportingType == "Event") {
        supporting.eventCity = updateTransaction.eventDetails.city;
      }

      console.log(transactionId, supporting);
      const updateSupportingResponse = await updateSupporting(
        transactionId,
        supporting
      );
      showTransactionUpdate.value = false;
      await sendNotification(updateSupportingResponse);
      await getTransactionData();
    };

    return {
      returnPath,
      returnPathName,
      transactionData,
      updateTransaction,
      toggleSupportingUpdate,
      showTransactionUpdate,
      updateTransactionSupporting
    };
  }
});
</script>
<style scoped>
.person-table td {
  border-bottom: 1px solid #ddd;
}
</style>
