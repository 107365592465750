<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Donations</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <div class="mt-4 sm:mt-0">
          <form id="grid-search" class="mr-3">
            <div class="w-64 relative text-gray-700 dark:text-gray-300">
              <input
                v-model="searchQuery"
                type="text"
                class="form-control p-3 w-64 box pr-10 placeholder-theme-13"
                placeholder="Search..."
              />
              <SearchIcon
                class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
              />
            </div>
          </form>
        </div>
        <div class="hidden md:block mx-auto text-gray-600">
          {{ donationsData.totalResults }} Total Donations
        </div>
      </div>
    </div>
    <div class="intro-y mt-5">
      <DonationsGrid :grid-data="formattedDonations" :query="searchQuery" />
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, computed, reactive, ref } from "vue";
import { useStore } from "@/store";
import { getLineItems } from "@/api/lineitems.js";
import DonationsGrid from "@/components/back-end/DonationsGrid";

export default defineComponent({
  components: {
    DonationsGrid
  },
  setup() {
    const store = useStore();
    let donationsData = reactive([]);
    let formattedDonations = reactive([]);
    let searchQuery = ref("");
    const appSettings = computed(() => store.getters["main/eventSettings"]);

    const getDonationsData = async eventYear => {
      if (!eventYear) {
        eventYear = appSettings.value.appYear;
      }
      let donationRequest = `?eventYear=${eventYear}&type=donation&limit=200`;
      let allDonations = await getLineItems(donationRequest);
      Object.assign(donationsData, allDonations);
      reformattedDonations(allDonations.results);
    };

    const reformattedDonations = theDonations => {
      let donations = theDonations;
      let formattedDonationData = [];
      donations.map(d => {
        let createdAt = new Date(d.createdAt);
        let obj = {};
        obj.id = d.id;
        obj.type = d.type;
        obj.name = d.primaryContact;
        if (d.team) obj.hasTeam = true;
        obj.eventCity = d.eventCity;
        obj.eventYear = d.eventYear;
        obj.amount = d.amount;
        obj.transactionId = d.transaction.id;
        obj.hasShipped = d.transaction.hasShipped;
        if (d.useEmployerAsName) {
          obj.name = d.myEmployer;
        }
        obj.created = createdAt
          .toDateString()
          .split(" ")
          .slice(1)
          .join(" ");

        formattedDonationData.push(obj);
      });
      Object.assign(formattedDonations, formattedDonationData);
    };

    onMounted(() => {
      getDonationsData();
    });

    return {
      donationsData,
      formattedDonations,
      searchQuery
    };
  }
});
</script>
