const customFetch = (url, options = {}) => {
  // The Content-Type header describes the type of the body so should be
  // omitted when there isn't one.
  const fetchOptions =
    typeof options.body === "undefined"
      ? options
      : Object.assign(
          {
            headers: {
              "Content-Type": "application/json"
            }
          },
          options
        );

  // refresh-fetch expects this fetch method to provided the latest token from the store.
  // It isn't smart enough to pass a refereshed token in here.
  const userJson = localStorage.getItem("pwUser");
  if (userJson) {
    const user = JSON.parse(userJson);
    const accessToken = user.tokens.access.token;
    if (options.headers)
      options.headers.Authorization = `Bearer ${accessToken}`;
  }

  return fetch(url, fetchOptions)
    .then(response => {
      return getResponseBody(response).then(body => ({
        response,
        body
      }));
    })
    .then(checkStatus);
};

const getResponseBody = response => {
  const contentType = response.headers.get("content-type");
  return contentType && contentType.indexOf("json") >= 0
    ? response
        .clone()
        .text()
        .then(tryParseJSON)
    : response.clone().text();
};

const tryParseJSON = json => {
  if (!json) {
    return null;
  }

  try {
    return JSON.parse(json);
  } catch (e) {
    throw new Error(`Failed to parse unexpected JSON response: ${json}`);
  }
};

function ResponseError(status, response, body) {
  this.name = "ResponseError";
  this.status = status;
  this.response = response;
  this.body = body;
}

// $FlowIssue
ResponseError.prototype = Error.prototype;

const checkStatus = ({ response, body }) => {
  if (response.ok) {
    //return { response, body };
    return response;
  } else {
    throw new ResponseError(response.status, response, body);
  }
};

export default customFetch;
