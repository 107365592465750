<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Teams Report</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <div class="hidden md:block mx-auto text-gray-600">
          {{ teamRowData.totalResults }} Total
          <span v-if="isActive == 'active-teams'">Active</span>
          <span v-if="isActive == 'inactive-teams'">Inactive</span> Teams
        </div>
      </div>
    </div>
    <div class="intro-y mt-5 h-full col-span-12">
      <div class="grid grid-cols-12 gap-6 mt-5 mb-5">
        <div class="col-span-6">
          <div class="flex items-center justify-start mb-4 report-button-group">
            <button
              :class="{ active: isActive == 'active-teams' }"
              class="active-teams text-blue-500 bg-transparent border-l border-t border-b border-blue-500 hover:bg-blue-500 hover:text-white font-bold uppercase text-xs px-4 py-2 rounded-l outline-none focus:outline-none mb-1"
              type="button"
              @click="(isActive = 'active-teams'), setTeams(activeTeamsOptions)"
            >
              Active
            </button>
            <button
              :class="{ active: isActive == 'inactive-teams' }"
              class="inactive-teams text-blue-500 bg-transparent border border-solid border-blue-500 hover:bg-blue-500 hover:text-white font-bold uppercase text-xs px-4 py-2 outline-none focus:outline-none mb-1"
              type="button"
              @click="
                (isActive = 'inactive-teams'), setTeams(inActiveTeamOptions)
              "
            >
              InActive
            </button>
            <button
              :class="{ active: isActive == 'all-teams' }"
              class="all-teams text-blue-500 bg-transparent border-t border-b border-r border-blue-500 hover:bg-blue-500 hover:text-white font-bold uppercase text-xs px-4 py-2 rounded-r outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
              type="button"
              @click="(isActive = 'all-teams'), setTeams()"
            >
              All Teams
            </button>
          </div>
        </div>
        <div class="col-span-6 text-right">
          <button class="btn btn-outline mr-6" @click="resetFilters()">
            Reset Filters
          </button>
          <button class="btn btn-outline" @click="exportToCSV()">
            Download CSV export file
          </button>
        </div>
      </div>
      <AgGridVue
        class="ag-theme-alpine w-full the-report-grid"
        :column-defs="columnDefs"
        :row-data="teamRowData.results"
        :default-col-def="defaultColDef"
        :row-class-rules="rowClassRules"
        @grid-ready="onGridReady"
      >
      </AgGridVue>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { getTeams } from "@/api/reports.js";

export default defineComponent({
  components: {
    AgGridVue
  },
  setup() {
    let teamRowData = reactive({});
    let isActive = ref("active-teams");
    let gridApi = null;
    // eslint-disable-next-line no-unused-vars
    let gridColumnApi = null;

    let defaultColDef = {
      minWidth: 50,
      filter: true,
      resizable: true
    };

    const activeTeamsOptions = {
      active: true
    };

    const inActiveTeamOptions = {
      active: false
    };

    const setTeams = async options => {
      let teams = [];
      if (options) {
        teams = await getTeams(options);
      } else {
        teams = await getTeams();
      }
      console.log(teams);
      Object.assign(teamRowData, teams);
    };

    const resetFilters = async () => {
      await setTeams(activeTeamsOptions);
      isActive.value = "active-teams";
    };

    onMounted(async () => {
      await setTeams(activeTeamsOptions);
    });

    const columnDefs = [
      {
        headerName: "Added",
        field: "createdAt",
        sortable: true,
        filter: "agDateColumnFilter",
        // eslint-disable-next-line no-undef
        filterParams: filterReportDate,
        // eslint-disable-next-line no-undef
        valueFormatter: dateFormatter,
        pinned: "left",
        width: 150
      },
      {
        headerName: "Reactivated",
        field: "reactivateDate",
        sortable: true,
        filter: "agDateColumnFilter",
        // eslint-disable-next-line no-undef
        filterParams: filterReportDate,
        // eslint-disable-next-line no-undef
        valueFormatter: dateFormatter,
        pinned: "left",
        width: 150
      },
      {
        headerName: "Team Name",
        field: "name",
        sortable: true,
        pinned: "left"
      },
      {
        headerName: "Active",
        field: "active",
        sortable: true
      },
      {
        headerName: "Event City",
        field: "eventCity",
        sortable: true
      },
      {
        headerName: "Total Raised",
        field: "totalRaised",
        sortable: true,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "Fundraising Goal",
        field: "teamDonationGoal",
        sortable: true,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "Percent Raised",
        field: "fundraisingGoalPercentage",
        sortable: true,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "Total Members",
        field: "members.length",
        sortable: true,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "Member Goal",
        field: "teamMemberGoal",
        sortable: true,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "Percent Joined",
        field: "memberGoalPercentage",
        sortable: true,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "Capt. First Name",
        field: "captainPerson.firstName",
        sortable: true
      },
      {
        headerName: "Capt. Last name",
        field: "captainPerson.lastName",
        sortable: true
      },
      {
        headerName: "Capt. Email",
        field: "captainPerson.email",
        sortable: true
      },
      {
        headerName: "Capt. Address",
        field: "captainPerson.address.address",
        sortable: false
      },
      {
        headerName: "Capt. City",
        field: "captainPerson.address.city",
        sortable: false
      },
      {
        headerName: "Capt. State",
        field: "captainPerson.address.state",
        sortable: false
      },
      {
        headerName: "Capt. Zip",
        field: "captainPerson.address.zipcode",
        sortable: false
      },
      {
        headerName: "Capt. Phone",
        field: "captainPerson.phone",
        sortable: false
      },
      {
        headerName: "Capt. Shirt Size",
        field: "captainPerson.shirtSize",
        sortable: false
      }
    ];

    const onGridReady = params => {
      gridApi = params.api;
      gridColumnApi = params.columnApi;
    };

    const exportToCSV = () => {
      /** const params = {
        columnSeparator: "|"
      }; **/
      gridApi.exportDataAsCsv();
    };

    const rowClassRules = {
      "team-is-active": params => {
        let active = params.data.active;
        return active;
      },
      "team-not-active": "!data.active"
    };

    return {
      columnDefs,
      teamRowData,
      onGridReady,
      exportToCSV,
      defaultColDef,
      rowClassRules,
      isActive,
      setTeams,
      activeTeamsOptions,
      inActiveTeamOptions,
      resetFilters
    };
  }
});
let filterReportDate = {
  comparator: function(filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    dateAsString.substring(0, 10);
    var yearMonthDay = dateAsString.split("T");
    var dateParts = yearMonthDay[0].split("-");
    var cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2])
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }

    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true
};
window.dateFormatter = date => {
  let dateWithoutTime = null;
  if (date) {
    dateWithoutTime = date.value.split("T");
    return dateWithoutTime[0];
  }
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
.the-report-grid {
  height: 800px;
}

.active {
  @apply bg-blue-500 text-white;
}
</style>
