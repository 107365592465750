<template>
  <div>
    <label for="size_list" class="form-label">
      Select A Shirt Size
    </label>
    <select
      id="size"
      v-model.trim="validate.size.$model"
      class="form-select sm:mr-2 p-3 text-base"
      aria-label="Size"
      :class="{ 'border-theme-6': validate.size.$error }"
      @change="$emit('update:modelValue', $event.target.value)"
    >
      <option value="" disabled selected>Size</option>
      <option
        v-for="(shirtSize, index) in shirtSizes"
        :key="index"
        :value="shirtSize"
      >
        {{ shirtSize }}
      </option></select
    >
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
  props: {
    sizeValue: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    shirtSizes: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    touch: {
      type: Boolean,
      required: false
    }
  },
  setup() {
    const validate = useVuelidate();

    return {
      validate
    };
  },
  data() {
    return {
      size: this.sizeValue
    };
  },
  watch: {
    sizeValue: function(val) {
      if (!val) {
        this.validate.$reset();
      }
      this.size = val;
    },
    touch: function(val) {
      if (val) {
        this.validate.size.$touch();
      }
    }
  },
  validations() {
    return {
      size: { required }
    };
  }
});
</script>
