<template>
  <div>
    <label for="team_city" class="form-label sr-only">
      Team City
    </label>
    <select
      id="team_city"
      v-model.trim="validate.team_city.$model"
      class="form-select sm:mr-2 p-3 sm:mt-3 text-base"
      aria-label="Team City"
      :class="{ 'border-theme-6': validate.team_city.$error }"
      @change="$emit('update:modelValue', $event.target.value)"
    >
      <option value="" disabled selected>Select Team City</option>
      <option value="Oklahoma City">Oklahoma City</option>
      <option value="Tulsa">Tulsa</option>
    </select>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { required, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
  props: {
    eventCityValue: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    touch: {
      type: Boolean,
      required: false
    }
  },
  setup: () => ({ validate: useVuelidate() }),
  data() {
    return {
      team_city: this.eventCityValue
    };
  },
  watch: {
    touch: function(val) {
      if (val) {
        this.validate.team_city.$touch();
      }
    }
  },
  validations() {
    return {
      team_city: { required, minLength: minLength(2) }
    };
  }
});
</script>
