<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.13 84.3" :fill="fill">
    <path
      d="M1247.53,796c1.39-3.71,2.39-7.64,4.27-11.08a7.89,7.89,0,0,1,12.62-2.13c3,2.72,5.86,1.84,8.66.16,4.25-2.55,6.26-11.51,3.74-15.78-2.43-4.1-7.63-4.08-10.14.06-1.66,2.74-2.5,7.52-6.49,6.19-2.56-.86-4.68-4.7-5.88-7.66-2.92-7.2-1.87-9.55,5.62-11.6,5.38-1.47,11.14-1.55,16.65-2.62,1.9-.36,3.58-1.84,5.36-2.8-1-1.71-1.69-3.75-3.08-5.05-5.3-4.95-4.32-13.24,2.77-16.46,3.57-1.62,8.07-2,12.06-1.67a9.73,9.73,0,0,1,8.88,7c1.38,4.31-.28,7.83-3,11-.64.76-1.69,1.26-2.11,2.1-.83,1.65-2.27,3.86-1.74,5.08s3.29,2.49,4.83,2.25c4-.63,8.25-1.4,11.71-3.34,4.1-2.31,6.32-1.34,7.65,2.49a24.77,24.77,0,0,1,1.41,8.26c-.07,6.83-4.53,10.15-10.54,7.17-6.34-3.14-10.59-.73-12,6.79-.86,4.62,1,8.29,5.73,8.83a49.5,49.5,0,0,0,13.28-.76c5.72-.93,8.12,1.21,6.26,6.73-2.52,7.47-7.8,12.82-14.78,16.16a8.32,8.32,0,0,1-10.09-1.9,26.49,26.49,0,0,0-37.55-.65c-.47.47-.94.94-1.39,1.43-7.23,7.87-9,7.56-12.7-2.23Z"
      transform="translate(-1247.53 -725.45)"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    fill: {
      type: String,
      default: function() {
        return "#bf202e;";
      },
      required: false
    }
  }
});
</script>
