<template>
  <div class="col-span-12">
    <div class="grid grid-cols-12 gap-4 gap-y-5">
      <!--Event Shirt-->
      <div class="intro-y col-span-12 sm:col-span-12 form-check mt-2 mb-2">
        <div class="w-full sm:w-auto flex items-center mt-3 sm:mt-0">
          <input
            id="has-event-shirt"
            v-model.trim="has_event_shirt"
            class="form-check-switch mr-0 ml-0"
            type="checkbox"
            :checked="has_event_shirt"
            @change="$emit('update:hasEventShirt', $event.target.checked)"
          />
          <label class="form-check-label font-medium text-base" for="has-5k"
            >Enable Products
            <a
              href="javascript:;"
              name="has-event-shirt"
              class="tooltip ml-1 relative -top-1 bg-gray-700 text-white px-1 rounded-full text-xs font-bold inline-block"
              >?</a
            ></label
          >
          <div class="tooltip-content">
            <TippyContent to="has-event-shirt">
              <div class="font-medium dark:text-gray-300">
                Event Shirt
              </div>
              <div class="flex items-center mt-2 sm:mt-0">
                <p>
                  This will enable products and add a call to action for people
                  to add a product to their purchase.
                </p>
              </div>
            </TippyContent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
// import { required, numeric } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
export default defineComponent({
  props: {
    hasEventShirt: {
      type: Boolean,
      required: false
    },
    touch: {
      type: Boolean,
      required: false
    }
  },
  setup: () => ({ validate: useVuelidate() }),
  data() {
    return {
      has_event_shirt: this.hasEventShirt
    };
  },
  watch: {
    hasEventShirt: function() {
      this.has_event_shirt = this.hasEventShirt;
    }
  },
  validations() {
    return {};
  }
});
</script>
