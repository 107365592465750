<template>
  <div>
    <label
      for="password"
      class="form-label placeholder-label relative w-full mt-3 text-base"
    >
      <input
        id="password"
        v-model.trim="validate.password.$model"
        :type="showPassword ? 'text' : 'password'"
        class="form-control p-3 text-base"
        :class="{
          'border-theme-6': validate.password.$error
        }"
        placeholder=" "
        autocomplete="new-password"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <span
        class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
        >Password</span
      >
    </label>
    <div
      v-for="error of validate.password.$errors"
      :key="error.$uid"
      class="input-errors"
    >
      <div class="error-msg text-theme-6 mt-2">
        {{ error.$message }}
      </div>
    </div>
    <div v-show="password && !validate.password.$errors.length > 0">
      <div class="w-full grid grid-cols-12 gap-4 h-1 mt-3">
        <div class="col-span-3 h-full rounded bg-theme-9"></div>
        <div class="col-span-3 h-full rounded bg-theme-9"></div>
        <div class="col-span-3 h-full rounded bg-theme-9"></div>
        <div class="col-span-3 h-full rounded bg-theme-9"></div>
      </div>
      <div class="text-theme-9 mt-2">Strong password</div>
    </div>
    <div class="show-password mt-4 mb-4">
      <input
        id="show_password"
        class="form-check-input"
        type="checkbox"
        @change="toggleShowPassword()"
      />
      <label class="form-check-label text-base" for="show_password"
        >Show Password</label
      >
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { required, minLength, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
  props: {
    touch: {
      type: Boolean,
      required: false
    }
  },
  setup() {
    const showPassword = ref(false);
    const validate = useVuelidate();

    const toggleShowPassword = () => {
      showPassword.value = !showPassword.value;
    };

    return {
      showPassword,
      toggleShowPassword,
      validate
    };
  },
  data() {
    return {
      password: ""
    };
  },
  watch: {
    touch: function(val) {
      if (val) {
        this.validate.password.$touch();
      }
    }
  },
  validations() {
    const containsUppercase = value => {
      return /[A-Z]/.test(value);
    };
    const containsLowercase = value => {
      return /[a-z]/.test(value);
    };
    const containsNumber = value => {
      return /[0-9]/.test(value);
    };
    const containsSpecial = value => {
      return /[#?!@$%^&*-]/.test(value);
    };

    const passwordValidate = value => {
      return (
        containsUppercase(value) &&
        containsLowercase(value) &&
        containsNumber(value) &&
        containsSpecial(value)
      );
    };

    return {
      password: {
        required,
        minLength: minLength(8),
        passwordValidate: helpers.withMessage(
          "Must contain an uppercase, lowercase, number and special character",
          passwordValidate
        )
      }
    };
  }
});
</script>
