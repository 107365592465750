<template>
  <div class="grid grid-cols-12 gap-6 mt-12">
    <!-- BEGIN: Team History -->
    <div class="col-span-12 md:col-span-12 -mt-6">
      <div v-if="teamHistory && teamHistory.length > 0">
        <div
          v-for="history in teamHistory"
          :key="history.year"
          class="intro-y box p-5 mb-6"
        >
          <h3 class="font-bold text-base">{{ history.year }}</h3>
          <h2 class="font-bold text-3xl">{{ history.teamData.name }}</h2>
          <div class="grid grid-cols-12 gap-6 mt-2">
            <div class="col-span-12 lg:col-span-4">
              <h4 class="font-bold">Captain Info</h4>
              <p class="text-base">
                {{ history.teamData.captainPerson.firstName }}
                {{ history.teamData.captainPerson.lastName }}
              </p>
              <p>{{ history.teamData.captainPerson.email }}</p>
              <p>{{ history.teamData.captainPerson.phone }}</p>
              <p
                v-if="history.teamData.captainPerson?.address?.city"
                class="mt-3"
              >
                {{ history.teamData.captainPerson?.address?.address }} <br />
                {{ history.teamData.captainPerson?.address?.city }},
                {{ history.teamData.captainPerson?.address?.state }}
                {{ history.teamData.captainPerson?.address?.zipcode }}
              </p>
              <p v-if="history.teamData.captainPerson.lifeChurch" class="mt-3">
                <span class="font-bold">Life.Church Location:</span>
                {{ history.teamData.captainPerson.lifeChurchLocation }}
              </p>
              <p v-if="history.teamData.captainPerson.shirtSize" class="mt-3">
                <span class="font-bold">Shirt Size:</span>
                {{ history.teamData.captainPerson.shirtSize }}
              </p>
              <div v-if="history.teamData.captainPerson" class="mt-6">
                <router-link
                  class="btn btn-outline"
                  :to="`/participants/${history.teamData.captainPerson.id}`"
                  ><EyeIcon class="h-4 w-4 mr-1" /> View Current
                  Profile</router-link
                >
              </div>
            </div>
            <div class="col-span-12 lg:col-span-4">
              <h4 class="font-bold">Team Results</h4>
              <p class="text-base mt-3">
                <span class="font-bold text-sm">Total Raised</span>
                ${{ history.teamData.totalRaised }}
              </p>
              <p class="text-base">
                <span class="font-bold text-sm">Total Participants</span>
                {{ history.teamData.members.length }}
              </p>
              <p class="mt-3">
                <span class="font-bold">Donation Goal</span>
                ${{ history.teamData.teamDonationGoal }}
              </p>
              <p>
                <span class="font-bold">Member Goal</span>
                {{ history.teamData.teamMemberGoal }}
              </p>
              <p class="mt-3">
                <span class="block"
                  ><CheckIcon
                    v-if="history.teamData.hasFacebookFundraiser"
                    class="h-4 w-4 mr-1"
                  /><XIcon v-else class="h-4 text-red w-4 mr-1" /> Facebook
                  Fundraiser
                </span>
                <span class="block"
                  ><CheckIcon
                    v-if="history.teamData.hasCommunityEvent"
                    class="h-4 w-4 mr-1"
                  /><XIcon v-else class="h-4 text-red w-4 mr-1" /> Team Event
                </span>
                <span class="block"
                  ><CheckIcon
                    v-if="history.teamData.hasEmployeeMatch"
                    class="h-4 w-4 mr-1"
                  /><XIcon v-else class="h-4 text-red w-4 mr-1" /> Employee
                  Match
                </span>
              </p>
            </div>
            <div class="col-span-12 lg:col-span-4">
              <h4 class="font-bold mb-3">Team Members</h4>
              <ul>
                <li
                  v-for="member in history.teamData.members"
                  :key="member.personId"
                  class="mb-1"
                >
                  <router-link
                    class="underline"
                    :to="`/participants/${member.personId}`"
                    >{{ member.firstName }} {{ member.lastName }}</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="intro-y box p-5">
          <h3 class="font-bold text-2xl">No Team History Found</h3>
        </div>
      </div>
    </div>
    <!-- END: Team History -->
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { getTeamHistory } from "@/api/teams.js";
export default defineComponent({
  components: {},
  props: {
    teamId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    console.log(props.teamId);
    let teamHistory = ref([]);

    const getHistory = async () => {
      teamHistory.value = await getTeamHistory(props.teamId);
    };

    onMounted(async () => {
      await getHistory();
    });
    return {
      teamHistory
    };
  }
});
</script>
