<template>
  <div class="grid grid-cols-12 gap-6">
    <!-- BEGIN: Goals -->
    <div class="intro-y box col-span-12 lg:col-span-6">
      <div
        class="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5"
      >
        <h2 class="font-medium py-2 text-base mr-auto">
          Team Goals
        </h2>
        <button
          v-show="!editGoals"
          class="btn btn-outline-secondary flex"
          @click="toggleEditGoals()"
        >
          <span v-show="!editGoals">
            <EditIcon class="w-4 h-4 mr-2" />
            Edit Goals
          </span>
        </button>
      </div>
      <div class="p-5">
        <div v-show="!editGoals" class="section-content">
          <h3 class="font-medium">
            Financial Goal
          </h3>
          <div class="flex">
            <div class="mr-auto">${{ totalRaised }} Raised</div>
            <div>${{ teamDonationGoal }}</div>
          </div>
          <div class="progress h-1 mt-2">
            <div
              :style="{
                width: calculatePercentage(totalRaised, teamDonationGoal) + '%'
              }"
              class="progress-bar bg-theme-9"
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <h3 class="font-medium mt-6">Team Membership Goal</h3>
          <div class="flex">
            <div class="mr-auto">{{ totalMembers.length }} Team Members</div>
            <div>{{ teamMemberGoal }}</div>
          </div>
          <div class="progress h-1 mt-2">
            <div
              :style="{
                width:
                  calculatePercentage(totalMembers.length, teamMemberGoal) + '%'
              }"
              class="progress-bar bg-theme-9"
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <div v-show="editGoals" class="section-content grid grid-cols-12 gap-6">
          <div class="col-span-12 lg:col-span-6">
            <label for="fundraisingGoal" class="form-label">
              Fundraising Goal
            </label>
            <div class="input-group">
              <div id="input-group-email" class="input-group-text">$</div>
              <input
                v-model="teamData.teamDonationGoal"
                type="number"
                for="fundraising-goal"
                class="form-control"
                placeholder="Fundraising Goal"
              />
            </div>
          </div>
          <div class="col-span-12 lg:col-span-6">
            <label for="memberGoal" class="form-label">
              Team Membership Goal
            </label>
            <input
              v-model="teamData.teamMemberGoal"
              type="number"
              for="membership-goal"
              class="form-control"
              placeholder="Team Membership Goal"
            />
          </div>
          <div class="col-span-12 flex justify-between">
            <button
              class="btn btn-outline-secondary block sm:flex"
              @click="toggleEditGoals()"
            >
              Cancel
            </button>
            <button
              class="btn btn-primary block sm:flex"
              @click="toggleEditGoals(), updateTeamGoals()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Goals -->
    <!-- BEGIN: Team Message -->
    <div class="intro-y box col-span-12 lg:col-span-6">
      <div
        class="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5"
      >
        <h2 class="font-medium py-2 text-base mr-auto">
          Team Message
        </h2>
        <button
          v-show="!editMessage"
          class="btn btn-outline-secondary sm:flex"
          @click="toggleEditMessage()"
        >
          <span v-show="!editMessage">
            <EditIcon class="w-4 h-4 mr-2" />
            Edit Message
          </span>
        </button>
      </div>
      <div class="p-5">
        <div v-show="!editMessage" class="section-content">
          <div v-html="parsedMessage"></div>
        </div>
        <div
          v-show="editMessage"
          class="section-content grid grid-cols-12 gap-6"
        >
          <div class="col-span-12 lg:col-span-12">
            <CKEditor
              v-model="parsedMessage"
              :editor="classicEditor"
              :config="simpleEditorConfig"
            />
          </div>
          <div class="col-span-12 flex justify-between">
            <button
              class="btn btn-outline-secondary block sm:flex"
              @click="toggleEditMessage()"
            >
              Cancel
            </button>
            <button
              class="btn btn-primary block sm:flex"
              @click="toggleEditMessage(), updateTeamMessage()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Team Message -->
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRef, watch } from "vue";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import UnderlinePlugin from "@ckeditor/ckeditor5-basic-styles/src/underline";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";

export default defineComponent({
  props: {
    teamDonationGoal: {
      type: Number,
      default: function() {
        return 0;
      },
      required: true
    },
    teamMemberGoal: {
      type: Number,
      default: function() {
        return 0;
      },
      required: true
    },
    totalRaised: {
      type: Number,
      default: function() {
        return 0;
      },
      required: true
    },
    totalMembers: {
      type: Array,
      default: function() {
        return [];
      }
    },
    teamMessage: {
      type: String,
      default: function() {
        return "";
      }
    }
  },
  emits: ["updateTeamData"],
  setup(props, { emit }) {
    const classicEditor = ClassicEditor;
    const simpleEditorConfig = {
      plugins: [
        ParagraphPlugin,
        BoldPlugin,
        UnderlinePlugin,
        ItalicPlugin,
        LinkPlugin
      ],
      toolbar: {
        items: ["bold", "italic", "underline", "link"]
      }
    };
    const calculatePercentage = (value, total) => {
      let percentage = (100 * value) / total;
      if (percentage > 100) {
        return 100;
      }
      return percentage;
    };
    const returnparsedMessage = message => {
      const parser = new DOMParser();
      const elem = parser.parseFromString(message, "text/html");
      return elem.body.innerText;
    };
    let editGoals = ref(false);
    let editMessage = ref(false);
    let newTeamMessage = ref("");

    let theTeamMessage = toRef(props, "teamMessage");
    let theTeamDonationGoal = toRef(props, "teamDonationGoal");
    let theTeamMemberGoal = toRef(props, "teamMemberGoal");
    let parsedMessage = ref(returnparsedMessage(props.teamMessage));

    let teamData = reactive({
      teamDonationGoal: props.teamDonationGoal,
      teamMemberGoal: props.teamMemberGoal
    });

    let teamMessageData = reactive({
      teamMessage: parsedMessage
    });

    watch(
      () => [
        theTeamDonationGoal.value,
        theTeamMemberGoal.value,
        theTeamMessage.value
      ],
      updatedData => {
        teamData.teamDonationGoal = updatedData[0]; //New Value for DonationGoal
        teamData.teamMemberGoal = updatedData[1]; //New Value for Member Goal
        teamData.teamMessage = updatedData[2]; //New value for team message
        parsedMessage.value = returnparsedMessage(updatedData[2]);
      },
      {
        lazy: false // immediate: true
      }
    );

    const updateTeamGoals = () => {
      emit("updateTeamData", teamData);
    };

    const updateTeamMessage = () => {
      emit("updateTeamData", teamMessageData);
    };

    return {
      classicEditor,
      simpleEditorConfig,
      editGoals,
      editMessage,
      updateTeamGoals,
      updateTeamMessage,
      teamData,
      parsedMessage,
      newTeamMessage,
      calculatePercentage
    };
  },
  methods: {
    toggleEditGoals() {
      this.editGoals = !this.editGoals;
    },
    toggleEditMessage() {
      this.editMessage = !this.editMessage;
    }
  }
});
</script>
<style lang="css">
.ck-editor__editable {
  min-height: 300px;
}
</style>
