<template>
  <div class="grid grid-cols-12 gap-6">
    <!-- BEGIN: Badges -->
    <div class="intro-y box col-span-12 lg:col-span-4">
      <div
        class="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5"
      >
        <h2 class="font-medium text-base mr-auto">
          Fundraising Badges
        </h2>
        <a
          :href="eventSettings.donationBadgeTipsUrl"
          class="btn btn-outline-secondary hidden sm:flex"
          target="_blank"
        >
          <ExternalLinkIcon class="w-4 h-4 mr-2" /> Tips
        </a>
      </div>
      <div class="p-5 pt-0">
        <div class="section-content">
          <div
            v-for="badge in fundraisingBadges"
            :key="badge.goal"
            class="mt-5 relative"
          >
            <div
              v-if="calculatePercentage(badgesTotalRaised, badge.goal) >= 100"
              class="completed absolute z-10 w-40 m-auto left-0 right-0 top-0 bottom-0"
            >
              <div
                class="py-2 px-4 shadow-md bg-white text-center border-2 border-solid border-theme-9"
              >
                <AwardIcon class="h-4 w-4 mr-2" />
                <span class="mb-1 uppercase">Congrats!</span>
              </div>
            </div>
            <div
              :class="{
                'opacity-25':
                  calculatePercentage(badgesTotalRaised, badge.goal) >= 100,
                'text-base':
                  calculatePercentage(badgesTotalRaised, badge.goal) < 100
              }"
              class="flex"
            >
              <div class="mr-auto">${{ badge.goal }} Raised</div>
              <div>
                {{ calculatePercentage(badgesTotalRaised, badge.goal) }}%
              </div>
            </div>
            <div class="progress h-1 mt-2">
              <div
                :style="{
                  width:
                    calculatePercentage(badgesTotalRaised, badge.goal) + '%'
                }"
                class="progress-bar bg-theme-9"
                role="progressbar"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Fundraising Badges -->
    <!-- BEGIN: Participation Badges -->
    <div class="intro-y box col-span-12 lg:col-span-4">
      <div
        class="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5"
      >
        <h2 class="font-medium text-base mr-auto">
          Participation Badges
        </h2>
        <a
          :href="eventSettings.participationBadgeTipsUrl"
          class="btn btn-outline-secondary hidden sm:flex"
          target="_blank"
        >
          <ExternalLinkIcon class="w-4 h-4 mr-2" /> Tips
        </a>
      </div>
      <div class="p-5 pt-0">
        <div
          v-for="badge in participationBadges"
          :key="badge.goal"
          class="relative flex items-center mt-5"
        >
          <div
            v-if="
              calculatePercentage(badgesTotalParticipants, badge.goal) > 100
            "
            class="completed absolute z-10 w-40 m-auto right-0 top-0 bottom-0"
          >
            <div
              class="py-2 px-4 shadow-md bg-white text-center border-2 border-solid border-theme-9"
            >
              <AwardIcon class="h-4 w-4 mr-2" />
              <span class="mb-1 uppercase">Congrats!</span>
            </div>
          </div>
          <div
            :class="{
              'opacity-25':
                calculatePercentage(badgesTotalParticipants, badge.goal) > 100
            }"
            class="w-12 h-12 flex-none image-fit"
          >
            <img
              alt="{{ badge.goal }} Members Badge"
              class="rounded-full"
              :src="badge.image"
            />
          </div>
          <div
            :class="{
              'opacity-25':
                calculatePercentage(badgesTotalParticipants, badge.goal) > 100
            }"
            class="ml-4 mr-auto"
          >
            <p class="text-base font-medium">{{ badge.goal }} Members</p>
            <div class="text-gray-600 mr-5 sm:mr-5">
              Recruit {{ badge.goal }} Team Members
            </div>
          </div>
          <div
            :class="{
              'opacity-25':
                calculatePercentage(badgesTotalParticipants, badge.goal) > 100
            }"
            class="font-medium text-theme-11 dark:text-gray-600"
          >
            <span
              >{{
                calculatePercentage(badgesTotalParticipants, badge.goal)
              }}%</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- END: Participation Badges -->
    <!-- BEGIN: Event Badges -->
    <div
      v-if="
        eventSettings.facebookFundraiser.active ||
          eventSettings.employerMatch.active ||
          eventSettings.teamEvent.active
      "
      class="intro-y box col-span-12 lg:col-span-4"
    >
      <div
        class="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5"
      >
        <h2 class="font-medium text-base mr-auto">
          Event Badges
        </h2>
        <a
          v-if="eventSettings.teamEvent.active"
          :href="eventSettings.submitEventUrl"
          target="_blank"
          class="btn btn-outline-secondary hidden sm:flex"
        >
          <CalendarIcon class="w-4 h-4 mr-2" /> Submit Event
        </a>
      </div>
      <div class="p-5">
        <div
          v-if="eventSettings.facebookFundraiser.active"
          class="section-content"
        >
          <div class="flex items-center">
            <p class="italic mb-3">
              To receive these badges you need to submit your event using the
              button above.
            </p>
          </div>
          <div class="flex items-center">
            <div class="border-l-2 border-theme-1 pl-4">
              <p class="font-medium">Facebook Fundraiser</p>
              <div class="text-gray-600">
                Create a Facebook fundariser event
              </div>
            </div>
            <input
              id="has-facebook"
              v-model="hasFacebookFundraiser"
              class="form-check-switch ml-auto"
              type="checkbox"
              :disabled="!isAdmin"
              :checked="hasFacebookFundraiser"
              @change="updateFacebook()"
            />
          </div>
          <div
            v-if="eventSettings.teamEvent.active"
            class="flex items-center mt-5"
          >
            <div class="border-l-2 border-theme-1 pl-4">
              <p class="font-medium">Team Event</p>
              <div class="text-gray-600">
                Create an event for your Team
              </div>
            </div>
            <input
              id="has-community-event"
              v-model="hasCommunityEvent"
              class="form-check-switch ml-auto"
              type="checkbox"
              :disabled="!isAdmin"
              :checked="hasCommunityEvent"
              @change="updateCommunityEvent()"
            />
          </div>
          <div
            v-if="eventSettings.employerMatch.active"
            class="flex items-center mt-5"
          >
            <div class="border-l-2 border-theme-1 pl-4">
              <p class="font-medium">Employee Match</p>
              <div class="text-gray-600">
                Ask your employer to match your donations.
              </div>
            </div>
            <input
              id="has-employee-match"
              v-model="hasEmployeeMatch"
              class="form-check-switch ml-auto"
              type="checkbox"
              :disabled="!isAdmin"
              :checked="hasEmployeeMatch"
              @change="updateEmployeeMatch()"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- END: Event Badges -->
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "@/store";
export default defineComponent({
  components: {},
  props: {
    facebookFundraiser: {
      type: Boolean,
      required: true
    },
    employeeMatch: {
      type: Boolean,
      required: true
    },
    communityEvent: {
      type: Boolean,
      required: true
    },
    totalParticipants: {
      type: Number,
      default: function() {
        return 0;
      },
      requried: true
    },
    totalRaised: {
      type: Number,
      default: function() {
        return 0;
      },
      requried: true
    }
  },
  emits: ["updateTeamData"],
  setup(props, { emit }) {
    const store = useStore();
    const eventSettings = computed(() => store.getters["main/eventSettings"]);
    const isAdmin = ref(false);
    let hasFacebookFundraiser = ref(props.facebookFundraiser);
    let hasEmployeeMatch = ref(props.employeeMatch);
    let hasCommunityEvent = ref(props.communityEvent);

    let badgesTotalParticipants = ref(props.totalParticipants);
    let badgesTotalRaised = ref(props.totalRaised);

    const sortedBadges = eventSettings.value.eventBadges.sort(function(a, b) {
      return a.goal - b.goal;
    });

    const participationBadges = computed(() => {
      return sortedBadges.filter(badge => {
        return badge.type === "participant";
      });
    });

    const fundraisingBadges = computed(() => {
      return sortedBadges.filter(badge => {
        return badge.type === "fundraising";
      });
    });

    const updateFacebook = () => {
      let updateData = {
        hasFacebookFundraiser: hasFacebookFundraiser.value
      };
      emit("updateTeamData", updateData);
    };

    const updateEmployeeMatch = () => {
      let updateData = {
        hasEmployeeMatch: hasEmployeeMatch.value
      };
      emit("updateTeamData", updateData);
    };

    const updateCommunityEvent = () => {
      let updateData = {
        hasCommunityEvent: hasCommunityEvent.value
      };
      emit("updateTeamData", updateData);
    };

    const calculatePercentage = (value, total) => {
      let percentage = (100 * value) / total;
      if (percentage >= 100) {
        return 100;
      }
      return percentage;
    };

    onMounted(async () => {
      const isAdminResponse = await store.getters["auth/isAdmin"];
      isAdmin.value = isAdminResponse;
    });

    return {
      isAdmin,
      hasFacebookFundraiser,
      hasEmployeeMatch,
      hasCommunityEvent,
      badgesTotalParticipants,
      badgesTotalRaised,
      eventSettings,
      updateFacebook,
      updateEmployeeMatch,
      updateCommunityEvent,
      calculatePercentage,
      participationBadges,
      fundraisingBadges
    };
  }
});
</script>
