import * as types from "./mutation-types";
import { getTeamList } from "@/api/teams.js";

const state = () => {
  return {
    supporting: {
      type: "",
      eventDetails: {
        value: null,
        city: ""
      },
      teamDetails: {
        value: null,
        name: ""
      }
    },
    teamList: [],
    cityList: [
      {
        id: 1,
        name: "Oklahoma City"
      },
      {
        id: 2,
        name: "Tulsa"
      }
    ],
    initState: {
      supporting: {
        type: "",
        eventDetails: {
          value: null,
          city: ""
        },
        teamDetails: {
          value: null,
          name: ""
        }
      },
      teamList: [],
      cityList: [
        {
          id: 1,
          name: "Oklahoma City"
        },
        {
          id: 2,
          name: "Tulsa"
        }
      ]
    }
  };
};

// getters
const getters = {
  supporting: state => state.supporting,
  teamList: state => state.teamList,
  cityList: state => state.cityList
};

// actions
const actions = {
  setSupporting: async ({ commit }, supporting) => {
    commit(types.SET_SUPPORTING, { supporting });
  },
  setTeamList: async ({ commit }) => {
    const teams = await getTeamList();
    commit(types.SET_TEAM_LIST, { teams });
  },
  resetSupport: async ({ commit }) => {
    commit(types.RESET_SUPPORT);
  }
};

// mutations
const mutations = {
  [types.SET_SUPPORTING](state, { supporting }) {
    state.supporting = supporting;
  },
  [types.SET_TEAM_LIST](state, { teams }) {
    state.teamList = teams;
  },
  [types.RESET_SUPPORT](state) {
    state.supporting = state.initState.supporting;
    state.teamList = state.initState.teamList;
    state.cityList = state.initState.cityList;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
