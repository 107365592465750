<template>
  <div>
    <div
      class="flex justify-start items-center col-span-12 rounded intro-y border-2 border-solid border-theme-9 px-3 py-2"
    >
      <div class="inline-block">
        <CheckCircleIcon class="w-6 h-6 mr-4 fill-theme-9 inline-block" />
      </div>
      <div class="donate-info inline-block flex-grow">
        <h6 class="text-xs">Contact</h6>
        <h4 class="text-base">
          <span> {{ contact.first_name }} {{ contact.last_name }} </span>
        </h4>
      </div>
      <div class="edit-donate-info justify-self-end">
        <button type="button" class="btn btn-sm" @click="editRoute()">
          Edit
        </button>
      </div>
    </div>
    <div
      v-if="teamMembers.members.length > 0 && showTeamMembers"
      class="flex justify-start items-center mt-1 col-span-12 rounded intro-y border-2 border-solid border-theme-9 px-3 py-2"
    >
      <div class="inline-block">
        <CheckCircleIcon class="w-6 h-6 mr-4 fill-theme-9 inline-block" />
      </div>
      <div class="donate-info inline-block flex-grow">
        <h6 class="text-xs">Additional Team Members</h6>
        <h4
          v-for="(member, index) in teamMembers.members"
          :key="index"
          class="text-base"
        >
          <span> {{ member.first_name }} {{ member.last_name }} </span>
        </h4>
      </div>
      <div class="edit-donate-info justify-self-end">
        <button
          type="button"
          class="btn btn-sm"
          @click="editAdditionalTeamMembers()"
        >
          Edit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    contact: {
      type: Object,
      default: function() {
        return {};
      },
      required: false
    },
    showTeamMembers: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    teamMembers: {
      type: Object,
      default: function() {
        return {};
      },
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const appTitle = computed(() => store.getters["main/appTitle"]);
    const appYear = computed(() => store.getters["main/appYear"]);

    const editRoute = () => {
      if (props.teamMembers.members.length > 0) {
        router.push({ path: "/frontend/contact", query: { join: true } });
      } else {
        router.push({ path: "/frontend/contact" });
      }
    };

    const editAdditionalTeamMembers = () => {
      router.push({ path: "/frontend/add-team-member" });
    };

    return {
      appTitle,
      appYear,
      editRoute,
      editAdditionalTeamMembers
    };
  }
});
</script>
