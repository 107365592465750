<template>
  <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Run Settings</h2>
    </div>
    <div class="mt-5">
      <!-- BEGIN: Run Config Content -->
      <div class="config-content">
        <form class="validate-form" @submit.prevent="saveEventSettings">
          <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12">
              <div class="intro-y box mt-2 p-6">
                <RunSettings
                  v-if="eventSettings && eventSettings.event5kSettings"
                  v-model:has5k="eventSettings.has5k"
                  v-model:runnerPrice="
                    eventSettings.event5kSettings.runnerPrice
                  "
                  v-model:hasVirtual="eventSettings.event5kSettings.hasVirtual"
                  v-model:virtualRunnerPrice="
                    eventSettings.event5kSettings.virtualRunnerPrice
                  "
                  :has-5k="eventSettings.has5k"
                  :runner-price="eventSettings.event5kSettings.runnerPrice"
                  :has-virtual="eventSettings.event5kSettings.hasVirtual"
                  :virtual-runner-price="
                    eventSettings.event5kSettings.virtualRunnerPrice
                  "
                />
                <div class="flex justify-end mt-8">
                  <button type="submit" class="btn btn-primary w-20 mr-auto">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- END: Run Config Content -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "@/store";
import { updateEventSettings } from "@/api/event-settings";
import RunSettings from "@/components/form-inputs/RunSettings";

export default defineComponent({
  components: {
    RunSettings
  },
  setup() {
    const validate = useVuelidate();
    const store = useStore();
    const eventSettings = computed(() => store.getters["main/eventSettings"]);

    const sendNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "Event settings have been updated."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem updating the event settings."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const saveEventSettings = async () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        let response = {
          code: 400
        };
        await sendNotification(response);
      } else {
        const saveEventSettingsResult = await updateEventSettings(
          eventSettings.value
        );
        await sendNotification(saveEventSettingsResult);
      }
    };

    return {
      eventSettings,
      saveEventSettings
    };
  }
});
</script>
