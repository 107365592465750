// import { useStore } from "@/store";
// const store = useStore();

const apiURL = process.env.VUE_APP_API_URL;

const authLogin = async auth => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(auth)
  };

  const response = await fetch(apiURL + "/v1/auth/login", requestOptions);
  const data = await response.json();
  return data;
};

const authLogout = async refreshToken => {
  let logoutRequest = {
    refreshToken: refreshToken
  };

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(logoutRequest)
  };

  const response = await fetch(apiURL + "/v1/auth/logout", requestOptions);
  const data = await response;
  return data;
};

const authRefresh = async refreshToken => {
  let refreshRequest = {
    refreshToken: refreshToken
  };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(refreshRequest)
  };

  const response = await fetch(
    apiURL + "/v1/auth/refresh-tokens",
    requestOptions
  );
  const data = await response.json();
  return data;
};

const authForgotPassword = async email => {
  let emailRequest = {
    email: email
  };

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(emailRequest)
  };

  const response = await fetch(
    apiURL + "/v1/auth/forgot-password",
    requestOptions
  );
  const data = await response.json();
  return data;
};

const authResetPassword = async (token, password) => {
  let passwordRequest = {
    password: password
  };

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(passwordRequest)
  };

  let tokenParam = "?token=" + token;

  const response = await fetch(
    apiURL + "/v1/auth/reset-password/" + tokenParam,
    requestOptions
  );
  const data = await response.json();
  return data;
};

export {
  authLogin,
  authLogout,
  authRefresh,
  authForgotPassword,
  authResetPassword
};
