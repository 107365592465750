<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Runners</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <div class="mt-4 sm:mt-0">
          <form id="grid-search" class="mr-3">
            <div class="w-64 relative text-gray-700 dark:text-gray-300">
              <input
                v-model="searchQuery"
                type="text"
                class="form-control p-3 w-64 box pr-10 placeholder-theme-13"
                placeholder="Search..."
              />
              <SearchIcon
                class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
              />
            </div>
          </form>
        </div>
        <div class="hidden md:block mx-auto text-gray-600">
          {{ runnerData.totalResults }} Total Runners
        </div>
      </div>
    </div>
    <div class="intro-y mt-5">
      <RunnersGrid :grid-data="formattedRunners" :query="searchQuery" />
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getLineItems } from "@/api/lineitems.js";
import RunnersGrid from "@/components/back-end/RunnersGrid";

export default defineComponent({
  components: {
    RunnersGrid
  },
  setup() {
    let runnerData = reactive([]);
    let formattedRunners = reactive([]);
    let searchQuery = ref("");

    const getRunnerData = async () => {
      let runnerRequest = "?type=5k&limit=100";
      let allRunners = await getLineItems(runnerRequest);
      Object.assign(runnerData, allRunners);
      reformattedRunners(allRunners.results);
    };

    const reformattedRunners = runners => {
      let formattedRunnerData = [];
      runners.map(r => {
        let createdAt = new Date(r.createdAt);
        let obj = {};
        obj.id = r.id;
        obj.type = r.type;
        obj.primaryContact = r.primaryContact;
        if (r.team) obj.hasTeam = true;
        obj.eventCity = r.eventCity;
        obj.eventYear = r.eventYear;
        obj.amount = r.amount;
        obj.transactionId = r.transaction.id;
        obj.hasShipped = r.transaction.hasShipped;
        if (r.runnerDetails) {
          obj.runnerName =
            r.runnerDetails.firstName + " " + r.runnerDetails.lastName;
          obj.runnerEmail = r.runnerDetails.email;
          obj.runType = r.runnerDetails.runType;
          obj.runShirt = r.runnerDetails.shirtSize;
          if (r.runnerDetails.gender) {
            obj.gender = r.runnerDetails.gender;
          }
          if (r.runnerDetails.birthDate) {
            obj.birthDate = r.runnerDetails.birthDate;
          }
          if (r.runnerDetails.shipping) {
            obj.shipping = r.runnerDetails.shipping;
          }
        }
        obj.created = createdAt
          .toDateString()
          .split(" ")
          .slice(1)
          .join(" ");

        formattedRunnerData.push(obj);
      });
      Object.assign(formattedRunners, formattedRunnerData);
    };

    onMounted(() => {
      getRunnerData();
    });

    return {
      runnerData,
      formattedRunners,
      searchQuery
    };
  }
});
</script>
