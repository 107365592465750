<template>
  <div class="col-span-12">
    <div class="grid grid-cols-12 gap-4 gap-y-5">
      <!--Has 5k-->
      <div class="intro-y col-span-12 sm:col-span-12 form-check mt-2 mb-2">
        <div class="w-full sm:w-auto flex items-center mt-3 sm:mt-0">
          <input
            id="has-5k"
            v-model.trim="has_5k"
            class="form-check-switch mr-0 ml-0"
            type="checkbox"
            :checked="has_5k"
            @change="$emit('update:has5k', $event.target.checked)"
          />
          <label class="form-check-label font-medium text-base" for="has-5k"
            >Enable 5k
            <a
              href="javascript:;"
              name="has-5k"
              class="tooltip ml-1 relative -top-1 bg-gray-700 text-white px-1 rounded-full text-xs font-bold inline-block"
              >?</a
            ></label
          >
          <div class="tooltip-content">
            <TippyContent to="has-5k">
              <div class="font-medium dark:text-gray-300">
                5k
              </div>
              <div class="flex items-center mt-2 sm:mt-0">
                <p>
                  This will enable 5k settings, routes and reports.
                </p>
              </div>
            </TippyContent>
          </div>
        </div>
      </div>
      <!--Runner Price-->
      <div
        v-if="has5k"
        class="intro-y col-span-12 sm:col-span-12 event-name-field"
      >
        <div class="runner-price">
          <label
            for="runner_price"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="runner_price"
              v-model.number="validate.runner_price.$model"
              type="number"
              min="5"
              step="1"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.runner_price.$error }"
              placeholder=" "
              @input="
                $emit('update:runnerPrice', parseInt($event.target.value))
              "
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Runner Price</span
            >
          </label>
          <div
            v-for="error of validate.runner_price.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Has Virtual-->
      <div
        v-if="has5k"
        class="intro-y col-span-12 sm:col-span-12 form-check mt-2 mb-2"
      >
        <div class="w-full sm:w-auto flex items-center mt-3 sm:mt-0">
          <input
            id="has-virtual"
            v-model.trim="has_virtual"
            class="form-check-switch mr-0 ml-0"
            type="checkbox"
            :checked="has_virtual"
            @change="$emit('update:hasVirtual', $event.target.checked)"
          />
          <label
            class="form-check-label font-medium text-base"
            for="has-virtual"
            >Has virtual run option
            <a
              href="javascript:;"
              name="has-virtual"
              class="tooltip ml-1 relative -top-1 bg-gray-700 text-white px-1 rounded-full text-xs font-bold inline-block"
              >?</a
            ></label
          >
          <div class="tooltip-content">
            <TippyContent to="has-virtual">
              <div class="font-medium dark:text-gray-300">
                Virtual Run
              </div>
              <div class="flex items-center mt-2 sm:mt-0">
                <p>
                  This will enable an option for users to select a virtual run
                  option with seperate pricing.
                </p>
              </div>
            </TippyContent>
          </div>
        </div>
      </div>
      <!--Virtual Runner Price-->
      <div
        v-if="hasVirtual && has5k"
        class="intro-y col-span-12 sm:col-span-12 event-name-field"
      >
        <div class="virtual-runner-price">
          <label
            for="virtual_runner_price"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="virtual_runner_price"
              v-model.number="validate.virtual_runner_price.$model"
              type="number"
              min="5"
              step="1"
              class="form-control p-3"
              :class="{
                'border-theme-6': validate.virtual_runner_price.$error
              }"
              placeholder=" "
              @input="
                $emit(
                  'update:virtualRunnerPrice',
                  parseInt($event.target.value)
                )
              "
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Virtual Runner Price</span
            >
          </label>
          <div
            v-for="error of validate.virtual_runner_price.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { required, numeric } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
export default defineComponent({
  props: {
    has5k: {
      type: Boolean,
      required: false
    },
    hasVirtual: {
      type: Boolean,
      required: false
    },
    runnerPrice: {
      type: Number,
      required: false,
      default: () => {
        return 30;
      }
    },
    virtualRunnerPrice: {
      type: Number,
      required: false,
      default: () => {
        return 25;
      }
    },
    touch: {
      type: Boolean,
      required: false
    }
  },
  setup: () => ({ validate: useVuelidate() }),
  data() {
    return {
      has_5k: this.has5k,
      runner_price: this.runnerPrice,
      virtual_runner_price: this.virtualRunnerPrice,
      has_virtual: this.hasVirtual
    };
  },
  watch: {
    has5k: function() {
      this.has_5k = this.has5k;
    },
    runnerPrice: function() {
      this.runner_price = this.runnerPrice;
    },
    hasVirtual: function() {
      this.has_virtual = this.hasVirtual;
    },
    virtualRunnerPrice: function() {
      this.virtual_runner_price = this.virtualRunnerPrice;
    }
  },
  validations() {
    return {
      runner_price: { required, numeric },
      virtual_runner_price: { required, numeric }
    };
  }
});
</script>
