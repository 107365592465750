import { configureRefreshFetch, fetchJSON } from "refresh-fetch";
import customFetch from "./customFetch";
import store from "../store";
const apiURL = process.env.VUE_APP_API_URL;

// Get the refresh token from storage
const retrieveToken = () => {
  const userJson = localStorage.getItem("pwUser");
  if (userJson) {
    const user = JSON.parse(userJson);
    return user.tokens.refresh.token;
  }
};

// Save the tokens to storage.
const saveTokens = async refreshedTokens => {
  const userJson = localStorage.getItem("pwUser");
  if (!userJson) {
    throw new Error("No user found in local storage to set refreshed token.");
  }
  const userData = JSON.parse(userJson);
  userData.tokens = refreshedTokens;
  await store.dispatch("auth/setAuth", userData);
};

// Remove the tokens from storage.
const clearToken = () => {
  localStorage.removeItem("pwUser");
};

// Decide whether this error returned from API means that we want
// to try refreshing the token. error.response contains the fetch Response
// object, error.body contains the parsed JSON response body
const shouldRefreshToken = error => {
  let shouldRefresh = false;

  // Try to refresh if we got a 401 and access token is expired
  if (error.response.status == 401) {
    const userDataJson = localStorage.getItem("pwUser");
    if (userDataJson) {
      const userData = JSON.parse(userDataJson);
      const expires = new Date(userData.tokens.access.expires);
      const now = Date.now();
      shouldRefresh = expires < now;
    }
  }

  return shouldRefresh;
};

// Do the actual token refreshing and update the saved token
const refreshToken = () => {
  console.log("refreshToken");
  const token = retrieveToken();
  const body = { refreshToken: token };

  const url = `${apiURL}/v1/auth/refresh-tokens`;
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(body)
  };

  return fetchJSON(url, fetchOptions)
    .then(response => {
      saveTokens(response.body);
      return response;
    })
    .catch(error => {
      // If we failed by any reason in refreshing, just clear the token
      clearToken();
      throw error;
    });
};

export const fetch = configureRefreshFetch({
  shouldRefreshToken,
  refreshToken,
  fetch: customFetch
});
