<template>
  <div class="grid grid-cols-12 gap-6">
    <!-- BEGIN: Members -->
    <div
      class="order-2 md:order-1 col-span-12 md:col-span-8 -mt-6 overflow-auto lg:overflow-visible"
    >
      <MembersGrid
        :members="members"
        :grid-columns="columns"
        :is-admin="admin"
        @setTeamData="setTeamData"
      />
    </div>
    <div class="order-1 md:order-2 col-span-12 md:col-span-4">
      <AddTeamMember :members="members" @setTeamData="setTeamData" />
    </div>
    <!-- END: Members -->
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MembersGrid from "@/components/back-end/MembersGrid";
import AddTeamMember from "@/components/back-end/AddTeamMember";
export default defineComponent({
  components: {
    MembersGrid,
    AddTeamMember
  },
  props: {
    members: {
      type: Array,
      default: function() {
        return [];
      },
      required: false
    },
    admin: {
      type: Boolean,
      default: function() {
        return false;
      }
    }
  },
  emits: ["setTeamData"],
  setup(props, { emit }) {
    const columns = ["firstName", "lastName", "email", "event"];
    const setTeamData = () => {
      emit("setTeamData");
    };

    return {
      columns,
      setTeamData
    };
  }
});
</script>
