<template>
  <div>
    <input
      id="anonymous"
      v-model.trim="anonymous"
      class="form-check-input"
      type="checkbox"
      @change="$emit('update:anonymous', $event.target.checked)"
    />
    <label class="form-check-label text-base" for="anonymous"
      >List donation as anonymous
      <a
        href="javascript:;"
        name="anonymous"
        class="tooltip ml-1 relative -top-1 bg-gray-700 text-white px-1 rounded-full text-xs font-bold inline-block"
        >?</a
      ></label
    >
    <div class="tooltip-content">
      <TippyContent to="anonymous">
        <div class="font-medium dark:text-gray-300">
          Anonymous
        </div>
        <div class="flex items-center mt-2 sm:mt-0">
          <p>
            Your name will not appear publicly and your donation will be listed
            as 'anonymous'.
          </p>
        </div>
      </TippyContent>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isAnonymous: {
      type: Boolean,
      default: function() {
        return false;
      },
      required: false
    }
  }
});
</script>
