import { fetch } from "@/api/fetch-auto-refresh";
import { useStore } from "@/store";
const store = useStore();
const apiURL = process.env.VUE_APP_API_URL;

const getLineItemsByTeam = async (team, options) => {
  let teamId = team;
  let authToken = store.state.auth.token;
  let request = `?team=${teamId}&sortBy=createdAt:desc`;
  if (options) {
    request = `?team=${teamId}${options}&sortBy=createdAt:desc`;
  }

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  const response = await fetch(
    `${apiURL}/v1/lineitems/${request}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

const getLineItems = async options => {
  let authToken = store.state.auth.token;
  let request = `${options}&populate=transaction&sortBy=createdAt:desc`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  const response = await fetch(
    `${apiURL}/v1/lineitems/${request}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

export { getLineItemsByTeam, getLineItems };
