<template>
  <div class="grid grid-cols-12 gap-6 mt-12">
    <!-- BEGIN: Transactions -->
    <div class="col-span-12 md:col-span-12 -mt-6">
      <TeamTransactionsGrid
        :transactions="teamTransactions"
        :grid-columns="columns"
      />
    </div>
    <!-- END: Transactions -->
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import TeamTransactionsGrid from "@/components/back-end/TeamTransactionsGrid";
export default defineComponent({
  components: {
    TeamTransactionsGrid
  },
  props: {
    transactions: {
      type: Array,
      default: function() {
        return [];
      },
      required: true
    }
  },
  setup(props) {
    const columns = ["id", "name", "email", "amount", "date"];
    const teamTransactions = computed(() => props.transactions);

    return {
      columns,
      teamTransactions
    };
  }
});
</script>
