<template>
  <div>
    <FrontEndHeader fill="#be2032" />
    <!-- BEGIN: Create DonateLayout -->
    <form class="validate-form" @submit.prevent="save">
      <div class="intro-y box mt-5 pb-10 max-w-3xl mx-auto">
        <div
          class="font-bold text-3xl px-5 sm:px-20 py-10 pb-5 sm:py-10 rounded-t"
        >
          {{ page.title }}
        </div>
        <div class="px-5 sm:px-20">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-3">
              <div v-show="!donationTypeCompleted" class="px-0 sm:pr-8">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  How would you like to apply your donation?
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Donations can be applied to a specific team or the general
                  Piecewalk event.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-3">
              <SupportCart
                v-show="donationTypeCompleted"
                :supporting="supporting"
                :from-donation="true"
              />
              <div v-show="!donationTypeCompleted">
                <div class="grid grid-cols-12 gap-4 gap-y-5">
                  <Supporting
                    v-model:supportType="supporting.type"
                    v-model:eventCity="supporting.eventDetails"
                    v-model:teamSelected="supporting.teamDetails"
                    subject="Donation"
                    :support-type="supporting.type"
                    :event-city-value="supporting.eventDetails"
                    :team-selected="supporting.teamDetails"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-show="donationTypeCompleted" aria-hidden="true">
            <div class="py-10">
              <div class="border-t border-gray-200" />
            </div>
          </div>
          <div
            v-show="donationTypeCompleted"
            class="md:grid md:grid-cols-3 md:gap-6"
          >
            <div class="md:col-span-3">
              <div class="px-0 sm:pr-8 intro-y">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Donation Amount
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  How much would you like to contribute?
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-3">
              <div class="grid grid-cols-12 gap-4 gap-y-5">
                <div class="intro-y col-span-12 sm:col-span-6">
                  <DollarAmount
                    v-model="createDonationData.donation_amount"
                    :dollar-amount="createDonationData.donation_amount"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="intro-y col-span-12 flex items-center justify-center mt-6"
          >
            <button
              v-show="
                (supporting.teamDetails.name || supporting.eventDetails.city) &&
                  !donationTypeCompleted
              "
              type="button"
              class="btn block w-full intro-y py-3 sm:inline-block sm:w-auto btn-red border-reddark sm:ml-2 sm:px-10 btn-lg"
              :disabled="validate.$errors.length > 0"
              @click="setSupporting()"
            >
              Next
            </button>
            <button
              v-show="
                donationTypeCompleted && createDonationData.donation_amount > 0
              "
              type="button"
              class="btn block w-full intro-y py-3 sm:inline-block sm:w-auto btn-red border-reddark sm:ml-2 sm:px-10 btn-lg"
              :disabled="
                validate.$errors.length > 0 ||
                  createDonationData.donation_amount === 0
              "
              @click="setDonation()"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
    <CancelButton />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  toRefs
} from "vue";
import { useStore } from "@/store";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Supporting from "@/components/form-inputs/Supporting";
import DollarAmount from "@/components/form-inputs/DollarAmount";
import FrontEndHeader from "@/components/front-end/FrontEndHeader";
import SupportCart from "@/components/front-end/SupportCart";
import CancelButton from "@/components/front-end/CancelButton";

export default defineComponent({
  components: {
    SupportCart,
    Supporting,
    FrontEndHeader,
    DollarAmount,
    CancelButton
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    let validateFields = ref(false);
    let resetFields = ref(false);
    let donationTypeCompleted = ref(false);
    const store = useStore();
    const supporting = store.state.support.supporting;
    const donation = store.state.donate.donation;

    /**
     * Go Straight to Donation Amount if Support has been set.
     */
    if (supporting.type !== "") {
      donationTypeCompleted.value = true;
    }

    /**
     * Set the Supporting values and move on to Donation Amount
     */
    const setSupporting = () => {
      store.dispatch("support/setSupporting", supporting);
      //console.log("test", store.state.support.supporting);
      donationTypeCompleted.value = true;
    };

    if (route.query.team) {
      let teamId = route.query.team;
      let teamName = route.query.name;
      let city = route.query.city;
      supporting.type = "Team";
      supporting.teamDetails.id = teamId;
      supporting.teamDetails.name = teamName;
      supporting.teamDetails.city = city;
      delete supporting.teamDetails.value;
      setSupporting();
    }

    /**
     * Set the Donation Amount
     */
    const setDonation = () => {
      store.dispatch("donate/setDonation", createDonationData);
      router.push({ path: "/frontend/contact", query: { skip: true } });
    };

    const page = {
      title: "Donate"
    };

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("frontend create-team");
    });

    const createDonationData = reactive(donation);

    const rules = computed(() => {
      return {
        donatation_amount: {
          required
        }
      };
    });

    const validate = useVuelidate(rules, toRefs(createDonationData));

    return {
      page,
      supporting,
      donationTypeCompleted,
      validate,
      createDonationData,
      validateFields,
      resetFields,
      donation,
      setSupporting,
      setDonation
    };
  },
  methods: {
    toggleDonationTypeCompleted() {
      this.donationTypeCompleted = !this.donationTypeCompleted;
    },
    setDonationType(type) {
      this.createDonationData.donation_type = type;
      this.resetDonationData();
    },
    resetDonationData() {
      this.createDonationData.donation_amount = 0;
      this.createDonationData.team_name = "";
      this.createDonationData.event_city = "";
      this.resetFields = true;
    }
  }
});
</script>
