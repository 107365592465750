<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ pageTitle }}</h2>
    </div>
    <!-- BEGIN: Team Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <TeamInfo
        data-title="Team Info"
        data-intro="Click the image to upload a team photo, or edit your contact details using the My Profile link in the navigation to the left."
        :team="team"
        @updateTeamData="teamUpdated"
      />
      <TeamActions
        data-title="Actions"
        data-intro="As the captain, you can Donate, Email your team, View Your Public Team Page, Tell us about any employer matching or facebook fundraisers you have."
        :team="team"
      />
      <div
        data-title="Team Management Tabs"
        data-intro="In these tabs, you can set goals in Team Info, Add Team Members, View Donations and Badges, and set your Star of the Team."
        :team="team"
        class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start"
        role="tablist"
      >
        <button
          type="button"
          class="py-4 sm:mr-8 flex items-center focus:outline-none"
          :class="{ active: show === 'teamInfo' }"
          @click="show = 'teamInfo'"
        >
          <BarChartIcon class="w-4 h-4 mr-2" /> Team Info
        </button>
        <button
          type="button"
          class="py-4 sm:mr-8 flex items-center focus:outline-none"
          :class="{ active: show === 'teamMembers' }"
          @click="show = 'teamMembers'"
        >
          <UsersIcon class="w-4 h-4 mr-2" /> Team Members
        </button>
        <button
          type="button"
          class="py-4 sm:mr-8 flex items-center focus:outline-none"
          :class="{ active: show === 'teamDonations' }"
          @click="show = 'teamDonations'"
        >
          <DollarSignIcon class="w-4 h-4 mr-2" /> Team Donations
        </button>
        <button
          type="button"
          class="py-4 sm:mr-8 flex items-center focus:outline-none"
          :class="{ active: show === 'teamBadges' }"
          @click="show = 'teamBadges'"
        >
          <AwardIcon class="w-4 h-4 mr-2" /> Badges
        </button>
        <button
          type="button"
          class="py-4 sm:mr-8 flex items-center focus:outline-none"
          :class="{ active: show === 'teamStar' }"
          @click="show = 'teamStar'"
        >
          <StarIcon class="w-4 h-4 mr-2" /> Star of the Team
        </button>
        <button
          v-if="isAdmin"
          type="button"
          class="py-4 sm:mr-8 flex items-center focus:outline-none"
          :class="{ active: show === 'teamSettings' }"
          @click="show = 'teamSettings'"
        >
          <SettingsIcon class="w-4 h-4 mr-2" />
          <span class="relative"
            ><span class="text-xs absolute top-4">Admin</span> Settings</span
          >
        </button>
        <button
          v-if="isAdmin"
          type="button"
          class="py-4 sm:mr-8 flex items-center focus:outline-none"
          :class="{ active: show === 'teamTransactions' }"
          @click="show = 'teamTransactions'"
        >
          <CodeIcon class="w-4 h-4 mr-2" />
          <span class="relative"
            ><span class="text-xs absolute top-4">Admin</span>
            Transactions</span
          >
        </button>
        <button
          v-if="isAdmin"
          type="button"
          class="py-4 sm:mr-8 flex items-center focus:outline-none"
          :class="{ active: show === 'teamHistory' }"
          @click="show = 'teamHistory'"
        >
          <ClockIcon class="w-4 h-4 mr-2" />
          <span class="relative"
            ><span class="text-xs absolute top-4">Admin</span> Team
            History</span
          >
        </button>
      </div>
    </div>
    <!-- END: Team Info -->
    <div class="tab-content mt-5">
      <div
        v-if="show === 'teamInfo' && team.teamDonationGoal"
        data-title="Team Goals"
        data-intro="You have a new team and we have set some default goals, but please edit your team goals or message here on the Team Info tab."
      >
        <Goals
          :team-donation-goal="team.teamDonationGoal"
          :team-member-goal="team.teamMemberGoal"
          :total-raised="team.totalRaised"
          :total-members="team.members"
          :team-message="team.teamMessage"
          @updateTeamData="teamUpdated"
        />
      </div>
      <div v-if="show === 'teamMembers'">
        <TeamMembers
          :members="team.members"
          :admin="isAdmin"
          @setTeamData="setTeam"
        />
      </div>
      <div v-if="show === 'teamDonations'">
        <TeamDonations :donations="teamDonations" :team="team" />
      </div>
      <div v-if="show === 'teamBadges'">
        <Badges
          :employee-match="team.hasEmployeeMatch"
          :facebook-fundraiser="team.hasFacebookFundraiser"
          :community-event="team.hasCommunityEvent"
          :total-raised="team.totalRaised"
          :total-participants="team.members.length"
          @updateTeamData="teamUpdated"
        />
      </div>
      <div v-if="show === 'teamStar'">
        <StarOfTheTeam
          :star-of-the-team="team.starOfTeam"
          :total-raised="team.totalRaised"
          @updateTeamData="teamUpdated"
        />
      </div>
      <div v-if="show === 'teamSettings' && isAdmin">
        <TeamSettings
          :team-captain="team.captain"
          :is-active="team.active"
          :team-id="team.id"
          @updateTeamData="teamUpdated"
        />
      </div>
      <div v-if="show === 'teamTransactions' && isAdmin">
        <TeamTransactions :transactions="teamTransactions" />
      </div>
      <div v-if="show === 'teamHistory' && isAdmin">
        <TeamHistory :team-id="team.id" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, ref, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { getTeam } from "@/api/teams.js";
import { getLineItemsByTeam } from "@/api/lineitems.js";
import { getTransactionsByTeam } from "@/api/transactions.js";
import TeamInfo from "./team-info/Main.vue";
import Goals from "./goals/Goals";
import Badges from "./badges/Badges";
import TeamMembers from "./members/Members.vue";
import TeamTransactions from "./transactions/Transactions.vue";
import TeamDonations from "./donations/Donations.vue";
import TeamSettings from "./settings/Settings.vue";
import StarOfTheTeam from "./star-of-the-team/StarOfTheTeam.vue";
import TeamActions from "./team-actions/TeamActions.vue";
import TeamHistory from "./history/TeamHistory.vue";

export default defineComponent({
  components: {
    TeamInfo,
    Goals,
    Badges,
    TeamMembers,
    TeamTransactions,
    TeamDonations,
    TeamSettings,
    StarOfTheTeam,
    TeamActions,
    TeamHistory
  },
  setup() {
    const store = useStore();
    const token = store.state.auth.token;
    const user = store.state.auth.user;
    const isAdmin = ref(false);
    const appSettings = computed(() => store.getters["main/eventSettings"]);
    const team = computed(() => store.getters["backEndTeam/currentTeam"]);
    const route = useRoute();
    const router = useRouter();
    const show = ref("teamInfo");
    let pageTitle = ref("My Team");

    let teamDonations = reactive([]);
    let teamTransactions = reactive([]);

    const formatTransactionData = transactionData => {
      let formattedTransactions = [];
      transactionData.map(transaction => {
        let obj = {};
        obj.id = transaction.id;
        obj.name =
          transaction.contactFirstName + " " + transaction.contactLastName;
        obj.email = transaction.contactEmail;
        obj.amount = transaction.totalAmount;
        let createdAt = new Date(transaction.createdAt);
        obj.date = createdAt
          .toDateString()
          .split(" ")
          .slice(1)
          .join(" ");
        formattedTransactions.push(obj);
      });
      return formattedTransactions;
    };

    const formatDonationData = donationData => {
      let formattedDonations = [];
      donationData.map(donation => {
        let obj = {};
        // obj.id = donation.id;
        obj.name = donation.primaryContact;
        obj.amount = donation.amount;
        if (donation.useEmployerAsName) {
          obj.name = donation.myEmployer;
        }
        let createdAt = new Date(donation.createdAt);
        obj.date = createdAt
          .toDateString()
          .split(" ")
          .slice(1)
          .join(" ");
        formattedDonations.push(obj);
      });
      return formattedDonations;
    };

    const setTeam = async () => {
      let theTeamID = "";
      if (route.params.id) {
        pageTitle.value = "Edit Team";
        theTeamID = route.params.id;
      }
      const getMyTeam = await getTeam(theTeamID);

      //Get Team Donations
      const eventYear = appSettings.value.appYear;
      let donationRequest = `&type=donation&eventYear=${eventYear}&limit=100`;
      let teamDonationResults = await getLineItemsByTeam(
        getMyTeam.id,
        donationRequest
      );
      let formattedDonations = formatDonationData(teamDonationResults.results);
      Object.assign(teamDonations, formattedDonations);

      if (isAdmin.value) {
        //Get Team Transactions
        let transactionRequest = "&limit=100";
        let teamTransactionsResults = await getTransactionsByTeam(
          getMyTeam.id,
          transactionRequest
        );
        let formattedTransactions = formatTransactionData(
          teamTransactionsResults.results
        );

        Object.assign(teamTransactions, formattedTransactions);
      }

      store.dispatch("backEndTeam/setCurrentTeam", getMyTeam);
    };

    const teamUpdated = async updateTeamData => {
      //Any updates from child components can run through this
      await store.dispatch("backEndTeam/updateTeam", updateTeamData);
      console.log("team updated");
    };

    onMounted(async () => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .removeClass("frontend")
        .addClass("team-config");
      isAdmin.value = await store.getters["auth/isAdmin"];
      await setTeam();
      //Reactivate Team if it is Inactive and it is not an Admin
      if (!isAdmin.value && !team.value.active) {
        router.push({ path: "/team-reactivate" });
      }

      //Add Cloudinary to head
      if (!window.cloudinary) {
        let cloudinaryScript = document.createElement("script");
        cloudinaryScript.setAttribute(
          "src",
          "https://upload-widget.cloudinary.com/global/all.js"
        );
        document.head.appendChild(cloudinaryScript);
      }

      const hasViewedIntro = localStorage.getItem("pw_has_viewed_intro");
      if (!window.introJs && !hasViewedIntro) {
        let introJsScript = document.createElement("script");
        introJsScript.setAttribute(
          "src",
          "https://cdn.jsdelivr.net/npm/intro.js@4.3.0/intro.min.js"
        );
        document.body.appendChild(introJsScript);
        introJsScript.addEventListener("load", () => {
          // eslint-disable-next-line no-undef
          introJs().start();
          window.localStorage.setItem("pw_has_viewed_intro", true);
        });
      } else if (window.introJs && !hasViewedIntro) {
        // eslint-disable-next-line no-undef
        introJs().start();
        window.localStorage.setItem("pw_has_viewed_intro", true);
      }
    });

    return {
      pageTitle,
      team,
      token,
      user,
      isAdmin,
      teamUpdated,
      setTeam,
      teamDonations,
      teamTransactions,
      show
    };
  }
});
</script>
<style>
@import url("https://cdn.jsdelivr.net/npm/intro.js@4.3.0/introjs.css");
</style>
