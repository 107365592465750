import * as types from "./mutation-types";
import { getEventSettings } from "@/api/event-settings";

const state = () => {
  return {
    /**
    darkMode: false,
    appTitle: "Piecewalk",
    appYear: "2022",
    appState: "Oklahoma",
    has5k: true,
    event5kSettings: {
      hasVirtual: true,
      runnerPrice: 30,
      virtualRunnerPrice: 25
    },
    starOfTheTeamLimit: 200,
    gender: ["Male", "Female"],
    hasEventShirt: true,
    eventShirtSettings: {
      eventShirtPrice: 25,
      eventShirtImage: "https://via.placeholder.com/800x800?text=T-ShirtImage"
    },
    eventBadges: [
      {
        name: "Five Participants",
        type: "participant",
        goal: 5,
        badgeImage: "https://via.placeholder.com/150?text=FiveParticipants"
      },
      {
        name: "Ten Participants",
        type: "participant",
        goal: 10,
        badgeImage: "https://via.placeholder.com/150?text=TenParticipants"
      },
      {
        name: "Fifteen Participants",
        type: "participant",
        goal: 15,
        badgeImage: "https://via.placeholder.com/150?text=FifteenParticipants"
      },
      {
        name: "25 Dollars",
        type: "fundraising",
        goal: 25,
        badgeImage: "https://via.placeholder.com/150?text=25Dollars"
      },
      {
        name: "50 Dollars",
        type: "fundraising",
        goal: 50,
        badgeImage: "https://via.placeholder.com/150?text=50Dollars"
      },
      {
        name: "100 Dollars",
        type: "fundraising",
        goal: 100,
        badgeImage: "https://via.placeholder.com/150?text=100Dollars"
      }
    ],
    termsAndConditionsUrl:
      "https://piecewalkweb.wpengine.com/terms-and-conditions/",
    captainTools: [
      {
        text: "Facebook Fundraiser",
        link: "https://piecewalkweb.wpengine.com/create-a-facebook-fundraiser/"
      },
      {
        text: "Employer Match",
        link:
          "https://piecewalkweb.wpengine.com/team-captain-tools/apply-for-an-employer-match/"
      }
    ]
     */
  };
};

// getters
const getters = {
  darkMode: state => state.darkMode,
  appTitle: state => state.appTitle,
  appYear: state => state.appYear,
  appState: state => state.appState,
  products: state => state.products,
  getProductBySlug: state => slug => {
    if (state.products) {
      return state.products.find(product => product.slug === slug);
    } else {
      return [];
    }
  },
  eventSettings: state => state
};

// actions
const actions = {
  setDarkMode({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, { darkMode });
  },
  setEventShirtImage({ commit }, eventShirtImage) {
    commit(types.SET_EVENT_SHIRT_IMAGE, { eventShirtImage });
  },
  addCaptainToolLink({ commit }, link) {
    commit(types.ADD_CAPTAIN_TOOL_LINK, { link });
  },
  removeCaptainToolLink({ commit }, index) {
    commit(types.REMOVE_CAPTAIN_TOOL_LINK, { index });
  },
  addCaptainAlert({ commit }, alert) {
    commit(types.ADD_CAPTAIN_ALERT, { alert });
  },
  removeCaptainAlert({ commit }, index) {
    commit(types.REMOVE_CAPTAIN_ALERT, { index });
  },
  addBadge({ commit }, badge) {
    commit(types.ADD_BADGE, { badge });
  },
  addProduct({ commit }, product) {
    commit(types.ADD_PRODUCT, { product });
  },
  editProductImage({ commit }, index, value) {
    commit(types.EDIT_PRODUCT_IMAGE, { index, value });
  },
  removeProduct({ commit }, index) {
    commit(types.REMOVE_PRODUCT, { index });
  },
  removeBadge({ commit }, index) {
    commit(types.REMOVE_BADGE, { index });
  },
  setTeamEventImage({ commit }, image) {
    commit(types.SET_TEAM_EVENT_IMAGE, { image });
  },
  setFacebookImage({ commit }, image) {
    commit(types.SET_FACEBOOK_IMAGE, { image });
  },
  setEmployerMatchImage({ commit }, image) {
    commit(types.SET_EMPLOYER_MATCH_IMAGE, { image });
  },
  setEliteBadgeImage({ commit }, image) {
    commit(types.SET_ELITE_BADGE_IMAGE, { image });
  },
  addTestEmail({ commit }, email) {
    commit(types.ADD_TEST_EMAIL, { email });
  },
  removeTestEmail({ commit }, index) {
    commit(types.REMOVE_TEST_EMAIL, { index });
  },
  setEventSettings: async ({ commit }) => {
    console.log("ran set event settings");
    const configSettings = await getEventSettings();
    if (configSettings) {
      commit(types.SET_EVENT_SETTINGS, { configSettings });
    }
  }
};

// mutations
const mutations = {
  [types.SET_DARK_MODE](state, { darkMode }) {
    state.darkMode = darkMode;
  },
  [types.SET_EVENT_SHIRT_IMAGE](state, { eventShirtImage }) {
    state.eventShirtSettings.eventShirtImage = eventShirtImage;
  },
  [types.ADD_CAPTAIN_TOOL_LINK](state, { link }) {
    state.captainTools.push(link);
  },
  [types.REMOVE_CAPTAIN_TOOL_LINK](state, { index }) {
    state.captainTools.splice(index, 1);
  },
  [types.ADD_CAPTAIN_ALERT](state, { alert }) {
    state.captainAlerts.push(alert);
  },
  [types.REMOVE_CAPTAIN_ALERT](state, { index }) {
    state.captainAlerts.splice(index, 1);
  },
  [types.ADD_BADGE](state, { badge }) {
    state.eventBadges.push(badge);
  },
  [types.REMOVE_BADGE](state, { index }) {
    state.eventBadges.splice(index, 1);
  },
  [types.SET_TEAM_EVENT_IMAGE](state, { image }) {
    state.teamEvent.image = image;
  },
  [types.SET_FACEBOOK_IMAGE](state, { image }) {
    state.facebookFundraiser.image = image;
  },
  [types.SET_EMPLOYER_MATCH_IMAGE](state, { image }) {
    state.employerMatch.image = image;
  },
  [types.SET_ELITE_BADGE_IMAGE](state, { image }) {
    state.eliteBadgeSettings.image = image;
  },
  [types.SET_EVENT_SETTINGS](state, { configSettings }) {
    Object.assign(state, configSettings);
  },
  [types.ADD_TEST_EMAIL](state, { email }) {
    console.log(email);
    state.testEmails.push(email);
  },
  [types.REMOVE_TEST_EMAIL](state, { index }) {
    state.testEmails.splice(index, 1);
  },
  [types.ADD_PRODUCT](state, { product }) {
    state.products.push(product);
  },
  [types.EDIT_PRODUCT_IMAGE](state, { index, value }) {
    console.log(index, value);
    let productIndex = index.index;
    let productImage = index.value;
    state.products[productIndex].image = productImage;
  },
  [types.REMOVE_PRODUCT](state, { index }) {
    state.products.splice(index, 1);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
