<template>
  <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Captain's Tools</h2>
    </div>
    <div class="mt-5">
      <!-- BEGIN: Captains Tools Content -->
      <div class="config-content">
        <form class="validate-form" @submit.prevent="saveEventSettings">
          <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12">
              <div class="intro-y box mt-2 p-6">
                <CaptainToolsSettings
                  v-if="eventSettings"
                  v-model:fundraisingUrl="eventSettings.donationBadgeTipsUrl"
                  v-model:participationUrl="
                    eventSettings.participationBadgeTipsUrl
                  "
                  v-model:submitEventUrl="eventSettings.submitEventUrl"
                  :fundraising-url="eventSettings.donationBadgeTipsUrl"
                  :participation-url="eventSettings.participationBadgeTipsUrl"
                  :submit-event-url="eventSettings.submitEventUrl"
                />
                <div>
                  <div
                    class="grid grid-cols-12 gap-6 mt-12 border border-solid border-gray-200 p-6 mb-6"
                  >
                    <div class="col-span-12">
                      <h3 class="text-base font-medium">
                        New Captain Tool Button
                      </h3>
                      <p class="italic">
                        Create buttons to go on the Captain Dashboard.
                      </p>
                    </div>
                    <div class="col-span-12 lg:col-span-4">
                      <label
                        for="button_url"
                        class="form-label placeholder-label relative w-full mt-3 text-base"
                      >
                        <input
                          id="button_url"
                          v-model="newCaptainLink.link"
                          type="text"
                          class="form-control p-3"
                          placeholder=" "
                        />
                        <span
                          class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                          >Button URL</span
                        >
                      </label>
                    </div>
                    <div class="col-span-12 lg:col-span-4">
                      <label
                        for="button_text"
                        class="form-label placeholder-label relative w-full mt-3 text-base"
                      >
                        <input
                          id="button_text"
                          v-model="newCaptainLink.text"
                          name="captainLinkText"
                          type="text"
                          class="form-control p-3"
                          placeholder=" "
                        />
                        <span
                          class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                          >Button Text</span
                        >
                      </label>
                    </div>
                    <div class="col-span-12 lg:col-span-4 mt-4">
                      <button
                        class="btn btn-outline-primary"
                        type="button"
                        @click="addCaptainLink()"
                      >
                        Add Captain Link
                      </button>
                    </div>
                  </div>
                </div>
                <h2 class="col-span-12 text-base font-medium">
                  Current Captain Buttons
                </h2>
                <ul
                  v-if="eventSettings && eventSettings.captainTools"
                  class="mt-2"
                >
                  <li
                    v-for="(link, index) in eventSettings.captainTools"
                    :key="index"
                  >
                    <div
                      class="grid grid-cols-12 gap-6 mb-2 border border-solid border-gray-400 p-3"
                    >
                      <div class="col-span-9">
                        <span class="block font-medium">{{ link.text }}</span>
                        <span class="block">{{ link.link }}</span>
                      </div>
                      <div class="col-span-3 text-right">
                        <button
                          class="btn btn-sm btn-red mt-1"
                          type="button"
                          @click="removeCaptainLink(index)"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
                <div v-if="eventSettings && eventSettings.captainTools">
                  <h4 v-if="eventSettings.captainTools.length === 0">
                    No captain Links have been added.
                  </h4>
                </div>
                <div class="flex justify-end mt-8">
                  <button
                    type="submit"
                    class="btn btn-lg btn-primary w-20 mr-auto"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- END: Captains Tools Content -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "@/store";
import { updateEventSettings } from "@/api/event-settings";
import CaptainToolsSettings from "@/components/form-inputs/CaptainToolsSettings";

export default defineComponent({
  components: {
    CaptainToolsSettings
  },
  setup() {
    const validate = useVuelidate();
    const store = useStore();
    const eventSettings = computed(() => store.getters["main/eventSettings"]);

    const newCaptainLink = reactive({});

    const addCaptainLink = () => {
      if (newCaptainLink.text && newCaptainLink.text) {
        saveLinkToStore();
      }
    };

    const removeCaptainLink = async index => {
      await store.dispatch("main/removeCaptainToolLink", index);
    };

    const saveLinkToStore = async () => {
      const captainLink = {};
      Object.assign(captainLink, newCaptainLink);
      await store.dispatch("main/addCaptainToolLink", captainLink);
      newCaptainLink.link = "";
      newCaptainLink.text = "";
    };

    const sendNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "Event settings have been updated."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem updating the event settings."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const saveEventSettings = async () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        let response = {
          code: 400
        };
        await sendNotification(response);
      } else {
        const saveEventSettingsResult = await updateEventSettings(
          eventSettings.value
        );
        await sendNotification(saveEventSettingsResult);
      }
    };

    return {
      eventSettings,
      saveEventSettings,
      newCaptainLink,
      addCaptainLink,
      removeCaptainLink
    };
  }
});
</script>
