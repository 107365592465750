<template>
  <div class="pt-6">
    <div class="cart px-5 sm:px-20 pt-1">
      <div class="social-share md:grid md:grid-cols-12">
        <div class="col-span-12 mb-3">
          <h3 class="text-xl font-bold">Spread the News!</h3>
          <p class="text-base">
            Tell your community that you are a part of the Oklahoma Piecewalk!
          </p>
        </div>
        <div class="col-span-6">
          <iframe
            :src="src"
            width="77"
            height="28"
            style="border:none;overflow:hidden"
            scrolling="no"
            frameborder="0"
            allowfullscreen
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    supporting: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  setup(props) {
    let src =
      "https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fpiecewalk.org&layout=button&size=large&width=77&height=28&appId";

    const string_to_slug = str => {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaaeeeeiiiioooouuuunc------";

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    };

    if (props.supporting.type == "Team") {
      const teamName = props.supporting.teamDetails.name;
      const teamSlug = string_to_slug(teamName);
      const teamUrl = `https://piecewalk.org/team/${teamSlug}/`;
      const teamPage = encodeURIComponent(teamUrl);
      const facebookShareWithTeam = `https://www.facebook.com/plugins/share_button.php?href=${teamPage}&layout=button&size=large&width=77&height=28&appId`;
      src = facebookShareWithTeam;
    }
    return {
      src
    };
  }
});
</script>
