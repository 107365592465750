<template>
  <div class="grid grid-cols-12 gap-6">
    <!-- BEGIN: Settings -->
    <div class="col-span-12 md:col-span-8">
      <div class="intro-y box grid grid-cols-12 gap-12 p-12">
        <div class="captain col-span-6">
          <div>
            <label for="captin" class="form-label text-base"
              >Team Captain</label
            >
            <select
              id="captain"
              v-model.trim="captain"
              class="form-select mt-2 sm:mr-2"
              aria-label="captain"
              :value="captain"
            >
              <option
                v-for="captain in formattedUsers"
                :key="captain.id"
                :value="captain.id"
                @change="onChangeCaptain($event)"
                >{{ captain.name }}</option
              >
            </select>
          </div>
        </div>
        <div class="status col-span-6">
          <div
            class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
          >
            <input
              id="is-active"
              v-model.trim="teamActive"
              class="form-check-switch mr-0 ml-0"
              type="checkbox"
              :checked="teamActive"
              @change="updateStatus()"
            />
            <label class="form-check-label font-medium" for="user-active"
              ><span v-show="teamActive">
                Team Is Active
              </span>
              <span v-show="!teamActive">
                Team Is Inactive
              </span></label
            >
          </div>
        </div>
        <div class="col-span-12">
          <button
            type="button"
            class="btn btn-primary w-40 mr-auto"
            @click="updateCaptain()"
          >
            Update Captain
          </button>
        </div>
      </div>
    </div>
    <div class="col-span-12 md:col-span-4">
      <div class="intro-y box grid grid-cols-4">
        <div class="col-span-4 p-12">
          <button
            v-show="!resetTeamCheck"
            type="button"
            class="btn border-red bg-transparent mt-3 w-40 mr-auto"
            @click="resetTeamCheck = true"
          >
            Reset Team
          </button>
          <button
            type="button"
            class="btn btn-red mt-3 mr-auto"
            @click="setTeamMemberEventDates(teamId)"
          >
            Update Event Year for Team Members
          </button>
          <div v-show="resetTeamCheck">
            <h3 class="text-red text-2xl">Danger!</h3>
            <h3 class="text-base font-bold">Are sure you want to do this?</h3>
            <p>
              This will reset the team to start over for a new Event Year. The
              team will be deactivated and there progress for the year will be
              reset until the team is reactivated.
            </p>
            <button
              type="button"
              class="btn btn-red mt-3 w-40 mr-auto"
              @click="resetThisTeam(teamId)"
            >
              Yes, Reset Team
            </button>
          </div>
          <button
            v-show="!reactivateTeamCheck && !teamActive"
            type="button"
            class="btn border-black block mt-3 w-40 mr-auto"
            @click="reactivateTeamCheck = true"
          >
            Reactivate Team
          </button>
          <div v-show="reactivateTeamCheck" class="mt-3">
            <h3 class="text-green text-2xl">Double-Check</h3>
            <h3 class="text-base font-bold">Are sure you want to do this?</h3>
            <p>
              This will launch the reactivate process for this team. This will
              allow you to activate the team and choose members to reactivate
              and set new goals. This team can also be reactivated by the
              captain of the team if they login using their credentials.
            </p>
            <button
              type="button"
              class="btn btn-red mt-3 w-40 mr-auto"
              @click="reactivateThisTeam(teamId)"
            >
              Yes, Begin Reactivation
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Settings -->
  </div>
</template>

<script>
import { defineComponent, reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { getAllUsers } from "@/api/users.js";
import {
  updateTeamCaptain,
  resetTeam,
  setTeamMembersToNewYear
} from "@/api/teams.js";
export default defineComponent({
  components: {},
  props: {
    teamCaptain: {
      type: String,
      default: function() {
        return "";
      },
      required: true
    },
    teamId: {
      type: String,
      default: function() {
        return "";
      },
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    }
  },
  emits: ["updateTeamData"],
  setup(props, { emit }) {
    const captain = ref(props.teamCaptain);
    const teamActive = ref(props.isActive);
    const theTeamId = ref(props.teamId);
    const store = useStore();
    const router = useRouter();
    const resetTeamCheck = ref(false);
    const reactivateTeamCheck = ref(false);

    let userData = reactive([]);
    let formattedUsers = reactive([]);

    const getUserData = async () => {
      const allUsers = await getAllUsers();
      Object.assign(userData, allUsers);
      reformattedUsers(allUsers.results);
    };

    const reformattedUsers = theUsers => {
      let users = theUsers;
      let formattedUserData = [];
      users.map(user => {
        let obj = {};
        obj.active = user.active;
        obj.id = user.id;
        obj.name = "No Person Assigned";
        obj.email = user.email;
        obj.role = user.role;
        obj.team = "Unassigned";

        if ("team" in user && user.team !== null) {
          obj.team = user.team.name;
        }

        //Has to have a person
        if ("person" in user) {
          obj.name = user.person.firstName + " " + user.person.lastName;
          obj.personId = user.person.id;
          formattedUserData.push(obj);
        }
      });
      Object.assign(formattedUsers, formattedUserData);
    };

    const onChangeCaptain = event => {
      captain.value = event.target.value;
    };

    const sendNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "The team captain has been changed."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem changing the team captain."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const updateStatus = () => {
      let updateData = {
        active: teamActive.value
      };
      emit("updateTeamData", updateData);
    };

    const updateCaptain = async () => {
      let updateCaptainData = {
        captain: captain.value,
        team: theTeamId.value
      };
      const updateTeamResponse = await updateTeamCaptain(
        updateCaptainData.team,
        updateCaptainData.captain
      );
      await sendNotification(updateTeamResponse);
      updateStatus();
    };

    const sendResetNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "The team has been reset."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem resetting the team."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const sendTeamYearNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "The team members have been updated."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem updating the team members."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const resetThisTeam = async team => {
      const resetResponse = await resetTeam(team);
      await sendResetNotification(resetResponse);
      router.push({ path: "/teams" });
    };

    const reactivateThisTeam = async team => {
      router.push({
        path: `/backend-team-reactivate/${team}/${props.teamCaptain}`
      });
    };

    const setTeamMemberEventDates = async team => {
      const setTeamMemberYearResponse = await setTeamMembersToNewYear(team);
      await sendTeamYearNotification(setTeamMemberYearResponse);
      router.push({ path: "/teams" });
    };

    onMounted(() => {
      getUserData();
    });

    return {
      captain,
      teamActive,
      updateCaptain,
      updateStatus,
      formattedUsers,
      onChangeCaptain,
      resetTeamCheck,
      resetThisTeam,
      reactivateTeamCheck,
      reactivateThisTeam,
      setTeamMemberEventDates
    };
  }
});
</script>
