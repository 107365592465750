<template>
  <div class="col-span-12">
    <div class="grid grid-cols-12 gap-4 gap-y-5">
      <!--Name on Card-->
      <div class="intro-y col-span-12 sm:col-span-6 name-on-card-field">
        <div class="name_on_card">
          <label
            for="name_on_card"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="name_on_card"
              v-model="validate.name_on_card.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.name_on_card.$error }"
              placeholder=" "
              @input="$emit('update:nameOnCard', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Name on Card</span
            >
          </label>
          <div
            v-for="error of validate.name_on_card.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Address-->
      <div class="intro-y col-span-12 sm:col-span-6 billing-address-field">
        <div class="billing-address">
          <label
            for="billing_address"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="billing_address"
              v-model="validate.billing_address.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.billing_address.$error }"
              placeholder=" "
              @input="$emit('update:billingAddress', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Billing Address</span
            >
          </label>
          <div
            v-for="error of validate.billing_address.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--City-->
      <div class="intro-y col-span-12 sm:col-span-6 billing-city-field">
        <div class="billing-city">
          <label
            for="billing_city"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="billing_city"
              v-model.trim="validate.billing_city.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.billing_city.$error }"
              placeholder=" "
              @input="$emit('update:billingCity', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >City</span
            >
          </label>
          <div
            v-for="error of validate.billing_city.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--State-->
      <div class="intro-y col-span-4 sm:col-span-3 billing-state-field">
        <div class="billing-state">
          <label
            for="billing_state"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <select
              id="billing_state"
              v-model="billing_state"
              class="form-select sm:mr-2 p-3 text-base"
              aria-label="State"
              :class="{ 'border-theme-6': validate.billing_state.$error }"
              @change="$emit('update:billingState', billing_state)"
            >
              <option value="" disabled selected>OK</option>
              <option
                v-for="theState in statesList"
                :key="theState"
                :value="theState"
              >
                {{ theState }}
              </option></select
            >
          </label>
          <div
            v-for="error of validate.billing_state.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Billing Zipcode-->
      <div class="intro-y col-span-8 sm:col-span-3 billing-zip-field">
        <div class="billing-zip">
          <label
            for="billing_zip"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="billing_zip"
              v-model.trim="validate.billing_zip.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.billing_zip.$error }"
              placeholder=" "
              @input="$emit('update:billingZip', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Billing Zip</span
            >
          </label>
          <div
            v-for="error of validate.billing_zip.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { required, helpers, minLength, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "@/store";
export default defineComponent({
  props: {
    nameOnCard: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    billingAddress: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    billingCity: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    billingState: {
      type: String,
      default: () => {
        return "OK";
      },
      required: false
    },
    billingZip: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    touch: {
      type: Boolean,
      required: false
    }
  },
  setup: () => ({ validate: useVuelidate() }),
  data() {
    const store = useStore();
    const states = store.state.statesList.states;
    return {
      name_on_card: this.nameOnCard,
      billing_address: this.billingAddress,
      billing_city: this.billingCity,
      billing_state: this.billingState,
      billing_zip: this.billingZip,
      statesList: states
    };
  },
  watch: {
    billingAddress: function() {
      this.billing_address = this.billingAddress;
    },
    billingCity: function() {
      this.billing_city = this.billingCity;
    },
    billingState: function() {
      this.billing_state = this.billingState;
    },
    billingZip: function() {
      this.billing_zip = this.billingZip;
    }
  },
  validations() {
    return {
      name_on_card: { required, minLength: minLength(2) },
      billing_address: { required },
      billing_city: { required },
      billing_state: {
        required,
        minLength: helpers.withMessage("Use state abbreviation.", minLength(2)),
        maxLength: helpers.withMessage("Use state abbreviation.", maxLength(2))
      },
      billing_zip: { required }
    };
  }
});
</script>
