<template>
  <div>
    <FrontEndHeader fill="#be2032" />
    <!-- BEGIN: Create ContactLayout -->
    <form class="validate-form" @submit.prevent="save">
      <div class="intro-y box mt-5 pb-10 max-w-3xl mx-auto">
        <Cart hide-contact />
        <div
          class="font-bold text-3xl px-5 sm:px-20 py-10 pb-5 sm:py-10 rounded-t"
        >
          {{ page.title }}
        </div>
        <div class="px-5 sm:px-20">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-3">
              <div class="px-0 sm:pr-8">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Please tell us a little about yourself.
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  We will not share your information outside our organization.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-3">
              <div class="grid grid-cols-12 gap-4 gap-y-5">
                <div
                  v-if="
                    captain.person &&
                      !useMyInfo &&
                      !createContactData.first_name
                  "
                  class="intro-y col-span-12 sm:col-span-12 form-check flex-wrap mt-2 mb-2"
                >
                  <MatchRunner
                    v-model="useMyInfo"
                    class="w-full"
                    :is-matched="useMyInfo"
                    text="Auto-fill using my profile information."
                    @change="matchCaptain()"
                  />
                  <p class="w-full mt-1">
                    You are currently logged in as
                    <span class="font-bold"
                      >{{ captain.person.firstName }}
                      {{ captain.person.lastName }}</span
                    >
                  </p>
                </div>
                <div
                  v-show="runners.length > 0 && !captain.person"
                  class="intro-y col-span-12 sm:col-span-6 form-check mt-2 mb-2"
                >
                  <MatchRunner
                    v-model="matched"
                    :is-matched="matched"
                    @change="matchRunner(runners[0])"
                  />
                </div>
                <BasicInfo
                  v-model:firstName="createContactData.first_name"
                  v-model:lastName="createContactData.last_name"
                  v-model:emailAddress="createContactData.email"
                  v-model:phoneNumber="createContactData.phone"
                  v-model:myEmployer="createContactData.employer"
                  v-model:useEmployerAsName="
                    createContactData.useEmployerAsName
                  "
                  :first-name="createContactData.first_name"
                  :last-name="createContactData.last_name"
                  :email-address="createContactData.email"
                  :phone-number="createContactData.phone"
                  :my-employer="createContactData.employer"
                  :use-employer-as-name="createContactData.useEmployerAsName"
                  show-employer
                  show-use-employer-as-name
                  :donation-amount="donation.donation_amount"
                />
                <LifeChurchField
                  v-if="createContactData.phone"
                  v-model:lifeChurch="createContactData.lifeChurch"
                  v-model:lifeChurchCampus="createContactData.lifeChurchCampus"
                  class="hidden"
                  :campus-value="createContactData.lifeChurchCampus"
                  :is-life-church="createContactData.lifeChurch"
                />
                <div
                  v-show="
                    createContactData.phone && donation.donation_amount > 0
                  "
                  class="intro-y col-span-12 sm:col-span-6 form-check mt-2 mb-2"
                >
                  <AnonymousField
                    v-model:anonymous="createContactData.anonymous"
                    :is-anonymous="createContactData.anonymous"
                  />
                </div>
              </div>
              <div
                v-show="createContactData.phone"
                :class="{ 'justify-between': hasTeam }"
                class="intro-y col-span-12 sm:flex items-center justify-end mt-6"
              >
                <button
                  v-if="hasTeam"
                  type="button"
                  class="btn btn-outline block w-full intro-y py-3 sm:inline-block sm:w-auto text-red border-red sm:px-10"
                  :disabled="
                    validate.$errors.length > 0 ||
                      (createContactData.lifeChurch &&
                        !createContactData.lifeChurchCampus.value)
                  "
                  @click="addTeamMember()"
                >
                  <PlusCircleIcon class="inline mx-auto h-5" />
                  <span v-if="teamMembers.members.length > 0"
                    >Edit Additional Team Members</span
                  >
                  <span v-else>Add Another Person to the Team</span>
                </button>
                <button
                  type="button"
                  class="btn block mt-4 w-full intro-y py-3 sm:mt-0 sm:inline-block sm:w-auto btn-red border-reddark sm:px-10 btn-lg"
                  :disabled="
                    validate.$errors.length > 0 ||
                      (createContactData.lifeChurch &&
                        !createContactData.lifeChurchCampus.value)
                  "
                  @click="setContact()"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <CancelButton />
    <!-- END: Contact Layout -->
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  toRefs
} from "vue";
import { useStore } from "@/store";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import FrontEndHeader from "@/components/front-end/FrontEndHeader";
import Cart from "@/components/front-end/Cart";
import MatchRunner from "@/components/form-inputs/MatchParticipantContact";
import BasicInfo from "@/components/form-inputs/BasicInfo";
import LifeChurchField from "@/components/form-inputs/LifeChurch";
import AnonymousField from "@/components/form-inputs/Anonymous";
import CancelButton from "@/components/front-end/CancelButton";

export default defineComponent({
  components: {
    FrontEndHeader,
    Cart,
    MatchRunner,
    BasicInfo,
    LifeChurchField,
    AnonymousField,
    CancelButton
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    let validateFields = ref(false);
    let resetFields = ref(false);
    let matched = ref(false);
    let useMyInfo = ref(false);
    const store = useStore();
    const donation = store.state.donate.donation;
    let contact = store.state.contact.contact;
    const supporting = store.state.support.supporting;
    const runners = store.state.run.runData.runners;

    const teamMembers = store.state.teamMembers.teamMembers;

    const captain = store.state.auth.user;

    const matchCaptain = () => {
      if (captain.person && captain.person.firstName) {
        contact.first_name = captain.person.firstName;
        contact.last_name = captain.person.lastName;
        contact.email = captain.person.email;
        contact.phone = captain.person.phone;
      }

      if (captain.person && captain.person.lifeChurch) {
        const lifeChurchCampusList = store.state.lifeChurchCampuses.locations;
        contact.lifeChurch = captain.person.lifeChurch;

        let campusMatch = lifeChurchCampusList.filter(function(campus) {
          return campus.name === captain.person.lifeChurchLocation; // Returns an array of matches
        });
        contact.lifeChurchCampus = campusMatch[0]; // Return just the first object match.
      }
    };

    const newContact = () => {
      store.dispatch("contact/setContact", createContactData);
      router.push({ path: "/frontend/summary" });
    };

    const setContact = () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        console.log("invalid");
      } else {
        newContact();
      }
    };

    const addTeamMember = () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        console.log("invalid");
      } else {
        newContact();
        toAddTeamMember();
      }
    };

    const toAddTeamMember = () => {
      router.push({ path: "/frontend/add-team-member" });
    };

    const page = {
      title: "Contact"
    };

    const contactCompleted = () => {
      if (
        contact.first_name == "" ||
        contact.last_name == "" ||
        contact.email == "" ||
        contact.phone == ""
      ) {
        return false;
      }

      return true;
    };

    onMounted(() => {
      //Check to make sure a supporting type has been assigned
      if (supporting.type == "") {
        router.push({ path: "/frontend/supporting" });
      }
      //If contact has been filled in, move on
      if (route.query.skip && contactCompleted()) {
        router.push({ path: "/frontend/summary" });
      }

      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("frontend create-team");
    });

    const createContactData = reactive(contact);

    const rules = computed(() => {
      return {
        first_name: {
          required
        },
        last_name: {
          required
        }
      };
    });

    const hasTeam = computed(() => {
      if (
        supporting.type === "Team" &&
        supporting.teamDetails.team_name !== "" &&
        route.query.join
      ) {
        return true;
      }

      return false;
    });

    const matchRunner = runner => {
      if (matched) {
        createContactData.first_name = runner.first_name;
        createContactData.last_name = runner.last_name;
        createContactData.email = runner.email;
        createContactData.phone = runner.phone;
        createContactData.lifeChurch = runner.lifeChurch;
        createContactData.lifeChurchCampus = runner.lifeChurchCampus;
      }
    };

    const validate = useVuelidate(rules, toRefs(createContactData));

    return {
      page,
      validate,
      createContactData,
      validateFields,
      resetFields,
      donation,
      supporting,
      runners,
      matched,
      matchRunner,
      setContact,
      addTeamMember,
      hasTeam,
      teamMembers,
      captain,
      useMyInfo,
      matchCaptain
    };
  }
});
</script>
