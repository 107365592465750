export const SET_DARK_MODE = "SET_DARK_MODE";
export const SET_SUPPORTING = "SET_SUPPORTING";
export const SET_TEAM_LIST = "SET_TEAM_LIST";
export const SET_DONATION = "SET_DONATION";
export const SET_CONTACT = "SET_CONTACT";
export const SET_TEAM = "SET_TEAM";
export const SET_CURRENT_TEAM = "SET_CURRENT_TEAM";
export const SET_SHIRTS = "SET_SHIRTS";
export const ADD_SHIRT = "ADD_SHIRT";
export const DELETE_SHIRT = "DELETE_SHIRT";
export const SET_RUNNERS = "SET_RUNNERS";
export const ADD_RUNNER = "ADD_RUNNER";
export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER";
export const SET_TEAM_MEMBERS = "SET_TEAM_MEMBERS";
export const SET_TEAM_MEMBER_QUANTITY = "SET_TEAM_MEMBER_QUANTITY";
export const SET_ERROR = "SET_ERROR";
export const SET_TOKEN = "SET_TOKEN";
export const SETUP_USER = "SETUP_USER";
export const LOGOUT = "LOGOUT";
export const DISPLAY_NOTIFICATION = "DISPLAY_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const SET_TRANSACTION = "SET_TRANSACTION";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const SET_ADDED_TEAM_MEMBERS = "ADDED_TEAM_MEMBERS";
export const SET_EVENT_SETTINGS = "SET_EVENT_SETTINGS";
export const SET_EVENT_SHIRT_IMAGE = "SET_EVENT_SHIRT_IMAGE";
export const ADD_CAPTAIN_TOOL_LINK = "ADD_CAPTAIN_TOOL_LINK";
export const REMOVE_CAPTAIN_TOOL_LINK = "REMOVE_CAPTAIN_TOOL_LINK";
export const ADD_CAPTAIN_ALERT = "ADD_CAPTAIN_ALERT";
export const REMOVE_CAPTAIN_ALERT = "REMOVE_CAPTAIN_ALERT";
export const ADD_BADGE = "ADD_BADGE";
export const REMOVE_BADGE = "REMOVE_BADGE";
export const SET_TEAM_EVENT_IMAGE = "SET_TEAM_EVENT_IMAGE";
export const SET_FACEBOOK_IMAGE = "SET_FACEBOOK_IMAGE";
export const SET_EMPLOYER_MATCH_IMAGE = "SET_EMPLOYER_MATCH_IMAGE";
export const SET_ELITE_BADGE_IMAGE = "SET_ELITE_BADGE_IMAGE";
export const SET_TEAM_SLUG = "SET_TEAM_SLUG";
export const ADD_TEST_EMAIL = "ADD_TEST_EMAIL";
export const REMOVE_TEST_EMAIL = "REMOVE_TEST_EMAIL";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const EDIT_PRODUCT_IMAGE = "EDIT_PRODUCT_IMAGE";

//Resets
export const RESET_TRANSACTION = "RESET_TRANSACTION";
export const RESET_CONTACT = "RESET_CONTACT";
export const RESET_SUPPORT = "RESET_SUPPORT";
export const RESET_RUNNERS = "RESET_RUNNERS";
export const RESET_SHIRT = "RESET_SHIRT";
export const RESET_DONATION = "RESET_DONATION";
export const RESET_TEAM_MEMBERS = "RESET_TEAM_MEMBERS";
