<template>
  <div class="col-span-12">
    <div class="grid grid-cols-12 gap-4 gap-y-5">
      <!--Email Subject-->
      <div class="intro-y col-span-12 sm:col-span-12 email-subject">
        <div class="email-subject">
          <label
            for="email_subject"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="email_subject"
              v-model="validate.email_subject.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.email_subject.$error }"
              placeholder=" "
              @input="$emit('update:emailSubject', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Email Subject Line</span
            >
          </label>
          <div
            v-for="error of validate.email_subject.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Email Message-->
      <div class="intro-y col-span-12 sm:col-span-12 email-subject">
        <div class="email-message">
          <label
            for="email_message"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <textarea
              id="email_message"
              v-model.trim="validate.email_message.$model"
              class="form-control text-base"
              :class="{
                'border-theme-6': validate.email_message.$error
              }"
              placeholder=" "
              rows="10"
              @input="$emit('update:emailMessage', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Email Message</span
            >
          </label>
          <div
            v-for="error of validate.email_message.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { required, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
export default defineComponent({
  props: {
    emailSubject: {
      type: String,
      default: () => {
        return "";
      },
      required: true
    },
    emailMessage: {
      type: String,
      default: () => {
        return "";
      },
      required: true
    },
    touch: {
      type: Boolean,
      required: false
    }
  },
  setup: () => ({ validate: useVuelidate() }),
  data() {
    return {
      email_subject: this.emailSubject,
      email_message: this.emailMessage
    };
  },
  watch: {
    emailSubject: function() {
      this.email_subject = this.emailSubject;
    },
    emailMessage: function() {
      this.email_message = this.emailMessage;
    }
  },
  validations() {
    return {
      email_subject: { required, minLength: minLength(10) },
      email_message: { required, minLength: minLength(10) }
    };
  }
});
</script>
