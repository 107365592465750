<template>
  <div class="grid grid-cols-12 gap-6">
    <!-- BEGIN: Donations -->
    <div class="col-span-12 md:col-span-8 -mt-6">
      <TeamDonationsGrid
        :transactions="teamDonations"
        :grid-columns="columns"
      />
    </div>
    <div class="col-span-12 md:col-span-4">
      <div class="intro-y box mt-5 p-6 max-w-3xl mx-auto">
        <h3 class="text-base font-bold mb-6">Make a Donation To Your Team</h3>
        <div class="action mt-6">
          <router-link
            class="btn btn-outline-primary w-full mb-2 flex-grow md:w-40"
            :to="
              `/frontend/donate/?team=${team.id}&city=${team.eventCity}&name=${team.name}`
            "
            target="_blank"
            >Donate</router-link
          >
        </div>
      </div>
    </div>
    <!-- END: Donations -->
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import TeamDonationsGrid from "@/components/back-end/TeamTransactionsGrid";
export default defineComponent({
  components: {
    TeamDonationsGrid
  },
  props: {
    team: {
      type: Object,
      default: () => {
        return {};
      },
      required: false
    },
    donations: {
      type: Array,
      default: function() {
        return [];
      },
      required: true
    }
  },
  setup(props) {
    const columns = ["name", "amount", "date"];
    const teamDonations = computed(() => props.donations);

    return {
      columns,
      teamDonations
    };
  }
});
</script>
