import { fetch } from "@/api/fetch-auto-refresh";

const apiURL = process.env.VUE_APP_API_URL;

const submitPayment = async (billingData, elements, stripe, checkoutTotal) => {
  let name = billingData.name_on_card;
  let address = billingData.billing_address;
  let city = billingData.billing_city;
  let state = billingData.billing_state;
  let zip = billingData.billing_zip;
  let email = billingData.email;
  let phone = billingData.phone;

  const billingDetails = {
    name: name,
    email: email,
    phone: phone,
    address: {
      city: city,
      line1: address,
      state: state,
      postal_code: zip
    }
  };

  const cardElement = elements.getElement("card");
  try {
    const createPaymentIntent = await fetch(apiURL + "/v1/payment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        amount: (checkoutTotal.value * 100).toFixed(0)
      })
    });

    const { secret, id } = await createPaymentIntent.json();

    const paymentMethodReq = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: billingDetails
    });

    if (paymentMethodReq.error) {
      return {
        message: paymentMethodReq.error.message
      };
    }

    const { error } = await stripe.confirmCardPayment(secret, {
      payment_method: paymentMethodReq.paymentMethod.id
    });

    if (error) return error;
    return {
      message: "Success",
      paymentId: id,
      paymentDetails: paymentMethodReq.paymentMethod.card
    };
  } catch (error) {
    return {
      message: error
    };
  }
};

export { submitPayment };
