<template>
  <div
    class="col-span-12 lg:col-span-4 xxl:col-span-3 flex lg:block flex-col-reverse"
  >
    <div class="intro-y box mt-5">
      <div
        v-for="(item, index) in settingsMenuItems"
        :key="index"
        class="p-5 border-t border-gray-200 dark:border-dark-5"
      >
        <router-link
          class="flex items-center text-theme-1 dark:text-theme-10"
          :to="{ name: item.path }"
        >
          {{ item.text }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
// import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    // const route = useRoute();
    let settingsMenuItems = [
      {
        text: "Event Settings",
        path: "event-settings"
      },
      {
        text: "Run Settings",
        path: "run-settings"
      },
      {
        text: "Badge Settings",
        path: "badge-settings"
      },
      {
        text: "Product Settings",
        path: "product-settings"
      },
      {
        text: "Captain Tools",
        path: "captains-tools"
      },
      {
        text: "Captain Alerts",
        path: "captain-alerts"
      },
      {
        text: "Constant Contact",
        path: "constant-contact-settings"
      },
      {
        text: "Debug Settings",
        path: "debug-settings"
      }
    ];
    return {
      settingsMenuItems
    };
  }
});
</script>

<style scoped>
.router-link-exact-active {
  @apply font-medium;
}
</style>
