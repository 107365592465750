import { fetch } from "@/api/fetch-auto-refresh";
import { useStore } from "@/store";
const store = useStore();
const apiURL = process.env.VUE_APP_API_URL;
const getLifeChurchParticipants = async eventYear => {
  let authToken = store.state.auth.token;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  const response = await fetch(
    apiURL +
      `/v1/persons/?eventYear=${eventYear}&lifeChurch=true&limit=1000&sortBy=createdAt:desc`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

const getTeams = async options => {
  const store = useStore();
  let authToken = store.state.auth.token;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  //Default
  let request = "?populate=captainPerson&limit=400&sortBy=totalRaised:desc";

  if (options) {
    var queryString = Object.keys(options)
      .map(key => key + "=" + options[key])
      .join("&");

    request = `?${queryString}&populate=captainPerson&limit=400&sortBy=totalRaised:desc`;
  }

  const response = await fetch(`${apiURL}/v1/teams/${request}`, requestOptions);
  const data = await response.json();
  return data;
};

const getLineItems = async options => {
  let authToken = store.state.auth.token;
  var queryString = Object.keys(options)
    .map(key => key + "=" + options[key])
    .join("&");
  let request = `?${queryString}&sortBy=createdAt:desc`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  const response = await fetch(
    `${apiURL}/v1/lineitems/${request}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

const getDailyReports = async () => {
  let authToken = store.state.auth.token;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  const response = await fetch(`${apiURL}/v1/daily-reports`, requestOptions);
  const data = await response.json();
  return data;
};

export { getLifeChurchParticipants, getTeams, getLineItems, getDailyReports };
