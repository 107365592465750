<template>
  <div>
    <FrontEndHeader fill="#be2032" />
    <!-- BEGIN: Create JoinTeamLayout -->
    <form class="validate-form" @submit.prevent="save">
      <div class="intro-y box mt-5 pb-10 max-w-3xl mx-auto">
        <div
          class="font-bold text-3xl px-5 sm:px-20 py-10 pb-5 sm:py-10 rounded-t"
        >
          {{ product.name }}
        </div>
        <div class="px-5 sm:px-20">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-3">
              <div v-if="shirts.length > 0" class="cart mb-6">
                <ShirtCart :shirts="shirts" delete-button />
              </div>
            </div>
            <div
              class="mt-5 md:mt-0 md:grid md:grid-cols-12 md:gap-6 md:col-span-3"
            >
              <div class="intro-y col-span-12 sm:col-span-8">
                <img :src="product.image" alt="Piecwalk T-Shirt" />
              </div>
              <div class="intro-y col-span-12 sm:col-span-4">
                <p class="mb-6 text-base text-gray-600">
                  ${{ product.price }} each
                </p>
                <div v-show="product.sizes && product.sizes.length > 0">
                  <p class="mb-6 text-gray-600">
                    Select a size and quantity to add to cart.
                  </p>
                  <ShirtSize
                    v-model="shirt.size"
                    :shirt-sizes="product.sizes"
                    :size-value="shirt.size"
                  />
                </div>
                <div v-show="product.sizes && !product.sizes.length > 0">
                  <p class="mb-6 text-gray-600">
                    Select a quantity to add to cart.
                  </p>
                </div>
                <ShirtQuantity
                  v-model="shirt.quantity"
                  :quantity-value="shirt.quantity"
                />
                <button
                  v-if="shirt.size"
                  type="button"
                  class="btn block py-3 btn-outline mt-6 w-full border-red text-red"
                  :disabled="validate.$errors.length > 0"
                  @click="addToCart()"
                >
                  Add To Cart
                </button>
              </div>
            </div>
          </div>
          <div
            class="intro-y col-span-12 flex items-center justify-between mt-6"
          >
            <div class="total text-center mb-6 sm:mb-0 sm:text-left">
              <div v-if="shirts.length > 0">
                <div>Total</div>
                <h4 class="text-xl">${{ totalShirtCost }}</h4>
              </div>
            </div>
            <button
              v-if="shirts.length > 0"
              type="button"
              class="btn block w-full intro-y py-3 sm:inline-block sm:w-auto btn-red border-reddark sm:ml-2 sm:px-10 btn-lg"
              :disabled="validate.$errors.length > 0"
              @click="checkOut()"
            >
              Checkout
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="flex justify-center mt-6 mb-6">
      <router-link
        :to="{ name: 'products' }"
        class="btn text-gray-600 bg-white border-transparent justify-self-start"
      >
        Back to All Products
      </router-link>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  toRefs
} from "vue";
import { useStore } from "@/store";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import FrontEndHeader from "@/components/front-end/FrontEndHeader";
import ShirtSize from "@/components/form-inputs/ShirtSize";
import ShirtQuantity from "@/components/form-inputs/ShirtQuantity";
import ShirtCart from "@/components/front-end/ShirtCart";

export default defineComponent({
  components: {
    FrontEndHeader,
    ShirtSize,
    ShirtQuantity,
    ShirtCart
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    let validateFields = ref(false);
    let resetFields = ref(false);
    let shipping = 8;
    const store = useStore();

    let productSlug = route.params.productSlug;

    const product = computed(() =>
      store.getters["main/getProductBySlug"](productSlug)
    );
    const shirts = store.state.shirt.shirts;

    const initShirt = {
      name: product.value.name,
      quantity: 1,
      price: product.value.price,
      size: ""
    };

    if (product.value.sizes && !product.value.sizes.length > 0) {
      initShirt.size = "N/A";
    }

    const shirt = reactive({ ...initShirt });

    const checkOut = async () => {
      await store.dispatch("shirt/setShirts", shirts);
      router.push({ path: "/frontend/contact", query: { skip: true } });
    };

    const addToCart = async () => {
      shirt.price = product.value.price;
      shirt.name = product.value.name;
      let newShirt = { ...shirt };
      await store.dispatch("shirt/addShirt", newShirt);
      Object.assign(shirt, initShirt);
    };

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("frontend create-team");
    });

    const createShirtsData = reactive(shirts);

    const rules = computed(() => {
      return {
        size: {
          required
        },
        quantity: {
          required
        }
      };
    });

    const validate = useVuelidate(rules, toRefs(createShirtsData));

    const totalShirtCost = computed(() => {
      let total = shirts.map(shirt => shirt.quantity * shirt.price);
      let sum = total.reduce((a, b) => a + b, 0) + shipping;
      return sum;
    });

    return {
      validate,
      createShirtsData,
      validateFields,
      resetFields,
      shirts,
      checkOut,
      addToCart,
      shirt,
      totalShirtCost,
      product,
      productSlug
    };
  }
});
</script>
