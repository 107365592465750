<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ pageTitle }}</h2>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <!-- BEGIN: Profile Menu -->
      <ProfileMenu :user="user" :person="person" />
      <!-- END: Profile Menu -->
      <router-view :user="user" :person="person" />
    </div>
    <!-- BEGIN: Admin Only -->
    <div v-if="isAdmin" class="mt-40 flex justify-center">
      <a href="" class="text-theme-6 flex items-center">
        <Trash2Icon class="w-4 h-4 mr-1" /> Deactivate Account
      </a>
    </div>
    <!-- END: Admin Only -->
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { getUser } from "@/api/users.js";
import ProfileMenu from "./ProfileMenu.vue";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    ProfileMenu
  },
  setup() {
    //const store = useStore();
    let user = reactive({});
    let person = reactive({});
    const route = useRoute();
    const store = useStore();
    let pageTitle = ref("My Profile");
    const isAdmin = ref(false);

    const setUser = async () => {
      let theUserID = "";
      if (route.params.id) {
        pageTitle.value = "Edit User";
        theUserID = route.params.id;
      }

      const getTheUser = await getUser(theUserID);
      const campuses = store.state.lifeChurchCampuses.locations;

      if (getTheUser.person.lifeChurchLocation) {
        let campusName = getTheUser.person.lifeChurchLocation;
        let foundCampusValue = campuses.find(c => c.name === campusName);
        getTheUser.person.lifeChurchCampus = foundCampusValue;
      }

      Object.assign(user, getTheUser);
      Object.assign(person, getTheUser.person);
    };

    onMounted(async () => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .removeClass("frontend")
        .addClass("profile-config");
      isAdmin.value = await store.getters["auth/isAdmin"];
      await setUser();
    });

    return {
      pageTitle,
      user,
      person,
      isAdmin
    };
  }
});
</script>
