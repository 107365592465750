<template>
  <div>
    <label
      for="dollar_amount"
      class="form-label placeholder-label relative w-full mt-3 text-base"
    >
      <input
        id="dollar_amount"
        v-model.number="validate.dollar_amount.$model"
        type="number"
        min="10"
        step="1"
        class="form-control p-3"
        :class="{ 'border-theme-6': validate.dollar_amount.$error }"
        placeholder=" "
        onkeydown="if(event.key==='.'){event.preventDefault();}"
        oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
        @input="$emit('update:modelValue', parseInt($event.target.value))"
      />
      <span
        class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
        >Donation Amount</span
      >
    </label>
    <div
      v-for="error of validate.dollar_amount.$errors"
      :key="error.$uid"
      class="input-errors"
    >
      <div class="error-msg text-theme-6 mt-2">
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { required, numeric, minValue } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
  props: {
    dollarAmount: {
      type: Number,
      default: () => {
        return 0;
      },
      required: false
    },
    touch: {
      type: Boolean,
      required: false
    }
  },
  setup: () => ({ validate: useVuelidate() }),
  data() {
    return {
      dollar_amount: this.dollarAmount
    };
  },
  watch: {
    touch: function(val) {
      if (val) {
        this.validate.dollar_amount.$touch();
      }
    }
  },
  validations() {
    return {
      dollar_amount: { required, numeric, minValue: minValue(10) }
    };
  }
});
</script>
