<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Participants</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <div class="mt-4 sm:mt-0">
          <form id="grid-search" class="mr-3">
            <div class="w-64 relative text-gray-700 dark:text-gray-300">
              <input
                v-model="searchQuery"
                type="text"
                class="form-control p-3 w-64 box pr-10 placeholder-theme-13"
                placeholder="Search..."
              />
              <SearchIcon
                class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
              />
            </div>
          </form>
        </div>
        <div class="hidden md:block mx-auto text-gray-600">
          {{ participantData.totalResults }} Total Participants
        </div>
      </div>
    </div>
    <div class="intro-y mt-5">
      <ParticipantGrid
        :grid-data="formattedParticipants"
        :query="searchQuery"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, computed, reactive, ref } from "vue";
import { useStore } from "@/store";
import { getParticipants } from "@/api/participants.js";
import ParticipantGrid from "@/components/back-end/ParticipantsGrid";

export default defineComponent({
  components: {
    ParticipantGrid
  },
  setup() {
    const store = useStore();
    let participantData = reactive([]);
    let formattedParticipants = reactive([]);
    let searchQuery = ref("");
    const appSettings = computed(() => store.getters["main/eventSettings"]);

    const getParticpantData = async eventYear => {
      if (!eventYear) {
        eventYear = appSettings.value.appYear;
      }
      console.log(eventYear);
      const allParticipants = await getParticipants(eventYear);
      Object.assign(participantData, allParticipants);
      reformattedUsers(allParticipants.results);
    };

    const reformattedUsers = theParticipants => {
      let participants = theParticipants;
      let formattedParticipantData = [];
      participants.map(p => {
        let obj = {};
        obj.active = p.active;
        obj.id = p.id;
        obj.name = p.firstName + " " + p.lastName;
        obj.email = p.email;
        if (p.teams.length > 0) obj.hasTeam = true;
        obj.team = p.teams;
        obj.phone = p.phone;
        obj.email = p.email;
        obj.lifeChurch = p.lifeChurch;
        if (p.lifeChurch) {
          obj.lifeChurchLocation = p.lifeChurchLocation;
        }
        obj.eventCity = p.eventCity;
        obj.eventYear = p.eventYear;

        formattedParticipantData.push(obj);
      });
      Object.assign(formattedParticipants, formattedParticipantData);
    };

    onMounted(() => {
      getParticpantData();
    });

    return {
      participantData,
      formattedParticipants,
      searchQuery,
      getParticpantData
    };
  }
});
</script>
