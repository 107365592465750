import * as types from "./mutation-types";

const state = () => {
  return {
    contact: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      lifeChurch: false,
      lifeChurchCampus: {},
      anonymous: false,
      employer: "",
      useEmployerAsName: false,
      marketing: true
    },
    initContact: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      lifeChurch: false,
      lifeChurchCampus: {},
      anonymous: false,
      employer: "",
      useEmployerAsName: false,
      marketing: true
    }
  };
};

// getters
const getters = {
  contact: state => state.contact
};

// actions
const actions = {
  setContact({ commit }, contact) {
    commit(types.SET_CONTACT, { contact });
  },
  resetContact({ commit }) {
    commit(types.RESET_CONTACT);
  }
};

// mutations
const mutations = {
  [types.SET_CONTACT](state, { contact }) {
    state.contact = contact;
  },
  [types.RESET_CONTACT](state) {
    state.contact = state.initContact;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
