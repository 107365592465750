<template>
  <div
    :class="{
      show: show
    }"
    class="alert relative intro-y alert-primary mt-6"
    role="alert"
  >
    <div class="flex items-center">
      <div class="font-medium text-lg">
        {{ headline }}
      </div>
      <button
        type="button"
        class="text-base bg-white px-2 absolute -right-1 -top-1 rounded-full text-gray-800"
        @click="closeAlert()"
      >
        X
      </button>
    </div>
    <div class="mt-3">
      {{ text }}
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    headline: {
      type: String,
      default: function() {
        return "";
      },
      required: false
    },
    text: {
      type: String,
      default: function() {
        return "";
      },
      required: false
    }
  },
  setup() {
    let show = ref(true);
    const closeAlert = () => {
      show.value = false;
    };

    return {
      show,
      closeAlert
    };
  }
});
</script>
