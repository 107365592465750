<template>
  <div>
    <div
      class="flex justify-start items-center col-span-12 rounded intro-y border-2 border-solid border-theme-9 px-3 py-2 mt-2"
    >
      <div class="inline-block">
        <CheckCircleIcon class="w-6 h-6 mr-4 fill-theme-9 inline-block" />
      </div>
      <div class="donate-info inline-block flex-grow">
        <h6 class="text-xs">5k Partipants</h6>
        <h4 v-for="(runner, index) in runners" :key="index" class="text-base">
          <span>
            {{ runner.first_name }} {{ runner.last_name }}
            <span v-if="!runner.virtual" class="text-sm">(in-person)</span
            ><span v-if="runner.virtual" class="text-sm">(virtual)</span></span
          >
          <span> @ ${{ runner.price }} </span>
        </h4>
      </div>
      <div class="edit-donate-info justify-self-end">
        <router-link class="btn btn-sm" to="/frontend/run">
          Edit
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    runners: {
      type: Array,
      default: function() {
        return [];
      },
      required: false
    }
  },
  setup() {
    const store = useStore();
    const appTitle = computed(() => store.getters["main/appTitle"]);
    const appYear = computed(() => store.getters["main/appYear"]);

    return {
      appTitle,
      appYear
    };
  }
});
</script>
