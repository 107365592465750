<template>
  <div>
    <label
      for="team_message"
      class="form-label placeholder-label relative w-full mt-3 text-base"
    >
      <textarea
        id="team_message"
        v-model.trim="validate.team_message.$model"
        class="form-control text-base"
        :class="{
          'border-theme-6': validate.team_message.$error
        }"
        placeholder=" "
        rows="10"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <span
        class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
        >Team Message</span
      >
    </label>
    <div
      v-for="error of validate.team_message.$errors"
      :key="error.$uid"
      class="input-errors"
    >
      <div class="error-msg text-theme-6 mt-2">
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
  props: {
    touch: {
      type: Boolean,
      required: false
    }
  },
  setup: () => ({ validate: useVuelidate() }),
  data() {
    return {
      team_message: ""
    };
  },
  watch: {
    touch: function(val) {
      if (val) {
        this.validate.team_message.$touch();
      }
    }
  },
  validations() {
    return {
      team_message: { required }
    };
  }
});
</script>
