import { fetch } from "@/api/fetch-auto-refresh";
import { useStore } from "@/store";
const apiURL = process.env.VUE_APP_API_URL;

const emailSend = async emailMessage => {
  const store = useStore();
  let authToken = store.state.auth.token;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    },
    body: JSON.stringify(emailMessage)
  };

  const response = await fetch(
    `${apiURL}/v1/teams/email-team/`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

export { emailSend };
