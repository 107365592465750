<template>
  <div>
    <label
      for="passwordConfirm"
      class="form-label placeholder-label relative w-full mt-3 text-base"
    >
      <input
        id="passwordConfirm"
        v-model.trim="validate.confirm.$model"
        type="password"
        class="form-control p-3 text-base"
        :class="{
          'border-theme-6': validate.confirm.$error
        }"
        placeholder=" "
        autocomplete="new-password"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <span
        class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
        >Confirm Password</span
      >
    </label>
    <div
      v-for="error of validate.confirm.$errors"
      :key="error.$uid"
      class="input-errors"
    >
      <div class="error-msg text-theme-6 mt-2">
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { required, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
  props: {
    touch: {
      type: Boolean,
      required: false
    },
    password: {
      type: String,
      required: true
    }
  },
  setup: () => ({ validate: useVuelidate() }),
  data() {
    return {
      confirm: ""
    };
  },
  watch: {
    touch: function(val) {
      if (val) {
        this.validate.confirm.$touch();
      }
    }
  },
  validations() {
    return {
      confirm: { required, sameAs: sameAs(this.password) }
    };
  }
});
</script>
