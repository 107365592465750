<template>
  <div class="mt-6 mb-6">
    <form
      v-if="search"
      id="grid-search"
      class="flex md:w-1/2 mx-auto items-center"
    >
      <span class="pr-4">Search</span>
      <input v-model="searchQuery" class="form-control p-3" name="query" />
    </form>
    <table class="border-gray-200 border w-full mt-6 table table-report">
      <thead class="p-3">
        <tr>
          <th
            v-for="(key, index) in columns"
            :key="index"
            :class="{ 'text-red active': sortKey == key }"
            class="cursor-pointer text-left px-4 py-2 capitalize bg-gray-200"
            @click="sortBy(key)"
          >
            {{ key }}
            <span class="arrow" :class="sortOrders[key] > 0 ? 'asc' : 'dsc'">
            </span>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(entry, index) in filteredData"
          :key="index"
          class="hover:bg-gray-100 transition"
        >
          <td v-for="(key, i) in columns" :key="i" class="border-b px-4 py-2">
            <span v-if="key !== 'active'" class="block">{{ entry[key] }}</span>
            <span v-if="key == 'active'" class="block">
              <span
                :class="{ 'btn-outline-danger': !entry[key] }"
                class="btn btn-outline-success w-24"
                ><span v-if="!entry[key]">In</span>Active</span
              >
            </span>
          </td>
          <td v-if="isAdmin">
            <router-link
              class="btn btn-outline"
              :to="`/participants/${entry.personId}`"
              ><EditIcon class="h-4 w-4 mr-1" /> Edit</router-link
            >
          </td>
          <td>
            <button
              v-show="index !== 0"
              type="button"
              class="text-red"
              @click="removeMember(entry.personId)"
            >
              <TrashIcon class="h-4 w-4" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, computed } from "vue";
import { useStore } from "@/store";
import { removePersonFromTeam } from "@/api/participants.js";
export default defineComponent({
  props: {
    search: {
      type: Boolean,
      default: function() {
        return false;
      },
      required: false
    },
    gridColumns: {
      type: Array,
      default: function() {
        return [];
      },
      required: false
    },
    members: {
      type: Array,
      default: function() {
        return [];
      },
      required: false
    },
    isAdmin: {
      type: Boolean,
      default: function() {
        return false;
      },
      required: false
    }
  },
  emits: ["setTeamData"],
  setup(props, { emit }) {
    const store = useStore();
    const team = computed(() => store.getters["backEndTeam/currentTeam"]);
    let sortKey = ref("");
    let searchQuery = ref("");
    let columns = props.gridColumns;

    let sortOrders = reactive({});
    columns.forEach(function(key) {
      sortOrders[key] = 1;
    });

    let data = reactive(props.members);

    const filteredData = computed(() => {
      let theSortKey = sortKey.value;
      let theSearchQuery = searchQuery.value && searchQuery.value.toLowerCase();
      let order = sortOrders[theSortKey] || 1;
      if (searchQuery) {
        data = props.members.filter(function(row) {
          return Object.keys(row).some(function(key) {
            return (
              String(row[key])
                .toLowerCase()
                .indexOf(theSearchQuery) > -1
            );
          });
        });
      }
      if (theSortKey) {
        data = data.slice().sort(function(a, b) {
          a = a[theSortKey];
          b = b[theSortKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return data;
    });

    const removeMember = async personId => {
      let teamId = team.value.id;
      let teamReq = {
        team: teamId
      };
      await removePersonFromTeam(personId, teamReq).then(response => {
        emit("setTeamData");
        console.log(response);
      });
    };

    const sortBy = key => {
      sortKey.value = key;
      sortOrders[key] = sortOrders[key] * -1;
    };

    return {
      sortKey,
      sortOrders,
      searchQuery,
      columns,
      filteredData,
      sortBy,
      removeMember
    };
  }
});
</script>
<style scoped>
th.active .arrow {
  opacity: 1;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #000;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
}
</style>
