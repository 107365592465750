<template>
  <div class="col-span-12">
    <div class="grid grid-cols-12 gap-4 gap-y-5">
      <div v-show="!teamOnly" class="intro-y col-span-12 sm:col-span-12">
        <div class="flex justify-center w-full rounded-lg text-lg" role="group">
          <button
            type="button"
            class="btn-group-btn rounded-l-lg border border-gray-600 border-r-0"
            :class="{
              selected: support_type === 'Team'
            }"
            @click="setSupportType('Team')"
          >
            Team {{ subject }}
          </button>
          <button
            type="button"
            class="btn-group-btn rounded-r-lg border border-gray-600"
            :class="{
              selected: support_type === 'Event'
            }"
            @click="setSupportType('Event')"
          >
            Event {{ subject }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-show="support_type === 'Event'"
      class="intro-y col-span-12 sm:col-span-12 mt-6"
    >
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        Select an Event City
      </h3>
      <div>
        <label for="city_list" class="form-label sr-only">
          Event City
        </label>
        <select
          id="city_list"
          v-model="event_city"
          class="form-select sm:mr-2 p-3 sm:mt-3 text-base"
          aria-label="Team List"
          :class="{ 'border-theme-6': validate.event_city.$error }"
          @change="
            $emit('update:eventCity', {
              value: parseInt(event_city.value),
              city: event_city.city
            })
          "
        >
          <option value="0" disabled selected>Select A City</option>
          <option
            v-for="city in cityList"
            :key="city.id"
            :value="{ value: city.id, city: city.name }"
          >
            {{ city.name }}
          </option></select
        >
      </div>
    </div>
    <div
      v-show="support_type === 'Team'"
      class="intro-y col-span-12 sm:col-span-12 mt-6"
    >
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        Select a Team to Support
      </h3>
      <div>
        <label for="team_list" class="form-label sr-only">
          Select A Team
        </label>
        <select
          id="team_list"
          v-model="team_selected"
          class="form-select sm:mr-2 p-3 sm:mt-3 text-base"
          aria-label="Team List"
          :class="{ 'border-theme-6': validate.team_selected.$error }"
          @change="
            $emit('update:teamSelected', {
              id: team_selected.id,
              name: team_selected.name,
              city: team_selected.city
            })
          "
        >
          <option value="0" disabled selected>Select A Team</option>
          <option
            v-for="team in teamList"
            :key="team.id"
            :value="{ id: team.id, name: team.name, city: team.eventCity }"
          >
            {{ team.name }}
          </option></select
        >
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from "vue";
import { useStore } from "@/store";
import { required, requiredIf, minValue } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
export default defineComponent({
  props: {
    teamOnly: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    subject: {
      type: String,
      default: () => {
        return "";
      }
    },
    supportType: {
      type: String,
      default: () => {
        return "";
      }
    },
    eventCityValue: {
      type: Object,
      default: () => {
        return {
          value: 0,
          name: "Select a City"
        };
      },
      required: false
    },
    teamSelected: {
      type: Object,
      default: () => {
        return {
          value: 0,
          name: "Select a Team",
          city: ""
        };
      },
      required: false
    },
    touch: {
      type: Boolean,
      required: false
    }
  },
  setup(props, context) {
    const store = useStore();
    const cityList = store.getters["support/cityList"];
    const validate = useVuelidate();

    let support_type = ref("");

    onMounted(async () => {
      await store.dispatch("support/setTeamList");
      if (props.teamOnly) {
        support_type.value = "Team";
        context.emit("update:supportType", support_type);
      } else {
        support_type.value = props.supportType;
      }
    });

    const teamList = computed(() => {
      return store.getters["support/teamList"];
    });

    const validTeamValue = computed(() => {
      if (props.supportType === "Team") {
        return 1;
      }
      return 0;
    });

    const validCityValue = computed(() => {
      if (props.supportType === "Event") {
        return 1;
      }
      return 0;
    });

    return {
      support_type,
      teamList,
      cityList,
      validate,
      validTeamValue,
      validCityValue
    };
  },
  data() {
    return {
      event_city: this.eventCityValue,
      team_selected: this.teamSelected
    };
  },
  watch: {},
  validations() {
    return {
      support_type: { required },
      event_city: {
        required: requiredIf(function() {
          return this.support_type === "Event";
        }),
        value: { minValue: minValue(this.validCityValue) }
      },
      team_selected: {
        required: requiredIf(function() {
          return this.support_type === "Team";
        }),
        value: { minValue: minValue(this.validTeamValue) }
      }
    };
  },
  methods: {
    setSupportType(type) {
      this.support_type = type;
      this.$emit("update:supportType", type);
    }
  }
});
</script>
