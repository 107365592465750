<template>
  <div>
    <input
      id="matchRunner"
      v-model.trim="matched"
      class="form-check-input"
      type="checkbox"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <label class="form-check-label text-base" for="matchRunner">{{
      text
    }}</label>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isMatched: {
      type: Boolean,
      default: () => {
        return false;
      },
      required: true
    },
    text: {
      type: String,
      default: () => {
        return "Same Contact Info as Runner 1";
      }
    }
  },
  data() {
    return {
      matched: this.isMatched
    };
  }
});
</script>
