<template>
  <div class="col-span-12">
    <div class="grid grid-cols-12 gap-4 gap-y-5">
      <!--FirstName-->
      <div class="intro-y col-span-6 sm:col-span-6 first-name-field">
        <div class="first-name">
          <label
            for="first_name"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="first_name"
              v-model="validate.first_name.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.first_name.$error }"
              placeholder=" "
              @input="$emit('update:firstName', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >First Name</span
            >
          </label>
          <div
            v-for="error of validate.first_name.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Last Name-->
      <div class="intro-y col-span-6 sm:col-span-6 last-name-field">
        <div class="last-name">
          <label
            for="last_name"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="last_name"
              v-model="validate.last_name.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.last_name.$error }"
              placeholder=" "
              @input="$emit('update:lastName', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Last Name</span
            >
          </label>
          <div
            v-for="error of validate.last_name.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Employer or Organization-->
      <div
        v-if="showEmployer"
        class="intro-y col-span-12 sm:col-span-12 employer-field"
      >
        <div class="employer">
          <label
            for="employer"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="employer"
              v-model.trim="validate.employer.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.employer.$error }"
              placeholder=" "
              @input="$emit('update:myEmployer', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-sm md:text-base"
              >Employer or Organization (Optional)</span
            >
          </label>
          <div
            v-for="error of validate.employer.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Use as name on donation-->
      <div
        v-if="
          (showEmployer &&
            showUseEmployerAsName &&
            donationAmount > 0 &&
            myEmployer !== '') ||
            backEnd
        "
        class="intro-y col-span-12 sm:col-span-12 form-check mt-2 mb-2"
      >
        <input
          id="use-employer"
          v-model.trim="use_employer_as_name"
          class="form-check-input"
          type="checkbox"
          @change="$emit('update:useEmployerAsName', $event.target.checked)"
        />
        <label class="form-check-label text-base" for="use-employer"
          >Use Employer or Organiztion as donor name
          <a
            href="javascript:;"
            name="use-employer"
            class="tooltip ml-1 relative -top-1 bg-gray-700 text-white px-1 rounded-full text-xs font-bold inline-block"
            >?</a
          ></label
        >
        <div class="tooltip-content">
          <TippyContent to="use-employer">
            <div class="font-medium dark:text-gray-300">
              Donor Name
            </div>
            <div class="flex items-center mt-2 sm:mt-0">
              <p>
                Check this if you want your donation to be recorded under an
                organization or employer name.
              </p>
            </div>
          </TippyContent>
        </div>
      </div>
      <!--Email-->
      <div class="intro-y col-span-12 sm:col-span-6 email-field">
        <div class="email">
          <label
            for="email"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="email"
              v-model.trim="validate.email.$model"
              type="email"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.email.$error }"
              placeholder=" "
              :disabled="emailDisabled"
              @input="$emit('update:emailAddress', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Email</span
            >
          </label>
          <div
            v-for="error of validate.email.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Phone Number-->
      <div class="intro-y col-span-12 sm:col-span-6 phone-number-field">
        <div class="phone-number">
          <label
            for="phone"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="phone"
              v-model.trim="validate.phone.$model"
              v-maska="'(###) ###-####'"
              type="tel"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.phone.$error }"
              placeholder=" "
              autocomplete="tel"
              @input="$emit('update:phoneNumber', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Phone</span
            >
          </label>
          <div
            v-for="error of validate.phone.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { required, helpers, minLength, email } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
export default defineComponent({
  props: {
    firstName: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    lastName: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    emailAddress: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    phoneNumber: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    myEmployer: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    useEmployerAsName: {
      type: Boolean,
      required: false
    },
    touch: {
      type: Boolean,
      required: false
    },
    phoneNotRequired: {
      type: Boolean,
      required: false
    },
    showEmployer: {
      type: Boolean,
      required: false
    },
    showUseEmployerAsName: {
      type: Boolean,
      required: false
    },
    donationAmount: {
      type: Number,
      default: () => {
        return 0;
      },
      required: false
    },
    backEnd: {
      type: Boolean,
      required: false
    },
    emailDisabled: {
      type: Boolean,
      required: false
    }
  },
  setup: () => ({ validate: useVuelidate() }),
  data() {
    return {
      first_name: this.firstName,
      last_name: this.lastName,
      employer: this.myEmployer,
      email: this.emailAddress,
      phone: this.phoneNumber,
      use_employer_as_name: this.useEmployerAsName
    };
  },
  watch: {
    firstName: function() {
      this.first_name = this.firstName;
    },
    lastName: function() {
      this.last_name = this.lastName;
    },
    emailAddress: function() {
      this.email = this.emailAddress;
    },
    phoneNumber: function() {
      this.phone = this.phoneNumber;
    },
    myEmployer: function() {
      this.employer = this.myEmployer;
    },
    useEmployerAsName: function() {
      this.use_employer_as_name = this.useEmployerAsName;
    }
  },
  validations() {
    let phone = {
      required,
      minLength: helpers.withMessage(
        "Must be a valid phone number.",
        minLength(14)
      )
    };
    if (this.phoneNotRequired) {
      phone = {
        minLength: helpers.withMessage(
          "Must be a valid phone number.",
          minLength(14)
        )
      };
    }
    return {
      first_name: { required, minLength: minLength(2) },
      last_name: { required, minLength: minLength(2) },
      employer: { minLength: minLength(2) },
      email: { required, email },
      phone: phone
    };
  }
});
</script>
