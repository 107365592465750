import * as types from "./mutation-types";
const state = () => {
  return {
    token: "",
    refreshToken: "",
    expiration: "",
    error: {
      status: false,
      message: ""
    },
    user: {},
    userTeamSlug: ""
  };
};

// getters
const getters = {
  auth: state => {
    const authInfo = {
      token: state.token,
      expiration: state.expiration
    };
    return authInfo;
  },
  isAuthenticated: async state => {
    let expires = new Date(state.expiration);
    if (expires < Date.now()) {
      //If it somehow gets to this, we probalby need to logou before removing pwUser
      localStorage.removeItem("pwUser");
    }
    return state.token !== "" && expires > Date.now();
  },
  isAdmin: async state => {
    if (state.user.role == "admin") {
      return true;
    }
    return false;
  },
  getTeamSlug: state => {
    return state.teamSlug;
  }
};

// actions
const actions = {
  setAuth: async ({ commit }, data) => {
    await commit(types.SETUP_USER, data);
    await commit(types.SET_TOKEN, data);
  },
  setupUser: async ({ commit }, data) => {
    await commit(types.SETUP_USER, data);
  },
  logout: async ({ commit }) => {
    await commit(types.LOGOUT);
  },
  setTeamSlug: async ({ commit }, slug) => {
    await commit(types.SET_TEAM_SLUG, slug);
  }
};

// mutations
const mutations = {
  [types.SET_TOKEN](state, data) {
    state.error.status = false;
    if (data.tokens) {
      localStorage.setItem("pwUser", JSON.stringify(data));
    }
  },
  [types.SETUP_USER](state, data) {
    state.error.status = false;
    state.token = data.tokens.access.token;
    state.refreshToken = data.tokens.refresh.token;
    state.expiration = data.tokens.access.expires;
    state.user = data.user;
  },
  [types.SET_TEAM_SLUG](state, slug) {
    state.userTeamSlug = slug;
  },
  [types.LOGOUT](state) {
    state.token = "";
    state.refreshToken = "";
    state.expiration = "";
    state.user = {};
    localStorage.removeItem("pwUser");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
