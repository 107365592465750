import * as types from "./mutation-types";

const state = () => {
  return {
    runData: {
      runners: [],
      quantity: 0,
      runCost: 30,
      virtualCost: 25
    },
    initRunData: {
      runners: [],
      quantity: 0,
      runCost: 30,
      virtualCost: 25
    }
  };
};

// getters
const getters = {
  runners: state => state.runners,
  runCost: state => state.runCost
};

// actions
const actions = {
  setRunners: async ({ commit }, runners) => {
    await commit(types.SET_RUNNERS, { runners });
  },
  resetRunners: async ({ commit }) => {
    await commit(types.RESET_RUNNERS);
  }
};

// mutations
const mutations = {
  [types.SET_RUNNERS](state, { runners }) {
    state.runners = runners;
  },
  [types.RESET_RUNNERS](state) {
    state.runners = state.initRunData;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
