<template>
  <div class="pt-6">
    <div class="cart px-5 sm:px-20 pt-1">
      <div class="contact-summary md:grid md:grid-cols-12">
        <div class="md:col-span-12">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Team Members Added
          </h3>
          <div class="md:grid md:grid-cols-12 md:gap-6 capitalize">
            <div
              v-for="(member, index) in members"
              :key="index"
              class="md:col-span-6 text-gray-600 mt-1"
            >
              <div class="new-member">
                {{ member.firstName }} {{ member.lastName }} <br />
                <span class="lowercase">{{ member.email }}</span> <br />
                {{ member.phone }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
  props: {
    members: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  setup() {}
});
</script>
