<template>
  <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Debug Settings
      </h2>
    </div>
    <div class="mt-5">
      <!-- BEGIN: Debug Settings Content -->
      <div class="config-content">
        <form class="validate-form" @submit.prevent="saveEventSettings">
          <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12">
              <div class="intro-y box mt-2 p-6">
                <div>
                  <h3 class="font-bold text-base mb-6">Email Debugging</h3>
                  <div class="grid grid-cols-12 gap-6">
                    <div class="col-span-12 lg:col-span-4">
                      <div
                        v-if="eventSettings"
                        class="w-full sm:w-auto flex items-center mt-3 sm:mt-0 mb-6"
                      >
                        <input
                          id="use-test-notifications"
                          v-model.trim="eventSettings.testEmailNotifications"
                          class="form-check-switch mr-0 ml-0"
                          type="checkbox"
                          :checked="eventSettings.testEmailNotifications"
                        />
                        <label
                          class="form-check-label font-medium"
                          for="use-test-notifications"
                          >Enable Test Notifications
                          <a
                            href="javascript:;"
                            name="use-test-notifications"
                            class="tooltip ml-1 relative -top-1 bg-gray-700 text-white px-1 rounded-full text-xs font-bold inline-block"
                            >?</a
                          ></label
                        >
                        <div class="tooltip-content">
                          <TippyContent to="use-test-notifications">
                            <div class="font-medium dark:text-gray-300">
                              Test Notifications
                            </div>
                            <div class="flex items-center mt-2 sm:mt-0">
                              <p>
                                Enable this to route all notifications to a test
                                email(s).
                              </p>
                            </div>
                          </TippyContent>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-show="
                      eventSettings && eventSettings.testEmailNotifications
                    "
                    class="grid grid-cols-12 gap-6 border border-solid border-gray-200 p-6 mb-6"
                  >
                    <div class="col-span-12">
                      <h3 class="text-base font-medium">
                        New Test Email
                      </h3>
                      <p class="italic">
                        Add emails to get notified when testing.
                      </p>
                    </div>
                    <div class="col-span-12">
                      <label
                        for="test_email"
                        class="form-label placeholder-label relative w-full mt-3 text-base"
                      >
                        <input
                          id="test_email"
                          v-model="newTestEmail"
                          type="email"
                          class="form-control p-3"
                          placeholder=" "
                        />
                        <span
                          class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                          >Email</span
                        >
                      </label>
                    </div>
                    <div class="col-span-12 lg:col-span-4 mt-4">
                      <button
                        class="btn btn-outline-primary"
                        type="button"
                        @click="addTestEmail()"
                      >
                        Add Email
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  v-show="eventSettings && eventSettings.testEmailNotifications"
                  class="grid grid-cols-12 gap-6"
                >
                  <div class="col-span-12">
                    <h2 class="col-span-12 text-base font-medium">
                      Current Test Emails
                    </h2>
                    <ul
                      v-if="eventSettings && eventSettings.testEmails"
                      class="mt-2"
                    >
                      <li
                        v-for="(email, index) in eventSettings.testEmails"
                        :key="index"
                      >
                        <div
                          class="grid grid-cols-12 gap-6 mb-2 border border-solid border-gray-400 p-3"
                        >
                          <div class="col-span-9">
                            <span class="block font-medium text-base">{{
                              email
                            }}</span>
                          </div>
                          <div class="col-span-3 text-right">
                            <button
                              class="btn btn-sm btn-red mt-1"
                              type="button"
                              @click="removeTestEmail(index)"
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div v-if="eventSettings && eventSettings.captainAlerts">
                      <h4 v-if="eventSettings.captainTools.length === 0">
                        No test emails have been added.
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end mt-8">
                  <button
                    type="submit"
                    class="btn btn-lg btn-primary w-20 mr-auto"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- END: Debug Settings Content -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "@/store";
import { updateEventSettings } from "@/api/event-settings";

export default defineComponent({
  setup() {
    const validate = useVuelidate();
    const store = useStore();
    const eventSettings = computed(() => store.getters["main/eventSettings"]);

    const newTestEmail = ref("");

    const addTestEmail = async () => {
      if (newTestEmail.value) {
        await saveTestEmailToStore();
        newTestEmail.value = "";
      }
    };

    const removeTestEmail = async index => {
      await store.dispatch("main/removeTestEmail", index);
    };

    const saveTestEmailToStore = async () => {
      let testEmail = newTestEmail.value;
      await store.dispatch("main/addTestEmail", testEmail);
    };

    const sendNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "Event settings have been updated."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem updating the event settings."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const saveEventSettings = async () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        let response = {
          code: 400
        };
        await sendNotification(response);
      } else {
        const saveEventSettingsResult = await updateEventSettings(
          eventSettings.value
        );
        await sendNotification(saveEventSettingsResult);
      }
    };

    return {
      eventSettings,
      saveEventSettings,
      newTestEmail,
      addTestEmail,
      removeTestEmail
    };
  }
});
</script>
