<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8 print-bar">
      <h2 class="text-lg font-medium mr-auto">Invoice</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-if="shippingAddress"
          class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0 mr-12"
        >
          <input
            id="shipped-active"
            v-model.trim="invoice.hasShipped"
            class="form-check-switch mr-0 ml-0"
            type="checkbox"
            :checked="invoice.hasShipped"
            @change="updateTransShipping()"
          />
          <label class="form-check-label font-medium" for="shipped-active"
            ><span v-show="invoice.hasShipped">
              Shipped
            </span>
            <span v-show="!invoice.hasShipped">
              Not Shipped
            </span></label
          >
        </div>
        <button
          class="btn btn-primary-outline shadow-md mr-2"
          @click="router.go(-1)"
        >
          <ChevronLeftIcon class="w-4 h-4" /> Back
        </button>
        <button onclick="window.print()" class="btn btn-primary shadow-md mr-2">
          Print
        </button>
      </div>
    </div>
    <!-- BEGIN: Invoice -->
    <div class="intro-y box overflow-hidden mt-5 invoice">
      <div
        class="border-b border-gray-200 dark:border-dark-5 text-center sm:text-left"
      >
        <div class="px-20 py-20">
          <div class="text-theme-1 dark:text-theme-10 font-semibold text-3xl">
            RECEIPT
          </div>
          <div class="mt-2">
            Receipt <span class="font-medium">#{{ invoice.id }}</span>
          </div>
          <div class="mt-1">{{ invoice.createdAt }}</div>
        </div>
        <div class="flex flex-row px-20 pt-10 pb-10 pb-20 w-full text-left">
          <div class="flex-1">
            <div class="text-base text-gray-600">Billing Details</div>
            <div
              class="text-lg font-medium text-theme-1 dark:text-theme-10 mt-2"
            >
              {{ invoice.contactFirstName }} {{ invoice.contactLastName }}
            </div>
            <div class="mt-1">{{ invoice.contactEmail }}</div>
            <div v-if="invoice.billingContact" class="mt-1">
              {{ invoice.billingContact.billing_address }}
              {{ invoice.billingContact.billing_city }},
              {{ invoice.billingContact.billing_state }}
              {{ invoice.billingContact.billing_zip }}
            </div>
          </div>
          <div v-if="shippingAddress" class="text-right mt-0 ml-auto">
            <div class="text-base text-gray-600">Ship to</div>
            <div
              class="text-lg font-medium text-theme-1 dark:text-theme-10 mt-2"
            >
              {{ invoice.contactFirstName }} {{ invoice.contactLastName }}
            </div>
            <div class="mt-1">
              {{ shippingAddress.address }} <br />
              {{ shippingAddress.city }},
              {{ shippingAddress.state }}
              {{ shippingAddress.zip }}
            </div>
          </div>
        </div>
      </div>
      <div class="px-5 sm:px-16 py-10 sm:py-20">
        <div class="overflow-x-auto">
          <table class="table">
            <thead>
              <tr>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  DESCRIPTION
                </th>
                <th
                  class="border-b-2 dark:border-dark-5 text-right whitespace-nowrap"
                >
                  QTY
                </th>
                <th
                  class="border-b-2 dark:border-dark-5 text-right whitespace-nowrap"
                >
                  PRICE
                </th>
                <th
                  class="border-b-2 dark:border-dark-5 text-right whitespace-nowrap"
                >
                  SUBTOTAL
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in invoice.lineItems" :key="item.id">
                <td class="border-b dark:border-dark-5">
                  <div class="md:grid grid-cols-12 grid-gap-6">
                    <div class="md:col-span-6">
                      <div class="font-medium whitespace-nowrap">
                        <span class="capitalize">
                          {{ item.type }}
                        </span>
                      </div>
                      <div class="text-gray-600 text-xs whitespace-nowrap">
                        <span
                          v-if="item.productDetails"
                          class="capitalize text-sm"
                          >{{ item.productDetails.name }} <br
                        /></span>
                        <span v-if="item.productDetails" class="capitalize"
                          >Size: {{ item.productDetails.size }}</span
                        >
                        <span v-if="item.runnerDetails" class="capitalize">
                          <span
                            >Runner: {{ item.runnerDetails.firstName }}
                            {{ item.runnerDetails.lastName }}</span
                          ><br />
                          <span class="capitalize"
                            >Type: {{ item.runnerDetails.runType }}</span
                          >
                          -
                          <span class="capitalize"
                            >Shirt: {{ item.runnerDetails.shirtSize }}</span
                          ></span
                        >
                      </div>
                    </div>
                    <div v-if="item.runnerDetails">
                      <div v-if="item.runnerDetails.runType == 'virtual'">
                        <div class="font-medium whitespace-nowrap">
                          <span class="capitalize">
                            Shipping
                          </span>
                        </div>
                        <div class="text-gray-600 text-xs whitespace-nowrap">
                          {{ item.runnerDetails.shipping.address }}<br />
                          {{ item.runnerDetails.shipping.city }},
                          {{ item.runnerDetails.shipping.state }}
                          {{ item.runnerDetails.shipping.zipcode }}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-right border-b dark:border-dark-5 w-32">
                  <span v-if="item.productDetails">{{
                    item.productDetails.unitCount
                  }}</span>
                </td>
                <td class="text-right border-b dark:border-dark-5 w-32">
                  <span v-if="item.productDetails"
                    >${{ item.productDetails.pricePerUnit }}</span
                  >
                  <span v-if="item.runnerDetails"
                    >${{ item.runnerDetails.registrationCost }}</span
                  >
                </td>
                <td
                  class="text-right border-b dark:border-dark-5 w-32 font-medium"
                >
                  ${{ item.amount }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="px-5 sm:px-20 pb-10 sm:pb-20 flex flex-col-reverse sm:flex-row"
      >
        <div class="text-center sm:text-left mt-10 sm:mt-0"></div>
        <div class="text-center sm:text-right sm:ml-auto">
          <div class="text-base text-gray-600">Total Amount</div>
          <div class="text-xl text-theme-1 dark:text-theme-10 font-medium mt-2">
            ${{ invoice.totalAmount }}
          </div>
        </div>
      </div>
      <div
        v-if="shippingAddress"
        class="px-5 sm:px-20 pb-10 shipping-label text-center"
      >
        <h4 class="text-theme-1 dark:text-theme-10 font-semibold text-3xl">
          Shipping Label
        </h4>
        <div class="mt-6 mb-6"></div>
        <div
          class="text-center py-32 ml-auto shipping-spaced border-4 border-dashed"
        >
          <div
            class="text-2xl font-medium shipping-name text-theme-1 dark:text-theme-10"
          >
            {{ invoice.contactFirstName }} {{ invoice.contactLastName }}
          </div>
          <div class="mt-1 text-xl">
            {{ shippingAddress.address }} <br />
            {{ shippingAddress.city }},
            {{ shippingAddress.state }}
            {{ shippingAddress.zip }}
          </div>
        </div>
      </div>
    </div>
    <!-- END: Invoice -->
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getTransaction, updateShipping } from "@/api/transactions.js";
import { useStore } from "@/store";

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    let invoice = reactive({});
    let transactionId = route.params.id;

    const getTransactionData = async () => {
      const theTransaction = await getTransaction(transactionId);
      let createdAt = new Date(theTransaction.createdAt);
      theTransaction.createdAt = createdAt
        .toDateString()
        .split(" ")
        .slice(1)
        .join(" ");
      Object.assign(invoice, theTransaction);
    };

    onMounted(async () => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .removeClass("frontend")
        .addClass("invoice");
      await getTransactionData();
    });

    let shippingAddress = computed(() => {
      if (invoice.lineItems && invoice.lineItems.length > 0) {
        for (var i = 0; i < invoice.lineItems.length; i++) {
          if (invoice.lineItems[i].shippingAddress) {
            return invoice.lineItems[i].shippingAddress;
            // eslint-disable-next-line no-unreachable
            break;
          }
        }
      }
      return null;
    });

    const sendNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "Invoice has been updated."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem updating the transaction."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const updateTransShipping = async () => {
      let shippedData = {
        hasShipped: invoice.hasShipped
      };
      let trans = route.params.id;
      const updateShippingResponse = await updateShipping(trans, shippedData);
      await sendNotification(updateShippingResponse);
    };

    return {
      invoice,
      router,
      shippingAddress,
      updateTransShipping
    };
  }
});
</script>
<style lang="scss">
@page {
  size: 8.5in 11in;
  margin: 2cm;
}
@media only print {
  body.invoice .side-nav,
  body.invoice .top-bar,
  body.invoice .print-bar,
  body.invoice .mobile-menu {
    visibility: hidden;
    display: none;
  }

  body.invoice {
    margin-top: -25px;
    font-size: 80% !important;
    background-color: #fff;

    div {
      padding: 0;
      font-size: 12px;
      margin: 0;

      .shipping-spaced {
        @apply py-32;
      }

      .shipping-label {
        @apply mt-32;
        page-break-before: always;
      }

      .shipping-label h4 {
        display: none;
        visibility: hidden;
      }

      .shipping-label div {
        font-size: 21px;

        .shipping-name {
          font-size: 32px;
        }
      }
    }
  }
}
</style>
