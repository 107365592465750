<template>
  <div class="pt-6">
    <div class="cart px-5 sm:px-20 pt-1">
      <div class="contact-summary md:grid md:grid-cols-12">
        <div class="md:col-span-6">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Primary Contact
          </h3>
          <p class="mt-1 text-sm text-gray-600">
            {{ person.firstName }} {{ person.lastName }} <br />
            {{ person.email }} <br />
            {{ person.phone }} <br />
            <span v-if="person.address">
              {{ person.address }}<br />
              {{ person.city }}, {{ person.state }} {{ person.zip }}
            </span>
          </p>
        </div>
        <div
          v-if="details.billingContact.name_on_card"
          class="mt-6 md:mt-0 md:col-span-6"
        >
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Billing Contact
          </h3>
          <p class="mt-1 text-sm text-gray-600">
            {{ details.billingContact.name_on_card }}<br />
            {{ details.billingContact.billing_address }}<br />
            {{ details.billingContact.billing_city }},
            {{ details.billingContact.billing_state }}
            {{ details.billingContact.billing_zip }}
          </p>
        </div>
      </div>
      <div class="contact-summary mt-12">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          Supporting
        </h3>
        <div v-if="supporting.type == 'Team'">
          <p class="font-bold mt-2">Team:</p>
          <p class="font-bold text-base">{{ supporting.teamDetails.name }}</p>
          <p>{{ supporting.teamDetails.city }}</p>
        </div>
        <div v-if="supporting.type == 'Event'">
          <p class="font-bold mt-2">Event:</p>
          <p class="font-bold text-base">Piecewalk</p>
          <p>{{ supporting.eventDetails.city }}</p>
        </div>
      </div>
      <div class="contact-summary mt-12">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          Items
        </h3>

        <div v-for="(item, index) in lineItems" :key="index">
          <LineItem :item="item" />
        </div>
      </div>
      <div v-show="details.feesCovered > 0" class="contact-summary mt-12">
        <div>
          <div class="pt-6 border-t mt-6">
            <div class="md:grid md:grid-cols-12 md:gap-6 capitalize">
              <div class="type md:col-span-8 font-bold">
                Credit Card Fees Covered
              </div>
              <div class="details md:col-span-2"></div>
              <div class="amount md:col-span-2 md:text-right">
                ${{ details.feesCovered }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="details.shippingFee && details.shippingFee > 0"
        class="contact-summary mt-12"
      >
        <div>
          <div class="pt-6 border-t mt-6">
            <div class="md:grid md:grid-cols-12 md:gap-6 capitalize">
              <div class="type md:col-span-8 font-bold">
                Shipping Fee
              </div>
              <div class="details md:col-span-2"></div>
              <div class="amount md:col-span-2 md:text-right">
                ${{ details.shippingFee }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="total-summary mt-12 border-t border-gray-200 border-solid">
        <div
          class="intro-y col-span-12 sm:flex items-center justify-between mt-6"
        >
          <div class="trans-id text-center mb-6 sm:mb-0 sm:text-right">
            <p>
              TransactionId: <span class="font-bold">{{ transId }}</span>
            </p>
          </div>
          <div class="total text-center mb-6 sm:mb-0 sm:text-right">
            <div>Total</div>
            <h4 class="text-xl font-bold">${{ total }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import LineItem from "@/components/front-end/LineItem";
export default defineComponent({
  components: {
    LineItem
  },
  props: {
    transaction: {
      type: Object,
      default: function() {
        return {};
      }
    },
    supporting: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  setup(props) {
    let transId = ref(props.transaction.id);
    let total = ref(props.transaction.total);
    let person = ref(props.transaction.payload.person);
    let lineItems = ref(props.transaction.payload.lineItems);
    let details = ref(props.transaction.payload.transaction);
    return {
      person,
      lineItems,
      details,
      transId,
      total
    };
  }
});
</script>
