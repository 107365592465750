<template>
  <div
    class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
  >
    <div
      class="md:flex flex-1 px-5 items-center justify-center lg:justify-start"
    >
      <div class="buttons mt-6 md:flex justify-between">
        <router-link
          class="btn btn-outline-primary w-full mb-2 flex-grow md:w-40"
          :to="
            `/frontend/donate/?team=${team.id}&city=${team.eventCity}&name=${team.name}`
          "
          target="_blank"
          >Donate</router-link
        >
        <router-link
          v-if="eventSettings.has5k"
          class="btn w-full mb-2 btn-outline-primary flex-grow md:ml-6 md:w-40"
          to="/frontend/run"
          target="_blank"
          >Run 5k</router-link
        >
        <router-link
          class="btn w-full mb-2 btn-outline-primary flex-grow md:ml-6 md:w-40"
          :to="{ name: 'email-team', params: { teamId: team.id } }"
        >
          Email My team
        </router-link>
        <span v-if="eventSettings && eventSettings.captainTools">
          <a
            v-for="(action, index) in eventSettings.captainTools"
            :key="index"
            :href="action.link"
            class="btn w-full mb-2 btn-outline-primary flex-grow md:ml-6 md:w-40"
            target="_blank"
          >
            {{ action.text }}
          </a>
        </span>
        <a
          :href="eventSettings.publicEventSite + publicTeamUrl"
          class="btn w-full mb-2 btn-outline-primary flex-grow md:ml-6 md:w-40"
          target="_blank"
        >
          <ExternalLinkIcon class="w-4 h-4 mr-2" /> View Team
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    team: {
      type: Object,
      default: () => {
        return {};
      },
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const eventSettings = computed(() => store.getters["main/eventSettings"]);
    const isAdmin = ref(false);

    onMounted(async () => {
      const isAdminResponse = await store.getters["auth/isAdmin"];
      isAdmin.value = isAdminResponse;
    });

    const publicTeamUrl = computed(() => {
      return "/team/" + props.team.slug;
    });

    const emailTeam = computed(() => {
      let emailList = [];
      let subject = "";

      if (props.team.members && props.team.name) {
        subject = `Piecewalk - ${props.team.name}!`;
        props.team.members.map(member => {
          let email = member.email;
          emailList.push(email);
        });
      }

      let emailListForSend = emailList.join(",");
      return `mailto:${emailListForSend}?subject=${subject}`;
    });

    return {
      eventSettings,
      emailTeam,
      publicTeamUrl,
      isAdmin
    };
  }
});
</script>
