<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Create a Team</h2>
    </div>
    <!-- BEGIN: Create TeamLayout -->
    <form class="validate-form" @submit.prevent="save">
      <div class="intro-y box mt-5 pb-10">
        <div
          class="px-5 sm:px-20 pt-10 border-t border-gray-200 dark:border-dark-5"
        >
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0 sm:pr-8">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Captain Profile
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Please tell us a little about yourself. This information will
                  be used for your captain user account.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <div class="grid grid-cols-12 gap-4 gap-y-5">
                <div class="intro-y col-span-12 sm:col-span-6">
                  <label for="first_name" class="form-label">First Name</label>
                  <input
                    id="first_name"
                    v-model.trim="validate.first_name.$model"
                    type="text"
                    class="form-control"
                    :class="{ 'border-theme-6': validate.first_name.$error }"
                    placeholder="First Name"
                  />
                  <div
                    v-for="error of validate.first_name.$errors"
                    :key="error.$uid"
                    class="input-errors"
                  >
                    <div class="error-msg text-theme-6 mt-2">
                      {{ error.$message }}
                    </div>
                  </div>
                </div>
                <div class="intro-y col-span-12 sm:col-span-6">
                  <label for="last_name" class="form-label">Last Name</label>
                  <input
                    id="last_name"
                    v-model.trim="validate.last_name.$model"
                    type="text"
                    class="form-control"
                    :class="{ 'border-theme-6': validate.last_name.$error }"
                    placeholder="Last Name"
                  />
                  <div
                    v-for="error of validate.last_name.$errors"
                    :key="error.$uid"
                    class="input-errors"
                  >
                    <div class="error-msg text-theme-6 mt-2">
                      {{ error.$message }}
                    </div>
                  </div>
                </div>
                <div class="intro-y col-span-12 sm:col-span-6">
                  <label for="email" class="form-label">Email</label>
                  <input
                    id="email"
                    v-model.trim="validate.email.$model"
                    type="email"
                    class="form-control"
                    :class="{ 'border-theme-6': validate.email.$error }"
                    placeholder="email@sample.com"
                  />
                  <div
                    v-for="error of validate.email.$errors"
                    :key="error.$uid"
                    class="input-errors"
                  >
                    <div class="error-msg text-theme-6 mt-2">
                      {{ error.$message }}
                    </div>
                  </div>
                </div>
                <div class="intro-y col-span-12 sm:col-span-6">
                  <label for="input-wizard-5" class="form-label">Phone</label>
                  <input
                    id="phone"
                    v-model.trim="validate.phone.$model"
                    v-maska="'(###) ###-####'"
                    type="text"
                    class="form-control"
                    placeholder="(333) 333-3333"
                  />
                  <div
                    v-for="error of validate.phone.$errors"
                    :key="error.$uid"
                    class="input-errors"
                  >
                    <div class="error-msg text-theme-6 mt-2">
                      {{ error.$message }}
                    </div>
                  </div>
                </div>
                <div class="intro-y col-span-12 sm:col-span-6">
                  <label for="password" class="form-label">Password</label>
                  <input
                    id="password"
                    v-model.trim="validate.password.password.$model"
                    type="password"
                    class="form-control border-theme-9"
                    :class="{
                      'border-theme-6': validate.password.password.$error
                    }"
                    placeholder="Password"
                  />
                  <div
                    v-for="error of validate.password.password.$errors"
                    :key="error.$uid"
                    class="input-errors"
                  >
                    <div class="error-msg text-theme-6 mt-2">
                      {{ error.$message }}
                    </div>
                  </div>
                  <div class="w-full grid grid-cols-12 gap-4 h-1 mt-3">
                    <div class="col-span-3 h-full rounded bg-theme-9"></div>
                    <div class="col-span-3 h-full rounded bg-theme-9"></div>
                    <div class="col-span-3 h-full rounded bg-theme-9"></div>
                    <div
                      class="col-span-3 h-full rounded bg-gray-200 dark:bg-dark-1"
                    ></div>
                  </div>
                  <div class="text-theme-9 mt-2">Strong password</div>
                </div>
                <div class="intro-y col-span-12 sm:col-span-6">
                  <label for="passwordConfirm" class="form-label">
                    Confirm Password
                  </label>
                  <input
                    id="passwordConfirm"
                    v-model.trim="validate.password.confirm.$model"
                    type="password"
                    class="form-control border-theme-9"
                    placeholder="Confirm Password"
                  />
                  <div
                    v-for="error of validate.password.confirm.$errors"
                    :key="error.$uid"
                    class="input-errors"
                  >
                    <div class="error-msg text-theme-6 mt-2">
                      {{ error.$message }}
                    </div>
                  </div>
                  <div class="w-full grid grid-cols-12 gap-4 h-1 mt-3">
                    <div class="col-span-3 h-full rounded bg-theme-9"></div>
                    <div class="col-span-3 h-full rounded bg-theme-9"></div>
                    <div class="col-span-3 h-full rounded bg-theme-9"></div>
                    <div
                      class="col-span-3 h-full rounded bg-gray-200 dark:bg-dark-1"
                    ></div>
                  </div>
                  <div class="text-theme-9 mt-2">Strong password</div>
                </div>
              </div>
            </div>
          </div>
          <div class="hidden sm:block" aria-hidden="true">
            <div class="py-10">
              <div class="border-t border-gray-200" />
            </div>
          </div>
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0 sm:pr-8">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Team Info
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Tell us your team name and a little bit about your team.
                </p>
                <p class="mt-4 text-sm text-gray-600 flex italic">
                  <InfoIcon class="w-6 mr-2" /> You can set your goals and add a
                  team photo after your team is created.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <div class="grid grid-cols-12 gap-4 gap-y-5">
                <div class="intro-y col-span-12 sm:col-span-6">
                  <label for="team_name" class="form-label">Team Name</label>
                  <input
                    id="teamName"
                    v-model.trim="validate.team_name.$model"
                    type="text"
                    class="form-control"
                    :class="{ 'border-theme-6': validate.team_name.$error }"
                    placeholder="Team Name"
                  />
                  <div
                    v-for="error of validate.team_name.$errors"
                    :key="error.$uid"
                    class="input-errors"
                  >
                    <div class="error-msg text-theme-6 mt-2">
                      {{ error.$message }}
                    </div>
                  </div>
                </div>
                <div class="intro-y col-span-12 sm:col-span-6">
                  <label for="team_city" class="form-label">Team City</label>
                  <select
                    id="team_city"
                    v-model.trim="validate.team_city.$model"
                    class="form-select sm:mr-2"
                    aria-label="Team City"
                  >
                    <option>Oklahoma City</option>
                    <option>Tulsa</option>
                  </select>
                </div>
                <div class="intro-y col-span-12 sm:col-span-12">
                  <label for="team_message" class="form-label">
                    Team Message
                  </label>
                  <textarea
                    id="team_message"
                    v-model.trim="validate.team_message.$model"
                    class="form-control"
                    :class="{ 'border-theme-6': validate.team_message.$error }"
                    placeholder="Team Message"
                    rows="10"
                  />
                  <div
                    v-for="error of validate.team_message.$errors"
                    :key="error.$uid"
                    class="input-errors"
                  >
                    <div class="error-msg text-theme-6 mt-2">
                      {{ error.$message }}
                    </div>
                  </div>
                  <p class="text-sm italic text-gray-600">
                    Tell us about your team and why you are creating one!
                  </p>
                </div>
                <!--<div class="intro-y col-span-12 mt-5">
                  <input
                    id="terms"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    checked
                  />
                  <label class="form-check-label" for="terms"
                    >I agree to the Terms & Conditions</label
                  >
                </div>-->
              </div>
            </div>
          </div>
          <div
            class="intro-y col-span-12 flex items-center justify-between mt-10 pt-10"
          >
            <router-link
              to="/teams"
              class="btn btn-secondary justify-self-start"
              >Cancel</router-link
            >
            <button type="submit" class="btn btn-primary ml-2 btn-lg">
              Create My Team!
            </button>
          </div>
        </div>
      </div>
    </form>
    <!-- END: Create Team Layout -->
    <!-- BEGIN: Success Notification Content -->
    <div id="success-notification-content" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Create a Team success!</div>
        <div class="text-gray-600 mt-1">
          Please check your e-mail for further info!
        </div>
      </div>
    </div>
    <!-- END: Success Notification Content -->
    <!-- BEGIN: Failed Notification Content -->
    <div id="failed-notification-content" class="toastify-content hidden flex">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">We failed to create your team!</div>
        <div class="text-gray-600 mt-1">
          Please check the form for details.
        </div>
      </div>
    </div>
    <!-- END: Failed Notification Content -->
  </div>
</template>

<script>
import { defineComponent, reactive, computed, toRefs } from "vue";
import {
  required,
  minLength,
  sameAs,
  //maxLength,
  email
  //integer
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import { createUser } from "@/api/users.js";
import { createTeam } from "@/api/teams.js";

export default defineComponent({
  setup() {
    const createTeamData = reactive({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: {
        password: "",
        confirm: ""
      },
      team_name: "",
      team_message: "",
      team_city: ""
    });
    const rules = computed(() => {
      return {
        first_name: {
          required,
          minLength: minLength(2)
        },
        last_name: {
          required,
          minLength: minLength(2)
        },
        email: {
          required,
          email
        },
        password: {
          password: { required, minLength: minLength(6) },
          confirm: {
            required,
            sameAs: sameAs(createTeamData.password.password)
          }
        },
        phone: {
          required
        },
        team_name: {
          required,
          minLength: minLength(2)
        },
        team_city: {
          required,
          minLength: minLength(2)
        },
        team_message: {
          required
        }
      };
    });
    const validate = useVuelidate(rules, toRefs(createTeamData));
    //just made this save async
    const save = async () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        Toastify({
          node: cash("#failed-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      } else {
        const user = await createUser({
          firstName: createTeamData.first_name,
          lastName: createTeamData.last_name,
          email: createTeamData.email,
          password: createTeamData.password.password,
          phone: createTeamData.phone,
          role: `captain`
        });

        console.log("returned user is", user);

        const team = {
          name: createTeamData.team_name,
          teamMessage: createTeamData.team_message,
          active: true,
          eventCity: createTeamData.team_city,
          teamDonationGoal: 0,
          teamMemberGoal: 0,
          totalRaised: 0,
          captains: [user.id]
        };

        console.log("team is", team);

        await createTeam(team);

        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      }
    };

    return {
      validate,
      createTeamData,
      save
    };
  }
});
</script>
