<template>
  <div>
    <FrontEndHeader fill="#be2032" />
    <!-- BEGIN: Create Checkout Layout -->
    <form class="validate-form" @submit.prevent="handleSubmit">
      <div class="intro-y box mt-5 pb-10 max-w-3xl mx-auto">
        <div
          class="font-bold text-3xl px-5 sm:px-20 py-10 pb-0 sm:py-10 sm:pb-0 rounded-t"
        >
          {{ page.title }}
        </div>
        <div class="px-5 sm:px-20 py-5 pb-0 sm:py-10 sm:pb-0">
          <div class="px-0 sm:pr-8">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Summary
            </h3>
            <p class="mt-1 text-sm text-gray-600">
              Please review your order before proceeding.
            </p>
          </div>
        </div>
        <div class="px-5 sm:px-20 py-5 pb-0 sm:py-10 sm:pb-0">
          <div class="px-0 sm:pr-8">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Primary Contact
            </h3>
            <p class="mt-1 text-sm text-gray-600">
              {{ contact.first_name }} {{ contact.last_name }} <br />
              {{ contact.email }} <br />
              {{ contact.phone }} <br />
              <span v-if="contact.address">
                {{ contact.address }}<br />
                {{ contact.city }}, {{ contact.state }} {{ contact.zip }}
              </span>
            </p>
          </div>
        </div>
        <Cart hide-contact show-team-members />
        <div class="px-5 sm:px-20 mt-6">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="mt-5 md:mt-0 md:col-span-12">
              <div class="mb-12">
                <Marketing
                  v-model:marketing="marketing"
                  :marketing="marketing"
                />
              </div>
              <div v-if="requiresShipping" class="mb-12">
                <h3 class="text-lg font-medium leading-6 text-gray-900 mb-6">
                  Shipping Address for Merchandise
                  <span class="block text-gray-600 text-sm"
                    >There is a $8 shipping fee.</span
                  >
                </h3>
                <div
                  v-if="captain.person && !matchCaptain"
                  class="intro-y col-span-12 sm:col-span-12 form-check flex-wrap mt-2 mb-2"
                >
                  <MatchShipping
                    v-model="matchCaptain"
                    :is-matched="matchCaptain"
                    text="Match Shipping Address from Profile"
                    @change="matchCaptainShipping()"
                  />
                  <p class="w-full mt-1">
                    You are currently logged in as
                    <span class="font-bold"
                      >{{ captain.person.firstName }}
                      {{ captain.person.lastName }}</span
                    >
                  </p>
                </div>
                <ShippingAddress
                  v-model:address="createShippingAddress.address"
                  v-model:city="createShippingAddress.city"
                  v-model:state="createShippingAddress.state"
                  v-model:zipCode="createShippingAddress.zipcode"
                  :address-value="createShippingAddress.address"
                  :city-value="createShippingAddress.city"
                  :state-value="createShippingAddress.state"
                  :zipcode-value="createShippingAddress.zipcode"
                />
              </div>
              <h3 class="text-lg font-medium leading-6 text-gray-900 mb-6">
                Billing Details
              </h3>
              <div
                v-if="isAdmin"
                class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 mb-6 sm:mt-0"
              >
                <input
                  id="offline"
                  v-model.trim="offline"
                  class="form-check-switch mr-0 ml-0"
                  type="checkbox"
                  :checked="offline"
                />
                <label class="form-check-label font-medium" for="offline"
                  >Make Transaction Offline</label
                >
              </div>
              <div
                v-show="createShippingAddress.zipcode"
                class="intro-y col-span-12 sm:col-span-6 form-check mt-2 mb-2"
              >
                <MatchShipping
                  v-model="matched"
                  :is-matched="matched"
                  text="Match Shipping Address"
                  @change="matchShipping(createShippingAddress)"
                />
              </div>
              <BillingInfo
                v-if="!offline"
                v-model:nameOnCard="createBillingData.name_on_card"
                v-model:billingAddress="createBillingData.billing_address"
                v-model:billingCity="createBillingData.billing_city"
                v-model:billingState="createBillingData.billing_state"
                v-model:billingZip="createBillingData.billing_zip"
                :name-on-card="createBillingData.name_on_card"
                :billing-address="createBillingData.billing_address"
                :billing-city="createBillingData.billing_city"
                :billing-state="createBillingData.billing_state"
                :billing-zip="createBillingData.billing_zip"
              />
              <div
                v-show="!offline"
                id="stripe-element-mount-point"
                class="cc-input mt-6 mb-6 form-control p-3 py-4"
              />
              <div
                v-show="!offline"
                class="intro-y col-span-12 sm:col-span-12 form-check mt-2 mb-12"
              >
                <input
                  :id="coverCC"
                  v-model="coverCC"
                  class="form-check-input"
                  type="checkbox"
                />
                <label class="form-check-label text-base" :for="coverCC"
                  >I want to cover the credit card fees
                  <a
                    href="javascript:;"
                    name="cc-fees"
                    class="tooltip ml-1 relative -top-1 bg-gray-700 text-white px-1 rounded-full text-xs font-bold inline-block"
                    >?</a
                  ></label
                >
                <div class="tooltip-content">
                  <TippyContent to="cc-fees">
                    <div class="font-medium dark:text-gray-300">
                      3.5%
                    </div>
                    <div class="flex items-center mt-2 sm:mt-0">
                      <p>
                        By covering the credit card fees, 100% of your donation
                        goes to supporting our mission.
                      </p>
                    </div>
                  </TippyContent>
                </div>
              </div>
              <div v-show="cardErrorMessage" class="card-errors">
                <p class="text-red">Error: {{ cardErrorMessage }}</p>
              </div>
              <div
                class="intro-y col-span-12 sm:flex items-center justify-between mt-6"
              >
                <div class="total text-center mb-6 sm:mb-0 sm:text-left">
                  <div>Total</div>
                  <h4 class="text-xl">${{ checkoutTotal }}</h4>
                </div>
                <button
                  type="submit"
                  :class="{ dis: loading }"
                  class="btn block mt-4 w-full intro-y py-3 sm:mt-0 sm:inline-block sm:w-auto btn-red border-reddark sm:px-10 btn-lg"
                  :disabled="validate.$errors.length > 0 || loading"
                >
                  {{ loading ? "Processing..." : "Submit Payment" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <CancelButton />
    <!-- END: Checkout Layout -->
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { useRouter, useRoute } from "vue-router";
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  toRefs
} from "vue";
import { useStore } from "@/store";
import { useVuelidate } from "@vuelidate/core";
import { submitPayment } from "@/api/payment.js";
import { createTransaction } from "@/api/transactions.js";
import { createPerson } from "@/api/participants.js";
import FrontEndHeader from "@/components/front-end/FrontEndHeader";
import Cart from "@/components/front-end/Cart";

import ShippingAddress from "@/components/form-inputs/Address";
import BillingInfo from "@/components/form-inputs/BillingInfo";
import CancelButton from "@/components/front-end/CancelButton";
import Marketing from "@/components/form-inputs/Marketing.vue";
import MatchShipping from "@/components/form-inputs/MatchParticipantContact";

export default defineComponent({
  components: {
    FrontEndHeader,
    Cart,
    ShippingAddress,
    BillingInfo,
    CancelButton,
    Marketing,
    MatchShipping
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    let matched = ref(false);
    let matchCaptain = ref(false);
    let isAdmin = ref(false);
    let offline = ref(false);
    let coverCC = ref(true);
    let shipping = 8;
    const store = useStore();
    const appSettings = computed(() => store.getters["main/eventSettings"]);
    const captain = store.state.auth.user;
    const shirts = store.state.shirt.shirts;
    const donation = store.state.donate.donation;
    const contact = store.state.contact.contact;
    const supporting = store.state.support.supporting;
    const runData = store.state.run.runData;
    const teamMembers = store.state.teamMembers.teamMembers;
    const newTransaction = store.state.transaction.transaction;

    const requiresShipping = ref(false);
    const marketing = ref(true);
    let shippingAddress = {
      state: "OK"
    };

    const page = {
      title: "Checkout"
    };

    onMounted(async () => {
      isAdmin.value = await store.getters["auth/isAdmin"];
      if (contact.first_name == "") {
        // If there is no contact get out of there
        router.push({ path: "/frontend/contact" });
      }
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("frontend checkout");
    });

    const billingData = {
      name_on_card: "",
      billing_address: "",
      billing_city: "",
      billing_state: "OK",
      billing_zip: "",
      email: contact.email,
      phone: contact.phone
    };

    let anonymous = false;

    if (contact.anonymous) {
      anonymous = true;
    }

    let transaction = {
      person: {
        email: contact.email,
        firstName: contact.first_name,
        lastName: contact.last_name,
        phone: contact.phone,
        eventYear: appSettings.value.appYear,
        event: "piecewalk",
        anonymous: anonymous,
        marketing: marketing
      },
      transaction: {
        eventYear: appSettings.value.appYear,
        supportingType: supporting.type.toLowerCase(),
        billingContact: billingData
      },
      lineItems: []
    };

    if (supporting.type.toLowerCase() === "team") {
      transaction.transaction.team = supporting.teamDetails.id;
    }

    if (supporting.type.toLowerCase() === "event") {
      transaction.transaction.eventCity = supporting.eventDetails.city;
    }

    if (shirts.length > 0) {
      //Loop over all shirts and create lineItem
      requiresShipping.value = true;
      console.log("has-shirts");
      shirts.map(shirt => {
        let shirtTotal = shirt.quantity * shirt.price;

        let shirtLineItem = {
          type: "tshirt",
          primaryContact: contact.first_name + " " + contact.last_name,
          eventYear: appSettings.value.appYear,
          amount: shirtTotal,
          shippingAddress: shippingAddress,
          productDetails: {
            type: "tshirt",
            name: shirt.name,
            size: shirt.size,
            pricePerUnit: shirt.price,
            unitCount: shirt.quantity
          }
        };

        transaction.lineItems.push(shirtLineItem);
      });
    }

    if (runData.runners.length > 0) {
      //Loop over all runners and create lineItem
      transaction.person.event = "5k"; //Set the person as a 5k event type
      runData.runners.map(runner => {
        let runnerType = "in-person";
        if (runner.virtual) {
          runnerType = "virtual";
        }
        let runnerLineItem = {
          type: "5k",
          primaryContact: contact.first_name + " " + contact.last_name,
          eventYear: appSettings.value.appYear,
          amount: runner.price,
          runnerDetails: {
            firstName: runner.first_name,
            lastName: runner.last_name,
            email: runner.email,
            runType: runnerType,
            registrationCost: runner.price,
            phone: runner.phone,
            shirtSize: runner.size,
            gender: runner.gender,
            birthDate: runner.birthDate
          }
        };

        //Check for LifeChurch
        if (runner.lifeChurch) {
          console.log("Test");
          runnerLineItem.runnerDetails.lifeChurch = true;
          if (runner.lifeChurchCampus) {
            runnerLineItem.runnerDetails.lifeChurchLocation =
              runner.lifeChurchCampus.name;
          }
        }

        let shipping = {};
        if (runner.address && runner.virtual) {
          shipping = {
            address: runner.address,
            city: runner.city,
            state: runner.state,
            zipcode: runner.zipcode
          };
          runnerLineItem.runnerDetails.shipping = shipping;
        }

        transaction.lineItems.push(runnerLineItem);
      });
    }

    if (donation.donation_amount > 0) {
      transaction.person.event = "donor"; //Set the person as a donor event type - overrides 5k
      let donationLineItem = {
        type: "donation",
        primaryContact: contact.first_name + " " + contact.last_name,
        amount: donation.donation_amount,
        eventYear: appSettings.value.appYear,
        anonymous: anonymous
      };
      if (contact.useEmployerAsName && contact.employer) {
        donationLineItem.myEmployer = contact.employer;
        donationLineItem.useEmployerAsName = true;
      }
      transaction.lineItems.push(donationLineItem);
    }

    //Check for LifeChurch
    if (contact.lifeChurch) {
      transaction.person.lifeChurch = true;
      if (contact.lifeChurchCampus) {
        transaction.person.lifeChurchLocation = contact.lifeChurchCampus.name;
      }
    }

    //Check for Employer
    if (contact.employer) {
      transaction.person.myEmployer = contact.employer;
      if (contact.useEmployerAsName) {
        transaction.person.useEmployerAsName = true;
      }
    }

    const createBillingData = reactive(billingData);
    const createShippingAddress = reactive(shippingAddress);

    const theTransaction = reactive(transaction);

    const rules = computed(() => {
      return {};
    });

    const matchCaptainShipping = () => {
      if (captain.person && captain.person.address) {
        const address = captain.person.address;
        createShippingAddress.address = address.address;
        createShippingAddress.city = address.city;
        createShippingAddress.state = address.state;
        createShippingAddress.zipcode = address.zipcode;
      } else {
        createShippingAddress.address = "";
        createShippingAddress.city = "";
        createShippingAddress.state = "";
        createShippingAddress.zipcode = "";
      }
    };

    const matchShipping = shipping => {
      if (matched.value) {
        createBillingData.billing_address = shipping.address;
        createBillingData.billing_city = shipping.city;
        createBillingData.billing_state = shipping.state;
        createBillingData.billing_zip = shipping.zipcode;
      } else {
        createBillingData.billing_address = "";
        createBillingData.billing_city = "";
        createBillingData.billing_state = "";
        createBillingData.billing_zip = "";
      }
    };

    const hasTeam = computed(() => {
      if (
        supporting.type === "Team" &&
        supporting.teamDetails.team_name !== "" &&
        route.query.join
      ) {
        return true;
      }

      return false;
    });

    const validate = useVuelidate(rules, toRefs(createBillingData));

    const checkoutTotal = computed(() => {
      let checkoutSum = 0;
      let shirtSum = 0;
      let runnerSum = 0;
      if (shirts.length > 0) {
        let total = shirts.map(shirt => shirt.quantity * shirt.price);
        shirtSum = total.reduce((a, b) => a + b, 0) + shipping;
      }
      if (runData.runners.length > 0) {
        let totalRunners = runData.runners.map(runner => runner.price);
        runnerSum = totalRunners.reduce((a, b) => a + b, 0);
      }
      checkoutSum = donation.donation_amount + shirtSum + runnerSum;

      if (coverCC.value && !offline.value) {
        checkoutSum = checkoutSum + checkoutSum * 0.035;
        checkoutSum = checkoutSum.toFixed(2);
      }
      return checkoutSum;
    });

    const feesCovered = computed(() => {
      // Duplicate code that can be better taken care of with another function
      if (coverCC.value && !offline.value) {
        let checkoutSum = 0;
        let shirtSum = 0;
        let runnerSum = 0;
        if (shirts.length > 0) {
          let total = shirts.map(shirt => shirt.quantity * shirt.price);
          shirtSum = total.reduce((a, b) => a + b, 0) + shipping;
        }
        if (runData.runners.length > 0) {
          let totalRunners = runData.runners.map(runner => runner.price);
          runnerSum = totalRunners.reduce((a, b) => a + b, 0);
        }
        checkoutSum = donation.donation_amount + shirtSum + runnerSum;

        let feesSum = checkoutSum * 0.035;
        feesSum = feesSum.toFixed(2);
        return feesSum;
      }
      return 0;
    });

    const checkAndAddTeamMembers = async () => {
      if (
        teamMembers.quantity > 0 &&
        teamMembers.members.length > 0 &&
        supporting.type == "Team"
      ) {
        let newTeamMembers = [];
        teamMembers.members.forEach(person => {
          let newPerson = {
            email: person.email,
            firstName: person.first_name,
            lastName: person.last_name,
            phone: person.phone,
            lifeChurch: person.lifeChurch,
            team: supporting.teamDetails.id,
            eventYear: appSettings.value.appYear
          };

          if (person.lifeChurchCampus && person.lifeChurch) {
            newPerson.lifeChurchLocation = person.lifeChurchCampus.name;
          }

          newTeamMembers.push(newPerson);
        });

        let returnedTeamMembers = [];

        await Promise.all(
          newTeamMembers.map(async person => {
            await createPerson(person).then(returnedPerson => {
              returnedTeamMembers.push(returnedPerson);
            });
          })
        );

        store.dispatch("teamMembers/setAddedTeamMembers", newTeamMembers);
        return returnedTeamMembers;
      }

      return null;
    };

    const handleSubmit = async () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        console.log("invalid");
      } else {
        //Process Data and direct to thank you
        if (loading.value) return;
        loading.value = true;

        let savedTransaction = {
          id: "Offline",
          payload: theTransaction,
          total: checkoutTotal.value
        };

        //If the transaction is offline
        if (offline.value) {
          theTransaction.transaction.totalCharged = checkoutTotal;
          theTransaction.transaction.feesCovered = 0;
          theTransaction.transaction.shippingFee = 0;
          if (requiresShipping.value) {
            theTransaction.transaction.shippingFee = 8;
          }
          //Need to check responses here
          await createTransaction(theTransaction);
          await checkAndAddTeamMembers();
          //Add New Transaction to Store
          store.dispatch("transaction/setTransaction", savedTransaction);
          router.push({ path: "/frontend/thank-you" });
        } else {
          const paymentResponse = await submitPayment(
            createBillingData,
            elements,
            stripe,
            checkoutTotal
          );
          if (paymentResponse.message == "Success") {
            let externalPaymentId = paymentResponse.paymentId;
            let paymentDetails = paymentResponse.paymentDetails;
            theTransaction.transaction.totalCharged = checkoutTotal;
            theTransaction.transaction.feesCovered = 0;
            theTransaction.transaction.shippingFee = 0;
            if (coverCC.value) {
              theTransaction.transaction.feesCovered = feesCovered;
            }
            if (requiresShipping.value) {
              theTransaction.transaction.shippingFee = 8;
            }

            theTransaction.transaction.externalTransactionId = externalPaymentId;
            theTransaction.transaction.paymentDetails = paymentDetails;
            await createTransaction(theTransaction);
            await checkAndAddTeamMembers();
            savedTransaction.id = externalPaymentId;
            store.dispatch("transaction/setTransaction", savedTransaction);
            router.push({ path: "/frontend/thank-you" });
          } else {
            loading.value = false;
            cardErrorMessage.value = paymentResponse.message;
            console.log("error", paymentResponse.message);
          }
        }
      }
    };

    const style = {
      style: {
        base: {
          iconColor: "#000",
          color: "#000",
          fontSize: "16px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": {
            color: "#fce883"
          },
          "::placeholder": {
            color: "green"
          }
        },
        invalid: {
          iconColor: "#FFC7EE",
          color: "red"
        }
      }
    };

    let cardErrorMessage = ref(null);

    let stripe = null;
    let loading = ref(true);
    let elements = null;
    onMounted(async () => {
      const ELEMENT_TYPE = "card";
      stripe = await loadStripe(process.env.VUE_APP_STRIPE_CLIENT);
      elements = stripe.elements();
      const element = elements.create(ELEMENT_TYPE, style);
      element.mount("#stripe-element-mount-point");
      loading.value = false;
      element.on("change", function() {
        resetCardErrors();
      });
    });

    const resetCardErrors = () => {
      cardErrorMessage.value = null;
    };

    return {
      page,
      validate,
      createBillingData,
      createShippingAddress,
      theTransaction,
      contact,
      matched,
      hasTeam,
      checkoutTotal,
      matchShipping,
      handleSubmit,
      loading,
      cardErrorMessage,
      isAdmin,
      offline,
      runData,
      requiresShipping,
      teamMembers,
      newTransaction,
      coverCC,
      marketing,
      matchCaptain,
      matchCaptainShipping,
      captain
    };
  }
});
</script>
