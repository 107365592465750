<template>
  <div>
    <FrontEndHeader fill="#be2032" />
    <!-- BEGIN: Create Summary Layout -->
    <form class="validate-form" @submit.prevent="save">
      <div class="intro-y box mt-5 pb-10 max-w-3xl mx-auto">
        <div
          class="font-bold text-3xl px-5 sm:px-20 py-10 pb-0 sm:py-10 sm:pb-0 rounded-t"
        >
          {{ page.title }}
        </div>
        <Cart show-team-members />
        <div class="px-5 sm:px-20 mt-6">
          <Marketing
            v-if="!checkoutTotal > 0 && page.complete"
            v-model:marketing="marketing"
            class="mb-6"
            :marketing="marketing"
          />
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-3">
              <div class="px-0 sm:pr-8">
                <p class="mt-1 text-sm text-gray-600">
                  There are several ways you can support the Piecewalk.
                </p>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  {{ page.actionSentence }}
                </h3>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-3">
              <div class="grid grid-cols-12 gap-4 gap-y-5">
                <div class="intro-y col-span-12">
                  <SummaryButtons />
                </div>
              </div>
              <div aria-hidden="true">
                <div class="pt-10">
                  <div class="border-t border-gray-200" />
                </div>
              </div>
              <div
                v-if="checkoutTotal > 0 && page.complete"
                class="intro-y col-span-12 sm:flex items-center justify-between mt-6"
              >
                <div class="total text-center mb-6 sm:mb-0 sm:text-left">
                  <div>Total</div>
                  <h4 class="text-xl">${{ checkoutTotal }}</h4>
                </div>
                <button
                  type="button"
                  class="btn block w-full intro-y py-3 sm:inline-block sm:w-auto btn-red border-reddark sm:ml-2 sm:px-10 btn-lg"
                  @click="toCheckout()"
                >
                  Proceed to Checkout
                </button>
              </div>
              <div
                v-if="error.active"
                class="errors mt-6 text-red font-bold text-center"
              >
                {{ error.message }}
              </div>
              <div
                v-if="!checkoutTotal > 0 && page.complete"
                class="intro-y col-span-12 sm:flex items-center justify-center mt-6"
              >
                <button
                  type="button"
                  class="btn block w-full intro-y py-3 sm:inline-block sm:w-auto btn-red border-reddark sm:ml-2 sm:px-10 btn-lg"
                  @click="joinTeam()"
                >
                  Join Team
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- END: Summary Layout -->
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import FrontEndHeader from "@/components/front-end/FrontEndHeader";
import Cart from "@/components/front-end/Cart";
import SummaryButtons from "@/components/front-end/SummaryButtonOptions";
import { createPerson } from "@/api/participants.js";
import Marketing from "@/components/form-inputs/Marketing";

export default defineComponent({
  components: {
    FrontEndHeader,
    Cart,
    SummaryButtons,
    Marketing
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const appSettings = store.state.main;
    const donation = store.state.donate.donation;
    const shirts = store.state.shirt.shirts;
    const runData = store.state.run.runData;
    const supporting = store.state.support.supporting;
    const contact = store.state.contact.contact;
    const teamMembers = store.state.teamMembers.teamMembers;
    let shipping = 8;

    const marketing = ref(true);

    const error = reactive({
      active: false,
      message: ""
    });

    const checkoutTotal = computed(() => {
      let checkoutSum = 0;
      let shirtSum = 0;
      let runnerSum = 0;
      if (shirts.length > 0) {
        let total = shirts.map(shirt => shirt.quantity * shirt.price);
        shirtSum = total.reduce((a, b) => a + b, 0) + shipping;
      }
      if (runData.runners.length > 0) {
        let totalRunners = runData.runners.map(runner => runner.price);
        runnerSum = totalRunners.reduce((a, b) => a + b, 0);
      }
      checkoutSum = donation.donation_amount + shirtSum + runnerSum;
      return checkoutSum;
    });

    const page = reactive({
      title: "Summary",
      actionSentence: "Would you like to add anything else?",
      complete: true
    });

    if (supporting.type === "") {
      page.title = "How to Support";
      page.actionSentence = "How would you like to support the Piecewalk?";
      page.complete = false;
    }

    const joinTeam = async () => {
      let newTeamMembers = [];
      console.log(teamMembers);

      if (!contact) {
        error.active = true;
        error.message = "No contact information provided!";
        return;
      }

      if (supporting.type !== "Team") {
        error.active = true;
        error.message = "No team selected!";
        return;
      }

      //Add Contact as Team Member
      let contactTeamMember = {
        email: contact.email,
        firstName: contact.first_name,
        lastName: contact.last_name,
        phone: contact.phone,
        lifeChurch: contact.lifeChurch,
        team: supporting.teamDetails.id,
        eventYear: appSettings.appYear,
        useEmployerAsName: contact.useEmployerAsName,
        marketing: marketing.value
      };

      if (contact.employer) {
        contactTeamMember.myEmployer = contact.employer;
      }

      if (contact.lifeChurchCampus && contact.lifeChurch) {
        contactTeamMember.lifeChurchLocation = contact.lifeChurchCampus.name;
      }

      newTeamMembers.push(contactTeamMember);

      if (teamMembers.quantity > 0 && teamMembers.members.length > 0) {
        // Add Each Team Member to newTeamMembers
        teamMembers.members.forEach(person => {
          let newPerson = {
            email: person.email,
            firstName: person.first_name,
            lastName: person.last_name,
            phone: person.phone,
            lifeChurch: person.lifeChurch,
            team: supporting.teamDetails.id,
            eventYear: appSettings.appYear,
            myEmployer: "",
            useEmployerAsName: false
          };

          if (person.lifeChurchCampus && person.lifeChurch) {
            newPerson.lifeChurchLocation = person.lifeChurchCampus.name;
          }

          newTeamMembers.push(newPerson);
        });
      }

      let returnedTeamMembers = [];

      await Promise.all(
        newTeamMembers.map(async person => {
          await createPerson(person).then(returnedPerson => {
            returnedTeamMembers.push(returnedPerson);
          });
        })
      );

      store.dispatch("teamMembers/setAddedTeamMembers", newTeamMembers);
      console.log("Finished Adding Folks", returnedTeamMembers);
      router.push({ path: "/frontend/thank-you" });
    };

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("frontend create-team");
    });

    const toCheckout = () => {
      router.push({ path: "/frontend/checkout" });
    };

    return {
      page,
      contact,
      checkoutTotal,
      marketing,
      toCheckout,
      joinTeam,
      error
    };
  }
});
</script>
