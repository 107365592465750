import * as types from "./mutation-types";

const state = () => {
  return {
    initNotification: {
      show: false,
      error: false,
      title: "",
      message: ""
    },
    notification: {
      show: false,
      error: false,
      title: "",
      message: ""
    }
  };
};

// getters
const getters = {
  getNotification: state => state.notification
};

// actions
const actions = {
  displayNotification: async ({ commit, dispatch }, notification) => {
    await commit(types.DISPLAY_NOTIFICATION, { notification });
    await new Promise(resolve => setTimeout(() => resolve(), 4000));
    dispatch("removeNotification");
  },
  removeNotification: async ({ commit }) => {
    await commit(types.REMOVE_NOTIFICATION);
  }
};

// mutations
const mutations = {
  [types.DISPLAY_NOTIFICATION](state, { notification }) {
    state.notification = notification;
  },
  [types.REMOVE_NOTIFICATION](state) {
    state.notification = state.initNotification;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
