<template>
  <div>
    <FrontEndHeader fill="#be2032" />
    <!-- BEGIN: Products Grid -->
    <form
      v-if="appSettings.hasEventShirt"
      class="validate-form"
      @submit.prevent="save"
    >
      <div class="intro-y mt-5 pb-10 max-w-3xl mx-auto">
        <div v-if="shirts.length > 0" class="cart mt-12 mb-0 mx-6">
          <ShirtCart class="bg-white" :shirts="shirts" delete-button />
          <div class="mx-auto text-center mt-6">
            <router-link
              :to="{ name: 'contact', query: { skip: true } }"
              class="btn block w-full intro-y py-3 sm:inline-block sm:w-auto btn-red border-reddark sm:ml-2 sm:px-10"
            >
              Checkout
            </router-link>
          </div>
        </div>
        <div class="md:grid md:grid-cols-12 md:gap-6 p-6">
          <h3 class="col-span-12 mb-0 pb-0 text-center text-lg">
            Please select a product.
            <span class="block text-base"
              >There is a <strong>$8</strong> shipping charge on all
              orders.</span
            >
          </h3>
          <router-link
            v-for="product in appSettings.products"
            v-show="product.active"
            :key="product.slug"
            class="col-span-12 lg:col-span-6 shadow-md hover:shadow-xl transform transition-all duration-500 hover:scale-105 bg-white"
            :to="{
              name: 'single-product',
              params: { productSlug: product.slug }
            }"
          >
            <div class="mb-2">
              <img :src="product.image" :alt="product.name" />
            </div>
            <div class="p-3 pb-12 border-solid border-t">
              <h2 class="text-lg text-red font-bold text-center">
                {{ product.name }}
              </h2>
              <h4 class="text-center text-base">${{ product.price }}</h4>
            </div>
          </router-link>
        </div>
      </div>
    </form>
    <!--<div class="flex justify-center mt-6 mb-6">
      <router-link
        to="/frontend/summary"
        class="btn text-gray-600 bg-white border-transparent justify-self-start"
      >
        Cancel
      </router-link>
    </div>-->
  </div>
</template>

<script>
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "@/store";
import FrontEndHeader from "@/components/front-end/FrontEndHeader";
import ShirtCart from "@/components/front-end/ShirtCart";

export default defineComponent({
  components: {
    FrontEndHeader,
    ShirtCart
  },
  setup() {
    const store = useStore();
    const appSettings = computed(() => store.getters["main/eventSettings"]);
    const shirts = store.state.shirt.shirts;
    const page = {
      title: "Piecewalk Event Products"
    };

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("frontend create-team");
    });

    return {
      page,
      shirts,
      appSettings
    };
  }
});
</script>
