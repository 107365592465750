<template>
  <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Constant Contact Configuration
      </h2>
    </div>
    <div class="mt-5">
      <!-- BEGIN: Constant Contact Config -->
      <div class="config-content">
        <form class="validate-form" @submit.prevent="saveEventSettings">
          <div class="grid grid-cols-12 gap-6 mb-6 mt-6">
            <div class="intro-y box mt-2 p-6 col-span-12">
              <div class="col-span-12 lg:col-span-4">
                <div
                  v-if="eventSettings"
                  class="w-full sm:w-auto flex items-center mt-3 sm:mt-0 mb-6"
                >
                  <input
                    id="use-constant-contact"
                    v-model.trim="eventSettings.useConstantContact"
                    class="form-check-switch mr-0 ml-0"
                    type="checkbox"
                    :checked="eventSettings.useConstantContact"
                  />
                  <label
                    class="form-check-label font-medium"
                    for="use-constant-contact"
                    >Enable Constant Contact
                    <a
                      href="javascript:;"
                      name="use-constant-contact"
                      class="tooltip ml-1 relative -top-1 bg-gray-700 text-white px-1 rounded-full text-xs font-bold inline-block"
                      >?</a
                    ></label
                  >
                  <div class="tooltip-content">
                    <TippyContent to="use-constant-contact">
                      <div class="font-medium dark:text-gray-300">
                        Constant Contact
                      </div>
                      <div class="flex items-center mt-2 sm:mt-0">
                        <p>
                          This will enable using Constant Contact.
                        </p>
                      </div>
                    </TippyContent>
                  </div>
                </div>
              </div>
              <div class="col-span-12 lg:col-span-4">
                <h3 class="font-medium mb-6">
                  Constant Contact Lists
                  <a
                    href="javascript:;"
                    name="constant-contact-lists"
                    class="tooltip ml-1 relative -top-1 bg-gray-700 text-white px-1 rounded-full text-xs font-bold inline-block"
                    >?</a
                  >
                </h3>
                <div class="tooltip-content">
                  <TippyContent to="constant-contact-lists">
                    <div class="font-medium dark:text-gray-300">
                      Constant Contact Lists
                    </div>
                    <div class="flex items-center mt-2 sm:mt-0">
                      <p>
                        You need to create constant contact lists in your
                        constant contact account and tie them to the appropriate
                        list options.
                      </p>
                    </div>
                  </TippyContent>
                </div>
                <div v-if="eventSettings && eventSettings.constantContactLists">
                  <div v-show="eventSettings.useConstantContact">
                    <div
                      class="w-full sm:w-auto flex items-center mt-3 sm:mt-0 mb-6"
                    >
                      <label
                        for="captains_list"
                        class="form-label placeholder-label relative w-full mt-3 text-base"
                      >
                        <input
                          id="captains_list"
                          v-model="
                            eventSettings.constantContactLists.captainList
                          "
                          type="text"
                          class="form-control p-3"
                          placeholder=" "
                        />
                        <span
                          class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                          >Captain List ID</span
                        >
                      </label>
                    </div>
                    <div
                      class="w-full sm:w-auto flex items-center mt-3 sm:mt-0 mb-6"
                    >
                      <label
                        for="runner_list"
                        class="form-label placeholder-label relative w-full mt-3 text-base"
                      >
                        <input
                          id="runner_list"
                          v-model="
                            eventSettings.constantContactLists.runnerList
                          "
                          type="text"
                          class="form-control p-3"
                          placeholder=" "
                        />
                        <span
                          class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                          >Runner List ID</span
                        >
                      </label>
                    </div>
                    <div
                      class="w-full sm:w-auto flex items-center mt-3 sm:mt-0 mb-6"
                    >
                      <label
                        for="general_list"
                        class="form-label placeholder-label relative w-full mt-3 text-base"
                      >
                        <input
                          id="general_list"
                          v-model="
                            eventSettings.constantContactLists.generalList
                          "
                          type="text"
                          class="form-control p-3"
                          placeholder=" "
                        />
                        <span
                          class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                          >General Event List ID</span
                        >
                      </label>
                    </div>
                    <div
                      class="w-full sm:w-auto flex items-center mt-3 sm:mt-0 mb-6"
                    >
                      <label
                        for="donor_list"
                        class="form-label placeholder-label relative w-full mt-3 text-base"
                      >
                        <input
                          id="donor_list"
                          v-model="eventSettings.constantContactLists.donorList"
                          type="text"
                          class="form-control p-3"
                          placeholder=" "
                        />
                        <span
                          class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                          >Donor List ID</span
                        >
                      </label>
                    </div>
                    <div
                      class="w-full sm:w-auto flex items-center mt-3 sm:mt-0 mb-6"
                    >
                      <label
                        for="okc_list"
                        class="form-label placeholder-label relative w-full mt-3 text-base"
                      >
                        <input
                          id="okc_list"
                          v-model="
                            eventSettings.constantContactLists.oklahomaCityList
                          "
                          type="text"
                          class="form-control p-3"
                          placeholder=" "
                        />
                        <span
                          class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                          >Oklahoma City List ID</span
                        >
                      </label>
                    </div>
                    <div
                      class="w-full sm:w-auto flex items-center mt-3 sm:mt-0 mb-6"
                    >
                      <label
                        for="tulsa_list"
                        class="form-label placeholder-label relative w-full mt-3 text-base"
                      >
                        <input
                          id="tulsa_list"
                          v-model="eventSettings.constantContactLists.tulsaList"
                          type="text"
                          class="form-control p-3"
                          placeholder=" "
                        />
                        <span
                          class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                          >Tulsa List ID</span
                        >
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-12">
                <button
                  type="submit"
                  class="btn btn-lg btn-primary w-20 mr-auto"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
        <div
          v-show="eventSettings.useConstantContact"
          class="grid grid-cols-12 gap-6"
        >
          <div class="col-span-12">
            <div class="intro-y box mt-2 p-6 flex">
              <button
                class="btn w-60 mb-2 mr-2 btn-outline-primary"
                type="button"
                @click="constantContactConnect()"
              >
                <ExternalLinkIcon class="w-4 h-4 mr-2" />
                Connect
              </button>
              <button
                class="btn w-60 mb-2 btn-outline-primary"
                type="button"
                @click="constantContactDisconnect()"
              >
                Disconnect
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Constant Contact Config -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { disconnect } from "@/api/constant-contact";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "@/store";
import { updateEventSettings } from "@/api/event-settings";

export default defineComponent({
  setup() {
    // const ctctApiUrl = process.env.VUE_APP_CTCT_API_URL;
    const ctctApiKey = process.env.VUE_APP_CTCT_API_KEY;
    const ctctRedirectUri = process.env.VUE_APP_CTCT_REDIRECT_URI;

    const constantContactConnect = () => {
      const params = new URLSearchParams({
        client_id: ctctApiKey,
        state: "piecewalk",
        redirect_uri: ctctRedirectUri,
        response_type: "code",
        scope: "contact_data offline_access"
      });

      const url = `https://authz.constantcontact.com/oauth2/default/v1/authorize?${params}`;
      window.location.href = url;
    };

    const constantContactDisconnect = async () => {
      await disconnect();
    };
    const validate = useVuelidate();
    const store = useStore();
    const eventSettings = computed(() => store.getters["main/eventSettings"]);

    const sendNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "Event settings have been updated."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem updating the event settings."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const saveEventSettings = async () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        let response = {
          code: 400
        };
        await sendNotification(response);
      } else {
        const saveEventSettingsResult = await updateEventSettings(
          eventSettings.value
        );
        await sendNotification(saveEventSettingsResult);
      }
    };

    return {
      constantContactConnect,
      constantContactDisconnect,
      eventSettings,
      saveEventSettings
    };
  }
});
</script>
