import { createStore } from "vuex";
import main from "./main";
// import sideMenu from "./side-menu";
import auth from "./auth";
import sideMenu from "./side-menu-dashboard";
import support from "./supporting";
import donate from "./donate";
import contact from "./contact";
import shirt from "./shirt";
import run from "./run";
import lifeChurchCampuses from "./life-church-campus";
import teamMembers from "./team-members";
import backEndTeam from "./backend-team";
import notifications from "./notifications";
import transaction from "./transaction";

import statesList from "./states-list";

const store = createStore({
  modules: {
    main,
    auth,
    sideMenu,
    support,
    donate,
    contact,
    shirt,
    run,
    lifeChurchCampuses,
    teamMembers,
    backEndTeam,
    notifications,
    transaction,
    statesList
  }
});

export function useStore() {
  return store;
}

export default store;
