<template>
  <div>
    <!--<DarkModeSwitcher />-->
    <MobileMenu />
    <div class="flex">
      <!-- BEGIN: Side Menu -->
      <nav class="side-nav">
        <!-- BEGIN: Logo -->
        <router-link
          :to="{ name: 'my-team' }"
          tag="a"
          class="intro-x flex items-center pl-5 pt-4"
        >
          <Logo class="w-6" :fill="fill" />
          <span class="hidden xl:block text-white text-lg ml-3 font-bold">
            {{ appTitle }}
          </span>
        </router-link>
        <!-- END: Logo -->
        <div class="side-nav__devider my-6"></div>
        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="menu == 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
            ></li>
            <li v-else :key="menu + menuKey">
              <SideMenuTooltip
                tag="a"
                :content="menu.title"
                :href="
                  menu.subMenu
                    ? 'javascript:;'
                    : router.resolve({ name: menu.pageName }).path
                "
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <component :is="menu.icon" />
                </div>
                <div class="side-menu__title">
                  {{ menu.title }}
                  <div
                    v-if="menu.subMenu"
                    class="side-menu__sub-icon"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </SideMenuTooltip>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subMenu && menu.activeDropdown">
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                    :key="subMenuKey"
                  >
                    <SideMenuTooltip
                      tag="a"
                      :content="subMenu.title"
                      :href="
                        subMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({ name: subMenu.pageName }).path
                      "
                      class="side-menu"
                      :class="{ 'side-menu--active': subMenu.active }"
                      @click="linkTo(subMenu, router, $event)"
                    >
                      <div class="side-menu__icon">
                        <component :is="subMenu.icon" v-if="subMenu.icon" />
                        <ActivityIcon v-if="!subMenu.icon" />
                      </div>
                      <div class="side-menu__title">
                        {{ subMenu.title }}
                        <div
                          v-if="subMenu.subMenu"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                        <li
                          v-for="(lastSubMenu,
                          lastSubMenuKey) in subMenu.subMenu"
                          :key="lastSubMenuKey"
                        >
                          <SideMenuTooltip
                            tag="a"
                            :content="lastSubMenu.title"
                            :href="
                              lastSubMenu.subMenu
                                ? 'javascript:;'
                                : router.resolve({ name: lastSubMenu.pageName })
                                    .path
                            "
                            class="side-menu"
                            :class="{ 'side-menu--active': lastSubMenu.active }"
                            @click="linkTo(lastSubMenu, router, $event)"
                          >
                            <div class="side-menu__icon">
                              <ZapIcon />
                            </div>
                            <div class="side-menu__title">
                              {{ lastSubMenu.title }}
                            </div>
                          </SideMenuTooltip>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div class="content">
        <TopBar :user="user" :team-slug="teamSlug" />
        <div v-if="eventSettings && eventSettings.captainAlerts">
          <Alert
            v-for="(alert, index) in eventSettings.captainAlerts"
            :key="index"
            :headline="alert.headline"
            :text="alert.text"
          />
        </div>
        <router-view />
      </div>
      <!-- END: Content -->
      <!-- BEGIN: Notification Content -->
      <div id="main-notification-content" class="toastify-content hidden flex">
        <CheckCircleIcon v-if="!notification.error" class="text-theme-9" />
        <XCircleIcon v-if="notification.error" class="text-theme-6" />
        <div class="ml-4 mr-4">
          <div class="font-medium">{{ notification.title }}</div>
          <div class="text-gray-600 mt-1">
            {{ notification.message }}
          </div>
        </div>
      </div>
      <!-- END: Notification Content -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { helper as $h } from "@/utils/helper";
import TopBar from "@/components/top-bar/TopBar.vue";
import MobileMenu from "@/components/mobile-menu/Main.vue";
//import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import SideMenuTooltip from "@/components/side-menu-tooltip/Main.vue";
import Logo from "@/components/front-end/Logo";
import Alert from "@/components/alerts/Alert";
import { linkTo, nestedMenu, enter, leave } from "./index";
import Toastify from "toastify-js";

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    //DarkModeSwitcher,
    SideMenuTooltip,
    Logo,
    Alert
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const formattedMenu = ref([]);
    const appTitle = computed(() => store.getters["main/appTitle"]);
    const user = store.state.auth.user;
    const teamSlug = computed(() => store.getters["auth/getTeamSlug"]);
    const eventSettings = computed(() => store.getters["main/eventSettings"]);

    const notification = computed(
      () => store.getters["notifications/getNotification"]
    );
    let isAdmin = ref(false);

    const sideMenu = computed(() => {
      if (isAdmin.value) {
        return nestedMenu(store.state.sideMenu.menu, route);
      }
      return nestedMenu(store.state.sideMenu.captainMenu, route);
    });

    const fill = "#FFFFFF";

    const showNotification = async () => {
      console.log("run notificaiton");
      return new Promise(resolve => {
        Toastify({
          node: cash("#main-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
        resolve();
      });
    };

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value);
      }
    );

    watch(notification, async () => {
      if (notification.value.show) {
        await new Promise(resolve => setTimeout(() => resolve(), 500));
        await showNotification();
      }
    });

    onMounted(async () => {
      isAdmin.value = await store.getters["auth/isAdmin"];
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .addClass("main");
      formattedMenu.value = $h.toRaw(sideMenu.value);
    });

    return {
      appTitle,
      user,
      teamSlug,
      formattedMenu,
      router,
      fill,
      linkTo,
      enter,
      leave,
      notification,
      eventSettings
    };
  }
});
</script>
