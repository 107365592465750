<template>
  <div class="mb-6 overflow-auto">
    <table class="w-full table table-report">
      <thead class="p-3">
        <tr>
          <th
            v-for="(key, index) in columns"
            :key="index"
            :class="{ 'text-red active': sortKey == key }"
            class="cursor-pointer text-left px-4 py-2 capitalize"
            @click="sortBy(key)"
          >
            {{ key }}
            <span class="arrow" :class="sortOrders[key] > 0 ? 'asc' : 'dsc'">
            </span>
          </th>
          <th class="text-left px-4 py-2 capitalize">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(entry, index) in filteredData"
          :key="index"
          class="hover:bg-gray-100 transition intro-x"
        >
          <td v-for="(key, i) in columns" :key="i" class="border-b px-4 py-2">
            <span
              v-if="key !== 'active' && key !== 'role'"
              :class="{ 'font-bold text-base': key == 'name' }"
              class="block"
            >
              {{ entry[key] }}
              <span v-if="key == 'name'" class="block font-normal">{{
                entry["email"]
              }}</span>
            </span>
            <span v-if="key == 'active'" class="block">
              <span
                :class="{ 'btn-outline-danger': !entry[key] }"
                class="btn btn-outline-success w-24"
                ><span v-if="!entry[key]">In</span>Active</span
              >
            </span>
            <span v-if="key == 'role'" class="block">
              <span
                :class="{ 'btn-outline-danger': entry[key] === 'admin' }"
                class="btn btn-outline w-24 capitalize"
                >{{ entry[key] }}</span
              >
            </span>
          </td>
          <td class="border-b px-4 py-2">
            <router-link class="btn btn-outline" :to="`/user/${entry['id']}`"
              ><EditIcon class="h-4 w-4 mr-1" /> Edit</router-link
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, computed } from "vue";

export default defineComponent({
  props: {
    gridData: {
      type: Array,
      default: function() {
        return [];
      },
      required: false
    },
    query: {
      type: String,
      default: function() {
        return "";
      },
      required: false
    }
  },
  setup(props) {
    let sortKey = ref("");
    let columns = ["name", "role", "team", "active"];
    let sortOrders = reactive({});
    columns.forEach(function(key) {
      sortOrders[key] = 1;
    });

    const filteredData = computed(() => {
      let searchQuery = props.query;
      let data = props.gridData;
      let theSortKey = sortKey.value;
      let theSearchQuery = searchQuery && searchQuery.toLowerCase();
      let order = sortOrders[theSortKey] || 1;
      if (searchQuery) {
        data = props.gridData.filter(function(row) {
          return Object.keys(row).some(function(key) {
            return (
              String(row[key])
                .toLowerCase()
                .indexOf(theSearchQuery) > -1
            );
          });
        });
      }
      if (theSortKey) {
        data = data.slice().sort(function(a, b) {
          a = a[theSortKey];
          b = b[theSortKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return data;
    });

    const sortBy = key => {
      sortKey.value = key;
      sortOrders[key] = sortOrders[key] * -1;
    };

    return {
      sortKey,
      sortOrders,
      columns,
      filteredData,
      sortBy
    };
  }
});
</script>
<style scoped>
.table-report tr:hover td {
  @apply bg-gray-100;
}

th.active .arrow {
  opacity: 1;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #000;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
}
</style>
