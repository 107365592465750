.<template>
  <div class="col-span-12">
    <div v-show="showOverlay" class="overlay">
      <div
        class="loading-icon-wrapper h-full w-full flex justify-center items-center"
      >
        <div class="w-20 h-20">
          <LoadingIcon icon="circles" color="#FFFFFF" />
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-8">
      <button
        v-show="process === 0"
        type="button"
        class="btn btn-primary btn-red text-base mx-auto"
        @click="increment()"
      >
        Begin Team Reactivation
      </button>
    </div>
    <form class="validate-form mb-12" @submit.prevent="reactivateTeamRequest">
      <!-- BEGIN: Personal Information -->
      <div v-show="process == 1" class="intro-y box mt-5">
        <div
          class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
        >
          <h2 class="font-medium text-base mr-auto">Personal Information</h2>
          <p class="italic">
            This information is used internally.
          </p>
        </div>
        <div class="p-5">
          <div class="grid grid-cols-12 gap-x-5">
            <div class="col-span-12">
              <BasicInfo
                v-model:firstName="personData.firstName"
                v-model:lastName="personData.lastName"
                v-model:emailAddress="personData.email"
                v-model:phoneNumber="personData.phone"
                :first-name="personData.firstName"
                :last-name="personData.lastName"
                :email-address="personData.email"
                :phone-number="personData.phone"
                email-disabled
              />
            </div>
            <div class="col-span-12">
              <div class="grid grid-cols-12 gap-x-5">
                <LifeChurchField
                  v-model:lifeChurch="personData.lifeChurch"
                  v-model:lifeChurchCampus="personData.lifeChurchCampus"
                  class="mt-6 mb-6"
                  :campus-value="personData.lifeChurchCampus"
                  :is-life-church="personData.lifeChurch"
                />
                <Address
                  v-model:address="personData.address.address"
                  v-model:city="personData.address.city"
                  v-model:state="personData.address.state"
                  v-model:zipCode="personData.address.zipcode"
                  no-shipping
                  :address-value="personData.address.address"
                  :city-value="personData.address.city"
                  :state-value="personData.address.state"
                  :zipcode-value="personData.address.zipcode"
                />
                <ShirtSize
                  v-model="personData.shirtSize"
                  :class="{ 'text-red font-bold': !personData.shirtSize }"
                  class="col-span-12 md:col-span-6 mt-6"
                  :size-value="personData.shirtSize"
                  :shirt-sizes="shirt.size"
                />
                <div class="col-span-12 md:col-span-6 mt-6">
                  <div v-show="!personData.shirtSize">
                    <div>Note for Shirt Sizes</div>
                    <div class="text-red">
                      We use this shirt size information in case of incentives
                      <br />
                      earned as the team captain.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-4 pb-8">
          <button
            type="button"
            :disabled="validate.$errors.length > 0 || !personData.shirtSize"
            class="btn btn-primary w-40 btn-red text-base mx-auto"
            @click="increment()"
          >
            Next
          </button>
        </div>
      </div>
      <!-- END: Personal Information -->
      <!-- BEGIN: Team Information -->
      <div v-show="process == 2" class="intro-y box mt-5">
        <div
          class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
        >
          <h2 class="font-medium text-base mr-auto">Team Information</h2>
          <p class="italic">
            Please review your team name and team goals
          </p>
        </div>
        <div class="p-5">
          <div class="grid grid-cols-12 gap-x-5">
            <div class="col-span-12 mb-6">
              <label for="teamName" class="form-label">
                Team Name
              </label>
              <input
                v-model="team.name"
                type="text"
                for="team-name"
                :class="{ 'border-red': team.name === '' }"
                class="form-control p-3 text-base"
                placeholder="Team Name"
                required
              />
              <div v-show="team.name === ''" class="text-red mt-2">
                <div>A team name is required.</div>
              </div>
              <div
                v-show="team.name && team.name.length < 2"
                class="text-red mt-2"
              >
                <div>Your team name must be at least 2 characters.</div>
              </div>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <label for="fundraisingGoal" class="form-label">
                Fundraising Goal
              </label>
              <div
                class="input-group text-base"
                :class="{ 'border border-red': team.teamDonationGoal < 6 }"
              >
                <div id="input-group-email" class="input-group-text">$</div>
                <input
                  v-model="team.teamDonationGoal"
                  type="number"
                  for="fundraising-goal"
                  class="form-control p-3"
                  placeholder="Fundraising Goal"
                />
              </div>
              <div v-show="team.teamDonationGoal < 6" class="text-red mt-2">
                <div>Donation Goal should be greater than 5.</div>
              </div>
            </div>
            <div class="col-span-12 mt-6 lg:mt-0 lg:col-span-6">
              <label for="memberGoal" class="form-label">
                Team Membership Goal
              </label>
              <input
                v-model="team.teamMemberGoal"
                type="number"
                for="membership-goal"
                :class="{ 'border-red': team.teamMemberGoal < 2 }"
                class="form-control p-3 text-base"
                placeholder="Team Membership Goal"
              />
              <div v-show="team.teamMemberGoal < 2" class="text-red mt-2">
                <div>Your team member goal should be greater than 1.</div>
              </div>
            </div>
            <div class="col-span-12 mt-6">
              <h2 class="font-medium text-base mr-auto">Team Members</h2>
              <p class="italic">
                Please select any past team members you would like to add to
                your team for this year.
              </p>
              <div class="past-team-members">
                <div
                  v-for="member in team.members"
                  :key="member.personId"
                  class="mt-1"
                >
                  <input
                    :id="member.personId"
                    v-model="selectedMembers"
                    type="checkbox"
                    :value="member.personId"
                  />
                  <label :for="member.personId" class="ml-2 text-base"
                    >{{ member.firstName }} {{ member.lastName }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="process == 2" class="flex justify-end mt-8">
        <button
          type="button"
          class="btn bg-white w-40 text-base mx-auto"
          @click="decrement()"
        >
          Back
        </button>
        <button
          type="submit"
          class="btn btn-primary btn-red text-base mx-auto"
          :disabled="
            validate.$errors.length > 0 ||
              team.name === '' ||
              (team.name && team.name.length < 2) ||
              team.teamMemberGoal < 2 ||
              team.teamDonationGoal < 6
          "
        >
          Reactivate My Team
        </button>
      </div>
      <!-- END: Team Information -->
    </form>
  </div>
</template>
<script>
import {
  defineComponent,
  onMounted,
  computed,
  reactive,
  ref,
  toRefs
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useVuelidate } from "@vuelidate/core";
import BasicInfo from "@/components/form-inputs/BasicInfo";
import Address from "@/components/form-inputs/Address";
import LifeChurchField from "@/components/form-inputs/LifeChurch";
import ShirtSize from "@/components/form-inputs/ShirtSize";
import { useStore } from "@/store";
import { getTeam, reactivateTeam } from "@/api/teams.js";

export default defineComponent({
  components: {
    BasicInfo,
    Address,
    LifeChurchField,
    ShirtSize
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      },
      required: true
    },
    person: {
      type: Object,
      default: () => {
        return {};
      },
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    let validateFields = ref(false);
    const showOverlay = ref(false);
    const formattedName = computed(() => {
      return props.person.firstName + " " + props.person.lastName;
    });

    const rules = computed(() => {
      return {};
    });

    const validate = useVuelidate(rules, toRefs(userData, personData, team));

    const userData = reactive(props.user);
    const personData = reactive(props.person);
    const appSettings = computed(() => store.getters["main/eventSettings"]);
    const team = computed(() => store.getters["backEndTeam/currentTeam"]);

    const shirt = reactive({
      size: ["Small", "Medium", "Large", "XLarge", "2XLarge", "3XLarge"]
    });

    const process = ref(0);

    const increment = () => {
      process.value += 1;
    };

    const decrement = () => {
      if (process.value > 0) {
        process.value -= 1;
      }
    };

    if (!personData.address) {
      personData.address = {};
    }

    const sendNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "Your profile has been updated."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem updating your profile."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    let selectedMembers = ref([]);

    const setTeam = async () => {
      let getMyTeam = await getTeam();
      if (route.params.id) {
        const theTeamID = route.params.id;
        getMyTeam = await getTeam(theTeamID);
      }
      store.dispatch("backEndTeam/setCurrentTeam", getMyTeam);
      selectedMembers.value = getMyTeam.members.map(member => member.personId);
    };

    //Reverse Lookup to Remove Any Who Are Not Selected
    const removeParticipants = computed(() => {
      let newArr = [];
      newArr = team.value.members
        .filter(member => {
          if (
            selectedMembers &&
            selectedMembers.value &&
            !selectedMembers.value.includes(member.personId)
          ) {
            return true;
          }
        })
        .map(member => member.personId);
      return newArr;
    });

    //Reverse Lookup to Remove Any Who Are Not Selected
    const selectedMemberEmails = computed(() => {
      let newArr = [];
      newArr = team.value.members
        .filter(member => {
          if (
            selectedMembers &&
            selectedMembers.value &&
            selectedMembers.value.includes(member.personId)
          ) {
            return true;
          }
        })
        .map(member => member.email);
      return newArr;
    });

    const reactivateTeamRequest = async () => {
      showOverlay.value = true;
      let teamData = {
        name: team.value.name,
        teamDonationGoal: parseInt(team.value.teamDonationGoal),
        teamMemberGoal: parseInt(team.value.teamMemberGoal),
        eventCity: team.value.eventCity
      };

      personData.eventYear = appSettings.value.appYear; // Set person to new year
      personData.marketing = true; // Set marketing to true

      const reactivateTeamResult = await reactivateTeam(
        team.value.id,
        personData,
        teamData,
        selectedMembers.value,
        removeParticipants.value,
        selectedMemberEmails.value
      );

      await sendNotification(reactivateTeamResult).then(() => {
        console.log(reactivateTeamResult, "send to dashboard");
        window.localStorage.removeItem("pw_has_viewed_intro");
        showOverlay.value = false;
        if (route.params.id) {
          router.push({ path: `/team/${route.params.id}` });
        } else {
          router.push({ path: "/" });
        }
      });
    };

    onMounted(async () => {
      await setTeam();
      if (team.value.active) {
        router.push({ path: "/" });
      }
    });

    return {
      formattedName,
      userData,
      personData,
      reactivateTeamRequest,
      team,
      removeParticipants,
      selectedMembers,
      selectedMemberEmails,
      increment,
      decrement,
      process,
      shirt,
      showOverlay,
      validateFields,
      validate
    };
  }
});
</script>

<style scoped>
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
