<template>
  <div
    class="flex justify-start items-center col-span-12 rounded intro-y border-2 border-solid border-theme-9 px-3 py-2"
  >
    <div class="inline-block">
      <CheckCircleIcon class="w-6 h-6 mr-4 fill-theme-9 inline-block" />
    </div>
    <div class="donate-info inline-block flex-grow">
      <h6 class="text-xs">Donation</h6>
      <h4 class="text-base">
        <span v-if="donation.donation_amount > 0" class="ml-1"
          >${{ donation.donation_amount }}</span
        >
      </h4>
    </div>
    <div class="edit-donate-info justify-self-end">
      <router-link to="/frontend/donate" class="btn btn-sm">
        Edit
      </router-link>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    supporting: {
      type: Object,
      default: function() {
        return {};
      }
    },
    donation: {
      type: Object,
      default: function() {
        return {};
      },
      required: false
    }
  },
  setup() {
    const store = useStore();
    const appTitle = computed(() => store.getters["main/appTitle"]);
    const appYear = computed(() => store.getters["main/appYear"]);

    return {
      appTitle,
      appYear
    };
  }
});
</script>
