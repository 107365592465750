import * as types from "./mutation-types";
import { updateTeam } from "@/api/teams.js";

const state = () => {
  return {
    team: {}
  };
};

const sendNotification = async response => {
  let note = {
    show: true,
    title: "Success",
    message: "The team has been updated."
  };
  if (response.code) {
    note = {
      show: true,
      error: true,
      title: "Error",
      message: "There was a problem updating the team."
    };
  }
  return note;
};

// getters
const getters = {
  currentTeam: state => state.team
};

// actions
const actions = {
  setCurrentTeam: async ({ commit }, team) => {
    await commit(types.SET_CURRENT_TEAM, team);
  },
  updateTeam: async ({ commit, dispatch, state }, team) => {
    let teamId = state.team.id;
    console.log(teamId);
    const updateTeamResponse = await updateTeam(team, teamId);
    if (!updateTeamResponse.code) {
      await commit(types.UPDATE_TEAM, updateTeamResponse);
    }
    let theNotification = await sendNotification(updateTeamResponse);
    dispatch("notifications/displayNotification", theNotification, {
      root: true
    });
  }
};

// mutations
const mutations = {
  [types.SET_CURRENT_TEAM](state, team) {
    state.team = team;
  },
  [types.UPDATE_TEAM](state, team) {
    console.log("Set Team");
    state.team = team;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
