<template>
  <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
    <!-- BEGIN: Display Information -->
    <div class="intro-y box lg:mt-5">
      <div
        class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
      >
        <h2 class="font-medium text-base mr-auto">Display Information</h2>
        <p class="italic">
          This information may be shared with your team members.
        </p>
      </div>
      <div class="p-5">
        <div class="flex flex-col-reverse xl:flex-row flex-col">
          <div class="flex-1 mt-6 xl:mt-0">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xxl:col-span-6">
                <div>
                  <Tippy
                    tag="span"
                    href="javascript:;"
                    class="tooltip"
                    content="This is the way your Captain name will be displayed"
                  >
                    <label for="displayName" class="form-label"
                      >Display Name</label
                    ></Tippy
                  >
                  <input
                    id="displayName"
                    type="text"
                    class="form-control"
                    placeholder="Input text"
                    :value="formattedName"
                    required
                    disabled
                  />
                </div>
              </div>
              <div class="col-span-12 xxl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Email</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Input text"
                    :value="user.email"
                    disabled
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Display Information -->
    <!-- BEGIN: Personal Information -->
    <div class="intro-y box mt-5">
      <div
        class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
      >
        <h2 class="font-medium text-base mr-auto">Personal Information</h2>
        <p class="italic">
          This information is used internally.
        </p>
      </div>
      <form class="validate-form" @submit.prevent="savePerson">
        <div class="p-5">
          <div class="grid grid-cols-12 gap-x-5">
            <div class="col-span-12">
              <BasicInfo
                v-model:firstName="personData.firstName"
                v-model:lastName="personData.lastName"
                v-model:emailAddress="personData.email"
                v-model:phoneNumber="personData.phone"
                :first-name="personData.firstName"
                :last-name="personData.lastName"
                :email-address="personData.email"
                :phone-number="personData.phone"
              />
            </div>
            <div class="col-span-12">
              <div class="grid grid-cols-12 gap-x-5">
                <LifeChurchField
                  v-model:lifeChurch="personData.lifeChurch"
                  v-model:lifeChurchCampus="personData.lifeChurchCampus"
                  class="mt-6 mb-6 hidden"
                  :campus-value="personData.lifeChurchCampus"
                  :is-life-church="personData.lifeChurch"
                />
                <Address
                  v-model:address="personData.address.address"
                  v-model:city="personData.address.city"
                  v-model:state="personData.address.state"
                  v-model:zipCode="personData.address.zipcode"
                  no-shipping
                  :address-value="personData.address.address"
                  :city-value="personData.address.city"
                  :state-value="personData.address.state"
                  :zipcode-value="personData.address.zipcode"
                  class="mt-6"
                />
                <ShirtSize
                  v-model="personData.shirtSize"
                  class="col-span-12 md:col-span-6 mt-6"
                  :size-value="personData.shirtSize"
                  :shirt-sizes="shirt.size"
                />
              </div>
            </div>
          </div>
          <div class="flex justify-end mt-8">
            <button type="submit" class="btn btn-primary w-20 mr-auto">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
    <!-- END: Personal Information -->
    <!-- BEGIN: Admin Information -->
    <div v-if="isAdmin" class="intro-y box lg:mt-5">
      <div class="p-5 border-b border-gray-200 dark:border-dark-5">
        <h2 class="font-medium text-base mr-auto">User Config</h2>
        <p class="italic">
          This information is only available to admins.
        </p>
      </div>
      <form class="validate-form" @submit.prevent="saveUser">
        <div class="p-5">
          <div class="flex flex-col-reverse xl:flex-row flex-col">
            <div class="flex-1 mt-6 xl:mt-0">
              <div class="grid grid-cols-12 gap-x-5">
                <div class="col-span-12 xxl:col-span-3">
                  <div>
                    <div
                      class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
                    >
                      <input
                        id="user-active"
                        v-model.trim="userData.active"
                        class="form-check-switch mr-0 ml-0"
                        type="checkbox"
                        :checked="userData.active"
                      />
                      <label
                        class="form-check-label font-medium"
                        for="user-active"
                        ><span v-show="userData.active">
                          Active
                        </span>
                        <span v-show="!userData.active">
                          Inactive
                        </span></label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-12 xxl:col-span-9">
                  <div>
                    <div
                      class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
                    >
                      <input
                        id="email-verified"
                        v-model.trim="userData.isEmailVerified"
                        class="form-check-switch mr-0 ml-0"
                        type="checkbox"
                        :checked="userData.isEmailVerified"
                      />
                      <label
                        class="form-check-label font-medium"
                        for="email-verified"
                        >Is Email Validated</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-span-12">
                  <div class="mt-6">
                    <label for="role" class="form-label">Role</label>
                    <select
                      id="role"
                      v-model.trim="userData.role"
                      class="form-select mt-2 sm:mr-2"
                      aria-label="user-role"
                      :value="userData.role"
                    >
                      <option value="admin">Admin</option>
                      <option value="captain">Captain</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-end mt-8">
            <button type="submit" class="btn btn-primary w-20 mr-auto">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
    <!-- END: Admin Information -->
  </div>
</template>
<script>
import { defineComponent, onMounted, computed, reactive, ref } from "vue";
import BasicInfo from "@/components/form-inputs/BasicInfo";
import Address from "@/components/form-inputs/Address";
import LifeChurchField from "@/components/form-inputs/LifeChurch";
import ShirtSize from "@/components/form-inputs/ShirtSize";
import { useStore } from "@/store";
import { updatePerson } from "@/api/participants";
import { updateUser } from "@/api/users";

export default defineComponent({
  components: {
    BasicInfo,
    Address,
    LifeChurchField,
    ShirtSize
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      },
      required: true
    },
    person: {
      type: Object,
      default: () => {
        return {};
      },
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const isAdmin = ref(false);
    const formattedName = computed(() => {
      return props.person.firstName + " " + props.person.lastName;
    });

    const userData = reactive(props.user);
    const personData = reactive(props.person);

    if (!personData.address) {
      personData.address = {};
    }

    const shirt = reactive({
      size: ["Small", "Medium", "Large", "XLarge", "2XLarge", "3XLarge"]
    });

    const sendNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "Your profile has been updated."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem updating your profile."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const savePerson = async () => {
      const savePersonResult = await updatePerson(personData);
      await sendNotification(savePersonResult);
    };

    let userUpdateData = computed(() => {
      return {
        isEmailVerified: userData.isEmailVerified,
        role: userData.role,
        active: userData.active
      };
    });

    const saveUser = async () => {
      const saveUserResult = await updateUser(
        userUpdateData.value,
        userData.id
      );
      await sendNotification(saveUserResult);
    };

    onMounted(async () => {
      isAdmin.value = await store.getters["auth/isAdmin"];
    });

    return {
      formattedName,
      userData,
      personData,
      savePerson,
      saveUser,
      userUpdateData,
      shirt,
      isAdmin
    };
  }
});
</script>
