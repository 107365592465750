<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-8">
      <div class="col-span-12 flex justify-center items-center">
        <router-link
          :to="{ name: 'users' }"
          class="btn btn-outline shadow-md mr-2"
          ><ChevronLeftIcon class="h-4 w-4 mr-1" />All Users</router-link
        >
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 mx-auto">
        <h2 class="intro-y text-lg font-medium mt-10 mb-6">New User</h2>
        <div class="intro-y box mt-2">
          <div class="p-8 text-base">
            <h3 class="font-bold">
              User Details
            </h3>
            <form class="validate-form" @submit.prevent="createNewUser">
              <div class="p-12 px-0">
                <div class="grid grid-cols-12 gap-x-5">
                  <div class="col-span-12">
                    <BasicInfo
                      v-model:firstName="userData.firstName"
                      v-model:lastName="userData.lastName"
                      v-model:emailAddress="userData.email"
                      v-model:phoneNumber="userData.phone"
                      :first-name="userData.firstName"
                      :last-name="userData.lastName"
                      :email-address="userData.email"
                      :phone-number="userData.phone"
                    />
                  </div>
                  <div class="col-span-12">
                    <div class="grid grid-cols-12 gap-x-5">
                      <div class="col-span-12 sm:col-span-6">
                        <div class="mt-6">
                          <label for="role" class="form-label">Role</label>
                          <select
                            id="role"
                            v-model.trim="userData.role"
                            class="form-select mt-2 sm:mr-2"
                            aria-label="user-role"
                            :value="userData.role"
                          >
                            <option value="admin">Admin</option>
                            <option value="captain">Captain</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-span-12 sm:col-span-6 mt-12">
                        <TeamCityField v-model="userData.eventCity" />
                      </div>
                      <LifeChurchField
                        v-model:lifeChurch="userData.lifeChurch"
                        v-model:lifeChurchCampus="userData.lifeChurchCampus"
                        class="mt-6 mb-6"
                        :campus-value="userData.lifeChurchCampus"
                        :is-life-church="userData.lifeChurch"
                      />
                      <Address
                        v-model:address="userData.address.address"
                        v-model:city="userData.address.city"
                        v-model:state="userData.address.state"
                        v-model:zipCode="userData.address.zipcode"
                        class="mb-6"
                        no-shipping
                        not-required
                        :address-value="userData.address.address"
                        :city-value="userData.address.city"
                        :state-value="userData.address.state"
                        :zipcode-value="userData.address.zipcode"
                      />
                      <div class="intro-y col-span-12 sm:col-span-6">
                        <PasswordField v-model="userData.password.password" />
                      </div>
                      <div class="intro-y col-span-12 sm:col-span-6">
                        <ConfirmPasswordField
                          v-model="userData.password.confirm"
                          :password="userData.password.password"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end mt-8">
                  <button
                    :disabled="validate.$errors.length > 0"
                    type="submit"
                    class="btn btn-primary w-60 mr-auto"
                  >
                    Create User
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "@/store";
import { createUser } from "@/api/users";
import BasicInfo from "@/components/form-inputs/BasicInfo";
import Address from "@/components/form-inputs/Address";
import TeamCityField from "@/components/form-inputs/TeamCity";
import PasswordField from "@/components/form-inputs/Password";
import ConfirmPasswordField from "@/components/form-inputs/PasswordConfirm";
import LifeChurchField from "@/components/form-inputs/LifeChurch";

export default defineComponent({
  components: {
    BasicInfo,
    Address,
    TeamCityField,
    PasswordField,
    ConfirmPasswordField,
    LifeChurchField
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const validate = useVuelidate();
    let userData = reactive({
      address: {},
      password: {
        password: "",
        confirm: ""
      },
      lifeChurch: false,
      role: "captain",
      marketing: true,
      eventYear: "2022"
    });

    const sendNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "New user has been created."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem creating the user."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const createUserRequest = async createUserData => {
      let createUser = {
        user: {},
        person: {}
      };

      createUser.user.email = createUserData.email;
      createUser.user.password = createUserData.password.password;
      createUser.user.role = createUserData.role;

      createUser.person.firstName = createUserData.firstName;
      createUser.person.lastName = createUserData.lastName;
      createUser.person.lifeChurch = createUserData.lifeChurch;
      createUser.person.marketing = createUserData.marketing;
      createUser.person.email = createUserData.email;
      createUser.person.phone = createUserData.phone;
      createUser.person.eventYear = createUserData.eventYear;
      createUser.person.eventCity = createUserData.eventCity;
      if (createUserData.lifeChurch) {
        createUser.person.lifeChurchLocation =
          createUserData.lifeChurchCampus.name;
      }
      if (createUserData.address.address) {
        createUser.person.address = createUserData.address;
      }

      return createUser;
    };

    const createNewUser = async () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        console.log("invalid");
        sendNotification({ code: true });
      } else {
        let userRequest = await createUserRequest(userData);
        const saveUserResult = await createUser(userRequest);
        sendNotification(saveUserResult);
        if (!saveUserResult.code) {
          router.push({ name: "users" });
        }
      }
    };

    return {
      userData,
      createNewUser,
      validate
    };
  }
});
</script>
<style scoped>
.person-table td {
  border-bottom: 1px solid #ddd;
}
</style>
