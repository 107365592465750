<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Users</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <div class="mt-4 sm:mt-0">
          <form id="grid-search" class="mr-3">
            <div class="w-64 relative text-gray-700 dark:text-gray-300">
              <input
                v-model="searchQuery"
                type="text"
                class="form-control p-3 w-64 box pr-10 placeholder-theme-13"
                placeholder="Search..."
              />
              <SearchIcon
                class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
              />
            </div>
          </form>
        </div>
        <div class="hidden md:block mx-auto text-gray-600">
          {{ totalResults }} Total Users
        </div>
        <router-link
          :to="{ name: 'new-user' }"
          class="btn btn-primary shadow-md mr-2"
          >Add New User</router-link
        >
      </div>
    </div>
    <div class="intro-y mt-5">
      <UserGrid :grid-data="formattedUsers" :query="searchQuery" />
    </div>
    <div class="mt-5 flex justify-center">
      <button v-show="page > 1" class="btn button mr-2" @click="prevPage">
        Prev Page
      </button>
      <button
        v-show="totalPages > 1 && page < totalPages"
        class="btn button mr-2"
        @click="nextPage"
      >
        Next Page
      </button>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getUsers } from "@/api/users.js";
import UserGrid from "@/components/back-end/UserGrid";

export default defineComponent({
  components: {
    UserGrid
  },
  setup() {
    let userData = reactive([]);
    let formattedUsers = reactive([]);
    let searchQuery = ref("");
    let page = ref(1);
    let totalResults = ref(0);
    let totalPages = ref(0);

    const getUserData = async () => {
      if (!page.value) {
        page.value = 1;
      }
      const allUsers = await getUsers(page.value);
      totalPages.value = allUsers.totalPages;
      totalResults.value = allUsers.totalResults;
      Object.assign(userData, allUsers);
      reformattedUsers(allUsers.results);
    };

    const reformattedUsers = theUsers => {
      let users = theUsers;
      let formattedUserData = [];
      users.map(user => {
        let obj = {};
        obj.active = user.active;
        obj.id = user.id;
        obj.name = "No Person Assigned";
        obj.email = user.email;
        obj.role = user.role;
        obj.team = "Unassigned";

        if ("team" in user && user.team !== null) {
          obj.team = user.team.name;
        }

        if ("person" in user) {
          obj.name = user.person.firstName + " " + user.person.lastName;
        }

        formattedUserData.push(obj);
      });
      Object.assign(formattedUsers, formattedUserData);
    };

    const nextPage = () => {
      page.value++;
      getUserData();
    };

    const prevPage = () => {
      page.value--;
      getUserData();
    };

    onMounted(() => {
      getUserData(null, page.value);
    });

    return {
      userData,
      formattedUsers,
      searchQuery,
      prevPage,
      nextPage,
      page,
      totalResults,
      totalPages
    };
  }
});
</script>
