import * as types from "./mutation-types";

const state = () => {
  return {
    shirts: [],
    shirtCost: 20,
    shirtSizes: [
      {
        value: "child-sm",
        name: "Child Small"
      },
      {
        value: "child-med",
        name: "Child Medium"
      },
      {
        value: "child-lg",
        name: "Child Large"
      },
      {
        value: "xs",
        name: "X-Small"
      },
      {
        value: "sm",
        name: "Small"
      },
      {
        value: "med",
        name: "Medium"
      },
      {
        value: "lg",
        name: "Large"
      },
      {
        value: "xl",
        name: "X-Large"
      },
      {
        value: "2xl",
        name: "2X-Large"
      },
      {
        value: "3xl",
        name: "3X-Large"
      }
    ]
  };
};

// getters
const getters = {
  shirts: state => state.shirts,
  shirtCost: state => state.shirtCost,
  shirtSizes: state => state.shirtSizes
};

// actions
const actions = {
  setShirts: async ({ commit }, shirts) => {
    await commit(types.SET_SHIRTS, { shirts });
  },
  addShirt: async ({ commit }, shirt) => {
    await commit(types.ADD_SHIRT, { shirt });
  },
  removeShirt: async ({ commit }, shirtIndex) => {
    await commit(types.DELETE_SHIRT, { shirtIndex });
  },
  resetShirt: async ({ commit }) => {
    await commit(types.RESET_SHIRT);
  }
};

// mutations
const mutations = {
  [types.SET_SHIRTS](state, { shirts }) {
    state.shirts = shirts;
  },
  [types.ADD_SHIRT](state, { shirt }) {
    state.shirts.push(shirt);
  },
  [types.DELETE_SHIRT](state, { shirtIndex }) {
    state.shirts.splice(shirtIndex, 1);
  },
  [types.RESET_SHIRT](state) {
    state.shirts = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
