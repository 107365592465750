<template>
  <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Badge Settings</h2>
    </div>
    <div class="mt-5">
      <!-- BEGIN: Badges Content -->
      <div class="config-content">
        <form class="validate-form" @submit.prevent="saveEventSettings">
          <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12">
              <div class="intro-y box mt-2 p-6">
                <h2 class="col-span-12 text-base font-medium">
                  Event Badges
                </h2>
                <div class="grid grid-cols-12 gap-6 mb-6 mt-6">
                  <div class="col-span-12 lg:col-span-4">
                    <h3 class="font-medium mb-2">Facebook Fundraiser</h3>
                    <div
                      v-if="eventSettings && eventSettings.facebookFundraiser"
                      class="w-full sm:w-auto flex items-center mt-3 sm:mt-0 mb-6"
                    >
                      <input
                        id="use-facebook-fundraiser"
                        v-model.trim="eventSettings.facebookFundraiser.active"
                        class="form-check-switch mr-0 ml-0"
                        type="checkbox"
                        :checked="eventSettings.facebookFundraiser.active"
                      />
                      <label
                        class="form-check-label font-medium"
                        for="use-facebook-fundraiser"
                        >Enable Facebook Fundraiser
                        <a
                          href="javascript:;"
                          name="use-facebok-fundraiser"
                          class="tooltip ml-1 relative -top-1 bg-gray-700 text-white px-1 rounded-full text-xs font-bold inline-block"
                          >?</a
                        ></label
                      >
                      <div class="tooltip-content">
                        <TippyContent to="use-facebok-fundraiser">
                          <div class="font-medium dark:text-gray-300">
                            Facebook Fundraiser
                          </div>
                          <div class="flex items-center mt-2 sm:mt-0">
                            <p>
                              This will enable a Facebook Fundraiser Badge.
                            </p>
                          </div>
                        </TippyContent>
                      </div>
                    </div>
                    <div
                      v-if="eventSettings && eventSettings.facebookFundraiser"
                    >
                      <div v-if="eventSettings.facebookFundraiser.active">
                        <img
                          v-if="eventSettings.facebookFundraiser.image"
                          :src="eventSettings.facebookFundraiser.image"
                          class="w-40 cursor-pointer"
                          @click="initializeImageUploadWidget('facebook')"
                        />
                        <button
                          v-if="!eventSettings.facebookFundraiser.image"
                          class="btn block btn-outline mt-2"
                          type="button"
                          @click="initializeImageUploadWidget('facebook')"
                        >
                          <UploadIcon class="w-4 h-4 mr-2" /> Add Badge Image
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-12 lg:col-span-4">
                    <h3 class="font-medium mb-2">Employer Match</h3>
                    <div
                      v-if="eventSettings && eventSettings.employerMatch"
                      class="w-full sm:w-auto flex items-center mt-3 sm:mt-0 mb-6"
                    >
                      <input
                        id="use-employer-match"
                        v-model.trim="eventSettings.employerMatch.active"
                        class="form-check-switch mr-0 ml-0"
                        type="checkbox"
                        :checked="eventSettings.employerMatch.active"
                      />
                      <label
                        class="form-check-label font-medium"
                        for="use-employer-match"
                        >Enable Employer Match
                        <a
                          href="javascript:;"
                          name="use-employer-match"
                          class="tooltip ml-1 relative -top-1 bg-gray-700 text-white px-1 rounded-full text-xs font-bold inline-block"
                          >?</a
                        ></label
                      >
                      <div class="tooltip-content">
                        <TippyContent to="use-employer-match">
                          <div class="font-medium dark:text-gray-300">
                            Employer Match
                          </div>
                          <div class="flex items-center mt-2 sm:mt-0">
                            <p>
                              This will enable a Employer Match Fundraiser
                              Badge.
                            </p>
                          </div>
                        </TippyContent>
                      </div>
                    </div>
                    <div v-if="eventSettings && eventSettings.employerMatch">
                      <div v-if="eventSettings.employerMatch.active">
                        <img
                          v-if="eventSettings.employerMatch.image"
                          :src="eventSettings.employerMatch.image"
                          class="w-40 cursor-pointer"
                          @click="initializeImageUploadWidget('employer')"
                        />
                        <button
                          v-if="!eventSettings.employerMatch.image"
                          class="btn block btn-outline mt-2"
                          type="button"
                          @click="initializeImageUploadWidget('employer')"
                        >
                          <UploadIcon class="w-4 h-4 mr-2" /> Add Badge Image
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-12 lg:col-span-4">
                    <h3 class="font-medium mb-2">Team Event</h3>
                    <div
                      v-if="eventSettings && eventSettings.teamEvent"
                      class="w-full sm:w-auto flex items-center mt-3 sm:mt-0 mb-6"
                    >
                      <input
                        id="use-team-event"
                        v-model.trim="eventSettings.teamEvent.active"
                        class="form-check-switch mr-0 ml-0"
                        type="checkbox"
                        :checked="eventSettings.teamEvent.active"
                      />
                      <label
                        class="form-check-label font-medium"
                        for="use-team-event"
                        >Enable Team Event
                        <a
                          href="javascript:;"
                          name="use-team-event"
                          class="tooltip ml-1 relative -top-1 bg-gray-700 text-white px-1 rounded-full text-xs font-bold inline-block"
                          >?</a
                        ></label
                      >
                      <div class="tooltip-content">
                        <TippyContent to="use-team-event">
                          <div class="font-medium dark:text-gray-300">
                            Team Event
                          </div>
                          <div class="flex items-center mt-2 sm:mt-0">
                            <p>
                              This will enable a Team Event Badge.
                            </p>
                          </div>
                        </TippyContent>
                      </div>
                    </div>
                    <div v-if="eventSettings && eventSettings.teamEvent">
                      <div v-if="eventSettings.teamEvent.active">
                        <img
                          v-if="eventSettings.teamEvent.image"
                          :src="eventSettings.teamEvent.image"
                          class="w-40 cursor-pointer"
                          @click="initializeImageUploadWidget('team')"
                        />
                        <button
                          v-if="!eventSettings.teamEvent.image"
                          class="btn block btn-outline mt-2"
                          type="button"
                          @click="initializeImageUploadWidget('team')"
                        >
                          <UploadIcon class="w-4 h-4 mr-2" /> Add Badge Image
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="eventSettings && eventSettings.eliteBadgeSettings"
                    class="col-span-12"
                  >
                    <div class="grid grid-cols-12 gap-6 mt-6 mb-6">
                      <h3 class="col-span-12 font-medium mb-2">
                        Elite Badge Settings
                      </h3>
                      <div class="col-span-12 lg:col-span-3">
                        <div>
                          <img
                            v-if="eventSettings.eliteBadgeSettings.image"
                            :src="eventSettings.eliteBadgeSettings.image"
                            class="w-40 cursor-pointer"
                            @click="initializeImageUploadWidget('elite')"
                          />
                          <button
                            v-if="!eventSettings.eliteBadgeSettings.image"
                            class="btn block btn-outline mt-2"
                            type="button"
                            @click="initializeImageUploadWidget('elite')"
                          >
                            <UploadIcon class="w-4 h-4 mr-2" /> Add Badge Image
                          </button>
                        </div>
                      </div>
                      <div class="col-span-12 lg:col-span-3">
                        <label
                          for="elite_badge_fundraising"
                          class="form-label placeholder-label relative w-full mt-3 text-base"
                        >
                          <input
                            id="elite_badge_fundraising"
                            v-model.number="
                              eventSettings.eliteBadgeSettings.fundraisingGoal
                            "
                            type="number"
                            min="100"
                            step="100"
                            class="form-control p-3"
                            placeholder=" "
                          />
                          <span
                            class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                            >Elite Badge Fundraising Goal</span
                          >
                        </label>
                      </div>
                      <div class="col-span-12 lg:col-span-3">
                        <label
                          for="elite_badge_participation"
                          class="form-label placeholder-label relative w-full mt-3 text-base"
                        >
                          <input
                            id="elite_badge_participation"
                            v-model.number="
                              eventSettings.eliteBadgeSettings.participationGoal
                            "
                            type="number"
                            min="0"
                            step="1"
                            class="form-control p-3"
                            placeholder=" "
                          />
                          <span
                            class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                            >Elite Badge Team Member Goal</span
                          >
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-12">
                    <button
                      type="submit"
                      class="btn btn-lg btn-primary w-20 mr-auto"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div class="intro-y box mt-2 p-6">
                <div>
                  <h2 class="col-span-12 text-base font-medium mb-4">
                    Fundraising & Participation Badges
                  </h2>
                  <button
                    v-if="!showBadgeForm"
                    class="btn btn-outline-primary mb-6"
                    type="button"
                    @click="toggleNewBadgeForm()"
                  >
                    Add New Badge
                  </button>
                  <div
                    v-if="showBadgeForm"
                    class="grid grid-cols-12 gap-6 border border-solid border-gray-200 p-6 mb-6"
                  >
                    <div class="col-span-12">
                      <h3 class="text-base font-medium">
                        Add New Badge
                      </h3>
                      <p class="italic">
                        Create a new event badge.
                      </p>
                    </div>
                    <div class="col-span-12 lg:col-span-3">
                      <label
                        for="badge_name"
                        class="form-label placeholder-label relative w-full mt-3 text-base"
                      >
                        <input
                          id="badge_name"
                          v-model="newBadge.name"
                          type="text"
                          class="form-control p-3"
                          placeholder=" "
                        />
                        <span
                          class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                          >Badge Name</span
                        >
                      </label>
                    </div>
                    <div class="col-span-12 lg:col-span-3">
                      <label for="badge_type" class="form-label sr-only">
                        Badge Type
                      </label>
                      <select
                        id="badge_type"
                        v-model.trim="newBadge.type"
                        class="form-select sm:mr-2 p-3 sm:mt-3 text-base"
                        aria-label="Badge Type"
                      >
                        <option value="" disabled selected
                          >Select Badge Type</option
                        >
                        <option value="participant">Participant</option>
                        <option value="fundraising">Fundraising</option>
                      </select>
                    </div>
                    <div class="col-span-12 lg:col-span-3">
                      <label
                        for="badge_type"
                        class="form-label placeholder-label relative w-full mt-3 text-base"
                      >
                        <input
                          id="button_text"
                          v-model.number="newBadge.goal"
                          name="badgeThreshold"
                          type="number"
                          min="5"
                          step="1"
                          class="form-control p-3"
                          placeholder=" "
                        />
                        <span
                          class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
                          >Badge Goal</span
                        >
                      </label>
                    </div>
                    <div class="col-span-12 lg:col-span-3 mt-4">
                      <button
                        v-if="!newBadge.image"
                        class="btn btn-outline"
                        type="button"
                        @click="initializeImageUploadWidget('badge')"
                      >
                        <UploadIcon class="w-4 h-4 mr-2" /> Add Badge Image
                      </button>
                      <img
                        v-if="newBadge.image"
                        class="w-40"
                        :src="newBadge.image"
                      />
                    </div>
                    <div
                      v-if="
                        newBadge.name &&
                          newBadge.type &&
                          newBadge.goal &&
                          newBadge.image
                      "
                      class="col-span-12"
                    >
                      <button
                        class="btn btn-outline-primary"
                        type="button"
                        @click="addBadge()"
                      >
                        Add Badge
                      </button>
                    </div>
                  </div>
                </div>
                <h2 class="col-span-12 text-base font-medium">
                  Current Badges
                </h2>
                <ul
                  v-if="eventSettings && eventSettings.eventBadges"
                  class="mt-2"
                >
                  <li
                    v-for="(badge, index) in eventSettings.eventBadges"
                    :key="index"
                  >
                    <div
                      class="grid grid-cols-12 gap-6 mb-2 border border-solid border-gray-400 p-3"
                    >
                      <div class="col-span-3">
                        <img
                          v-if="badge.image"
                          class="w-10"
                          :src="badge.image"
                        />
                      </div>
                      <div class="col-span-7">
                        <span class="block font-medium">{{ badge.name }}</span>
                        <span class="capitalize">{{ badge.type }}</span> -
                        <span>{{ badge.goal }}</span>
                      </div>
                      <div class="col-span-2 text-right">
                        <button
                          class="btn btn-sm btn-red mt-1"
                          type="button"
                          @click="removeBadge(index)"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
                <div v-if="eventSettings && eventSettings.eventBadges">
                  <h4 v-if="eventSettings.eventBadges.length === 0">
                    No event badges have been added.
                  </h4>
                </div>
                <div class="flex justify-end mt-8">
                  <button
                    type="submit"
                    class="btn btn-lg btn-primary w-20 mr-auto"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- END: Badges Content -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "@/store";
import { updateEventSettings } from "@/api/event-settings";

export default defineComponent({
  setup() {
    const validate = useVuelidate();
    const store = useStore();
    const eventSettings = computed(() => store.getters["main/eventSettings"]);

    const showBadgeForm = ref(false);

    const toggleNewBadgeForm = () => {
      showBadgeForm.value = true;
    };

    //Add Cloudinary to head
    if (!window.cloudinary) {
      let cloudinaryScript = document.createElement("script");
      cloudinaryScript.setAttribute(
        "src",
        "https://upload-widget.cloudinary.com/global/all.js"
      );
      document.head.appendChild(cloudinaryScript);
    }

    const newBadge = reactive({
      type: "",
      image: ""
    });

    const addBadge = () => {
      if (newBadge.name && newBadge.type) {
        saveBadgeToStore();
      }
    };

    const removeBadge = async index => {
      await store.dispatch("main/removeBadge", index);
    };

    const saveBadgeToStore = async () => {
      const badge = {};
      Object.assign(badge, newBadge);
      await store.dispatch("main/addBadge", badge);
      newBadge.name = "";
      newBadge.type = "";
      newBadge.goal = "";
      newBadge.image = "";
    };

    const sendNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "Event settings have been updated."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem updating the event settings."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const saveEventSettings = async () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        let response = {
          code: 400
        };
        await sendNotification(response);
      } else {
        const saveEventSettingsResult = await updateEventSettings(
          eventSettings.value
        );
        await sendNotification(saveEventSettingsResult);
      }
    };

    const setFacebookImage = async image => {
      await store.dispatch("main/setFacebookImage", image);
    };

    const setTeamEventImage = async image => {
      await store.dispatch("main/setTeamEventImage", image);
    };

    const setEmployerMatchImage = async image => {
      await store.dispatch("main/setEmployerMatchImage", image);
    };

    const setEliteBadgeImage = async image => {
      await store.dispatch("main/setEliteBadgeImage", image);
    };

    const initializeImageUploadWidget = async type => {
      // eslint-disable-next-line no-undef
      const imageWidget = await cloudinary.createUploadWidget(
        {
          cloudName: process.env.VUE_APP_CLOUDINARY_CONTAINER,
          uploadPreset: process.env.VUE_APP_CLOUDINARY_TEAM_IMAGE_PRESET,
          sources: ["local", "google_drive", "dropbox"],
          maxFileSize: 2100000,
          cropping: true,
          styles: {
            palette: {
              window: "#ffffff",
              sourceBg: "#f4f4f5",
              windowBorder: "#90a0b3",
              tabIcon: "#000000",
              inactiveTabIcon: "#555a5f",
              menuIcons: "#555a5f",
              link: "#0433ff",
              action: "#339933",
              inProgress: "#0433ff",
              complete: "#339933",
              error: "#cc0000",
              textDark: "#000000",
              textLight: "#fcfffd"
            }
          }
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
            console.log(type);
            if (type === "badge") {
              newBadge.image = result.info.secure_url;
            }
            if (type === "facebook") {
              setFacebookImage(result.info.secure_url);
            }
            if (type === "team") {
              setTeamEventImage(result.info.secure_url);
            }
            if (type === "employer") {
              setEmployerMatchImage(result.info.secure_url);
            }
            if (type === "elite") {
              setEliteBadgeImage(result.info.secure_url);
            }
          }
        }
      );

      imageWidget.open();
    };

    return {
      eventSettings,
      saveEventSettings,
      newBadge,
      addBadge,
      removeBadge,
      showBadgeForm,
      toggleNewBadgeForm,
      initializeImageUploadWidget
    };
  }
});
</script>
