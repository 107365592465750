<template>
  <div>
    <label
      for="first_name"
      class="form-label placeholder-label relative w-full mt-3 text-base"
    >
      <input
        id="first_name"
        v-model="validate.first_name.$model"
        type="text"
        class="form-control p-3"
        :class="{ 'border-theme-6': validate.first_name.$error }"
        placeholder=" "
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <span
        class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
        >First Name</span
      >
    </label>
    <div
      v-for="error of validate.first_name.$errors"
      :key="error.$uid"
      class="input-errors"
    >
      <div class="error-msg text-theme-6 mt-2">
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { required, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
  props: {
    firstName: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    touch: {
      type: Boolean,
      required: false
    },
    setToEmpty: {
      type: Boolean,
      required: false
    }
  },
  setup: () => ({ validate: useVuelidate() }),
  data() {
    return {
      first_name: this.firstName
    };
  },
  watch: {
    touch: function(val) {
      if (val) {
        this.validate.first_name.$touch();
      }
    },
    setToEmpty: function(val) {
      if (val) {
        console.log(val);
        this.first_name = "";
      }
    }
  },
  validations() {
    return {
      first_name: { required, minLength: minLength(2) }
    };
  },
  methods: {
    reloadComponent() {
      this.$forceUpdate();
    }
  }
});
</script>
