import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import globalComponents from "./global-components";
import utils from "./utils";
import "./libs";
import maska from "maska";

import VueGtag from "vue-gtag";

// SASS Theme
import "./assets/sass/app.scss";

const app = createApp(App)
  .use(store)
  .use(router);

globalComponents(app);
utils(app);

app.use(maska);

// Provide the Config Settings Here
// Inject them where needed
/*** Not really necessary if we are using Vuex, rather than using inject/provide just use Vuex store for settings */
/**
const eventConfig = store.state.main;
app.provide("$eventConfig", eventConfig);
*/

//Set the Event Config
const setConfig = async () => {
  await store.dispatch("main/setEventSettings");
};

setConfig();

const useGoogleAnaltyics = process.env.VUE_APP_USE_GA;
if (useGoogleAnaltyics > 0) {
  console.log("Use Google Analytics", process.env.VUE_APP_GA_ID);
  app.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GA_ID }
    },
    router
  );
}

app.mount("#app");
