import { fetch } from "@/api/fetch-auto-refresh";
import { useStore } from "@/store";
const apiURL = process.env.VUE_APP_API_URL;

const getEventSettings = async () => {
  const requestOptions = {
    method: "GET"
  };

  const response = await fetch(`${apiURL}/v1/event-settings/`, requestOptions);
  const data = await response.json();
  return data;
};

const updateEventSettings = async eventSettings => {
  const store = useStore();
  let authToken = store.state.auth.token;
  //Remove some properties
  delete eventSettings.createdAt;
  delete eventSettings.updatedAt;
  delete eventSettings.id;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    },
    body: JSON.stringify(eventSettings)
  };

  const response = await fetch(`${apiURL}/v1/event-settings/`, requestOptions);
  const data = await response.json();
  return data;
};

export { getEventSettings, updateEventSettings };
