<template>
  <div>
    <div class="grid grid-cols-12 gap-6">
      <!-- BEGIN: Settings Menu -->
      <EventConfigMenu class="mt-16 pt-1" />
      <!-- END: Settings Menu -->
      <router-view />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import EventConfigMenu from "./EventConfigMenu.vue";

export default defineComponent({
  components: {
    EventConfigMenu
  },
  setup() {
    return {};
  }
});
</script>
