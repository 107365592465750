import { fetch } from "@/api/fetch-auto-refresh";
import { useStore } from "@/store";
const store = useStore();
const apiURL = process.env.VUE_APP_API_URL;
const getParticipants = async eventYear => {
  let authToken = store.state.auth.token;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  let requestParams = "/v1/persons/?limit=1000&sortBy=createdAt:desc";

  if (eventYear) {
    requestParams = `/v1/persons/?eventYear=${eventYear}&limit=1000&sortBy=createdAt:desc`;
  }

  const response = await fetch(apiURL + requestParams, requestOptions);
  const data = await response.json();
  return data;
};

const updatePerson = async person => {
  let authToken = store.state.auth.token;
  let personId = person.id;

  if (person.lifeChurchCampus) {
    person.lifeChurchLocation = person.lifeChurchCampus.name;
  }

  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    },
    body: JSON.stringify(person)
  };

  const response = await fetch(
    `${apiURL}/v1/persons/${personId}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

const createPerson = async person => {
  let authToken = store.state.auth.token;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    },
    body: JSON.stringify(person)
  };

  const response = await fetch(`${apiURL}/v1/persons/`, requestOptions);
  const data = await response.json();
  return data;
};

const removePersonFromTeam = async (personId, team) => {
  let authToken = store.state.auth.token;

  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    },
    body: JSON.stringify(team)
  };

  const response = await fetch(
    `${apiURL}/v1/persons/remove/${personId}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

const getParticipant = async (participant, withTeam) => {
  let authToken = store.state.auth.token;
  let personID = participant;
  let request = personID;
  if (withTeam) {
    request = `${personID}?populateTeam=true`;
  }

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  const response = await fetch(
    `${apiURL}/v1/persons/${request}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

export {
  getParticipants,
  updatePerson,
  createPerson,
  removePersonFromTeam,
  getParticipant
};
