<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">All Teams</h2>
    <div
      class="intro-y flex flex-col sm:flex-row justify-between items-center mt-8"
    >
      <div class="mt-4 sm:mt-0">
        <form id="grid-search" class="mr-3">
          <div class="w-64 relative text-gray-700 dark:text-gray-300">
            <input
              v-model="searchQuery"
              type="text"
              class="form-control p-3 w-64 box pr-10 placeholder-theme-13"
              placeholder="Search..."
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </form>
      </div>
      <div>
        <strong>{{ totalResults }} Total Teams</strong>
      </div>
      <div>
        <router-link
          :to="{ name: 'new-team' }"
          class="btn btn-primary shadow-md"
        >
          Add New Team
        </router-link>
      </div>
    </div>
    <div class="intro-y mt-5">
      <TeamGrid :grid-data="formattedTeams" :query="searchQuery" />
    </div>
    <div class="mt-5 flex justify-center">
      <button v-show="page > 1" class="btn button mr-2" @click="prevPage">
        Prev Page
      </button>
      <button
        v-show="totalPages > 1 && page < totalPages"
        class="btn button mr-2"
        @click="nextPage"
      >
        Next Page
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getTeams } from "@/api/teams.js";
import TeamGrid from "@/components/back-end/TeamGrid";

export default defineComponent({
  components: {
    TeamGrid
  },
  setup() {
    let teamData = reactive([]);
    let formattedTeams = reactive([]);
    let searchQuery = ref("");
    let page = ref(1);
    let totalResults = ref(0);
    let totalPages = ref(0);

    const setTeams = async () => {
      if (!page.value) {
        page.value = 1;
      }
      const allTeams = await getTeams(page.value);
      totalPages.value = allTeams.totalPages;
      totalResults.value = allTeams.totalResults;
      Object.assign(teamData, allTeams);
      reformattedTeams(allTeams.results);
    };

    const reformattedTeams = theTeams => {
      let teams = theTeams;
      let formattedTeamData = [];
      teams.map(team => {
        let obj = {};
        obj.active = team.active;
        obj.id = team.id;
        obj.name = team.name;
        obj.participants = team.members.length;
        obj.raised = team.totalRaised;
        obj.city = team.eventCity;
        obj.captain = "Not Assigned";
        obj.email = "";

        if ("captainPerson" in team) {
          obj.captain =
            team.captainPerson.firstName + " " + team.captainPerson.lastName;
          obj.email = team.captainPerson.email;
        }

        formattedTeamData.push(obj);
      });
      Object.assign(formattedTeams, formattedTeamData);
    };

    const nextPage = () => {
      page.value++;
      setTeams();
    };

    const prevPage = () => {
      page.value--;
      setTeams();
    };

    onMounted(() => {
      setTeams();
    });

    return {
      teamData,
      formattedTeams,
      searchQuery,
      prevPage,
      nextPage,
      page,
      totalResults,
      totalPages
    };
  }
});
</script>
