<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Kindful Queue</h2>
    <div
      class="intro-y flex flex-col sm:flex-row justify-between items-center mt-8"
    >
      <div class="mt-4 sm:mt-0">
        <form id="grid-search" class="mr-3">
          <div class="w-64 relative text-gray-700 dark:text-gray-300">
            <input
              v-model="searchQuery"
              type="text"
              class="form-control p-3 w-64 box pr-10 placeholder-theme-13"
              placeholder="Search..."
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </form>
      </div>
      <div>
        <strong>{{ kindfulData.totalResults }} Total Kindful Reqeusts</strong>
      </div>
    </div>
    <div class="intro-y mt-5">
      <KindfulGrid
        :grid-data="formattedKindful"
        :query="searchQuery"
        @kindful-update="fetchKindful(page.value)"
      />
    </div>
    <div class="mt-5 flex justify-center">
      <button v-show="page > 1" class="btn button mr-2" @click="prevPage">
        Prev Page
      </button>
      <button class="btn button mr-2" @click="nextPage">Next Page</button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, reactive, ref } from "vue";
import { useStore } from "@/store";
import { getKindfulRequests } from "@/api/kindful.js";
import KindfulGrid from "@/components/back-end/KindfulGrid";

export default defineComponent({
  components: {
    KindfulGrid
  },
  setup() {
    const store = useStore();
    let kindfulData = reactive([]);
    let formattedKindful = reactive([]);
    let searchQuery = ref("");
    const appSettings = computed(() => store.getters["main/eventSettings"]);
    let page = ref(1);

    const setKindful = async eventYear => {
      if (!eventYear) {
        // eventYear = appSettings.value.appYear;
      }

      console.log(appSettings.value.appYear);

      if (!page.value) {
        page.value = 1;
      }

      const allKindful = await getKindfulRequests(
        // appSettings.value.appYear,
        null,
        page.value
      );
      Object.assign(kindfulData, allKindful);
      console.log(kindfulData);
      reformattedKindful(allKindful.results);
    };

    const nextPage = () => {
      page.value++;
      setKindful();
    };

    const prevPage = () => {
      page.value--;
      setKindful();
    };

    const reformattedKindful = kindful => {
      let kindfuls = kindful;
      let formattedKindfulData = [];
      kindfuls.map(kindful => {
        let createdDate = new Date(kindful.createdAt);
        let obj = {};
        obj.created = createdDate.toDateString();
        obj.id = kindful.id;
        obj.name = `${kindful.data.first_name} ${kindful.data.last_name}`;
        obj.email = kindful.data.email;
        obj.phone = kindful.data.primary_phone;
        obj.campaign = kindful.data.campaign;
        obj.fund = kindful.data.fund;
        obj.amount = kindful.data.amount_in_cents;
        obj.imported = kindful.imported;
        if (!kindful.offline) {
          obj.offline = "";
        } else {
          obj.offline = "Offline";
        }
        if (kindful.importResponse) {
          obj.status = kindful.importResponse.status;
          obj.jobId = kindful.importResponse.id;
        }

        formattedKindfulData.push(obj);
      });
      Object.assign(formattedKindful, formattedKindfulData);
    };

    onMounted(() => {
      setKindful();
    });

    const fetchKindful = () => {
      setKindful(null, page.value);
    };

    return {
      kindfulData,
      formattedKindful,
      searchQuery,
      fetchKindful,
      nextPage,
      prevPage,
      page
    };
  }
});
</script>
