<template>
  <div class="pt-6">
    <div v-if="supporting.type !== ''" class="cart px-5 sm:px-20 pt-1">
      <SupportCart :supporting="supporting" />
    </div>
    <div v-if="!hideContact" class="cart px-5 sm:px-20 pt-1">
      <ContactCart
        v-if="contact.first_name !== ''"
        class=""
        :contact="contact"
        :team-members="teamMembers"
        :show-team-members="showTeamMembers"
      />
    </div>
    <div v-if="donation.donation_amount > 0" class="cart px-5 sm:px-20 pt-1">
      <DonateCart :donation="donation" />
    </div>
    <div v-if="shirts.length > 0" class="cart px-5 sm:px-20 pt-1">
      <ShirtCart :shirts="shirts" />
    </div>
    <div v-if="runners.runners.length > 0" class="cart px-5 sm:px-20 pt-1">
      <RunCart :runners="runners.runners" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "@/store";
import SupportCart from "@/components/front-end/SupportCart";
import DonateCart from "@/components/front-end/DonateCart";
import ShirtCart from "@/components/front-end/ShirtCart";
import RunCart from "@/components/front-end/RunCart";
import ContactCart from "@/components/front-end/ContactCart";

export default defineComponent({
  components: {
    SupportCart,
    DonateCart,
    ShirtCart,
    RunCart,
    ContactCart
  },
  props: {
    hideContact: {
      type: Boolean,
      default: () => {
        return false;
      },
      required: false
    },
    showTeamMembers: {
      type: Boolean,
      default: () => {
        return false;
      },
      required: false
    }
  },
  setup() {
    const store = useStore();
    const supporting = store.state.support.supporting;
    const donation = store.state.donate.donation;
    const contact = store.state.contact.contact;
    const shirts = store.state.shirt.shirts;
    const runners = store.state.run.runData;
    const teamMembers = store.state.teamMembers.teamMembers;

    return {
      supporting,
      donation,
      contact,
      shirts,
      runners,
      teamMembers
    };
  }
});
</script>
