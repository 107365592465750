<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Runner Report</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <div class="hidden md:block mx-auto text-gray-600">
          {{ runnerRowData.totalResults }} Total Runners
        </div>
      </div>
    </div>
    <div class="intro-y mt-5 h-full col-span-12">
      <div class="mb-2 text-right">
        <button class="btn btn-outline mr-6" @click="resetFilters()">
          Reset Filters
        </button>
        <button class="btn btn-outline" @click="exportToCSV()">
          Download CSV export file
        </button>
      </div>
      <AgGridVue
        class="ag-theme-alpine w-full the-report-grid"
        :column-defs="columnDefs"
        :row-data="runnerRowData.results"
        :default-col-def="defaultColDef"
        @grid-ready="onGridReady"
      >
      </AgGridVue>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { getLineItems } from "@/api/reports.js";

export default defineComponent({
  components: {
    AgGridVue
  },
  setup() {
    let runnerRowData = reactive({});
    let gridApi = null;
    // eslint-disable-next-line no-unused-vars
    let gridColumnApi = null;

    let defaultColDef = {
      minWidth: 50,
      filter: true,
      resizable: true
    };

    const runnerRequest = {
      type: "5k",
      limit: 100
    };

    const setRunnerData = async () => {
      const theRunners = await getLineItems(runnerRequest);
      Object.assign(runnerRowData, theRunners);
    };

    onMounted(async () => {
      await setRunnerData();
    });

    const resetFilters = async () => {
      await setRunnerData();
    };

    const columnDefs = [
      {
        headerName: "Added",
        field: "createdAt",
        sortable: true,
        filter: "agDateColumnFilter",
        // eslint-disable-next-line no-undef
        filterParams: filterReportDate,
        // eslint-disable-next-line no-undef
        valueFormatter: dateFormatter,
        pinned: "left"
      },
      {
        headerName: "Last Name",
        field: "runnerDetails.lastName",
        sortable: true,
        filter: true,
        pinned: "left"
      },
      {
        headerName: "First Name",
        field: "runnerDetails.firstName",
        sortable: true,
        filter: true,
        pinned: "left"
      },
      {
        headerName: "Run Type",
        field: "runnerDetails.runType",
        sortable: true,
        filter: true
      },
      {
        headerName: "Gender",
        field: "runnerDetails.gender",
        sortable: true,
        filter: true
      },
      {
        headerName: "BirthDate",
        field: "runnerDetails.birthDate",
        sortable: true,
        filter: true
      },
      {
        headerName: "Shirt Size",
        field: "runnerDetails.shirtSize",
        sortable: true,
        filter: true
      },
      {
        headerName: "Registration",
        field: "runnerDetails.registrationCost"
      },
      {
        headerName: "Primary Contact",
        field: "primaryContact",
        sortable: true,
        filter: true
      },
      {
        headerName: "Shipping Address",
        field: "runnerDetails.shipping.address",
        sortable: true,
        filter: true
      },
      {
        headerName: "Shipping City",
        field: "runnerDetails.shipping.city",
        sortable: true,
        filter: true
      },
      {
        headerName: "Shipping State",
        field: "runnerDetails.shipping.state",
        sortable: true,
        filter: true
      },
      {
        headerName: "Shipping Zip",
        field: "runnerDetails.shipping.zipcode",
        sortable: true,
        filter: true
      }
    ];

    const onGridReady = params => {
      gridApi = params.api;
      gridColumnApi = params.columnApi;
    };

    const exportToCSV = () => {
      /** const params = {
        columnSeparator: "|"
      }; **/
      gridApi.exportDataAsCsv();
    };

    return {
      columnDefs,
      runnerRowData,
      onGridReady,
      exportToCSV,
      defaultColDef,
      resetFilters
    };
  }
});
let filterReportDate = {
  comparator: function(filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    dateAsString.substring(0, 10);
    var yearMonthDay = dateAsString.split("T");
    var dateParts = yearMonthDay[0].split("-");
    var cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2])
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }

    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true
};
window.dateFormatter = date => {
  let dateWithoutTime = date.value.split("T");
  return dateWithoutTime[0];
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
.the-report-grid {
  height: 800px;
}
</style>
