import * as types from "./mutation-types";

const state = () => {
  return {
    teamMembers: {
      quantity: 0,
      members: []
    },
    addedTeamMembers: []
  };
};

// getters
const getters = {
  teamMembers: state => state.teamMembers,
  addedTeamMebers: state => state.addedTeamMebers
};

// actions
const actions = {
  setTeamMembers: async ({ commit }, teamMembers) => {
    await commit(types.SET_TEAM_MEMBERS, { teamMembers });
  },
  setAddedTeamMembers: async ({ commit }, teamMembers) => {
    await commit(types.SET_ADDED_TEAM_MEMBERS, { teamMembers });
  },
  resetTeamMembers: async ({ commit }) => {
    await commit(types.RESET_TEAM_MEMBERS);
  }
};

// mutations
const mutations = {
  [types.SET_TEAM_MEMBERS](state, { teamMembers }) {
    state.teamMembers = teamMembers;
  },
  [types.SET_ADDED_TEAM_MEMBERS](state, { teamMembers }) {
    state.addedTeamMembers = teamMembers;
  },
  [types.RESET_TEAM_MEMBERS](state) {
    state.teamMembers = {
      quantity: 0,
      members: []
    };
    state.addedTeamMembers = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
