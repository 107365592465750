<template>
  <div>
    <label for="quantity" class="form-label mt-6">
      Quantity
    </label>
    <select
      id="size"
      v-model.trim="validate.quantity.$model"
      class="form-select sm:mr-2 p-3 text-base"
      aria-label="Size"
      :class="{ 'border-theme-6': validate.quantity.$error }"
      @change="$emit('update:modelValue', parseInt($event.target.value))"
    >
      <option value="1" selected>1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option></select
    >
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
  props: {
    quantityValue: {
      type: Number,
      default: () => {
        return 1;
      },
      required: false
    },
    touch: {
      type: Boolean,
      required: false
    }
  },
  setup: () => ({ validate: useVuelidate() }),
  data() {
    return {
      quantity: this.quantityValue
    };
  },
  watch: {
    quantityValue: function(val) {
      if (val) {
        this.quantity = val;
      }
    },
    touch: function(val) {
      if (val) {
        this.validate.quantity.$touch();
      }
    }
  },
  validations() {
    return {
      quantity: { required }
    };
  }
});
</script>
