<template>
  <div class="flex justify-center mt-6 mb-6">
    <!-- <router-link
      :to="to"
      class="btn text-gray-600 bg-white border-transparent justify-self-start"
    >
      Cancel
    </router-link> -->
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    to: {
      type: String,
      default: function() {
        return "/frontend/summary/";
      },
      required: false
    }
  }
});
</script>
