<template>
  <div>
    <input
      id="marketing"
      v-model.trim="send_marketing"
      class="form-check-input"
      type="checkbox"
      @change="$emit('update:marketing', $event.target.checked)"
    />
    <label class="form-check-label text-base" for="marketing"
      >I would like to receive updates and information from
      AutismOklahoma</label
    >
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    marketing: {
      type: Boolean,
      default: () => {
        return false;
      },
      required: true
    }
  },
  data() {
    return {
      send_marketing: this.marketing
    };
  }
});
</script>
