import { useStore } from "@/store";
const store = useStore();
import { useRouter } from "vue-router";
import { fetch } from "@/api/fetch-auto-refresh";

const apiURL = process.env.VUE_APP_API_URL;
// const ctctApiUrl = process.env.VUE_APP_CTCT_API_URL;
const ctctApiKey = process.env.VUE_APP_CTCT_API_KEY;
const ctctRedirectUri = process.env.VUE_APP_CTCT_REDIRECT_URI;

const connect = async code => {
  let authToken = store.state.auth.token;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    }
  };
  const params = new URLSearchParams({ code });
  const url = `${apiURL}/v1/constantcontact/connect?${params}`;
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  return data;
};

const startConnect = () => {
  const params = new URLSearchParams({
    client_id: ctctApiKey,
    state: "piecewalk",
    redirect_uri: ctctRedirectUri,
    response_type: "code",
    scope: "contact_data offline_access"
  });

  const url = `https://authz.constantcontact.com/oauth2/default/v1/authorize/?${params}`;
  const router = useRouter();
  router.push(url);
};

const disconnect = async () => {
  let authToken = store.state.auth.token;

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    }
  };

  const url = `${apiURL}/v1/constantcontact/disconnect`;
  await fetch(url, requestOptions);
};

const testRefreshToken = async () => {
  let authToken = store.state.auth.token;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    }
  };

  const url = `${apiURL}/v1/auth/test-refresh-token`;
  await fetch(url, requestOptions);
};

export { connect, startConnect, disconnect, testRefreshToken };
