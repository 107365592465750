<template>
  <div class="col-span-12">
    <div class="grid grid-cols-12 gap-4 gap-y-5">
      <!--Fundraising Badge Tip Url-->
      <div class="intro-y col-span-12 sm:col-span-12 event-name-field">
        <div class="fundraising-url">
          <label
            for="fundraising_url"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="fundraising_url"
              v-model="validate.fundraising_url.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.fundraising_url.$error }"
              placeholder=" "
              @input="$emit('update:fundraisingUrl', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Fundraising Tips Url</span
            >
          </label>
          <div
            v-for="error of validate.fundraising_url.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Participation Badge Tip Url-->
      <div class="intro-y col-span-12 sm:col-span-12 event-name-field">
        <div class="participation-url">
          <label
            for="participation_url"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="participation_url"
              v-model="validate.participation_url.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.participation_url.$error }"
              placeholder=" "
              @input="$emit('update:participationUrl', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Participation Tips Url</span
            >
          </label>
          <div
            v-for="error of validate.participation_url.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Submit an Event Url-->
      <div class="intro-y col-span-12 sm:col-span-12 event-name-field">
        <div class="submit-event-url">
          <label
            for="submit_event_url"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="submit_event_url"
              v-model="validate.submit_event_url.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.submit_event_url.$error }"
              placeholder=" "
              @input="$emit('update:submitEventUrl', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Submit an Event Url</span
            >
          </label>
          <div
            v-for="error of validate.submit_event_url.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { required, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
export default defineComponent({
  props: {
    fundraisingUrl: {
      type: String,
      default: () => {
        return "";
      },
      required: true
    },
    participationUrl: {
      type: String,
      default: () => {
        return "";
      },
      required: true
    },
    submitEventUrl: {
      type: String,
      default: () => {
        return "";
      },
      required: true
    },
    touch: {
      type: Boolean,
      required: false
    }
  },
  setup: () => ({ validate: useVuelidate() }),
  data() {
    return {
      fundraising_url: this.fundraisingUrl,
      participation_url: this.participationUrl,
      submit_event_url: this.submitEventUrl
    };
  },
  watch: {
    fundraisingUrl: function() {
      this.fundraising_url = this.fundraisingUrl;
    },
    participationUrl: function() {
      this.participation_url = this.participationUrl;
    },
    submitEventUrl: function() {
      this.submit_event_url = this.submitEventUrl;
    }
  },
  validations() {
    return {
      fundraising_url: { required, minLength: minLength(1) },
      participation_url: { required, minLength: minLength(1) },
      submit_event_url: { required, minLength: minLength(1) }
    };
  }
});
</script>
