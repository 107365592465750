// import * as types from "./mutation-types";
const state = () => {
  return {
    locations: [
      {
        name: "Broadway and Britton",
        value: 1
      },
      {
        name: "Central Support Office",
        value: 2
      },
      {
        name: "Edmond",
        value: 3
      },
      {
        name: "Midwest City",
        value: 4
      },
      {
        name: "Moore",
        value: 5
      },
      {
        name: "Mustang",
        value: 6
      },
      {
        name: "Norman",
        value: 7
      },
      {
        name: "NW Oklahoma City",
        value: 8
      },
      {
        name: "Oklahoma City",
        value: 9
      },
      {
        name: "South Oklahoma City",
        value: 10
      },
      {
        name: "Yukon",
        value: 11
      },
      {
        name: "Broken Arrow",
        value: 12
      },
      {
        name: "Catoosa",
        value: 13
      },
      {
        name: "Jenks",
        value: 14
      },
      {
        name: "Midtown Tulsa",
        value: 15
      },
      {
        name: "Owasso",
        value: 16
      },
      {
        name: "South Broken Arrow",
        value: 17
      },
      {
        name: "South Tulsa",
        value: 21
      },
      {
        name: "Shawnee",
        value: 18
      },
      {
        name: "Stillwater",
        value: 19
      },
      {
        name: "Church Online",
        value: 20
      }
    ]
  };
};

// getters
const getters = {
  donation: state => state.locations
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
