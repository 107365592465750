<template>
  <div class="col-span-12">
    <div class="grid grid-cols-12 gap-4 gap-y-5">
      <div class="intro-y col-span-12 sm:col-span-6 birth-date-field">
        <div class="birth-date">
          <label
            for="birth_date"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="birth_date"
              v-model="validate.birth_date.$model"
              type="date"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.birth_date.$error }"
              placeholder=" "
              @input="$emit('update:birthDate', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Birth Date</span
            >
          </label>
          <div
            v-for="error of validate.birth_date.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Gender-->
      <div class="intro-y col-span-12 sm:col-span-6 gender-field">
        <label for="runner_gender" class="form-label sr-only">
          Gender
        </label>
        <select
          id="runner_gender"
          v-model.trim="validate.runner_gender.$model"
          class="form-select sm:mr-2 p-3 mt-3 text-base"
          aria-label="Gender"
          :class="{ 'border-theme-6': validate.runner_gender.$error }"
          @change="$emit('update:gender', $event.target.value)"
        >
          <option value="" disabled selected>Select Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
  props: {
    gender: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    birthDate: {
      type: String,
      default: () => {
        return "";
      }
    },
    touch: {
      type: Boolean,
      required: false
    }
  },
  setup: () => ({ validate: useVuelidate() }),
  data() {
    return {
      runner_gender: this.gender,
      birth_date: this.birthDate
    };
  },
  watch: {
    touch: function(val) {
      if (val) {
        this.validate.runner_gender.$touch();
        this.validate.birth_date.$touch();
      }
    }
  },
  validations() {
    return {
      runner_gender: { required },
      birth_date: { required }
    };
  }
});
</script>
