<template>
  <div>
    <input
      :id="id"
      v-model.trim="virtual"
      class="form-check-input"
      type="checkbox"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <label class="form-check-label text-base" :for="id"
      >Run virtually
      <a
        href="javascript:;"
        name="virtual"
        class="tooltip ml-1 relative -top-1 bg-gray-700 text-white px-1 rounded-full text-xs font-bold inline-block"
        >?</a
      ></label
    >
    <div class="tooltip-content">
      <TippyContent to="virtual">
        <div class="font-medium dark:text-gray-300">
          Virtual 5k
        </div>
        <div class="flex items-center mt-2 sm:mt-0">
          <p>
            Quick info on Virtual 5k.
          </p>
        </div>
      </TippyContent>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isVirtual: {
      type: Boolean,
      default: () => {
        return false;
      },
      required: true
    },
    idHack: {
      type: Number,
      default: function() {
        return 0;
      },
      required: false
    }
  },
  data() {
    let data = {
      virtual: this.isVirtual,
      id: "virtual"
    };

    if (this.idHack > 0) {
      data.id = "virtual" + this.idHack;
    }
    return data;
  }
});
</script>
