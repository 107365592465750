<template>
  <div class="col-span-12">
    <div class="grid grid-cols-12 gap-4 gap-y-5">
      <!--Event Name-->
      <div class="intro-y col-span-12 sm:col-span-12 event-name-field">
        <div class="event-name">
          <label
            for="event_name"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="event_name"
              v-model="validate.event_name.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.event_name.$error }"
              placeholder=" "
              @input="$emit('update:eventName', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Event Name</span
            >
          </label>
          <div
            v-for="error of validate.event_name.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Event Year-->
      <div class="intro-y col-span-12 sm:col-span-12 event-name-field">
        <div class="event-year">
          <label
            for="event_year"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="event_year"
              v-model="validate.event_year.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.event_year.$error }"
              placeholder=" "
              @input="$emit('update:eventYear', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Event Year</span
            >
          </label>
          <div
            v-for="error of validate.event_year.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Event State-->
      <div class="intro-y col-span-12 sm:col-span-12 event-name-field">
        <div class="event-state">
          <label
            for="event_state"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="event_state"
              v-model="validate.event_state.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.event_state.$error }"
              placeholder=" "
              @input="$emit('update:eventState', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Event State</span
            >
          </label>
          <div
            v-for="error of validate.event_state.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Event Fundraising Goal-->
      <div class="intro-y col-span-12 sm:col-span-12 event-name-field">
        <div class="event-fundraising-goal">
          <label
            for="event_fundraising_goal"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="event_fundraising_goal"
              v-model.number="validate.event_fundraising_goal.$model"
              type="number"
              min="100"
              step="1"
              class="form-control p-3"
              :class="{
                'border-theme-6': validate.event_fundraising_goal.$error
              }"
              placeholder=" "
              @input="
                $emit(
                  'update:eventFundraisingGoal',
                  parseInt($event.target.value)
                )
              "
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Event Fundraising Goal</span
            >
          </label>
          <div
            v-for="error of validate.event_fundraising_goal.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Event Terms & Conditions-->
      <div class="intro-y col-span-12 sm:col-span-12 event-name-field">
        <div class="event-terms">
          <label
            for="event_terms"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="event_terms"
              v-model="validate.event_terms.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.event_terms.$error }"
              placeholder=" "
              @input="$emit('update:eventTerms', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Event Terms & Conditions Link</span
            >
          </label>
          <div
            v-for="error of validate.event_terms.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Public Event Site-->
      <div class="intro-y col-span-12 sm:col-span-12 event-name-field">
        <div class="event-site">
          <label
            for="event_site"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="event_terms"
              v-model="validate.public_event_site.$model"
              type="text"
              class="form-control p-3"
              :class="{ 'border-theme-6': validate.public_event_site.$error }"
              placeholder=" "
              @input="$emit('update:publicEventSite', $event.target.value)"
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Public Event Site (if embedding process)</span
            >
          </label>
          <div
            v-for="error of validate.public_event_site.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
      <!--Star of the Team Limit-->
      <div class="intro-y col-span-12 sm:col-span-12 event-name-field">
        <div class="star-of-the-team">
          <label
            for="star_of_the_team_limit"
            class="form-label placeholder-label relative w-full mt-3 text-base"
          >
            <input
              id="star_of_the_team_limit"
              v-model.number="validate.star_of_the_team_limit.$model"
              type="number"
              min="0"
              step="1"
              class="form-control p-3"
              :class="{
                'border-theme-6': validate.star_of_the_team_limit.$error
              }"
              placeholder=" "
              @input="
                $emit(
                  'update:starOfTheTeamLimit',
                  parseInt($event.target.value)
                )
              "
            />
            <span
              class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
              >Star of the Team Goal</span
            >
          </label>
          <div
            v-for="error of validate.star_of_the_team_limit.$errors"
            :key="error.$uid"
            class="input-errors"
          >
            <div class="error-msg text-theme-6 mt-2">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { required, minLength, numeric, minValue } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
export default defineComponent({
  props: {
    eventName: {
      type: String,
      default: () => {
        return "";
      },
      required: true
    },
    eventState: {
      type: String,
      default: () => {
        return "";
      },
      required: true
    },
    eventYear: {
      type: String,
      default: () => {
        return "";
      },
      required: true
    },
    eventFundraisingGoal: {
      type: Number,
      required: false,
      default: () => {
        return 100;
      }
    },
    starOfTheTeamLimit: {
      type: Number,
      required: false,
      default: () => {
        return 200;
      }
    },
    eventTerms: {
      type: String,
      default: () => {
        return "";
      },
      required: true
    },
    publicEventSite: {
      type: String,
      default: () => {
        return "";
      },
      required: true
    },
    touch: {
      type: Boolean,
      required: false
    }
  },
  setup: () => ({ validate: useVuelidate() }),
  data() {
    return {
      event_name: this.eventName,
      event_state: this.eventState,
      event_year: this.eventYear,
      event_fundraising_goal: this.eventFundraisingGoal,
      star_of_the_team_limit: this.starOfTheTeamLimit,
      event_terms: this.eventTerms,
      public_event_site: this.publicEventSite
    };
  },
  watch: {
    eventName: function() {
      this.event_name = this.eventName;
    },
    eventState: function() {
      this.event_state = this.eventState;
    },
    eventYear: function() {
      this.event_year = this.eventYear;
    },
    eventFundraisingGoal: function() {
      this.event_fundraising_goal = this.eventFundraisingGoal;
    },
    starOfTheTeamLimit: function() {
      this.star_of_the_team_limit = this.starOfTheTeamLimit;
    },
    eventTerms: function() {
      this.event_terms = this.eventTerms;
    },
    publicEventSite: function() {
      this.public_event_site = this.publicEventSite;
    }
  },
  validations() {
    return {
      event_name: { required, minLength: minLength(2) },
      event_state: { required, minLength: minLength(2) },
      event_year: { required, minLength: minLength(4) },
      event_fundraising_goal: { required, numeric, minValue: minValue(200) },
      star_of_the_team_limit: { required, numeric },
      event_terms: { required, minLength: minLength(1) },
      public_event_site: {}
    };
  }
});
</script>
