<template>
  <div class="col-span-12">
    <div class="grid grid-cols-12 gap-4 gap-y-5">
      <div class="intro-y col-span-12 sm:col-span-12 form-check mt-2 mb-2">
        <input
          :id="id"
          v-model.trim="lifeChurch"
          class="form-check-input"
          type="checkbox"
          @change="$emit('update:lifeChurch', $event.target.checked)"
        />
        <label class="form-check-label text-base" :for="id"
          >I attend Life.Church
          <a
            href="javascript:;"
            name="why-life-church"
            class="tooltip ml-1 relative -top-1 bg-gray-700 text-white px-1 rounded-full text-xs font-bold inline-block"
            >?</a
          ></label
        >
        <div class="tooltip-content">
          <TippyContent to="why-life-church">
            <div class="font-medium dark:text-gray-300">
              Why Life.Church?
            </div>
            <div class="flex items-center mt-2 sm:mt-0">
              <p>Life.Church provides matching funds to AutismOklahoma</p>
            </div>
          </TippyContent>
        </div>
      </div>
      <div
        v-if="isLifeChurch || lifeChurch"
        class="intro-y col-span-12 sm:col-span-12 form-check -mt-2 mb-2"
      >
        <div>
          <label for="campus" class="form-label text-xs">
            Select a Campus
          </label>
          <select
            id="campus"
            v-model="campus"
            class="form-select sm:mr-2 p-3 text-base"
            aria-label="Campus"
            :class="{ 'border-theme-6': validate.campus.$error }"
            @change="
              $emit('update:lifeChurchCampus', {
                value: parseInt(campus.value),
                name: campus.name
              })
            "
          >
            <option value="0" disabled selected
              >Select Life.Church Campus</option
            >
            <option
              v-for="camp in campusList"
              :key="camp.value"
              :value="{ value: camp.value, name: camp.name }"
            >
              {{ camp.name }}
            </option></select
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { requiredIf, minValue } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "@/store";
export default defineComponent({
  props: {
    isLifeChurch: {
      type: Boolean,
      default: function() {
        return false;
      },
      required: false
    },
    campusValue: {
      type: Object,
      default: () => {
        return {
          value: 0,
          name: "Select a Campus"
        };
      },
      required: false
    },
    idHack: {
      type: Number,
      default: function() {
        return 0;
      },
      required: false
    }
  },
  setup(props) {
    const validate = useVuelidate();

    const validCampusValue = computed(() => {
      if (props.isLifeChurch) {
        return 1;
      }
      return 0;
    });

    return {
      validate,
      validCampusValue
    };
  },
  data() {
    const store = useStore();
    const campuses = store.state.lifeChurchCampuses.locations;

    let data = {
      lifeChurch: this.isLifeChurch,
      id: "life-church",
      campus: this.campusValue,
      campusList: campuses
    };

    if (this.idHack > 0) {
      data.id = "life-church" + this.idHack;
    }

    return data;
  },
  watch: {
    isLifeChurch: function() {
      this.lifeChurch = this.isLifeChurch;
    },
    campusValue: function() {
      this.campus = this.campusValue;
    }
  },
  validations() {
    return {
      campus: {
        required: requiredIf(function() {
          return this.lifeChurch;
        }),
        value: { minValue: minValue(this.validCampusValue) }
      }
    };
  }
});
</script>
