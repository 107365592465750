<template>
  <div>
    <FrontEndHeader fill="#be2032" />
    <!-- BEGIN: Create TeamLayout -->
    <div v-show="showOverlay" class="overlay">
      <div class="h-full w-full flex justify-center items-center">
        <div class="intro-y box mt-5 p-6 max-w-3xl mx-6">
          <h3 class="text-center text-red uppercase font-bold text-xl">
            Notice
          </h3>
          <p class="text-base font-bold text-center mb-6">
            A user with that email already exists and is connected to: <br />
            <span class="font-normal">Team:</span> {{ foundTeam }}
          </p>
          <p class="text-base text-center mb-6">
            Because you already have a team connected to your account, we
            recommend that you REACTIVATE your team for this years event. This
            enables you to use past team information, reactivate past team
            members, set new goals. You can still update your personal
            information and even your team name.
          </p>
          <p class="text-base text-center font-bold">
            To reactivate your past team, you simply need to login to begin the
            process. If you have forgotten your password, you can use the Forgot
            Password process on the Login page to reset it.
          </p>
          <div class="text-center mt-6 mb-12">
            <router-link class="btn text-base btn-red" :to="`/login/`"
              >Login & Reactivate</router-link
            >
          </div>
          <p
            class="text-center underline cursor-pointer"
            @click="showOverlay = false"
          >
            No thanks! I want to start a brand new team
          </p>
        </div>
      </div>
    </div>
    <form class="validate-form" @submit.prevent="save">
      <div class="intro-y box mt-5 pb-10 max-w-3xl mx-auto">
        <div
          class="font-bold text-3xl px-5 sm:px-20 py-10 pb-5 sm:py-10 rounded-t"
        >
          {{ page.title }}
        </div>
        <div class="px-5 sm:px-20">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-3">
              <div v-show="!captainCompleted" class="px-0 sm:pr-8">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Your Captain Profile
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Please enter the following information to create your captain
                  profile for your team.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-3">
              <div
                v-show="captainCompleted"
                class="captain-summary flex justify-start items-center col-span-12 rounded intro-y border-2 border-solid border-theme-9 px-3 py-2"
              >
                <div class="inline-block">
                  <CheckCircleIcon
                    class="w-6 h-6 mr-4 fill-theme-9 inline-block"
                  />
                </div>
                <div class="captain-info inline-block flex-grow">
                  <h6 class="text-xs">Captain Profile</h6>
                  <h4 class="text-base">
                    {{ createTeamData.captain.firstName }}
                    {{ createTeamData.captain.lastName }}
                  </h4>
                </div>
                <div class="edit-captain-info justify-self-end">
                  <button
                    type="button"
                    class="btn btn-sm"
                    @click="setCaptainCompleted(false)"
                  >
                    Edit
                  </button>
                </div>
              </div>
              <div
                v-show="!captainCompleted"
                class="grid grid-cols-12 gap-4 gap-y-5"
              >
                <BasicInfo
                  v-model:firstName="createTeamData.captain.firstName"
                  v-model:lastName="createTeamData.captain.lastName"
                  v-model:emailAddress="createTeamData.captain.email"
                  v-model:phoneNumber="createTeamData.captain.phone"
                  v-model:myEmployer="createTeamData.captain.myEmployer"
                  :first-name="createTeamData.captain.firstName"
                  :last-name="createTeamData.captain.lastName"
                  :email-address="createTeamData.captain.email"
                  :phone-number="createTeamData.captain.phone"
                  :my-employer="createTeamData.captain.myEmployer"
                  show-employer
                />
                <Address
                  v-model:address="createTeamData.captain.address.address"
                  v-model:city="createTeamData.captain.address.city"
                  v-model:state="createTeamData.captain.address.state"
                  v-model:zipCode="createTeamData.captain.address.zipcode"
                  no-shipping
                  :address-value="createTeamData.captain.address.address"
                  :city-value="createTeamData.captain.address.city"
                  :state-value="createTeamData.captain.address.state"
                  :zipcode-value="createTeamData.captain.address.zipcode"
                />
                <div class="intro-y col-span-12">
                  <ShirtSize
                    v-model="createTeamData.captain.shirtSize"
                    class=""
                    :size-value="createTeamData.captain.shirtSize"
                    :shirt-sizes="shirt.size"
                  />
                  <div class="text-sm mt-1">
                    * See Incentive page to see how you can earn your t-shirt!
                  </div>
                </div>
                <div class="intro-y col-span-12 sm:col-span-6">
                  <PasswordField
                    v-model="createTeamData.captain.password.password"
                    :touch="validateFields"
                  />
                </div>
                <div class="intro-y col-span-12 sm:col-span-6">
                  <ConfirmPasswordField
                    v-model="createTeamData.captain.password.confirm"
                    :touch="validateFields"
                    :password="createTeamData.captain.password.password"
                  />
                </div>
                <div
                  v-show="
                    createTeamData.captain.password.password &&
                      createTeamData.captain.password.confirm
                  "
                  class="intro-y col-span-12 sm:col-span-12 form-check mt-2 mb-2 hidden"
                >
                  <LifeChurchField
                    v-model:lifeChurch="createTeamData.captain.lifeChurch"
                    v-model:lifeChurchCampus="
                      createTeamData.captain.lifeChurchCampus
                    "
                    :campus-value="createTeamData.captain.lifeChurchCampus"
                    :is-life-church="createTeamData.captain.lifeChurch"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-show="captainCompleted" aria-hidden="true">
            <div class="py-10">
              <div class="border-t border-gray-200" />
            </div>
          </div>
          <div v-if="captainCompleted" class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-3">
              <div class="px-0 sm:pr-8 intro-y">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Your Team
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Tell us your team name and a little bit about your team.
                </p>
                <p class="mt-4 text-sm text-gray-600 flex italic">
                  <InfoIcon class="w-6 mr-2" /> You can set your goals and add a
                  team photo after your team is created.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-3">
              <div class="grid grid-cols-12 gap-4 gap-y-5">
                <div class="intro-y col-span-12 sm:col-span-6">
                  <TeamNameField v-model="createTeamData.team.name" />
                </div>
                <div class="intro-y col-span-12 sm:col-span-6">
                  <TeamCityField v-model="createTeamData.team.eventCity" />
                </div>
                <div class="intro-y col-span-12 sm:col-span-12">
                  <TeamMessageField v-model="createTeamData.team.teamMessage" />
                  <p class="text-sm italic text-gray-600">
                    Tell us about your team and why you are creating one!
                  </p>
                </div>
                <div class="intro-y col-span-12 mt-5">
                  <input
                    id="terms"
                    v-model="terms"
                    class="form-check-input"
                    type="checkbox"
                    checked
                  />
                  <label class="form-check-label" for="terms"
                    >I agree to the
                    <a
                      class="text-theme-1 dark:text-theme-10"
                      target="_blank"
                      rel="noopener"
                      :href="termsAndConditionsUrl"
                      >Terms and Conditions</a
                    ></label
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="intro-y col-span-12 flex items-center justify-center mt-6"
          >
            <button
              v-show="!captainCompleted"
              type="button"
              class="btn block w-full py-3 sm:inline-block sm:w-auto btn-red border-reddark sm:ml-2 sm:px-10 btn-lg"
              :disabled="validate.$errors.length > 0"
              @click="checkUser(), toggleCaptainCompleted()"
            >
              Next
            </button>
            <button
              v-show="captainCompleted"
              :disabled="
                terms !== true || validate.$errors.length > 0 || processing
              "
              type="submit"
              class="btn btn-red block w-full py-3 sm:inline-block sm:w-auto sm:ml-2 btn-lg"
            >
              Create My Team!
            </button>
          </div>
        </div>
      </div>
    </form>
    <!-- END: Create Team Layout -->
    <!-- BEGIN: Success Notification Content -->
    <div id="success-notification-content" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Create a Team success!</div>
        <div class="text-gray-600 mt-1">
          Please login using the information you provided when creating your
          team!
        </div>
      </div>
    </div>
    <!-- END: Success Notification Content -->
    <!-- BEGIN: Failed Notification Content -->
    <div id="failed-notification-content" class="toastify-content hidden flex">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">We failed to create your team!</div>
        <div class="text-gray-600 mt-1">
          Please check the form for details.
        </div>
      </div>
    </div>
    <!-- END: Failed Notification Content -->
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  toRefs
} from "vue";
import { useStore } from "@/store";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import { createTeam } from "@/api/teams.js";
import { userExistsWithTeam } from "@/api/users.js";
import BasicInfo from "@/components/form-inputs/BasicInfo";
import Address from "@/components/form-inputs/Address";
import ShirtSize from "@/components/form-inputs/ShirtSize";
import PasswordField from "@/components/form-inputs/Password";
import ConfirmPasswordField from "@/components/form-inputs/PasswordConfirm";
import TeamNameField from "@/components/form-inputs/TeamName";
import TeamCityField from "@/components/form-inputs/TeamCity";
import TeamMessageField from "@/components/form-inputs/TeamMessage";
import LifeChurchField from "@/components/form-inputs/LifeChurch";
import FrontEndHeader from "@/components/front-end/FrontEndHeader";

export default defineComponent({
  components: {
    BasicInfo,
    Address,
    PasswordField,
    ConfirmPasswordField,
    TeamNameField,
    TeamCityField,
    TeamMessageField,
    LifeChurchField,
    FrontEndHeader,
    ShirtSize
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const captainCompleted = ref(false);
    let validateFields = ref(false);
    let terms = ref(true);
    let processing = ref(false);

    const termsAndConditionsUrl = store.state.main.termsAndConditionsUrl;

    const shirt = reactive({
      size: ["Small", "Medium", "Large", "XLarge", "2XLarge", "3XLarge"]
    });

    const page = {
      title: "Create Your Team"
    };

    onMounted(() => {
      cash("body")
        .removeClass("main login")
        .removeClass("error-page")
        .addClass("frontend create-team");
    });

    const createTeamData = reactive({
      captain: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        myEmployer: "",
        password: {
          password: "",
          confirm: ""
        },
        address: {
          state: "OK"
        },
        shirtSize: ""
      },
      team: {
        name: "",
        teamMessage: "",
        eventCity: ""
      }
    });

    const rules = computed(() => {
      return {};
    });

    const validate = useVuelidate(rules, toRefs(createTeamData));
    //just made this save async
    const save = async () => {
      processing.value = true;
      validate.value.$touch();
      if (validate.value.$invalid) {
        processing.value = false;
        Toastify({
          node: cash("#failed-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      } else {
        /**
         * Todo: If this returns an error we need to stop
         * Test: Put in an email that matches in the database
         */
        let createTeamResponse = await createTeam(createTeamData);
        if (createTeamResponse.code) {
          alert(createTeamResponse.message);
          processing.value = false;
          Toastify({
            node: cash("#failed-notification-content")
              .clone()
              .removeClass("hidden")[0],
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true
          }).showToast();
          return;
        }

        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();

        /**
         * Todo: Is there a bettert way to handle this class swapping?
         * Probably need to look into a different template for frontend instead
         */
        cash("body")
          .removeClass("error-page")
          .removeClass("frontend create-team")
          .addClass("main");
        /**
         * Todo:Ultimately needs to go to My Team
         */
        router.push({ path: "/" });
      }
    };

    const showOverlay = ref(false);
    const foundTeam = ref("");

    const checkUser = async () => {
      const userExists = await userExistsWithTeam(createTeamData.captain.email);
      if (userExists && userExists.user) {
        //Alert the User
        showOverlay.value = true;
        foundTeam.value = userExists.team;
      }
    };

    return {
      page,
      captainCompleted,
      validate,
      createTeamData,
      validateFields,
      terms,
      termsAndConditionsUrl,
      processing,
      save,
      checkUser,
      showOverlay,
      foundTeam,
      shirt
    };
  },
  methods: {
    setCaptainCompleted(val) {
      this.captainCompleted = val;
    },
    toggleCaptainCompleted() {
      //Check Captain Fields
      this.validate.$touch();
      this.validateFields = true;
      if (this.validate.$errors.length < 1) {
        this.captainCompleted = !this.captainCompleted;
      }
    }
  }
});
</script>

<style scoped>
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
</style>
