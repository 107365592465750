<template>
  <div
    class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
  >
    <div class="flex flex-1 px-5 items-center justify-center lg:justify-start">
      <div
        class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative"
      >
        <div
          v-if="!team.imageUrl"
          class="text-xs text-center h-full w-full flex justify-center items center flex-col rounded-full bg-gray-200 cursor-pointer"
          @click="initializeTeamImageUploadWidget"
        >
          <UploadIcon class="w-4 h-4 mx-auto -mb-3" /> <br />
          Upload <br />
          Team Image
        </div>
        <div
          v-if="team.imageUrl"
          :style="{
            'background-image': 'url(' + setImageSize(team.imageUrl) + ')'
          }"
          class="h-full w-full rounded-md cursor-pointer bg-center bg-cover"
          @click="initializeTeamImageUploadWidget"
        ></div>
      </div>
      <div class="ml-5">
        <div
          class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg"
        >
          {{ team.name }}
        </div>
        <div class="text-gray-600">{{ team.eventCity }}</div>
        <button
          type="button"
          class="btn w-full mt-3 btn-outline-gray-200 btn-sm px-0 md:w-32"
          @click="initializeTeamImageUploadWidget"
        >
          Change Team Image
        </button>
      </div>
    </div>
    <div
      class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
    >
      <div class="font-medium text-center lg:text-left lg:mt-3">
        Contact Details
      </div>
      <div
        class="flex flex-col justify-center items-center lg:items-start mt-4"
      >
        <div class="md:grid md:grid-cols-2 md:gap-6">
          <div v-if="team.captainPerson" class="md:col-span-6">
            <div class="truncate sm:whitespace-normal flex items-center">
              <UserIcon class="w-4 h-4 mr-2" />
              {{ team.captainPerson.firstName }}
              {{ team.captainPerson.lastName }}
            </div>
            <div class="truncate sm:whitespace-normal flex items-center mt-3">
              <MailIcon class="w-4 h-4 mr-2" />
              {{ team.captainPerson.email }}
            </div>
            <div class="truncate sm:whitespace-normal flex items-center mt-3">
              <PhoneIcon class="w-4 h-4 mr-2" />
              {{ team.captainPerson.phone }}
            </div>
          </div>
          <div v-else>
            <p class="text-red">Captain Unassigned</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0"
    >
      <div class="text-center rounded-md w-20 py-3">
        <div class="font-medium text-theme-1 dark:text-theme-10 text-xl">
          ${{ team.totalRaised }}
        </div>
        <div class="text-gray-600">Raised</div>
      </div>
      <div class="text-center rounded-md w-20 py-3">
        <div
          v-if="team.members"
          class="font-medium text-theme-1 dark:text-theme-10 text-xl"
        >
          {{ team.members.length }}
        </div>
        <div class="text-gray-600">Participants</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    team: {
      type: Object,
      default: () => {
        return {};
      },
      required: false
    }
  },
  setup(props, { emit }) {
    let teamImage = ref(props.team.imageUrl);
    const initializeTeamImageUploadWidget = async () => {
      // eslint-disable-next-line no-undef
      const teamImageWidget = await cloudinary.createUploadWidget(
        {
          cloudName: process.env.VUE_APP_CLOUDINARY_CONTAINER,
          uploadPreset: process.env.VUE_APP_CLOUDINARY_TEAM_IMAGE_PRESET,
          sources: ["local", "google_drive", "dropbox"],
          maxFileSize: 7100000,
          cropping: true,
          showSkipCropButton: false,
          styles: {
            palette: {
              window: "#ffffff",
              sourceBg: "#f4f4f5",
              windowBorder: "#90a0b3",
              tabIcon: "#000000",
              inactiveTabIcon: "#555a5f",
              menuIcons: "#555a5f",
              link: "#0433ff",
              action: "#339933",
              inProgress: "#0433ff",
              complete: "#339933",
              error: "#cc0000",
              textDark: "#000000",
              textLight: "#fcfffd"
            }
          }
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
            console.log(result.info);
            teamImage.value = result.info.secure_url;
            let teamData = {
              imageUrl: teamImage.value
            };

            emit("updateTeamData", teamData);
          }
        }
      );

      teamImageWidget.open();
    };

    const setImageSize = imageUrl => {
      const path = imageUrl;
      const withSizing = path.replace(/\/upload\//, "/upload/w_300/");
      return withSizing;
    };

    return {
      teamImage,
      initializeTeamImageUploadWidget,
      setImageSize
    };
  }
});
</script>
