<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">T-Shirt Report</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <div class="hidden md:block mx-auto text-gray-600">
          {{ productRowData.totalResults }} Total Shirt Line Items
        </div>
      </div>
    </div>
    <div class="intro-y mt-5 h-full col-span-12">
      <div class="grid grid-cols-12 gap-6 mt-5 mb-5">
        <div class="col-span-3">
          <select
            v-model="selectedYear"
            class="form-select text-base"
            @change="updateProductData(selectedYear)"
          >
            <option
              v-for="(year, index) in eventYears"
              :key="index"
              :value="year"
            >
              {{ year }}
            </option>
          </select>
        </div>
        <div class="col-span-9">
          <div class="mb-2 text-right">
            <button class="btn btn-outline mr-6" @click="resetFilters()">
              Reset Filters
            </button>
            <button class="btn btn-outline" @click="exportToCSV()">
              Download CSV export file
            </button>
          </div>
        </div>
      </div>
      <AgGridVue
        class="ag-theme-alpine w-full the-report-grid"
        :column-defs="columnDefs"
        :row-data="productRowData.results"
        :default-col-def="defaultColDef"
        @grid-ready="onGridReady"
      >
      </AgGridVue>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { getLineItems } from "@/api/reports.js";
import { eventYears } from "@/api/event-years.js";

export default defineComponent({
  components: {
    AgGridVue
  },
  setup() {
    let productRowData = reactive({});
    let gridApi = null;
    // eslint-disable-next-line no-unused-vars
    let gridColumnApi = null;

    let defaultColDef = {
      minWidth: 50,
      filter: true,
      resizable: true
    };

    let selectedYear = ref(eventYears[0]);

    const productRequest = {
      type: "tshirt",
      eventYear: eventYears[0],
      limit: 250
    };

    const setProductData = async () => {
      const theProducts = await getLineItems(productRequest);
      Object.assign(productRowData, theProducts);
    };

    const updateProductData = async eventYear => {
      let updateProductRequest = Object.assign({}, productRequest);
      updateProductRequest.eventYear = eventYear;
      const theProducts = await getLineItems(updateProductRequest);
      Object.assign(productRowData, theProducts);
    };

    const resetFilters = async () => {
      selectedYear.value = eventYears[0];
      await setProductData();
    };

    onMounted(async () => {
      await setProductData();
    });

    const columnDefs = [
      {
        headerName: "Added",
        field: "createdAt",
        sortable: true,
        filter: "agDateColumnFilter",
        // eslint-disable-next-line no-undef
        filterParams: filterReportDate,
        // eslint-disable-next-line no-undef
        valueFormatter: dateFormatter,
        pinned: "left"
      },
      {
        headerName: "Contact",
        field: "primaryContact",
        sortable: true,
        filter: true,
        pinned: "left"
      },
      {
        headerName: "Name",
        field: "productDetails.name",
        sortable: true,
        filter: true
      },
      {
        headerName: "Size",
        field: "productDetails.size",
        sortable: true,
        filter: true
      },
      {
        headerName: "Quantity",
        field: "productDetails.unitCount",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "Price per Unit",
        field: "productDetails.pricePerUnit",
        sortable: true,
        filter: true
      },
      {
        headerName: "Shipping Address",
        field: "shippingAddress.address",
        sortable: true,
        filter: true
      },
      {
        headerName: "Shipping City",
        field: "shippingAddress.city",
        sortable: true,
        filter: true
      },
      {
        headerName: "Shipping State",
        field: "shippingAddress.state",
        sortable: true,
        filter: true
      },
      {
        headerName: "Shipping Zip",
        field: "shippingAddress.zipcode",
        sortable: true,
        filter: true
      }
    ];

    const onGridReady = params => {
      gridApi = params.api;
      gridColumnApi = params.columnApi;
    };

    const exportToCSV = () => {
      /** const params = {
        columnSeparator: "|"
      }; **/
      gridApi.exportDataAsCsv();
    };

    return {
      columnDefs,
      productRowData,
      onGridReady,
      exportToCSV,
      defaultColDef,
      resetFilters,
      updateProductData,
      eventYears,
      selectedYear
    };
  }
});
let filterReportDate = {
  comparator: function(filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    dateAsString.substring(0, 10);
    var yearMonthDay = dateAsString.split("T");
    var dateParts = yearMonthDay[0].split("-");
    var cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2])
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }

    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true
};
window.dateFormatter = date => {
  let dateWithoutTime = date.value.split("T");
  return dateWithoutTime[0];
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
.the-report-grid {
  height: 800px;
}
</style>
