<template>
  <div class="overflow-hidden min-h-screen xl:h-auto">
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="xl:hidden mx-auto">
          <router-link
            :to="{ name: 'my-team' }"
            tag="a"
            class="intro-x flex justify-center items-center pt-20"
          >
            <Logo class="w-6" :fill="fill" />
            <span class="text-white text-lg ml-3">
              {{ appTitle }}
            </span>
          </router-link>
        </div>
        <div class="hidden xl:flex flex-col min-h-screen">
          <router-link
            :to="{ name: 'my-team' }"
            tag="a"
            class="intro-x flex items-center pl-5 pt-4"
          >
            <Logo class="w-6" :fill="fill" />
            <span class="hidden xl:block text-white text-lg ml-3">
              {{ appTitle }}
            </span>
          </router-link>
          <div class="my-auto">
            <div class="-intro-x text-white font-medium text-4xl leading-tight">
              {{ appYear }} {{ appState }} {{ appTitle }}
            </div>
            <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
            >
              Manage your team by signing in to your account.
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <form
          v-show="showLogin"
          class="xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0"
          @submit.prevent="login"
        >
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Sign In
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              Manage your team by signing in to your account.
            </div>
            <div class="intro-x mt-8">
              <input
                v-model="loginData.email"
                type="text"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                placeholder="Email"
              />
              <input
                v-model="loginData.password"
                :type="showPassword ? 'text' : 'password'"
                autocomplete="on"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                placeholder="Password"
              />
            </div>
            <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
              <div class="flex items-center mr-auto">
                <input
                  id="show_password"
                  class="form-check-input border mr-2"
                  type="checkbox"
                  @change="toggleShowPassword()"
                />
                <label class="cursor-pointer select-none" for="show_password"
                  >Show Password</label
                >
                <!--<input
                  id="remember-me"
                  type="checkbox"
                  class="form-check-input border mr-2"
                />
                <label class="cursor-pointer select-none" for="remember-me"
                  >Remember me</label
                >-->
              </div>
              <button type="button" @click="toggleLogin()">
                Forgot Password?
              </button>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                type="submit"
                class="btn btn-red py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
              >
                Login
              </button>
              <router-link
                to="/frontend/create-a-team"
                class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
              >
                Create a Team
              </router-link>
            </div>
            <div
              v-if="error.status"
              class="warning mt-6 text-red pl-4 bg-red text-white font-bold text-base p-6 py-3"
            >
              <p class="text-white">{{ error.note }}</p>
            </div>
            <div
              v-if="error.status"
              class="error mt-6 font-bold text-red text-base pl-4 border-l-solid border-l-2 border-red"
            >
              <p>{{ error.message }}</p>
            </div>
            <div
              class="intro-x mt-10 xl:mt-10 text-gray-700 dark:text-gray-600 text-center xl:text-left"
            >
              By signin up, you agree to our <br />
              <a
                class="text-theme-1 dark:text-theme-10"
                target="_blank"
                rel="noopener"
                :href="termsAndConditionsUrl"
                >Terms and Conditions</a
              >
            </div>
          </div>
        </form>
        <!-- END: Login Form -->
        <form
          v-show="!showLogin"
          class="xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0"
          @submit.prevent="sendForgotPassword"
        >
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Forgot Password
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              Enter your email to receive an email link to reset your password.
            </div>
            <div class="intro-x mt-8 w-full lg:w-96">
              <Email
                v-model="forgotPasswordData.email"
                :email-address="forgotPasswordData.email"
              />
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="btn btn-red py-3 px-4 w-full align-top"
                type="submit"
                :disabled="validate.$errors.length > 0"
              >
                Send Reset Email
              </button>
            </div>
            <div
              v-if="error.status"
              class="warning mt-6 text-red pl-4 bg-red text-white font-bold text-base p-6 py-3"
            >
              <p class="text-white">{{ error.note }}</p>
            </div>
            <div
              v-if="error.status"
              class="error mt-6 font-bold text-red text-base pl-4 border-l-solid border-l-2 border-red"
            >
              <p>{{ error.message }}</p>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="btn btn-outline py-3 px-4 w-full align-top mt-3"
                type="button"
                @click="toggleLogin()"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import {
  defineComponent,
  onMounted,
  toRefs,
  reactive,
  ref,
  computed
} from "vue";
import { useStore } from "@/store";
import Logo from "@/components/front-end/Logo";
import Email from "@/components/form-inputs/Email";
import { authLogin, authForgotPassword } from "@/api/auth.js";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default defineComponent({
  components: {
    Logo,
    Email
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const appTitle = computed(() => store.getters["main/appTitle"]);
    const appYear = computed(() => store.getters["main/appYear"]);
    const appState = computed(() => store.getters["main/appState"]);
    const termsAndConditionsUrl = computed(
      () => store.state.main.termsAndConditionsUrl
    );
    const fill = "#FFFFFF";
    let showLogin = ref(true);

    const showPassword = ref(false);
    const toggleShowPassword = () => {
      showPassword.value = !showPassword.value;
    };

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .removeClass("frontend create-team")
        .addClass("login");
    });

    const loginData = reactive({
      email: "",
      password: ""
    });

    const forgotPasswordData = reactive({
      email: ""
    });

    const error = reactive({
      message: "",
      note: "",
      status: false
    });

    const toggleLogin = () => {
      showLogin.value = !showLogin.value;
      error.status = false;
    };

    const login = async () => {
      const loginResponse = await authLogin(loginData);
      if (loginResponse.message) {
        error.message = loginResponse.message;
        error.note =
          "NOTE: If you have forgotten your password, click the Forgot Password link above and we will send you reset instructions.";
        error.status = true;
      } else {
        await store.dispatch("auth/setAuth", loginResponse);
        error.message = "";
        error.note = "";
        error.status = false;
        const isAdmin = await store.getters["auth/isAdmin"];
        if (isAdmin) {
          router.push({ path: "/teams" });
        } else {
          router.push({ path: "/" });
        }
      }
    };

    const sendForgotPassword = async () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        console.log("invalid");
      } else {
        const forgotPasswordResponse = await authForgotPassword(
          forgotPasswordData.email
        );
        if (forgotPasswordResponse.message) {
          error.message = forgotPasswordResponse.message;
          error.note = "That email does not exist in our system.";
          error.status = true;
        } else {
          error.message = "An email has been sent.";
          error.note = "";
          error.status = true;
          showLogin.value = true;
          let forgotPasswordReset = {
            email: ""
          };
          Object.assign(forgotPasswordData, forgotPasswordReset);
        }
      }
    };

    const rules = computed(() => {
      return {
        email: { required, email }
      };
    });

    const validate = useVuelidate(rules, toRefs(forgotPasswordData));

    return {
      appTitle,
      appState,
      appYear,
      termsAndConditionsUrl,
      fill,
      loginData,
      forgotPasswordData,
      sendForgotPassword,
      login,
      showLogin,
      toggleLogin,
      validate,
      error,
      showPassword,
      toggleShowPassword
    };
  }
});
</script>
