import { createRouter, createWebHistory } from "vue-router";
import SideMenu from "../layouts/side-menu/Main.vue";

/**
 * Crud
 */
// import CrudDataList from "../views/crud/crud-data-list/Main.vue";
// import CrudForm from "../views/crud/crud-form/Main.vue";

/**
 * Piecewalk
 */
// import DashboardOverview from "../views/piecewalk/dashboard-overview/Main.vue";
import Teams from "../views/piecewalk/teams/AllTeams.vue";
import NewTeam from "../views/piecewalk/teams/Team.vue";
import Users from "../views/piecewalk/users/Users.vue";
import User from "../views/piecewalk/users/User.vue";
import Transactions from "../views/piecewalk/transactions/Transactions.vue";
import Transaction from "../views/piecewalk/transactions/Transaction.vue";
import Participants from "../views/piecewalk/participants/Participants.vue";
import Participant from "../views/piecewalk/participants/Participant.vue";

// Config and Settings
import EventSettings from "../views/piecewalk/event-config/EventSettings.vue";
import RunSettings from "../views/piecewalk/event-config/RunSettings.vue";
import ProductSettings from "../views/piecewalk/event-config/ProductSettings.vue";
import CaptainToolsSettings from "../views/piecewalk/event-config/CaptainsToolsSettings.vue";
import CaptainAlerts from "../views/piecewalk/event-config/CaptainAlerts.vue";
import BadgeSettings from "../views/piecewalk/event-config/BadgeSettings.vue";
import ConstantContactSettings from "../views/piecewalk/event-config/ConstantContactSettings.vue";
import DebugSettings from "../views/piecewalk/event-config/DebugSettings.vue";
import ConfigPage from "../views/piecewalk/event-config/ConfigPage.vue";

import Team from "../views/piecewalk/team/Team.vue";
import CreateTeam from "../views/piecewalk/create-team/Main.vue";
import Login from "../views/piecewalk/login/Main.vue";
// import Register from "../views/piecewalk/register/Main.vue";
import ErrorPage from "../views/piecewalk/error-page/Main.vue";
import Deactivated from "../views/piecewalk/deactivated/Deactivated.vue";
import Invoice from "../views/piecewalk/invoice/Invoice.vue";

import Donors from "../views/piecewalk/donations/Donations.vue";
import Runners from "../views/piecewalk/runners/Runners.vue";
import TShirts from "../views/piecewalk/products/Products.vue";

import MyProfile from "../views/piecewalk/profile/Profile.vue"; // Parent Route
import ProfileEdit from "../views/piecewalk/profile/ProfileEdit.vue";
import ChangePassword from "../views/piecewalk/profile/ChangePassword.vue";
import Kindful from "../views/piecewalk/kindful/Kindful.vue";
import Icon from "../views/_archived/components/icon/Main.vue";

import EmailTeam from "../views/piecewalk/team/email-team/EmailTeam.vue";

//Reports
import LifeChurchReport from "../views/piecewalk/reports/LifeChurchReport.vue";
import RunnerReport from "../views/piecewalk/reports/RunnerReport.vue";
import TeamsReport from "../views/piecewalk/reports/TeamsReport.vue";
import ProductReport from "../views/piecewalk/reports/ProductReport.vue";
import DonationsReport from "../views/piecewalk/reports/DonationsReport.vue";
import TransactionsReport from "../views/piecewalk/reports/TransactionsReport.vue";
import DailyReports from "../views/piecewalk/reports/DailyReport.vue";

/**
 * Pages
 */
// import Calendar from "../views/pages/calendar/Main.vue";
// import Post from "../views/pages/post/Main.vue";
// import Blog from "../views/pages/blog-layout/Main.vue";
// import Faq from "../views/pages/faq-layout/Main.vue";

/**
 * Components
 */
/** import RegularTable from "../views/components/regular-table/Main.vue";
import Tabulator from "../views/components/tabulator/Main.vue";
import Accordion from "../views/components/accordion/Main.vue";
import Button from "../views/components/button/Main.vue";
import Modal from "../views/components/modal/Main.vue";
import SlideOver from "../views/components/slide-over/Main.vue";
import Notification from "../views/components/notification/Main.vue";
import Alert from "../views/components/alert/Main.vue";
import ProgressBar from "../views/components/progress-bar/Main.vue";
import Tooltip from "../views/components/tooltip/Main.vue";
import Dropdown from "../views/components/dropdown/Main.vue";
import Typography from "../views/components/typography/Main.vue";
import LoadingIcon from "../views/components/loading-icon/Main.vue";
** /

/**
 * Forms
 */
/** import RegularForm from "../views/forms/regular-form/Main.vue";
import Datepicker from "../views/forms/datepicker/Main.vue";
import TailSelect from "../views/forms/tail-select/Main.vue";
import FileUpload from "../views/forms/file-upload/Main.vue";
import WysiwygEditor from "../views/forms/wysiwyg-editor/Main.vue";
import Validation from "../views/forms/validation/Main.vue"; **/

/**
 * Widgets
 */
/** import Chart from "../views/widgets/chart/Main.vue";
import Slider from "../views/widgets/slider/Main.vue"; **/

/**
 * Frontend
 */

import TeamCreateFrontEnd from "../views/frontend/create-team/Main.vue";
import Supporting from "../views/frontend/supporting/Main.vue";
import Donate from "../views/frontend/donate/Main.vue";
import Contact from "../views/frontend/contact/Main.vue";
import Join from "../views/frontend/join-team/Main.vue";
// import Shirt from "../views/frontend/t-shirt/Main.vue";
import ProductsGrid from "../views/frontend/products/ProductsGrid.vue";
import Product from "../views/frontend/products/Product.vue";
import Run from "../views/frontend/run/Main.vue";
import AddTeamMember from "../views/frontend/add-team-member/Main.vue";
import Summary from "../views/frontend/summary/Main.vue";
import Checkout from "../views/frontend/checkout/Main.vue";
import ThankYou from "../views/frontend/thank-you/Main.vue";
import ConstantContact from "../views/frontend/constant-contact/Main.vue";

import ResetPassword from "../views/frontend/reset-password/ResetPassword.vue";

import ReactivateTeam from "../views/piecewalk/reactivate-team/ReactivateTeam";

import { useStore } from "@/store";
import { authRefresh, authLogout } from "@/api/auth.js";

const store = useStore();

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: SideMenu,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "/",
        name: "my-team",
        component: Team
      },
      {
        path: "my-profile",
        name: "my-profile",
        component: MyProfile,
        children: [
          {
            path: "change-password",
            name: "change-password",
            component: ChangePassword
          },
          {
            path: "",
            name: "edit-profile",
            component: ProfileEdit
          }
        ]
      },
      {
        path: "email-team/:teamId",
        name: "email-team",
        component: EmailTeam
      },
      {
        path: "user/:id",
        name: "user-edit",
        component: MyProfile,
        meta: {
          requiresAdmin: true
        },
        children: [
          {
            path: "user-password",
            name: "change-user-password",
            component: ChangePassword
          },
          {
            path: "",
            name: "edit-user-profile",
            component: ProfileEdit
          }
        ]
      },
      {
        path: "config",
        name: "event-config",
        component: ConfigPage,
        meta: {
          requiresAdmin: true
        },
        children: [
          {
            path: "run-settings",
            name: "run-settings",
            component: RunSettings
          },
          {
            path: "badges",
            name: "badge-settings",
            component: BadgeSettings
          },
          {
            path: "product",
            name: "product-settings",
            component: ProductSettings
          },
          {
            path: "captains-tools",
            name: "captains-tools",
            component: CaptainToolsSettings
          },
          {
            path: "captain-alerts",
            name: "captain-alerts",
            component: CaptainAlerts
          },
          {
            path: "constant-contact",
            name: "constant-contact-settings",
            component: ConstantContactSettings
          },
          {
            path: "debug-settings",
            name: "debug-settings",
            component: DebugSettings
          },
          {
            path: "",
            name: "event-settings",
            component: EventSettings
          }
        ]
      },
      {
        path: "kindful",
        name: "kindful",
        component: Kindful,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "admin/reports/teams",
        name: "teamsReport",
        component: TeamsReport,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "admin/reports/lifeChurch",
        name: "lifeChurchReport",
        component: LifeChurchReport,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "admin/reports/runners",
        name: "runnerReport",
        component: RunnerReport,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "admin/reports/products",
        name: "productReport",
        component: ProductReport,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "admin/reports/donations",
        name: "donationReport",
        component: DonationsReport,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "admin/reports/transactions",
        name: "transactionsReport",
        component: TransactionsReport,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "admin/reports/daily-reports",
        name: "dailyReports",
        component: DailyReports,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "teams",
        name: "teams",
        component: Teams,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "teams/new",
        name: "new-team",
        component: NewTeam,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "team/:id",
        name: "edit-team",
        component: Team,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "users",
        name: "users",
        component: Users,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "users/new",
        name: "new-user",
        component: User,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "participants",
        name: "participants",
        component: Participants,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "participants/donors",
        name: "donors",
        component: Donors,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "participants/products",
        name: "products-backend",
        component: TShirts,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "participants/runners",
        name: "runners",
        component: Runners,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "participants/:id",
        name: "participant",
        component: Participant,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "transactions",
        name: "transactions",
        component: Transactions,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "transactions/:id",
        name: "view-transaction",
        component: Transaction,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "create-team",
        name: "create-team",
        component: CreateTeam,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "invoice/:id",
        name: "invoice",
        component: Invoice,
        meta: {
          requiresAdmin: true
        }
      },
      {
        path: "change-password",
        name: "side-menu-change-password",
        component: ChangePassword
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      loginAuthCheck: true
    }
  },
  {
    path: "/backend-team-reactivate/:id/:user",
    name: "backendTeamReactivate",
    component: ReactivateTeam,
    meta: {
      requiresAuth: true,
      reqiresAdmin: true
    }
  },
  {
    path: "/team-reactivate",
    name: "team-reactivate",
    component: ReactivateTeam,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage
  },
  {
    path: "/frontend/create-a-team",
    name: "create-a-team",
    component: TeamCreateFrontEnd
  },
  {
    path: "/frontend/donate",
    name: "donate",
    component: Donate
  },
  {
    path: "/frontend/contact",
    name: "contact",
    component: Contact
  },
  {
    path: "/frontend/summary",
    name: "summary",
    component: Summary
  },
  {
    path: "/frontend/join",
    name: "join-team",
    component: Join
  },
  /* {
    path: "/frontend/shirt",
    name: "shirt",
    component: Shirt
  }, */
  {
    path: "/frontend/products",
    name: "products",
    component: ProductsGrid
  },
  {
    path: "/frontend/products/:productSlug",
    name: "single-product",
    component: Product
  },
  {
    path: "/frontend/run",
    name: "run",
    component: Run
  },
  {
    path: "/frontend/add-team-member",
    name: "add-team-member",
    component: AddTeamMember
  },
  {
    path: "/frontend/supporting",
    name: "supporting",
    component: Supporting
  },
  {
    path: "/frontend/checkout",
    name: "checkout",
    component: Checkout
  },
  {
    path: "/frontend/thank-you",
    name: "thankyou",
    component: ThankYou
  },
  {
    path: "/frontend/constant-contact",
    name: "constantcontact",
    component: ConstantContact
  },
  {
    path: "/frontend/reset-password",
    name: "resetpassword",
    component: ResetPassword
  },
  {
    path: "/icon",
    name: "side-menu-icon",
    component: Icon
  },
  {
    path: "/deactivated",
    name: "deactivated",
    component: Deactivated
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});

const setUpUserData = async user => {
  await store.dispatch("auth/setupUser", user);
};

const hasNotExpired = date => {
  const now = Date.now();
  const expires = new Date(date);
  return expires > now;
};

const setNewTokens = async (refreshToken, userData) => {
  const refreshResponse = await authRefresh(refreshToken);
  userData.tokens = refreshResponse;
  await store.dispatch("auth/setAuth", userData);
  return;
};

const isExpired = async userData => {
  let userTokens = userData.tokens;
  let notExpired = false;
  let refreshNotExpired = false;
  if (userTokens.access) {
    notExpired = hasNotExpired(userTokens.access.expires);
    refreshNotExpired = hasNotExpired(userTokens.refresh.expires);
  }

  //If not expired, should be good to go
  if (notExpired) {
    return false;
  }

  if (!notExpired && refreshNotExpired) {
    //Refresh Token
    console.log("refresh");
    let refreshToken = userTokens.refresh.token;
    await setNewTokens(refreshToken, userData);
    return false;
  }

  console.log("has expired");

  return true;
};

router.beforeEach(async (to, from, next) => {
  //Set User if User is found
  const hasUserData = localStorage.getItem("pwUser");
  let isAuthenticated = await store.getters["auth/isAuthenticated"];
  let userData = {};
  let hasExpired = false;
  if (hasUserData) {
    userData = JSON.parse(hasUserData);
    hasExpired = await isExpired(userData);
  }

  // Set user data if authenticated and not expired
  if (hasUserData && !isAuthenticated && !hasExpired) {
    await setUpUserData(userData);
    isAuthenticated = await store.getters["auth/isAuthenticated"];
  }

  // Check to see if refresh needs to happen
  if (hasExpired) {
    let refreshToken = userData.tokens.refresh.token;
    await authLogout(refreshToken);
  }

  //Redirect Login Page if Logged In
  if (to.matched.some(record => record.meta.loginAuthCheck)) {
    if (isAuthenticated && hasUserData && !hasExpired) {
      next("/");
    } else {
      next();
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    //If User is Not Active
    if (hasUserData && !userData.user.active) {
      // User Not Allowed
      store.dispatch("auth/logout");
      next("/deactivated");
    }
    //If Authenticated and has User Data
    if (isAuthenticated && hasUserData && !hasExpired) {
      if (to.matched.some(record => record.meta.requiresAdmin)) {
        const isAdmin = await store.getters["auth/isAdmin"];
        if (isAdmin) {
          next();
        } else {
          next("/404");
        }
      } else {
        next();
      }
    } else {
      store.dispatch("auth/logout");
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
