<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Transactions</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <div class="mt-4 sm:mt-0">
          <form id="grid-search" class="mr-3">
            <div class="w-64 relative text-gray-700 dark:text-gray-300">
              <input
                v-model="searchQuery"
                type="text"
                class="form-control p-3 w-64 box pr-10 placeholder-theme-13"
                placeholder="Search..."
              />
              <SearchIcon
                class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
              />
            </div>
          </form>
        </div>
        <div class="hidden md:block mx-auto text-gray-600">
          {{ transactionData.totalResults }} Total Transactions
        </div>
      </div>
    </div>
    <div class="intro-y mt-5">
      <TransactionGrid
        :grid-data="formattedTransactions"
        :query="searchQuery"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, computed, reactive, ref } from "vue";
import { useStore } from "@/store";
import { getTransactions } from "@/api/transactions.js";
import TransactionGrid from "@/components/back-end/TransactionsGrid";

export default defineComponent({
  components: {
    TransactionGrid
  },
  setup() {
    const store = useStore();
    let transactionData = reactive([]);
    let formattedTransactions = reactive([]);
    let searchQuery = ref("");
    const appSettings = computed(() => store.getters["main/eventSettings"]);

    const getTransactionData = async eventYear => {
      if (!eventYear) {
        eventYear = appSettings.value.appYear;
      }
      console.log(eventYear);
      const allTransactions = await getTransactions(eventYear);
      Object.assign(transactionData, allTransactions);
      reformattedTransactions(allTransactions.results);
    };

    const reformattedTransactions = theTransactions => {
      let transactions = theTransactions;
      let formattedTransactionData = [];
      transactions.map(transaction => {
        let obj = {};
        obj.active = transaction.active;
        obj.type = transaction.supportingType;
        obj.id = transaction.id;
        obj.eventCity = transaction.eventCity;
        obj.eventYear = transaction.eventYear;
        obj.charged = transaction.totalAmount;
        obj.feesPaid = 0;
        obj.shippingFee = 0;
        if (transaction.totalCharged) {
          obj.charged = transaction.totalCharged.toFixed(2);
        }
        if (transaction.feesCovered && transaction.feesCovered > 0) {
          obj.feesPaid = transaction.feesCovered.toFixed(2);
        }
        if (transaction.shippingFee && transaction.shippingFee > 0) {
          obj.shippingFee = transaction.shippingFee.toFixed(2);
        }
        // obj.externalId = transaction.externalTransactionId;
        obj.amount = transaction.totalAmount;
        obj.name =
          transaction.contactFirstName + " " + transaction.contactLastName;

        formattedTransactionData.push(obj);
      });
      Object.assign(formattedTransactions, formattedTransactionData);
    };

    onMounted(() => {
      getTransactionData();
    });

    return {
      transactionData,
      formattedTransactions,
      searchQuery
    };
  }
});
</script>
