<template>
  <div
    class="flex justify-start items-center col-span-12 rounded intro-y border-2 border-solid border-theme-9 px-3 py-2"
  >
    <div class="inline-block">
      <CheckCircleIcon class="w-6 h-6 mr-4 fill-theme-9 inline-block" />
    </div>
    <div class="donate-info inline-block flex-grow">
      <div v-for="(shirt, index) in shirts" :key="index">
        <h6 class="text-xs">{{ shirt.name }}</h6>
        <h4
          :class="{ 'mb-3 flex items-center': deleteButton }"
          class="text-base"
        >
          <span class="flex-grow">
            {{ shirt.quantity }} @ ${{ shirt.price }}
            <span class="text-sm">(Size: {{ shirt.size }})</span>
          </span>
          <button
            v-show="deleteButton"
            type="button"
            class="btn btn-sm justify-self-end"
            @click="removeShirt(index)"
          >
            <Trash2Icon class="w-4 h-4 justify-self-end" />
          </button>
        </h4>
      </div>
      <div>
        <h6 class="text-xs">+ Shipping @ $8</h6>
      </div>
    </div>
    <div v-if="!deleteButton" class="edit-donate-info justify-self-end">
      <router-link :to="{ name: 'products' }" class="btn btn-sm">
        Edit
      </router-link>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    shirts: {
      type: Array,
      default: function() {
        return [];
      },
      required: false
    },
    deleteButton: {
      // Show Delete Button
      type: Boolean,
      default: function() {
        return false;
      }
    }
  },
  setup() {
    const store = useStore();
    const appTitle = computed(() => store.getters["main/appTitle"]);
    const appYear = computed(() => store.getters["main/appYear"]);

    const removeShirt = async shirtIndex => {
      await store.dispatch("shirt/removeShirt", shirtIndex);
      console.log("removed shirt");
    };

    return {
      appTitle,
      appYear,
      removeShirt
    };
  }
});
</script>
