<template>
  <div>
    <label
      for="team_name"
      class="form-label placeholder-label relative w-full mt-3 text-base"
    >
      <input
        id="teamName"
        v-model.trim="validate.team_name.$model"
        type="text"
        class="form-control p-3"
        :class="{ 'border-theme-6': validate.team_name.$error }"
        placeholder=" "
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <span
        class="p-3 absolute opacity-50 left-0 top-0 transition-all duration-300 ease-in-out text-base"
        >Team Name</span
      >
    </label>
    <div
      v-for="error of validate.team_name.$errors"
      :key="error.$uid"
      class="input-errors"
    >
      <div class="error-msg text-theme-6 mt-2">
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { required, minLength, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
  props: {
    touch: {
      type: Boolean,
      required: false
    }
  },
  setup: () => ({ validate: useVuelidate() }),
  data() {
    return {
      team_name: ""
    };
  },
  watch: {
    touch: function(val) {
      if (val) {
        this.validate.team_name.$touch();
      }
    }
  },
  validations() {
    const noSpecialStart = value => {
      return /^[a-zA-Z0-9](.*[a-zA-Z0-9])?/.test(value);
    };
    return {
      team_name: {
        required,
        minLength: minLength(2),
        noSpecailStart: helpers.withMessage(
          "Team name cannot start with a special character.",
          noSpecialStart
        )
      }
    };
  }
});
</script>
