<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-8">
      <div class="col-span-12 flex justify-center items-center">
        <router-link
          :to="{ name: 'teams' }"
          class="btn btn-outline shadow-md mr-2"
          ><ChevronLeftIcon class="h-4 w-4 mr-1" />All Teams</router-link
        >
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 md:mx-auto md:w-1/2">
        <h2 class="intro-y text-lg font-medium mt-10 mb-6">New Team</h2>
        <div class="intro-y box mt-2">
          <div class="p-8 text-base">
            <h3 class="font-bold">
              Team Details
            </h3>
            <form class="validate-form" @submit.prevent="createNewTeam">
              <div class="p-12 px-0">
                <div class="grid grid-cols-12 gap-x-5">
                  <div class="intro-y col-span-12 sm:col-span-6">
                    <TeamNameField v-model="teamData.name" />
                  </div>
                  <div class="intro-y col-span-12 sm:col-span-6">
                    <TeamCityField v-model="teamData.eventCity" />
                  </div>
                  <div class="intro-y col-span-12 sm:col-span-12 mt-6">
                    <TeamMessageField v-model="teamData.teamMessage" />
                    <p class="text-sm italic text-gray-600">
                      Tell us about your team and why you are creating one!
                    </p>
                  </div>
                  <div class="capatin intro-y col-span-6 mt-6">
                    <div>
                      <label for="captin" class="form-label text-base"
                        >Team Captain</label
                      >
                      <select
                        id="captain"
                        v-model.trim="teamData.captain"
                        class="form-select mt-2 sm:mr-2"
                        aria-label="captain"
                        :value="teamData.captain"
                        required
                      >
                        <option
                          v-for="captain in formattedUsers"
                          :key="captain.id"
                          :value="captain.id"
                          @change="onChangeCaptain($event)"
                          >{{ captain.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end mt-8">
                  <button
                    :disabled="validate.$errors.length > 0"
                    type="submit"
                    class="btn btn-primary w-60 mr-auto"
                  >
                    Create Team
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "@/store";
import { createTeamWithCaptain } from "@/api/teams";
import { getUsers } from "@/api/users.js";
import TeamNameField from "@/components/form-inputs/TeamName";
import TeamCityField from "@/components/form-inputs/TeamCity";
import TeamMessageField from "@/components/form-inputs/TeamMessage";

export default defineComponent({
  components: {
    TeamNameField,
    TeamCityField,
    TeamMessageField
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const validate = useVuelidate();
    let teamData = reactive({});

    let userData = reactive([]);
    let formattedUsers = reactive([]);

    const getUserData = async () => {
      const allUsers = await getUsers();
      Object.assign(userData, allUsers);
      reformattedUsers(allUsers.results);
    };

    onMounted(() => {
      getUserData();
    });

    const reformattedUsers = theUsers => {
      let users = theUsers;
      let formattedUserData = [];
      users.map(user => {
        let obj = {};
        obj.active = user.active;
        obj.id = user.id;
        obj.name = "No Person Assigned";
        obj.email = user.email;
        obj.role = user.role;
        obj.team = "Unassigned";

        if ("team" in user && user.team !== null) {
          obj.team = user.team.name;
        }

        //Has to have a person
        if ("person" in user) {
          obj.name = user.person.firstName + " " + user.person.lastName;
          obj.personId = user.person.id;
          formattedUserData.push(obj);
        }
      });
      Object.assign(formattedUsers, formattedUserData);
    };

    const onChangeCaptain = event => {
      teamData.captain.value = event.target.value;
    };

    const sendNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "New team has been created."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem creating the team."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const createNewTeam = async () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        console.log("invalid");
        sendNotification({ code: true });
      } else {
        console.log(teamData);
        const saveTeamResult = await createTeamWithCaptain(teamData);
        console.log(saveTeamResult);
        sendNotification(saveTeamResult);
        if (!saveTeamResult.code) {
          router.push({ name: "teams" });
        }
      }
    };

    return {
      teamData,
      formattedUsers,
      onChangeCaptain,
      createNewTeam,
      validate
    };
  }
});
</script>
<style scoped>
.person-table td {
  border-bottom: 1px solid #ddd;
}
</style>
