<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Life Church Report</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <div class="hidden md:block mx-auto text-gray-600">
          {{ participantRowData.totalResults }} Total Life Church Participants
        </div>
      </div>
    </div>
    <div class="intro-y mt-5 h-full col-span-12">
      <div class="grid grid-cols-12 gap-6 mt-5 mb-5">
        <div class="col-span-3">
          <!--<select
            v-model="selectedYear"
            class="form-select text-base"
            @change="updateLifeChurchData(selectedYear)"
          >
            <option
              v-for="(year, index) in eventYears"
              :key="index"
              :value="year"
            >
              {{ year }}
            </option>
          </select>-->
        </div>
        <div class="col-span-9">
          <div class="mb-2 text-right">
            <button class="btn btn-outline mr-6" @click="resetFilters()">
              Reset Filters
            </button>
            <button class="btn btn-outline" @click="exportToCSV()">
              Download CSV export file
            </button>
          </div>
        </div>
      </div>
      <AgGridVue
        class="ag-theme-alpine w-full the-report-grid"
        :column-defs="columnDefs"
        :default-col-def="defaultColDef"
        :row-data="participantRowData.results"
        @grid-ready="onGridReady"
      >
      </AgGridVue>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { getLifeChurchParticipants } from "@/api/reports.js";
import { eventYears } from "@/api/event-years.js";

export default defineComponent({
  components: {
    AgGridVue
  },
  setup() {
    let participantRowData = reactive({});
    let gridApi = null;
    // eslint-disable-next-line no-unused-vars
    let gridColumnApi = null;

    let selectedYear = ref(eventYears[0]);
    const setLifeChurchData = async () => {
      const theParticipants = await getLifeChurchParticipants(
        selectedYear.value
      );
      Object.assign(participantRowData, theParticipants);
    };

    const updateLifeChurchData = async eventYear => {
      const theParticipants = await getLifeChurchParticipants(eventYear);
      Object.assign(participantRowData, theParticipants);
    };

    const resetFilters = async () => {
      selectedYear.value = eventYears[0];
      await setLifeChurchData();
    };

    onMounted(async () => {
      await setLifeChurchData();
    });

    let defaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true
    };

    const columnDefs = [
      {
        headerName: "First",
        field: "firstName",
        sortable: true,
        filter: true,
        pinned: "left"
      },
      {
        headerName: "Last",
        field: "lastName",
        sortable: true,
        filter: true,
        pinned: "left"
      },
      { headerName: "Email", field: "email" },
      { headerName: "Phone", field: "phone" },
      {
        headerName: "LifeChurch",
        field: "lifeChurch",
        sortable: true,
        filter: true
      },
      {
        headerName: "LifeChurch Campus",
        field: "lifeChurchLocation",
        sortable: true,
        filter: true
      },
      {
        headerName: "Created",
        field: "createdAt",
        sortable: true,
        // eslint-disable-next-line no-undef
        valueFormatter: dateFormatter
      }
    ];

    const onGridReady = params => {
      gridApi = params.api;
      gridColumnApi = params.columnApi;
    };

    const exportToCSV = () => {
      /** const params = {
        columnSeparator: "|"
      }; **/
      gridApi.exportDataAsCsv();
    };

    return {
      columnDefs,
      participantRowData,
      onGridReady,
      exportToCSV,
      defaultColDef,
      resetFilters,
      eventYears,
      selectedYear,
      updateLifeChurchData
    };
  }
});
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
.the-report-grid {
  height: 800px;
}
</style>
