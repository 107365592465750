import { fetch } from "@/api/fetch-auto-refresh";
import { useStore } from "@/store";
const apiURL = process.env.VUE_APP_API_URL;
const store = useStore();

const getKindfulRequests = async (eventYear, page) => {
  let authToken = store.state.auth.token;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  if (!page) {
    page = 1;
  }

  let requestParams = `/v1/kindful/?limit=25&page=${page}&sortBy=createdAt:desc`;

  if (eventYear) {
    requestParams = `/v1/kindful/?eventYear=${eventYear}&limit=25&page=${page}&sortBy=createdAt:desc`;
  }

  const response = await fetch(apiURL + requestParams, requestOptions);
  const data = await response.json();
  return data;
};

const trySingleKindfulImport = async kindfulId => {
  let authToken = store.state.auth.token;
  const requestOptions = {
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  const response = await fetch(
    `${apiURL}/v1/kindful/${kindfulId}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

const checkKindfulImportStatus = async (jobId, kindfulId) => {
  let authToken = store.state.auth.token;
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    },
    body: JSON.stringify({ kindfulId: kindfulId })
  };

  const response = await fetch(
    `${apiURL}/v1/kindful/${jobId}/status/`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

export { getKindfulRequests, trySingleKindfulImport, checkKindfulImportStatus };
