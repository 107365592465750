import * as types from "./mutation-types";

const state = () => {
  return {
    donation: {
      donation_amount: 0
    }
  };
};

// getters
const getters = {
  donation: state => state.donation
};

// actions
const actions = {
  setDonation({ commit }, donation) {
    commit(types.SET_DONATION, { donation });
  },
  resetDonation({ commit }) {
    commit(types.RESET_DONATION);
  }
};

// mutations
const mutations = {
  [types.SET_DONATION](state, { donation }) {
    state.donation = donation;
  },
  [types.RESET_DONATION](state) {
    state.donation = {
      donation_amount: 0
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
