<template>
  <form
    class="validate-form intro-y box mt-5 p-6 max-w-3xl mx-auto"
    @submit.prevent="addMember(member)"
  >
    <h3 class="text-base font-bold mb-6">Add a Team Member</h3>
    <div class="form">
      <BasicInfo
        :key="componentKey"
        v-model:firstName="member.firstName"
        v-model:lastName="member.lastName"
        v-model:emailAddress="member.email"
        v-model:phoneNumber="member.phone"
        v-model:myEmployer="member.myEmployer"
        phone-not-required
        show-employer
      />
      <LifeChurchField
        :key="componentKey"
        v-model:lifeChurch="member.lifeChurch"
        v-model:lifeChurchCampus="member.lifeChurchCampus"
        class="mt-4 hidden"
      />
    </div>
    <div class="action mt-6">
      <button
        class="btn btn-primary"
        type="submit"
        :disabled="validate.$errors.length > 0"
      >
        Add Team Member
      </button>
    </div>
  </form>
</template>

<script>
import { defineComponent, computed, reactive, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "@/store";
import BasicInfo from "@/components/form-inputs/BasicInfo";
import LifeChurchField from "@/components/form-inputs/LifeChurch";
import { createPerson } from "@/api/participants.js";
export default defineComponent({
  components: {
    BasicInfo,
    LifeChurchField
  },
  emits: ["setTeamData"],
  setup(props, { emit }) {
    const store = useStore();
    const appSettings = computed(() => store.getters["main/eventSettings"]);
    const team = computed(() => store.getters["backEndTeam/currentTeam"]);
    let initMember = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      myEmployer: "",
      lifeChurch: false,
      lifeChurchCampus: {},
      eventYear: appSettings.value.appYear
    };
    let member = reactive({ ...initMember });

    const resetMemberForm = () => {
      Object.assign(member, initMember);
      forceUpdate();
    };

    /**
     * This is a hack to force reload the component after a sumbission
     */
    let componentKey = ref(0);
    const forceUpdate = () => {
      componentKey.value += 1;
    };

    const validate = useVuelidate();

    const addMember = async member => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        console.log("invalid");
      } else {
        member.team = team.value.id;
        const newPerson = (({
          firstName,
          lastName,
          email,
          lifeChurch,
          team,
          eventCity
        }) => ({
          firstName,
          lastName,
          email,
          lifeChurch,
          team,
          eventCity
        }))(member);

        if (member.phone) {
          newPerson.phone = member.phone;
        }
        if (member.myEmployer) {
          newPerson.myEmployer = member.myEmployer;
        }
        if (member.lifeChurchCampus) {
          newPerson.lifeChurchLocation = member.lifeChurchCampus.name;
        }

        await createPerson(newPerson).then(person => {
          emit("setTeamData");
          resetMemberForm();
          console.log(person);
        });
      }
    };
    return {
      addMember,
      member,
      validate,
      componentKey
    };
  }
});
</script>
