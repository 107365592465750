import { fetch } from "@/api/fetch-auto-refresh";
import { useStore } from "@/store";
const store = useStore();
const apiURL = process.env.VUE_APP_API_URL;

const createUser = async user => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user)
  };

  const response = await fetch(apiURL + "/v1/users", requestOptions);
  const data = await response.json();
  return data;
};

const getUsers = async page => {
  let authToken = store.state.auth.token;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  if (!page) {
    page = 1;
  }

  let requestParams = `/v1/users/?populate=team,person&limit=100&page=${page}&sortBy=createdAt:desc`;

  const response = await fetch(apiURL + requestParams, requestOptions);
  const data = await response.json();
  return data;
};

const getAllUsers = async () => {
  let authToken = store.state.auth.token;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  let requestParams = `/v1/users/?populate=team,person&limit=400&sortBy=createdAt:desc`;

  const response = await fetch(apiURL + requestParams, requestOptions);
  const data = await response.json();
  return data;
};

const getUser = async user => {
  let authToken = store.state.auth.token;
  let userID = store.state.auth.user.id;
  if (user) {
    userID = user; //If we pass in the ID set that as the team
  }

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  const response = await fetch(`${apiURL}/v1/users/${userID}`, requestOptions);
  const data = await response.json();
  return data;
};

const updateUser = async (user, userId) => {
  let authToken = store.state.auth.token;

  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    },
    body: JSON.stringify(user)
  };

  const response = await fetch(`${apiURL}/v1/users/${userId}`, requestOptions);
  const data = await response.json();
  return data;
};

const userExistsWithTeam = async email => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ email: email })
  };

  const response = await fetch(`${apiURL}/v1/users/exists/`, requestOptions);
  const data = await response.json();
  return data;
};

export {
  createUser,
  getUsers,
  getAllUsers,
  getUser,
  updateUser,
  userExistsWithTeam
};
