import { fetch } from "@/api/fetch-auto-refresh";
import { useStore } from "@/store";
const apiURL = process.env.VUE_APP_API_URL;

const createTeam = async teamData => {
  console.log("createTeam", teamData);

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(teamData)
  };

  const response = await fetch(apiURL + "/v1/teams", requestOptions);
  const data = await response.json();
  return data;
};

const createTeamWithCaptain = async teamData => {
  const store = useStore();
  let authToken = store.state.auth.token;
  console.log("createTeamWithCaptain", teamData);

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    },
    body: JSON.stringify(teamData)
  };

  const response = await fetch(apiURL + "/v1/teams/new-team", requestOptions);
  const data = await response.json();
  return data;
};

const getTeam = async team => {
  const store = useStore();
  let authToken = store.state.auth.token;
  let teamID = store.state.auth.user.team;
  if (team) {
    teamID = team; //If we pass in the ID set that as the team
  }

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  const response = await fetch(`${apiURL}/v1/teams/${teamID}`, requestOptions);
  const data = await response.json();
  return data;
};

const getTeams = async page => {
  const store = useStore();
  let authToken = store.state.auth.token;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + authToken
    }
  };

  let limit = 200;

  if (!page) {
    page = 1;
    limit = 400;
  }

  let requestParams = `/v1/teams/?populate=captainPerson&limit=${limit}&sortBy=totalRaised:desc,active:desc&page=${page}`;

  const response = await fetch(apiURL + requestParams, requestOptions);
  const data = await response.json();
  return data;
};

const getTeamList = async () => {
  const requestOptions = {
    method: "GET"
  };

  const response = await fetch(`${apiURL}/v1/teams/list`, requestOptions);
  const data = await response.json();
  return data;
};

const updateTeam = async (team, teamId) => {
  const store = useStore();
  let authToken = store.state.auth.token;

  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    },
    body: JSON.stringify(team)
  };

  const response = await fetch(`${apiURL}/v1/teams/${teamId}`, requestOptions);
  const data = await response.json();
  return data;
};

const updateTeamCaptain = async (teamId, captain) => {
  const store = useStore();
  let authToken = store.state.auth.token;

  let captainReq = {
    captain: captain
  };

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    },
    body: JSON.stringify(captainReq)
  };

  const response = await fetch(
    `${apiURL}/v1/teams/captain/${teamId}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

const reactivateTeam = async (
  teamId,
  captainData,
  teamData,
  updateMembers,
  removeMembers,
  updateMemberEmails
) => {
  const store = useStore();
  let authToken = store.state.auth.token;

  let reactivateReq = {
    captain: captainData,
    team: teamData,
    updateMembers: updateMembers,
    removeMembers: removeMembers,
    updateMemberEmails: updateMemberEmails
  };

  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    },
    body: JSON.stringify(reactivateReq)
  };

  const response = await fetch(
    `${apiURL}/v1/teams/reactivate/${teamId}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

const setTeamMembersToNewYear = async teamId => {
  const store = useStore();
  let authToken = store.state.auth.token;
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    }
  };

  const response = await fetch(
    `${apiURL}/v1/teams/event-year-update/${teamId}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

const resetTeam = async teamId => {
  const store = useStore();
  let authToken = store.state.auth.token;

  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    }
  };

  const response = await fetch(
    `${apiURL}/v1/teams/reset/${teamId}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

const getTeamHistory = async teamId => {
  const store = useStore();
  let authToken = store.state.auth.token;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    }
  };

  const response = await fetch(
    `${apiURL}/v1/teams/history/${teamId}`,
    requestOptions
  );
  const data = await response.json();
  return data;
};

export {
  createTeam,
  createTeamWithCaptain,
  getTeam,
  getTeams,
  getTeamList,
  updateTeam,
  updateTeamCaptain,
  reactivateTeam,
  setTeamMembersToNewYear,
  resetTeam,
  getTeamHistory
};
