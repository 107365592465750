<template>
  <div class="sm:flex justify-between items-center flex-wrap col-span-12">
    <router-link
      v-show="!run.runners.length > 0 && appSettings.has5k"
      to="/frontend/run/"
      class="mt-2 block btn btn-lg btn-outline border-red text-red flex-grow hover:bg-red hover:text-white sm:mr-6"
      >Run the 5k</router-link
    >
    <router-link
      v-show="!shirts.length > 0 && appSettings.hasEventShirt"
      :to="{ name: 'products' }"
      class="mt-2 block btn btn-lg btn-outline border-red text-red flex-grow hover:bg-red hover:text-white sm:mr-6"
    >
      Buy a T-Shirt</router-link
    >
    <router-link
      v-show="!donation.donation_amount > 0"
      to="/frontend/donate/"
      class="mt-2 block btn btn-lg btn-outline border-red text-red flex-grow hover:bg-red hover:text-white sm:mr-6"
    >
      Donate</router-link
    >
    <router-link
      v-show="
        !teamMembers.members.length > 0 &&
          supporting.type === 'Team' &&
          !donation.donation_amount > 0
      "
      to="/frontend/add-team-member/"
      class="mt-2 block btn btn-lg btn-outline border-red text-red flex-grow hover:bg-red hover:text-white sm:mr-6"
    >
      Add a Team Member</router-link
    >
    <router-link
      v-show="supporting.type !== 'Team'"
      to="/frontend/join/"
      class="mt-2 block btn btn-lg btn-outline border-red text-red flex-grow hover:bg-red hover:text-white sm:mr-6"
    >
      Join a Team</router-link
    >
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {},
  setup() {
    const store = useStore();
    const appSettings = computed(() => store.getters["main/eventSettings"]);
    const donation = store.state.donate.donation;
    const shirts = store.state.shirt.shirts;
    const run = store.state.run.runData;
    const teamMembers = store.state.teamMembers.teamMembers;
    const supporting = store.state.support.supporting;

    return {
      appSettings,
      donation,
      shirts,
      run,
      teamMembers,
      supporting
    };
  }
});
</script>
