<template>
  <div>
    <FrontEndHeader fill="#be2032" />
    <!-- BEGIN: Create JoinTeamLayout -->
    <form class="validate-form" @submit.prevent="save">
      <div class="intro-y box mt-5 pb-10 max-w-3xl mx-auto">
        <div
          class="font-bold text-3xl px-5 sm:px-20 py-10 pb-5 sm:py-10 rounded-t"
        >
          {{ page.title }}
        </div>
        <div class="px-5 sm:px-20">
          <div
            v-show="captain.person && captain.role !== 'admin'"
            class="md:grid md:grid-cols-3 md:gap-6"
          >
            <div class="md:col-span-3">
              <div class="px-0 sm:pr-8 mb-6 alert show alert-warning px-6">
                <h3 class="text-lg font-medium leading-6">
                  You are currently logged in as a Team Captain and part of a
                  team.
                </h3>
                <p class="mt-1 text-sm">
                  You can join a different team as a team member, but you should
                  first logout.
                </p>
                <p class="mt-2 text-sm">
                  If you are looking to add a team member to your own team,
                  please go to your team dashboard and select Team Members.
                </p>
              </div>
              <button
                type="button"
                class="btn btn-lg w-full sm:w-auto block sm:inline-block border-red text-red btn-outline mr-2"
                @click="logout()"
              >
                Logout
              </button>
              <router-link
                type="button"
                class="btn block w-full sm:inline-block sm:w-auto btn-red border-reddark btn-lg"
                target="_blank"
                :to="{ name: 'my-team' }"
              >
                Dashboard
              </router-link>
            </div>
          </div>
          <div
            v-show="!captain.person || captain.role == 'admin'"
            class="md:grid md:grid-cols-3 md:gap-6"
          >
            <div class="md:col-span-3">
              <div class="px-0 sm:pr-8">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Which team would you like to join?
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Please select a team from the list below.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-3">
              <Supporting
                v-model:supportType="supporting.type"
                v-model:eventCity="supporting.eventDetails"
                v-model:teamSelected="supporting.teamDetails"
                :support-type="supporting.type"
                :event-city-value="supporting.eventDetails"
                :team-selected="supporting.teamDetails"
                team-only
              />
            </div>
          </div>
          <div
            class="intro-y col-span-12 flex items-center justify-center mt-6"
          >
            <button
              v-show="
                (supporting.teamDetails.name && supporting.type === 'Team') ||
                  (supporting.eventDetails.city && supporting.type === 'Event')
              "
              type="button"
              class="btn block w-full intro-y py-3 sm:inline-block sm:w-auto btn-red border-reddark sm:ml-2 sm:px-10 btn-lg"
              :disabled="validate.$errors.length > 0"
              @click="setSupporting()"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
    <CancelButton />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { defineComponent, onMounted, computed, toRefs } from "vue";
import { useStore } from "@/store";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { authLogout } from "@/api/auth.js";
import FrontEndHeader from "@/components/front-end/FrontEndHeader";
import Supporting from "@/components/form-inputs/Supporting";
import CancelButton from "@/components/front-end/CancelButton";

export default defineComponent({
  components: {
    FrontEndHeader,
    Supporting,
    CancelButton
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const appTitle = computed(() => store.getters["main/appTitle"]);
    const appYear = computed(() => store.getters["main/appYear"]);
    const supporting = store.state.support.supporting;

    const captain = store.state.auth.user;

    const setSupporting = () => {
      store.dispatch("support/setSupporting", supporting);
      router.push({ path: "/frontend/contact", query: { join: true } });
    };

    if (route.query.team) {
      let teamId = route.query.team;
      let teamName = route.query.name;
      let city = route.query.city;
      supporting.type = "Team";
      supporting.teamDetails.id = teamId;
      supporting.teamDetails.name = teamName;
      supporting.teamDetails.city = city;
      delete supporting.teamDetails.value;
      setSupporting();
    }

    const page = {
      title: "Join a Team"
    };

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("frontend create-team");
    });

    const rules = computed(() => {
      return {
        donate_type: {
          required
        }
      };
    });

    const validate = useVuelidate(rules, toRefs(supporting));

    const logout = async () => {
      const refreshToken = store.state.auth.refreshToken;
      await authLogout(refreshToken);
      await store.dispatch("auth/logout");
      router.push({ path: "/login" });
    };

    return {
      page,
      validate,
      appTitle,
      appYear,
      supporting,
      setSupporting,
      captain,
      logout
    };
  }
});
</script>
